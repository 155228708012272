import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { usePallet } from "../../../../contexts/PalletContext";
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import RelatorioService from "../../../../services/RelatoriosService";
import { format, subYears } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ImageLoader from '../../../../components/ImageLoader';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styled from "styled-components";
import { formatDate } from '../../../../utils/dateUtils';
import PieChartInfoRelatorioParlamentar from './PieChartInfoRelatorioParlamentar';
import { IParlamentar } from '../../../../models/Parlamentar.Model';
import ParlamentarService from '../../../../services/ParlamentarService';
import { calculaIdade } from "../../../../utils/dateUtils";
import ParlamentarDemandaService from '../../../../services/ParlamentarDemandaService';
import DoughnutParlamentarDemandas from '../DoughnutParlamentarDemandas';
import ParlamentarAcaoInfluenciaService from '../../../../services/ParlamentarAcaoInfluenciaService';
import PieChartParlamentarAcoesInfluencia from '../PieChartParlamentarAcoesInfluencia';
import PieChartParlamentarAcoesInfluenciaRelatorio from '../PieChartParlamentarAcoesInfluenciaRelatorio';
import DoughnutChartParlamentarProposicao from '../DoughnutChartParlamentarProposicao';
import ParlamentarProposicaoService from '../../../../services/ParlamentarProposicaoService';
import DoughnutParlamentarDiscurso from '../DoughnutParlamentarDiscurso';
import ParlamentarDiscursoService from '../../../../services/ParlamentarDiscursoService';
import PieChartParlamentarDiscursoRelatorio from '../DoughnutParlamentarDiscursoRelatorio';
import { IFilterData } from '../../../../components/filter/ListFilter';
import ParlamentarAmbitoService from '../../../../services/ParlamentarAmbitoService';
import ParlamentarOutrosService from '../../../../services/ParlamentarOutrosService';

export default function RelatorioInterlocutor() {



    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(true)
    const [parlamentar, setParlamentar] = React.useState<IParlamentar>()
    const [countExito, setCountExito] = useState<any>();
    const [countExitoParcial, setCountExitoParcial] = useState<any>();
    const [countSemExito, setCountSemExito] = useState<any>();
    const [countPendente, setCountPendente] = useState<any>();
    const [filtrores, setFiltrores] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);
    const [filtroint, setFiltroint] = useState<Date>(new Date());
    const [sumaryint, setSumaryint] = useState([{ ano: '', mes: '', qtd: '' }]);
    const [sumaryres, setSumaryres] = useState({ Total: 0, Pendente: 0, Exito: 0, SemExito: 0, ExitoParcial: 0 });
    const [sumaryProposicao, setSumaryProposicao] = useState({ total: 0, seguindo: 0, posicionamento: [] });
    const [listaProposicao, setListaProposicao] = useState([]);
    const [summaryDiscurso, setSummaryDiscurso] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [filtroDiscursoLista, setFiltroDiscursoLista] = useState<IFilterData[]>([{ key: 'qualificacao', label: 'Qualificação', value: 'T' }]);
    const [listaComissao, setListaComissao] = useState([]);
    const [filtroComissao, setFiltroComissao] = useState<string>('A');
    const [outros, setOutros] = useState<{ eventos: Number, frentes: Number, mandatos: Number, historicos: Number }>({ eventos: 0, frentes: 0, mandatos: 0, historicos: 0 });
    const [listaFrentes, setListaFrentes] = useState([]);
    const [listaEventos, setListaEventos] = useState([]);


    interface IlistaDemanda {
        assunto?: string,
        autor?: string,
        conclusao?: string,
        prioridade?: string,
        criado_em?: Date,
        departamento?: string,
        objetivo?: string,
        id: number,
        id_autor: number,
        prazo: Date,
        status?: string,
        resultado?: string,
        excluidos?: boolean,
        posicionamentos?: Array<{
            departamento?: string,
            id: number,
            id_demanda: number,
            id_responsavel: number,
            impacto?: string,
            posicionamento?: string,
            responsavel?: string,
            status?: string,
            tipo_impacto?: number,
            observacao?: string,
            confirmacao?: string,
            data_recebimento?: Date,
        }>
    }


    const [lista, setLista] = useState([]);
    const [listaAcaoInfluencia, setListaAcaoInfluencia] = useState([]);
    const [filtroDiscurso, setFiltroDiscurso] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);
    const [listaDiscurso, setListaDiscurso] = useState([]);
    const [changeOutros, setChangeOutros] = React.useState("")





    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter)

    //const [resTots, setResTots] = useState([])
    const [resList, setResList] = useState([])

    //let esferaTipo = filter.substring(27, 29)
    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };

    // let esferaTipo = (filtrotitle.length>0 ? filtrotitle[0].value : "Geral")

    const Imprimir = () => {
        window.print()
    }


    const Assunto = styled.div`
    font-size: ${pallet.general.size12};
    color: ${pallet.color.secundary.font};
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    align-self: center;
    max-height: ${isMobile ? "80px" : "none"};
    max-width: ${isMobile ? "200px" : "550px"}; 
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    margin-left: ${isMobile ? "10px" : "0px"};
    `;

    const ParlamentarConsultaSpanCustom = (props) => {
        const { pallet } = usePallet()
        return (
            <span style={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontWeight: pallet.fontWeight.subtitle,
                fontSize: `${pallet.fontSize.smallButton}`,
                ...props?.style
            }}>
                {props?.children}
            </span>
        )
    }

    const CustomGrid = (props: { children: React.ReactNode, style?: any }): JSX.Element => {
        const { pallet } = usePallet();
        return (
            <Grid style={{
                fontWeight: pallet.fontWeight.subtitle,
                fontSize: `${pallet.fontSize.text_10}`,
                ...props.style
            }}>
                {props.children}
            </Grid>
        )
    }

    const idadeParlamentar = calculaIdade(new Date(parlamentar?.data_nascimento))

    const { id } = useParams();
    const { statusDemandaDados } = useParams();
    const { statusDemandaLista } = useParams();
    const { statusAcaoInfluenciaDados } = useParams();
    const { statusAcaoInfluenciaLista } = useParams();
    const { statusProposicaoDados } = useParams();
    const { statusProposicaoLista } = useParams();
    const { statusComissaoLista } = useParams();
    const { statusDiscursoDados } = useParams();
    const { statusDiscursoLista } = useParams();
    const { statusOutrosFrentes } = useParams();
    const { statusOutrosEventos } = useParams();

    React.useEffect(() => {
        if (id) {
            setTimeout(() => {

                loadParlamentar()
                getDemandasSummary();
                getDemandasList();
                getDataRes();
                getDataInt();
                getListAcaoInfluencia();

            }, 1000);

            setTimeout(() => {
                getListProposicaoParlamentarLista();
                getSummaryProposicaoParlamentar();
                getSummaryDiscurso();
                getListDiscurso();
                getListComissao();

            }, 3000);


            setTimeout(() => {

                getListFrentes();
                getListEventos();
            }, 5000)
        }
    }, [id])


    React.useEffect(() => {
        getSummaryDiscurso();
    }, [filtroDiscurso])


    const getDemandasSummary = () => {
        ParlamentarDemandaService.GetSummary(Number(id)).then(async res => {
            if (res) {
                let pendente = res.filter(item => item.resultado === 0 || item.resultado === null).length === 0 ? 0 : parseInt(res.filter(item => item.resultado === 0 || item.resultado === null)[0]?.count)
                let exito = res.filter(item => item.resultado === 1).length === 0 ? 0 : parseInt(res.filter(item => item.resultado === 1)[0]?.count)
                let semExito = res.filter(item => item.resultado === 2).length === 0 ? 0 : parseInt(res.filter(item => item.resultado === 2)[0]?.count)
                let exitoParcial = res.filter(item => item.resultado === 3).length === 0 || null || undefined || NaN ? 0 : parseInt(res.filter(item => item.resultado === 3)[0]?.count)

                setCountExito(exito ? exito : 0)
                setCountExitoParcial(exitoParcial ? exitoParcial : 0)
                setCountSemExito(semExito ? semExito : 0)
                setCountPendente(pendente ? pendente : 0)

                //             setLoading(false)
            }
        })
    }
    // eslint-disable-next-line


    const loadParlamentar = () => {

        ParlamentarService
            .Get(Number(id))
            .then(parlamentar => {
                setParlamentar(parlamentar)
            });
    }

    const getDemandasList = () => {
        ParlamentarDemandaService.GetList(Number(id), "T", 1, 5).then(async res => {
            if (res && res.success) {
                setLista(res.lista)
                setTimeout(() => {
                    setLoading(false);
                    //setLoadingAfterSalvar(false)
                }, 1000);
                //setLoading(false);
            }
        })
    }

    const getDataRes = () => {

        if (id) {

            ParlamentarAcaoInfluenciaService.GetResultadoSummary(Number(id), [
                { key: 'dtIni', value: format(filtrores[0], 'yyyy-MM-dd 00:00:00') },
                { key: 'dtFin', value: format(filtrores[1], 'yyyy-MM-dd 23:59:59') },
            ])
                .then(async res => {
                    setSumaryres(() => res)
                    //setLoading(false)
                })
        }

    }

    const getDataInt = () => {

        if (id) {


            ParlamentarAcaoInfluenciaService.GetInteracoesSummary(Number(id), [
                { key: 'ano', value: format(filtroint, 'yyyy') },
            ])
                .then(async res => {

                    setSumaryint(() => res)
                    //setLoading(false)
                })
        }

    }


    const getListAcaoInfluencia = () => {



        if (id)
            ParlamentarAcaoInfluenciaService.GetList(Number(id), "T", 1, 5).then(async res => {
                setListaAcaoInfluencia(res?.lista);



            })
    }

    const getSummaryProposicaoParlamentar = () => {
        ParlamentarProposicaoService.GetSummary(Number(id)).then(async res => {
            if (res && res.success) {
                setSumaryProposicao(res.data);

            }
        })
    }

    const getListProposicaoParlamentarLista = () => {

        ParlamentarProposicaoService.GetList(Number(id), 1, 5).then(async res => {
            if (res && res.success) {
                setListaProposicao(res?.lista);

            }
        })
    }




    const CheckEsfera = (select) => {
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }

    const resultadoDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Êxito";
            case 2:
                return "Sem êxito";
            case 3:
                return "Êxito parcial";
            case 4:
                return "Pendente";
            default:
                return "Não definido";
        }
    };

    const resultadoColor = (value: string) => {
        switch (value) {
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Êxito":
                return `${pallet.charts.green}`;
            case "Sem êxito":
                return `${pallet.charts.red}`;
            case "Êxito parcial":
                return `${pallet.charts.blue}`;
            default:
                return "white";
        }
    };

    const posicionamentoDescricao = (value: number) => {
        switch (value) {
            case 0:
                return "Indefinido";
            case 1:
                return "Contrário";
            case 2:
                return "Contrário com emenda saneadora";
            case 3:
                return "Neutro";
            case 4:
                return "Favorável com emenda saneadora";
            case 5:
                return "Favorável";
            case 6:
                return "Favorável ao parecer do relator";
            case 7:
                return "Favorável ao parecer do relator com emenda";
            case 8:
                return "Contrário ao parecer do relator";
            case 9:
                return "Contrário ao parecer do relator com emenda";
            case 99:
                return "Pendente";
            default:
                return "Indefinido";
        }
    };

    const posicionamentoColor = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const prioridadeDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Mínima";
            case 2:
                return "Baixa";
            case 3:
                return "Média";
            case 4:
                return "Alta";
            case 5:
                return "Máxima";
            default:
                return "Não definido";
        }
    };


    const qualificacaoColor = (value: string) => {
        switch (value) {
            case "Convergente":
                return `${pallet.charts.green}`;
            case "Divergente":
                return `${pallet.charts.red}`;
            case "Não Classificado":
                return `${pallet.charts.purple}`;
            case "Neutro":
                return `${pallet.charts.lightGrey}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const setFilterDateRange = (ini, fin) => {
        setFiltrores(() => [ini, fin])
    }

    const setFilterDateYear = (dtyear) => {
        setFiltroint(() => dtyear)
    }


    const setFilterDate = (ini, fin) => {
        setFiltroDiscurso(() => [ini, fin])
    }



    const getPosicionamentoCount = (value: string) => {
        const posicionamentoCount = sumaryProposicao.posicionamento.filter(item => item.proposicao_cliente_posicionamentos === value)
        if (posicionamentoCount.length) {
            return posicionamentoCount[0].count
        }
        else {
            return 0
        }
    }

    const getSummaryDiscurso = () => {
        const filter = [
            { key: 'dtIni', value: format(filtroDiscurso[0], 'yyyy-MM-dd') },
            { key: 'dtFin', value: format(filtroDiscurso[1], 'yyyy-MM-dd') },
        ]
        if (String(format(new Date(filtroDiscurso[0]), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(filtroDiscurso[0]), "yyyy")).slice(0, 1) !== '0') {
            ParlamentarDiscursoService.GetSummary(Number(id), filter)
                .then(async res => {
                    setSummaryDiscurso(() => res)
                })
        }

    }


    const getListDiscurso = () => {
        const filter = filtroDiscursoLista.map(item => { return { key: item.key, value: item.value } });


        ParlamentarDiscursoService.GetList(Number(id), filter, 1, 5).then(async res => {
            if (res && res.success) {
                setListaDiscurso(res.lista);


            }
        })

    }

    const getListComissao = () => {
        const f = [{ key: 'tipo', value: filtroComissao }]


        //   ParlamentarAmbitoService.GetList(Number(id), f, 1, 5).then(async res => {
        //       if (res && res.success) {
        //           setListaComissao(res.lista);

        //       }
        //   })

        ParlamentarAmbitoService.GetSummaryList(Number(id)).then(async res => {
            if (res) {
                setListaComissao(res)

            }

        })


    }



    const getListFrentes = () => {


        ParlamentarOutrosService.GetFrentes(Number(id), 1, 5).then(async res => {
            if (res && res.data) {
                setListaFrentes(res.data);

            }
        })
    }



    const getListEventos = () => {


        ParlamentarOutrosService.GetEventos(Number(id))
            .then(async res => {
                setListaEventos(res?.data)
            })
    }












    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            {/* <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton> */}
                                            {parlamentar?.nome}
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px',

                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <ImageLoader
                                                src={parlamentar?.url_foto}
                                                pallet={pallet}
                                                canEdit={false}
                                            />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>

                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                {parlamentar?.nome_civil}<span style={{ fontSize: '16px' }}> ({parlamentar?.nome}) </span>
                                            </Grid>
                                            <ParlamentarConsultaSpanCustom >
                                                {parlamentar?.tratamento} • {" "}
                                            </ParlamentarConsultaSpanCustom>
                                            <ParlamentarConsultaSpanCustom >
                                                {parlamentar?.sigla_partido}  • {" "}
                                            </ParlamentarConsultaSpanCustom>
                                            <ParlamentarConsultaSpanCustom style={{ textTransform: "uppercase" }}>
                                                {parlamentar?.uf_exercicio} • {" "}
                                            </ParlamentarConsultaSpanCustom>

                                            <ParlamentarConsultaSpanCustom>

                                            </ParlamentarConsultaSpanCustom>
                                            <Grid style={{ marginTop: "10px" }}>
                                                <ParlamentarConsultaSpanCustom>
                                                    {parlamentar?.emails && parlamentar?.emails.length > 0 ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            {parlamentar?.emails?.map((item, index) => {
                                                                return (
                                                                    <Grid container>
                                                                        <Grid style={{ fontWeight: pallet.fontWeight.subtitle, marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                                            @ E-mail {index + 1}
                                                                        </Grid>
                                                                        <Grid style={{ marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                                            {item.label}
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Box>
                                                        :
                                                        <Grid style={{ marginLeft: "5px" }}>
                                                            Email não informado
                                                        </Grid>
                                                    }
                                                </ParlamentarConsultaSpanCustom>
                                            </Grid>
                                            <ParlamentarConsultaSpanCustom>
                                                <Grid style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}>

                                                    <Grid><PhoneIcon fontSize="small" /></Grid> <Grid>{parlamentar?.telefones && parlamentar?.telefones.length > 0 ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            {parlamentar?.telefones?.map((item, index) => {
                                                                return (
                                                                    <Grid container>
                                                                        <Grid style={{ marginLeft: "5px", fontWeight: pallet.fontWeight.subtitle }}>
                                                                            Telefone {index + 1}
                                                                        </Grid>
                                                                        <Grid style={{ marginLeft: "5px" }}>
                                                                            {/* <span>({item.label.substring(0, 2)})</span><span>{item.label.substring(2, 10)}</span> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Box>
                                                        :
                                                        <Grid style={{ marginLeft: "5px" }}>
                                                            Telefone não informado
                                                        </Grid>
                                                    }</Grid>

                                                </Grid>
                                            </ParlamentarConsultaSpanCustom>
                                            <ParlamentarConsultaSpanCustom>
                                                <Grid style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}>

                                                    <Grid><LocationOnIcon fontSize="small" /></Grid> <Grid>
                                                        {parlamentar?.endereco && parlamentar?.endereco !== '' ?
                                                            <Grid style={{ marginLeft: "5px" }}>
                                                                {"Endereço " + parlamentar?.endereco}
                                                            </Grid>
                                                            :
                                                            <Grid style={{ marginLeft: "5px" }}>
                                                                Endereço não informado
                                                            </Grid>
                                                        }
                                                    </Grid>

                                                </Grid>
                                            </ParlamentarConsultaSpanCustom>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>

                                    <Grid style={{
                                        width: '990px',
                                        marginTop: '30px',
                                    }}>
                                        <Grid style={{ paddingLeft: '40px' }} container>
                                            <Grid style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginRight: '50px' }}>
                                                <Grid style={{ color: "black", marginBottom: "6px", marginTop: "10px", fontWeight: pallet.general.weight_600 }}>
                                                    IDADE
                                                </Grid>
                                                <Grid style={{ color: "black", fontWeight: 100 }}>{idadeParlamentar} anos</Grid>
                                            </Grid>
                                            <Grid style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px", marginRight: '50px' }}>
                                                <Grid style={{ color: "black", marginBottom: "6px", fontWeight: pallet.fontWeight.subtitle }}>
                                                    DATA DE NASCIMENTO
                                                </Grid>
                                                <Grid>{format(new Date(parlamentar?.data_nascimento ? parlamentar?.data_nascimento : null), 'dd/MM/yyyy')}</Grid>
                                            </Grid>
                                            <Grid style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px", marginRight: '50px' }}>
                                                <Grid style={{ color: "black", marginBottom: "6px", fontWeight: pallet.fontWeight.subtitle }}>
                                                    ESTADO DE NASCIMENTO
                                                </Grid>
                                                <Grid>{parlamentar?.uf_nascimento}</Grid>
                                            </Grid>
                                            <Grid style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px", marginRight: '30px' }}>
                                                <Grid style={{ color: "black", marginBottom: "6px", fontWeight: pallet.general.weight_600 }}>
                                                    ESCOLARIDADE
                                                </Grid>
                                                {parlamentar?.escolaridade}
                                            </Grid>
                                            <Grid style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginTop: "10px" }}>
                                                <Grid style={{ color: "black", marginBottom: "6px", fontWeight: pallet.general.weight_600 }}>
                                                    PROFISSÃO
                                                </Grid>
                                                <Grid>
                                                    {parlamentar?.profissoes && parlamentar?.profissoes?.length > 0 ?
                                                        parlamentar?.profissoes?.map(item => {
                                                            return (

                                                                item.label

                                                            )
                                                        }).join(", ")
                                                        :
                                                        <Grid>
                                                            Não Informada
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    {/* } */}

                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{ paddingLeft: '30px' }}>
                        <Divider />
                    </Grid>
                    <Grid style={{
                        marginTop: '20px',
                        marginLeft: '30px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>


                                <tr>
                                    <td>

                                        {

                                            statusDemandaDados === "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    DEMANDAS
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                {statusDemandaDados === "sim" ?
                                    <tr>
                                        <Grid
                                            item
                                            xl={12}
                                            lg={12}
                                            xs={12}
                                            style={{
                                                margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
                                            }}
                                        >
                                            <DoughnutParlamentarDemandas
                                                masterTitle={"ÍNDICE DE RESULTADO DE DEMANDAS"}
                                                // loading={loading}
                                                //titleChart={`Total: ${sumary?.titular + sumary?.suplente}`}
                                                titleChart=""
                                                labels={[
                                                    { key: "Êxito", data: countExito, color: `${pallet.charts.green}` },
                                                    { key: "Êxito Parcial", data: countExitoParcial, color: `${pallet.charts.purple}` },
                                                    { key: "Sem Êxito", data: countSemExito, color: `${pallet.charts.red}` },
                                                    { key: "Pendente", data: countPendente, color: `${pallet.charts.yellow}` },
                                                ]}
                                            // dtIni={ subYears(new Date(), 1)}
                                            // dtFin={ new Date()}
                                            // onFilterChange={setFilterDateRange}
                                            />
                                        </Grid>
                                    </tr>
                                    :
                                    null
                                }
                                <tr>
                                    {statusDemandaLista === "sim" ?
                                        <td>
                                            <Assunto item >
                                                {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                {lista.length > 1 ? "ÚLTIMAS" + "(" + lista.length + ") " + "DEMANDAS REGISTRADAS" : "ÚLTIMA" + " (" + lista.length + ") " + "DEMANDA REGISTRADA"}
                                            </Assunto>
                                        </td>
                                        :

                                        null
                                    }
                                </tr>
                                {
                                    statusDemandaLista === "sim" ?
                                        lista.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block" }}>{format(new Date(item.prazo ? item.prazo : null), 'dd/MM/yyyy')} - RESULTADO:</Grid>
                                                                    <Grid
                                                                        style={{
                                                                            display: "inline-block",
                                                                            borderRadius: "50%",
                                                                            marginLeft: "10px",
                                                                            marginRight: "2px",
                                                                            marginTop: "3px",
                                                                            height: "6px",
                                                                            width: "6px",
                                                                            backgroundColor:
                                                                                resultadoColor(
                                                                                    item.resultado
                                                                                ),
                                                                        }}
                                                                    ></Grid>
                                                                    {"    "}{item.resultado}
                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>
                                                                Responsável: </Grid>{" "}<Grid style={{ display: 'inline-block' }}>{item.autor}</Grid>{" "}<Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>| Descrição:</Grid>{item.objetivo}| <Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>Conclusão:</Grid>{" " + item.status}
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }

                                        )

                                        :
                                        null

                                }

                                <tr>
                                    <td>
                                        {
                                            statusAcaoInfluenciaDados == "sim" || statusAcaoInfluenciaLista == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginTop: "30px", marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    AÇÕES DE INFLUÊNCIA
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            statusAcaoInfluenciaDados == 'sim' ?
                                                <div>
                                                    <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                                        <Grid item xl={12} lg={12} xs={12}>
                                                            <PieChartParlamentarAcoesInfluencia
                                                                masterTitle={"INTERAÇÕES"}
                                                                //titleChart={`Total: ${sumary?.titular + sumary?.suplente}`}                       
                                                                yearSel={filtroint}
                                                                onDateChange={setFilterDateYear}
                                                                titleChart="2021"
                                                                total={0}
                                                                seguindo={0}
                                                                labels={sumaryint}
                                                            /*
                                                            labels={[
                                                                { key: "Êxito", data: sumaryint?.titular, color: `${pallet.charts.green}` },
                                                                { key: "Êxito Parcial", data: sumaryint?.suplente, color: `${pallet.charts.blue}` },
                                                                { key: "Sem Êxito", data: sumaryint?.suplente, color: `${pallet.charts.red}` },
                                                                { key: "Pendente", data: sumaryint?.suplente, color: `${pallet.charts.red}` },
                                                            ]}
                                                            */
                                                            />
                                                        </Grid>


                                                        <Grid item xl={12} lg={12} xs={12} style={{ paddingTop: "40px" }}>
                                                            <PieChartParlamentarAcoesInfluenciaRelatorio
                                                                masterTitle={"ÍNDICE DE RESULTADO DE AÇÕES"}
                                                                //titleChart={`Total: ${sumary?.titular + sumary?.suplente}`}
                                                                titleChart=""
                                                                total={sumaryres?.Total}
                                                                seguindo={0}
                                                                labels={[
                                                                    {
                                                                        key: "Êxito", data: sumaryres?.Exito, color: `${pallet.charts.green}`,
                                                                    },
                                                                    {
                                                                        key: "Êxito Parcial", data: sumaryres?.ExitoParcial, color: `${pallet.charts.blue}`,
                                                                    },


                                                                    {
                                                                        key: "Sem Êxito", data: sumaryres?.SemExito, color: `${pallet.charts.red}`,
                                                                    },
                                                                    {
                                                                        key: "Pendente", data: sumaryres?.Pendente, color: `${pallet.charts.yellow}`,
                                                                    },
                                                                ]}
                                                                dtIni={filtrores ? filtrores[0] : subYears(new Date(), 1)}
                                                                dtFin={filtrores ? filtrores[1] : new Date()}
                                                                onFilterChange={setFilterDateRange}
                                                            />
                                                        </Grid>

                                                    </Grid>

                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            statusAcaoInfluenciaLista == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    {listaAcaoInfluencia?.length > 1 ? "ÚLTIMAS" + "(" + listaAcaoInfluencia?.length + ") " + "AÇÕES DE INFLUENCIA" : "ÚLTIMA" + " (" + listaAcaoInfluencia?.length + ") " + "AÇÃO DE INFLUENCIA"}
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                {
                                    statusAcaoInfluenciaLista == "sim" ?
                                        listaAcaoInfluencia?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block", color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>{item.proposicao + " "}</Grid>
                                                                    <Grid style={{ display: "inline-block" }}> - RESULTADO</Grid>
                                                                    <Grid
                                                                        style={{
                                                                            display: "inline-block",
                                                                            borderRadius: "50%",
                                                                            marginLeft: "10px",
                                                                            marginRight: "2px",
                                                                            marginTop: "3px",
                                                                            height: "6px",
                                                                            width: "6px",
                                                                            backgroundColor:
                                                                                posicionamentoColor(
                                                                                    item.posicionamento
                                                                                ),
                                                                        }}
                                                                    ></Grid>

                                                                    {"    "}{item.posicionamento}
                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    {item.acoes.map(acao => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>
                                                                        Reponsável: </Grid>{" "}<Grid style={{ display: 'inline-block' }}></Grid>{acao?.responsavel?.nome}<Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>| Prazo: </Grid><span>&nbsp;</span>{format(new Date(acao.prazo ? acao.prazo : null), 'dd/MM/yyyy') + " - " + format(new Date(acao.prazo ? acao.prazo : null), 'HH:MM')}<span>&nbsp;</span>|<span>&nbsp;</span> <Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>Casa Legislativa:</Grid>{" " + acao?.casa?.nome}
                                                                    <span>&nbsp;</span> <Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>| Âmbito:</Grid>{" " + acao?.ambito?.descricao}<span>&nbsp;</span> <Grid style={{ display: 'inline-block', color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>| Descrição:</Grid>{" " + acao?.descricao}
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}

                                                </>
                                            )
                                        }

                                        )
                                        :
                                        null
                                }

                                <tr>
                                    <td>
                                        {
                                            statusProposicaoDados == "sim" || statusProposicaoLista == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginTop: "30px", marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    PROPOSIÇÕES
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            statusProposicaoDados == "sim" ?
                                                <div>
                                                    <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                                        <Grid item xl={12} lg={12} xs={12}>
                                                            <DoughnutChartParlamentarProposicao
                                                                loading={loading}
                                                                masterTitle={"PROPOSIÇÕES DO INTERLOCUTOR"}
                                                                titleChart={"POSICIONAMENTO DAS PROPOSIÇÕES SEGUIDAS"}
                                                                total={sumaryProposicao.total}
                                                                seguindo={sumaryProposicao.seguindo}
                                                                labels=
                                                                {[
                                                                    {
                                                                        key: "Favorável", data: getPosicionamentoCount("Favorável"), color: posicionamentoColor("Favorável"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'


                                                                    },
                                                                    {
                                                                        key: "Favorável ao parecer do relator", data: getPosicionamentoCount("Favorável ao parecer do relator"), color: posicionamentoColor("Favorável ao parecer do relator"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": 6},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'


                                                                    },
                                                                    {
                                                                        key: "Favorável ao parecer do relator com emenda", data: getPosicionamentoCount("Favorável ao parecer do relator com emenda"), color: posicionamentoColor("Favorável ao parecer do relator, com emenda"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator, com emenda", "value": 7},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'


                                                                    },
                                                                    {
                                                                        key: "Contrário ao parecer do relator", data: getPosicionamentoCount("Contrário ao parecer do relator"), color: posicionamentoColor("Contrário ao parecer do relator"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": 8},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'


                                                                    },
                                                                    {
                                                                        key: "Contrário ao parecer do relator com emenda", data: getPosicionamentoCount("Contrário ao parecer do relator com emenda"), color: posicionamentoColor("Contrário ao parecer do relator com emenda"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": 9},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'


                                                                    },
                                                                    {
                                                                        key: "Contrário", data: getPosicionamentoCount("Contrário"), color: posicionamentoColor("Contrário"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'


                                                                    },
                                                                    {
                                                                        key: "Contrário com Emenda Saneadora", data: getPosicionamentoCount("Contrário com Emenda Saneadora"), color: posicionamentoColor("Contrário com Emenda Saneadora"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Neutro", data: getPosicionamentoCount("Neutro"), color: posicionamentoColor("Neutro"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Favorável com Emenda Saneadora", data: getPosicionamentoCount("Favorável com Emenda Saneadora"), color: posicionamentoColor("Favorável com Emenda Saneadora"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Pendente", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Pendente", data: getPosicionamentoCount("Favorável ao parecer do relator"), color: posicionamentoColor("Favorável ao parecer do relator"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator", "value": 6},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Pendente", data: getPosicionamentoCount("Favorável ao parecer do relator com emenda"), color: posicionamentoColor("Favorável ao parecer do relator com emenda"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável ao parecer do relator com emenda", "value": 7},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Pendente", data: getPosicionamentoCount("Contrário ao parecer do relator"), color: posicionamentoColor("Contrário ao parecer do relator"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator", "value": 8},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Pendente", data: getPosicionamentoCount("Contrário ao parecer do relator com emenda"), color: posicionamentoColor("Contrário ao parecer do relator com emenda"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Contrário ao parecer do relator com emenda", "value": 9},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Pendente", data: getPosicionamentoCount("Pendente"), color: posicionamentoColor("Pendente"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                    {
                                                                        key: "Indefinido", data: getPosicionamentoCount("Indefinido"), color: posicionamentoColor("Indefinido"),
                                                                        url: '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"},{"key": "posicionamento", "label": "POSICIONAMENTO: Favorável", "value": 5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"}]'

                                                                    },
                                                                ]}
                                                                links=
                                                                {[
                                                                    {
                                                                        key: 0, url:
                                                                            '/proposicao?filtro=[{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"}]'

                                                                    },
                                                                    {
                                                                        key: 1, url:
                                                                            '/proposicao?filtro=[{ "key": "visualizacao", "label": "VISUALIZAÇÃO: Seguidas", "value": "Seguidas" },{"key":"autorI","label":"Autor: ' + id + '","value":"' + id + '"}]'

                                                                    }

                                                                ]}

                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        {
                                            statusProposicaoLista == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    {listaProposicao?.length > 1 ? "ÚLTIMAS" + "(" + listaProposicao?.length + ") " + "PROPOSIÇÕES APRESENTADAS" : "ÚLTIMA" + " (" + listaProposicao?.length + ") " + "PROPOSIÇÃO APRESENTADA"}
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>


                                {
                                    statusProposicaoLista == "sim" ?
                                        listaProposicao?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block", color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>{item.proposicao_descricao + " "}</Grid>
                                                                    <Grid style={{ display: "inline-block" }}> - {format(new Date(item.proposicao_data_apresentacao ? item.proposicao_data_apresentacao : null), 'dd/MM/yyyy')} - POSICIONAMENTO:
                                                                        <Grid
                                                                            style={{
                                                                                display: "inline-block",
                                                                                borderRadius: "50%",
                                                                                marginLeft: "10px",
                                                                                marginRight: "2px",
                                                                                marginTop: "3px",
                                                                                height: "6px",
                                                                                width: "6px",
                                                                                backgroundColor:
                                                                                    posicionamentoColor(
                                                                                        item.proposicao_cliente_posicionamento_descricao

                                                                                    ),
                                                                            }}

                                                                        ></Grid> {item.proposicao_cliente_posicionamento_descricao} |  PRIORIDADE:                     <Grid
                                                                            style={{
                                                                                display: "inline-block",
                                                                                borderRadius: "50%",
                                                                                marginLeft: "10px",
                                                                                marginRight: "2px",
                                                                                marginTop: "3px",
                                                                                height: "6px",
                                                                                width: "6px",
                                                                                backgroundColor:
                                                                                    prioridadeColor(
                                                                                        item.proposicao_cliente_prioridade_descricao


                                                                                    ),
                                                                            }}


                                                                        ></Grid>
                                                                        <span>&nbsp;</span>  {item.proposicao_cliente_prioridade_descricao}
                                                                    </Grid>


                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block' }}>
                                                                <span style={{ color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>Ementa: </span> {item.proposicao_ementa}</Grid>                    </td>
                                                    </tr>



                                                </>
                                            )

                                        }

                                        )
                                        :
                                        null
                                }

                                {
                                    statusComissaoLista == "sim" ?
                                        <tr>
                                            <td>
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    {listaComissao?.length > 1 ? "ÚLTIMAS" + "(" + listaComissao?.length + ") " + "COMISSÕES" : "ÚLTIMA" + " (" + listaComissao?.length + ") " + "COMISSÃO"}
                                                </Assunto>
                                            </td>
                                        </tr>
                                        :
                                        null
                                }
                                {
                                    statusComissaoLista == "sim" ?
                                        listaComissao?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600, maxWidth: '800px' }}><Grid style={{ display: "inline-block", fontWeight: pallet.general.weight_600 }}>{item.sigla + " (" + item?.nome + ") "}</Grid>
                                                                    <Grid style={{ display: "inline-block" }}>&nbsp;

                                                                    </Grid>


                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block' }}>
                                                                <span style={{ color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>Status: </span> {item.participacao} |<span style={{ color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}> Entrada: </span> {format(new Date(item.data_entrada ? item.data_entrada : null), 'dd/MM/yyyy')} </Grid>
                                                        </td>
                                                    </tr>



                                                </>
                                            )
                                        }

                                        )
                                        :
                                        null

                                }


                                <tr>
                                    <td>
                                        {statusDiscursoDados === "sim" || statusDiscursoLista == "sim" ?
                                            <Assunto item >
                                                {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                DISCURSOS
                                            </Assunto>
                                            :
                                            null}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {statusDiscursoDados === "sim" ?
                                            <div>
                                                <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                                    <Grid item xl={12} lg={12} xs={12}>
                                                        <PieChartParlamentarDiscursoRelatorio
                                                            loading={loading}
                                                            dtIni={filtroDiscurso ? filtroDiscurso[0] : subYears(new Date(), 1)}
                                                            dtFin={filtroDiscurso ? filtroDiscurso[1] : new Date()}
                                                            masterTitle={"ÍNDICE DE QUALIFICAÇÃO DE DISCURSOS"}
                                                            // titleChart={`Total: ${sumary?.convergente + sumary?.divergente + sumary?.neutro + sumary?.naoClassificado}`}
                                                            labels={[
                                                                { key: "Convergente", data: summaryDiscurso?.convergente, color: `${pallet.charts.green}` },
                                                                { key: "Divergente", data: summaryDiscurso?.divergente, color: `${pallet.charts.red}` },
                                                                { key: "Neutro", data: summaryDiscurso?.neutro, color: `${pallet.charts.lightGrey}` },
                                                                { key: "Não Classificado", data: summaryDiscurso?.naoClassificado, color: `${pallet.charts.blue}` },
                                                            ]}
                                                            onFilterChange={setFilterDate}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </div>
                                            :
                                            null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        {statusDiscursoLista == "sim" ?
                                            <Assunto item >
                                                {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                {listaDiscurso?.length > 1 ? "ÚLTIMOS" + "(" + listaDiscurso?.length + ") " + "DISCURSOS" : "ÚLTIMO" + " (" + listaDiscurso?.length + ") " + "DISCURSO"}
                                            </Assunto>
                                            :
                                            null
                                        }
                                    </td>
                                </tr>
                                {
                                    statusDiscursoLista == "sim" ?
                                        listaDiscurso?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block", fontWeight: pallet.general.weight_600 }}>{format(new Date(item.data ? item.data : null), 'dd/MM/yyyy') + " ás " + format(new Date(item.data ? item.data : null), 'HH') + "h" + format(new Date(item.data ? item.data : null), 'MM')}</Grid>
                                                                    <Grid style={{ display: "inline-block" }}>&nbsp; PELA ORDEM | QUALIFICAÇÃO
                                                                        <Grid
                                                                            style={{
                                                                                display: "inline-block",
                                                                                borderRadius: "50%",
                                                                                marginLeft: "10px",
                                                                                marginRight: "2px",
                                                                                marginTop: "3px",
                                                                                height: "6px",
                                                                                width: "6px",
                                                                                backgroundColor:
                                                                                    qualificacaoColor(
                                                                                        item.qualificacao

                                                                                    ),
                                                                            }}

                                                                        ></Grid> {item.qualificacao ? item.qualificacao : "Não Classificado"}
                                                                    </Grid>


                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block' }}>
                                                                <span style={{ color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>Resumo: </span> {item.sumario}</Grid>                    </td>
                                                    </tr>



                                                </>
                                            )
                                        }

                                        )

                                        :
                                        null
                                }

                                {statusOutrosEventos === 'sim' || statusOutrosFrentes === 'sim' ?

                                    <tr>
                                        <td>
                                            <Assunto item >
                                                {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                OUTROS
                                            </Assunto>

                                        </td>
                                    </tr>
                                    :
                                    null
                                }
                                {statusOutrosFrentes === 'sim' ?
                                    <Assunto item >
                                        {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                        {listaFrentes?.length > 1 ? "ÚLTIMAS" + "(" + listaDiscurso?.length + ") " + "FRENTES" : "ÚLTIMA" + " (" + listaFrentes?.length + ") " + "FRENTE"}

                                    </Assunto>
                                    :
                                    null
                                }


                                {
                                    statusOutrosFrentes === 'sim' ?
                                        listaFrentes?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block", fontWeight: pallet.general.weight_600 }}>LEGISLATURA: {item.legislatura}</Grid>


                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block' }}>
                                                                {item.descricao}</Grid>                    </td>
                                                    </tr>



                                                </>
                                            )
                                        }

                                        )
                                        :
                                        null

                                }

                                {
                                    statusOutrosEventos === 'sim' ?

                                        <Assunto item >
                                            {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                            {listaEventos?.length >= 5 ? "ÚLTIMOS" + "(5) EVENTOS" : listaEventos?.length === 1 ? "ÚLTIMO" + " (" + listaEventos?.length + ") " + "EVENTO" : "ÚLTIMOS" + " (" + listaEventos?.length + ") " + "EVENTOS"}

                                        </Assunto>
                                        :
                                        null
                                }
                                {
                                    statusOutrosEventos === 'sim' ?
                                        listaEventos?.filter((i, e) => e <= 4).map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block", fontWeight: pallet.general.weight_600 }}>
                                                                    {format(new Date(item.dataEvento ? item.dataEvento : null), 'dd/MM/yyyy')} <span style={{ color: pallet.general.color4 }}> - {item.status}
                                                                    </span></Grid>


                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block' }}>
                                                                {item.descricao}</Grid>                    </td>
                                                    </tr>



                                                </>
                                            )
                                        }

                                        )

                                        :
                                        null


                                }





                            </tbody>
                        </table>

                    </Grid>

                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
