import { Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import { usePallet } from "../../../contexts/PalletContext";
import styled from 'styled-components';
import legis_agenda from "../../../img/legis_agenda.png";
import legis_agenda2 from "../../../img/legis_agenda2.png";

import PrintIcon from '@mui/icons-material/Print';
import { isMobile, isWindows } from 'react-device-detect';
import { fontSize } from '@mui/system';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../utils/utils';
import CircularLoading from '../../../components/CircularLoading';
import AgendaService from '../../../services/AgendaService';
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import { formatDate } from '../../../utils/dateUtils';
import PlayArrowIcon from "../../../img/play-arrow.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from "moment-timezone";

moment.tz.setDefault(process.env.TIMEZONE || 'America/Sao_Paulo');

export default function AgendaRenderPDFOperacionalNovo() {
    const { pallet } = usePallet();
    const [relatorio, setRelatorio] = React.useState([]);
    let data_agora = new Date();
    let dia = String(data_agora.getDay()).padStart(2, "0");
    let dia_n = String(data_agora.getDate()).padStart(2, "0");
    let mes = String(data_agora.getMonth() + 1);
    let ano = data_agora.getFullYear();
    const { dtIni } = useParams();
    const { dtFin } = useParams();
    const { tipo } = useParams();

    const { hasAccess } = useAuthorization();
    const { user } = useAuthorization();

    React.useEffect(() => {
        // loadJornal(dtIni, dtFin, tipo)

        AgendaService.GetListRelatorio(format(new Date(dtIni), "yyyy-MM-dd"), format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"), tipo).then((jornal) => {
            setRelatorio(jornal &&
                jornal.informacoes &&
                jornal.informacoes.data &&
                Array.isArray(jornal.informacoes.data) ?
                jornal.informacoes.data :
                []);
            // setUltimaTramitacao(res2)
        });
    }, []);

    const Imprimir = () => {
        window.print()
    }

    const posicionamentoDescricao = (value: number) => {
        switch (value) {
          case 0:
            return "Indefinido";
          case 1:
            return "Contrário";
          case 2:
            return "Contrário com emenda saneadora";
          case 3:
            return "Neutro";
          case 4:
            return "Favorável com emenda saneadora";
          case 5:
            return "Favorável";
          case 6:
            return "Favorável ao parecer do relator";
          case 7:
            return "Favorável ao parecer do relator com emenda";
          case 8:
            return "Contrário ao parecer do relator";
          case 9:
            return "Contrário ao parecer do relator com emenda";
          case 99: 
            return "Pendente";
          default: 
            return "Indefinido";
        }
      };
    
      const posicionamentoColor = (value: string) => {
        switch (value) {
          case "Contrário":
              return `${pallet.charts.red}`;
          case "Contrário com emenda saneadora":
              return `${pallet.charts.orange}`;
          case "Neutro":
              return `${pallet.charts.purple}`;
          case "Favorável com emenda saneadora":
              return `${pallet.charts.blue}`;
          case "Favorável":
              return `${pallet.charts.green}`;
          case "Pendente":
              return `${pallet.charts.yellow}`;
          case "Indefinido":
              return `${pallet.charts.lightGrey}`;
          case "Favorável ao parecer do relator":
                return '#C71585';
          case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
          case "Contrário ao parecer do relator":
                return '#facb74';
          case "Contrário ao parecer do relator com emenda":
                return '#000000';
          default:
              return `${pallet.charts.lightGrey}`;
      }
      };

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };


    const prioridadeDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Mínima";
            case 2:
                return "Baixa";
            case 3:
                return "Média";
            case 4:
                return "Alta";
            case 5:
                return "Máxima";
            default:
                return "Não definido";
        }
    };

    const filtroDeTipo = (value: any) => {
        switch (value) {
            case "SF":
                return "SENADO";
            case "CD":
                return "CÂMARA DOS DEPUTADOS";
            case "CN":
                return "CONGRESSO NACIONAL";
            default:
                return "TUDO";
        }
    };

    const diaSemana = (value: any) => {
        switch (value) {
            case "01":
                return "SEGUNDA-FEIRA";
            case "02":
                return "TERÇA-FEIRA";
            case "03":
                return "QUARTA-FEIRA";
            case "04":
                return "QUINTA-FEIRA";
            case "05":
                return "SEXTA-FEIRA";
            case "06":
                return "SÁBADO";
            default:
                return "DOMINGO";
        }
    };

    const mesAagora = (value: string) => {
        switch (value) {
            case "1":
                return "JANEIRO";
            case "2":
                return "FEVEREIRO";
            case "3":
                return "MARÇO";
            case "4":
                return "ABRIL";
            case "5":
                return "MAIO";
            case "6":
                return "JUNHO";
            case "7":
                return "JULHO";
            case "8":
                return "AGOSTO";
            case "9":
                return "SETEMBRO";
            case "10":
                return "OUTUBRO";
            case "11":
                return "NOVEMBRO";
            case "12":
                return "DEZEMBRO";
        }
    };

    let letra = window.location.href.search("/");
    let ini_end = window.location.href.slice(0, letra);
    let end = window.location.href.slice(letra + 2);
    let letra2 = end.search("/");
    let end2 = end.slice(0, letra2);

    let largura = window.innerWidth
    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <span style={{
                                                color: 'white',
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size12}`
                                            }}>Agenda Operacional</span>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '150px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                {
                                                    "BRASÍLIA " +
                                                    diaSemana(dia) +
                                                    ", " +
                                                    dia_n +
                                                    " DE " +
                                                    mesAagora(mes) +
                                                    " DE " +
                                                    ano
                                                }
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Agenda do Congresso Nacional
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '15px',
                                                marginTop: '8px'
                                            }}>
                                                {
                                                    "Período entre " +
                                                    formatDate(new Date(dtIni), "dd/MM/yyyy") +
                                                    " a " +
                                                    formatDate(new Date(dtFin), "dd/MM/yyyy")
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            {relatorio.filter((k) =>
                                k.eventos.find((i) =>
                                    i.pautas.every((f) => f.proposicoes.length > 0)
                                )
                            )
                                .filter((q) => q.eventos.find((v) => v.agenda_casa_sigla === "SF")).map((x, o) => {
                                    return (
                                        <Grid>
                                            <Grid style={{ display: o === 0 ? "block" : "none", marginTop: '30px', }}>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: '990px',
                                                        height: "2px",
                                                    }}
                                                ></Grid>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: "370px",
                                                        height: "80px",
                                                        marginLeft: "25px",
                                                        borderBottomLeftRadius: "10px",
                                                        borderBottomRightRadius: "30px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Grid style={{ marginBottom: "8px" }}>
                                                        <img
                                                            style={{
                                                                width: "35px",
                                                                height: "30px",
                                                                marginTop: "10px",
                                                                marginLeft: "10px",
                                                            }}
                                                            src={PlayArrowIcon}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            color: "white",
                                                            fontSize: `${pallet.general.size12}`,
                                                        }}
                                                    >
                                                        SENADO FEDERAL
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ padding: "20px" }}>
                                                <Grid

                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: '990px',

                                                    }}>
                                                    <Grid
                                                        style={{
                                                            height: "38px",
                                                            width: "35px",
                                                            borderBottomLeftRadius: "5px",
                                                            borderTopLeftRadius: "5px",
                                                            backgroundColor: pallet.backgroundColorQuaternary,
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >

                                                        <CalendarTodayIcon />
                                                    </Grid>
                                                    <Grid container
                                                        style={{
                                                            border: '1px solid #E8E8E8',
                                                            height: "38px",
                                                            width: "100%",
                                                            alignContent: 'center'
                                                        }}
                                                    >
                                                        <Grid
                                                            style={{
                                                                fontSize: pallet.general.size16,
                                                                marginTop: "3px",
                                                                marginBottom: "3px",
                                                                marginLeft: "5px",
                                                               
                                                            }}

                                                        >
                                                            {diaSemana(
                                                                String(new Date(x.dia).getDay()).padStart(2, "0")
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                color: pallet.general.color1,
                                                                fontSize: pallet.general.size16,
                                                                marginLeft: "10px",
                                                                paddingTop: '3px'
                                                            }}

                                                        >
                                                            {moment(x.dia).format("DD/MM")}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {x.eventos.filter((v) => v.agenda_casa_sigla === "SF").map((y) => {
                                                    return (
                                                        <Grid>
                                                            <Grid>
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "20px",
                                                                        color: pallet.general.color1,
                                                                    }}
                                                                >
                                                                    {y.comissoes_descricao === "" || y.comissoes_descricao === null ?
                                                                        "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm")
                                                                        :
                                                                        "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm") + " " + "- " + y.comissoes_descricao

                                                                    }
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "5px",
                                                                        marginLeft: "8px",
                                                                    }}
                                                                >
                                                                    {"LOCAL: " + y.agenda_local_nome}
                                                                </Grid>
                                                            </Grid>
                                                            {y.pautas.filter(w => w.proposicoes.length > 0).map((b) => {
                                                                return (
                                                                    <Grid style={{ marginBottom: "30px" }}>
                                                                        {b.agenda_pauta_regime ?
                                                                            <Grid
                                                                                style={{
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "5px",
                                                                                    marginLeft: "8px",
                                                                                }}
                                                                            >
                                                                                {"REGIME: " + b.agenda_pauta_regime}
                                                                            </Grid>
                                                                            :
                                                                            <Grid></Grid>
                                                                        }

                                                                        <Grid
                                                                            style={{
                                                                                marginTop: "15px",
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                style={{
                                                                                    borderRadius: "50%",
                                                                                    border: "2px",
                                                                                    borderColor: "#EAE7E7",
                                                                                    width: "9px",
                                                                                    height: "9px",
                                                                                    marginTop: "6px",
                                                                                    backgroundColor: pallet.general.color1,
                                                                                }}
                                                                            ></Grid>
                                                                            <Grid
                                                                                style={{
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginLeft: "5px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {"ITEM " + b.agenda_pauta_item}
                                                                            </Grid>
                                                                        </Grid>



                                                                        {/* -------------- NUMERO DA PROPOSICAO SF --------------- */}
                                                                        {b.proposicoes.map((z) => {
                                                                            return (

                                                                                <Grid style={{ marginLeft: "15px", marginBottom: "5px" }}>
                                                                                    <Grid
                                                                                        style={{
                                                                                            fontSize: pallet.general.size16,
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >


                                                                                        {z.cliente.proposicao_cliente_apelido ?
                                                                                            <Grid>
                                                                                                <a href={
                                                                                                    ini_end +
                                                                                                    "//" +
                                                                                                    end2 +
                                                                                                    "/proposicao/" +
                                                                                                    z.proposicao_id
                                                                                                }
                                                                                                >
                                                                                                    {
                                                                                                        z.proposicao_descricao
                                                                                                    }
                                                                                                </a>
                                                                                                <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                    <Grid style={{ color: "red" }} >
                                                                                                        <Grid>
                                                                                                            {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >
                                                                                                                    {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                                }
                                                                                            </Grid> :
                                                                                            <Grid>
                                                                                                <Grid>
                                                                                                    <a href={
                                                                                                        ini_end +
                                                                                                        "//" +
                                                                                                        end2 +
                                                                                                        "/proposicao/" +
                                                                                                        z.proposicao_id
                                                                                                    }
                                                                                                    >
                                                                                                        {
                                                                                                            z.proposicao_descricao + " "
                                                                                                        }
                                                                                                    </a>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                                <Grid>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>
                                                                                    {b.terminativo === true ?
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                style={{
                                                                                                    width: "15px",
                                                                                                    height: "12px",
                                                                                                    marginRight: "5px",
                                                                                                }}
                                                                                                src={PlayArrowIcon}
                                                                                            />
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                }}
                                                                                            >
                                                                                                TERMINATIVO
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }





                                                                                    {/* -------------- AUTORES SF --------------- */}

                                                                                    <Grid container style={{ maxWidth: '1000px' }}>
                                                                                        {z.autores.length > 1 ? (
                                                                                            <Grid container
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "10px",
                                                                                                }}
                                                                                            >
                                                                                                <Grid item>{'>> Autores:'}</Grid>
                                                                                                <Grid item>
                                                                                                    {z.autores?.map((k, i) => {
                                                                                                        return (
                                                                                                            <Grid container>
                                                                                                                <Grid item style={{ width: '100%' }}>
                                                                                                                    <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                        {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                    </a>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        );
                                                                                                    })}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        ) : z.autores.length == 1 ? (
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "10px",
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row"

                                                                                                }}
                                                                                            >
                                                                                                <Grid>{'>> Autor:'}</Grid>
                                                                                                {z.autores?.map((k) => {
                                                                                                    return (
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/interlocutor/" +
                                                                                                            k.proposicao_autores_id_parlamentar
                                                                                                        }
                                                                                                        >
                                                                                                            {k.proposicao_autores_nome}
                                                                                                        </a>
                                                                                                    );
                                                                                                })}
                                                                                            </Grid>
                                                                                        ) : (
                                                                                            <Grid
                                                                                                style={{ display: "none" }}
                                                                                            ></Grid>
                                                                                        )}
                                                                                    </Grid>




                                                                                    {/* -------------- EMENTA SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,

                                                                                            }}
                                                                                        >
                                                                                            {">>"} Ementa:{" "}


                                                                                            <span style={{ color: "black" }}>  {z.proposicao_ementa}</span>

                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- PARECER DO RELATOR SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                                flex: 1,
                                                                                                flexWrap: "wrap",
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Parecer do Relator:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {b.agenda_pauta_parecer_relator !== "" && b.agenda_pauta_parecer_relator !== null && b.agenda_pauta_parecer_relator !== undefined ? b.agenda_pauta_parecer_relator : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                                flex: 1,
                                                                                                flexWrap: "wrap",
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Relator:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {b.agenda_pauta_nome_relator !== "" && b.agenda_pauta_nome_relator !== null && b.agenda_pauta_nome_relator !== undefined ? b.agenda_pauta_nome_relator : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- PEDIDO DE VISTA SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Pedido de vista:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.pedido_de_vista ? z.pedido_de_vista : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>





                                                                                    {/* -------------- VOTO EM SEPARADO SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Voto em separado:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.voto_em_separado ? z.voto_em_separado : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- SITUAÇÃO SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Situação:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.situacao ? z.situacao : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* -------------- ULTIMA TRAMITAÇAO SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Última tramitação:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.ultima_tramitacao.length > 0 ? "Ocorreu em " + "[" +
                                                                                                    moment(z.ultima_tramitacao[0].data_atualizacao).format('DD-MM-yyyy') +
                                                                                                    "] - " + z.ultima_tramitacao[0].ultima_tramitacao : 'Não informado'}

                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* -------------- DESPACHO SF --------------- */}

                                                                                    {b.agenda_pauta_despacho ?
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Despacho:{" "}
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {b.agenda_pauta_despacho}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }






                                                                                    {/* -------------- POSICIONAMETO PRINCIPAL SF --------------- */}
                                                                                    {user.id_permissao !== 4 ?

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Posicionamento Principal:{" "}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                style={{
                                                                                                    borderRadius: "50%",
                                                                                                    marginRight: "3px",
                                                                                                    marginTop: "7px",
                                                                                                    height: "8px",
                                                                                                    width: "8px",
                                                                                                    marginLeft: "10px",
                                                                                                    backgroundColor: posicionamentoColor(
                                                                                                        posicionamentoDescricao(
                                                                                                            z.cliente
                                                                                                                .proposicao_cliente_posicaoDaOrganizacao
                                                                                                        )
                                                                                                    ),
                                                                                                }}
                                                                                            ></Grid>
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                }}
                                                                                            >
                                                                                                {posicionamentoDescricao(
                                                                                                    z.cliente
                                                                                                        .proposicao_cliente_posicaoDaOrganizacao
                                                                                                )}
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        :

                                                                                        null

                                                                                    }









                                                                                    {/* -------------- POSICIONAMETO DETALHADO SF --------------- */}
                                                                                    {user.id_permissao !== 4 ?


                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Posicionamento Detalhado:{" "}
                                                                                                {
                                                                                                    z.acoes_preparatorias.length === 0 ?
                                                                                                        <span style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            color: "black",
                                                                                                        }}>Não definido</span>


                                                                                                        :
                                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        null
                                                                                    }






                                                                                    {/* <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: pallet.general.size16,
                              color: pallet.general.color1,
                            }}
                          >
                            {">>"} Pedido de Vista:{" "}
                            <Text
                              style={{
                                fontSize: pallet.general.size16,
                                flex: 1,
                                flexWrap: "wrap",
                                color: "black",
                              }}
                            >
                              [19/03/2019] - Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </Text>
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: pallet.general.size16,
                              color: pallet.general.color1,
                              flexShrink: 1,
                            }}
                          >
                            {">>"} Declaração de Voto em Separado:{" "}
                            <Text
                              style={{
                                fontSize: pallet.general.size16,
                                color: "black",
                              }}
                            >
                              [19/03/2019] - Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </Text>
                          </Text>
                        </View> */}







                                                                                    {/* -------------- ACAO PREPARATÓRIA SF --------------- */}

                                                                                    {z.acoes_preparatorias.length > 0 && user.id_permissao !== 4 ?
                                                                                        z.acoes_preparatorias.map((t) => {
                                                                                            return (
                                                                                                t.nota_tecnica.map((q) => {
                                                                                                    return (
                                                                                                        <Grid container
                                                                                                            style={{ marginLeft: "14px", }}
                                                                                                        >
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    fontSize: pallet.general.size16,
                                                                                                                    marginTop: "3px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                            </Grid>
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    flexDirection: "row",
                                                                                                                }}>
                                                                                                                <Grid style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                                                                                    /
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: posicionamentoColor(posicionamentoDescricao(q.nota_tecnica_posicionamento)),
                                                                                                                    }}
                                                                                                                />
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {posicionamentoDescricao(q.nota_tecnica_posicionamento)}
                                                                                                                </Grid>
                                                                                                                <Grid style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                                                                                    /
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                    }}
                                                                                                                ></Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>




                                                                                                    )
                                                                                                })


                                                                                            );
                                                                                        })
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }

                                                                                        {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                        {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4  ? 
                                                                                                        <Grid     style={{fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 : z.posicionamento_unidade_negocio.length < 0 && user.id_permissao !== 4  ?
                                                                                                 <Grid     style={{fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  <span style={{color:'black'}}>Não possui</span></Grid>
                                                                                                :
                                                                                                null     
                                                                                                }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>
                                                                                </Grid>


                                                                            );
                                                                        })}

                                                                    </Grid>
                                                                );
                                                            })}


                                                        </Grid>

                                                    );
                                                })}

                                            </Grid>
                                        </Grid>
                                    );
                                })}


                            {relatorio.filter((k) =>
                                k.eventos.find((i) =>
                                    i.pautas.every((f) => f.proposicoes.length > 0)
                                )
                            )
                                .filter((q) => q.eventos.find((v) => v.agenda_casa_sigla === "CD")).map((x, o) => {
                                    return (
                                        <Grid>
                                            <Grid style={{ display: o === 0 ? "block" : "none", marginTop: '30px', }}>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: '990px',
                                                        height: "2px",
                                                    }}
                                                ></Grid>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: "370px",
                                                        height: "80px",
                                                        marginLeft: "25px",
                                                        borderBottomLeftRadius: "10px",
                                                        borderBottomRightRadius: "30px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Grid style={{ marginBottom: "8px" }}>
                                                        <img
                                                            style={{
                                                                width: "35px",
                                                                height: "30px",
                                                                marginTop: "10px",
                                                                marginLeft: "10px",
                                                            }}
                                                            src={PlayArrowIcon}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            color: "white",
                                                            fontSize: `${pallet.general.size12}`,
                                                        }}
                                                    >
                                                        CÂMARA DOS DEPUTADOS
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ padding: "20px" }}>
                                                <Grid

                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: '990px',

                                                    }}>
                                                    <Grid
                                                        style={{
                                                            height: "38px",
                                                            width: "35px",
                                                            borderBottomLeftRadius: "5px",
                                                            borderTopLeftRadius: "5px",
                                                            backgroundColor: pallet.backgroundColorQuaternary,
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >

                                                        <CalendarTodayIcon />
                                                    </Grid>
                                                    <Grid container
                                                        style={{
                                                            border: '1px solid #E8E8E8',
                                                            height: "38px",
                                                            width: "100%",
                                                            alignContent: 'center'
                                                        }}
                                                    >
                                                        <Grid
                                                            style={{
                                                                fontSize: pallet.general.size16,
                                                                marginTop: "3px",
                                                                marginBottom: "3px",
                                                                marginLeft: "5px",
                                                            }}

                                                        >
                                                            {diaSemana(
                                                                String(new Date(x.dia).getDay()).padStart(2, "0")
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                color: pallet.general.color1,
                                                                fontSize: pallet.general.size16,
                                                                marginLeft: "10px",
                                                                paddingTop: '3px'
                                                            }}

                                                        >
                                                            {moment(x.dia).format("DD/MM")}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {x.eventos.filter((v) => v.agenda_casa_sigla === "CD").map((y) => {
                                                    return (
                                                        <Grid>
                                                            <Grid>
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "20px",
                                                                        color: pallet.general.color1,
                                                                    }}
                                                                >
                                                                    {y.comissoes_descricao === "" || y.comissoes_descricao === null ?
                                                                        "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm")
                                                                        :
                                                                        "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm") + " " + "- " + y.comissoes_descricao

                                                                    }
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "5px",
                                                                        marginLeft: "8px",
                                                                    }}
                                                                >
                                                                    {"LOCAL: " + y.agenda_local_nome}
                                                                </Grid>
                                                            </Grid>
                                                            {y.pautas.filter(w => w.proposicoes.length > 0).map((b) => {
                                                                return (
                                                                    <Grid style={{ marginBottom: "30px" }}>
                                                                        {b.agenda_pauta_regime ?
                                                                            <Grid
                                                                                style={{
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "5px",
                                                                                    marginLeft: "8px",
                                                                                }}
                                                                            >
                                                                                {"REGIME: " + b.agenda_pauta_regime}
                                                                            </Grid>
                                                                            :
                                                                            <Grid></Grid>
                                                                        }

                                                                        <Grid
                                                                            style={{
                                                                                marginTop: "15px",
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                style={{
                                                                                    borderRadius: "50%",
                                                                                    border: "2px",
                                                                                    borderColor: "#EAE7E7",
                                                                                    width: "9px",
                                                                                    height: "9px",
                                                                                    marginTop: "6px",
                                                                                    backgroundColor: pallet.general.color1,
                                                                                }}
                                                                            ></Grid>
                                                                            <Grid
                                                                                style={{
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginLeft: "5px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {"ITEM " + b.agenda_pauta_item}
                                                                            </Grid>
                                                                        </Grid>



                                                                        {/* -------------- NUMERO DA PROPOSICAO SF --------------- */}
                                                                        {b.proposicoes.map((z) => {
                                                                            return (

                                                                                <Grid style={{ marginLeft: "15px", marginBottom: "5px" }}>
                                                                                    <Grid
                                                                                        style={{
                                                                                            fontSize: pallet.general.size16,
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >


                                                                                        {z.cliente.proposicao_cliente_apelido ?
                                                                                            <Grid>
                                                                                                <a href={
                                                                                                    ini_end +
                                                                                                    "//" +
                                                                                                    end2 +
                                                                                                    "/proposicao/" +
                                                                                                    z.proposicao_id
                                                                                                }
                                                                                                >
                                                                                                    {
                                                                                                        z.proposicao_descricao
                                                                                                    }
                                                                                                </a>
                                                                                                <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                    <Grid style={{ color: "red" }} >
                                                                                                        <Grid>
                                                                                                            {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >
                                                                                                                    {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                                }
                                                                                            </Grid> :
                                                                                            <Grid>
                                                                                                <Grid>
                                                                                                    <a href={
                                                                                                        ini_end +
                                                                                                        "//" +
                                                                                                        end2 +
                                                                                                        "/proposicao/" +
                                                                                                        z.proposicao_id
                                                                                                    }
                                                                                                    >
                                                                                                        {
                                                                                                            z.proposicao_descricao + " "
                                                                                                        }
                                                                                                    </a>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                                <Grid>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>
                                                                                    {b.terminativo === true ?
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                style={{
                                                                                                    width: "15px",
                                                                                                    height: "12px",
                                                                                                    marginRight: "5px",
                                                                                                }}
                                                                                                src={PlayArrowIcon}
                                                                                            />
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                }}
                                                                                            >
                                                                                                TERMINATIVO
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }





                                                                                    {/* -------------- AUTORES CD --------------- */}

                                                                                    <Grid container style={{ maxWidth: '1000px' }}>
                                                                                        {z.autores.length > 1 ? (
                                                                                            <Grid container
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "10px",
                                                                                                }}
                                                                                            >
                                                                                                <Grid item>{'>> Autores:'}</Grid>
                                                                                                <Grid item>
                                                                                                    {z.autores?.map((k, i) => {
                                                                                                        return (
                                                                                                            <Grid container>
                                                                                                                <Grid item style={{ width: '100%' }}>
                                                                                                                    <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                        {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                    </a>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        );
                                                                                                    })}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        ) : z.autores.length == 1 ? (
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "10px",
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row"

                                                                                                }}
                                                                                            >
                                                                                                <Grid>{'>> Autor:'}</Grid>
                                                                                                {z.autores?.map((k) => {
                                                                                                    return (
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/interlocutor/" +
                                                                                                            k.proposicao_autores_id_parlamentar
                                                                                                        }
                                                                                                        >
                                                                                                            {k.proposicao_autores_nome}
                                                                                                        </a>
                                                                                                    );
                                                                                                })}
                                                                                            </Grid>
                                                                                        ) : (
                                                                                            <Grid
                                                                                                style={{ display: "none" }}
                                                                                            ></Grid>
                                                                                        )}
                                                                                    </Grid>




                                                                                    {/* -------------- EMENTA CD --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,

                                                                                            }}
                                                                                        >
                                                                                            {">>"} Ementa:{" "}


                                                                                            <span style={{ color: "black" }}>  {z.proposicao_ementa}</span>

                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- PARECER DO RELATOR CD --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                                flex: 1,
                                                                                                flexWrap: "wrap",
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Parecer do Relator:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {b.agenda_pauta_parecer_relator !== "" && b.agenda_pauta_parecer_relator !== null && b.agenda_pauta_parecer_relator !== undefined ? b.agenda_pauta_parecer_relator : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                                flex: 1,
                                                                                                flexWrap: "wrap",
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Relator:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {b.agenda_pauta_nome_relator !== "" && b.agenda_pauta_nome_relator !== null && b.agenda_pauta_nome_relator !== undefined ? b.agenda_pauta_nome_relator : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- PEDIDO DE VISTA CD --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Pedido de vista:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.pedido_de_vista ? z.pedido_de_vista : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>





                                                                                    {/* -------------- VOTO EM SEPARADO CD --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Voto em separado:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.voto_em_separado ? z.voto_em_separado : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- SITUAÇÃO CD --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Situação:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.situacao ? z.situacao : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* -------------- ULTIMA TRAMITAÇAO CD --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Última tramitação:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.ultima_tramitacao.length > 0 ? "Ocorreu em " + "[" +
                                                                                                    moment(z.ultima_tramitacao[0].data_atualizacao).format('DD-MM-yyyy') +
                                                                                                    "] - " + z.ultima_tramitacao[0].ultima_tramitacao : 'Não informado'}

                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* -------------- DESPACHO CD --------------- */}

                                                                                    {b.agenda_pauta_despacho ?
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Despacho:{" "}
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {b.agenda_pauta_despacho}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }






                                                                                    {/* -------------- POSICIONAMETO PRINCIPAL CD --------------- */}
                                                                                    {user.id_permissao !== 4 ?

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Posicionamento Principal:{" "}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                style={{
                                                                                                    borderRadius: "50%",
                                                                                                    marginRight: "3px",
                                                                                                    marginTop: "7px",
                                                                                                    height: "8px",
                                                                                                    width: "8px",
                                                                                                    marginLeft: "10px",
                                                                                                    backgroundColor: posicionamentoColor(
                                                                                                        posicionamentoDescricao(
                                                                                                            z.cliente
                                                                                                                .proposicao_cliente_posicaoDaOrganizacao
                                                                                                        )
                                                                                                    ),
                                                                                                }}
                                                                                            ></Grid>
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                }}
                                                                                            >
                                                                                                {posicionamentoDescricao(
                                                                                                    z.cliente
                                                                                                        .proposicao_cliente_posicaoDaOrganizacao
                                                                                                )}
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        :

                                                                                        null

                                                                                    }


                                                                                    {/* -------------- POSICIONAMETO DETALHADO CD --------------- */}
                                                                                    {user.id_permissao !== 4 ?


                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Posicionamento Detalhado:{" "}
                                                                                                {
                                                                                                    z.acoes_preparatorias.length === 0 ?
                                                                                                        <span style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            color: "black",
                                                                                                        }}>Não definido</span>


                                                                                                        :
                                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        null
                                                                                    }






                                                                                    {/* <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: pallet.general.size16,
                              color: pallet.general.color1,
                            }}
                          >
                            {">>"} Pedido de Vista:{" "}
                            <Text
                              style={{
                                fontSize: pallet.general.size16,
                                flex: 1,
                                flexWrap: "wrap",
                                color: "black",
                              }}
                            >
                              [19/03/2019] - Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </Text>
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: pallet.general.size16,
                              color: pallet.general.color1,
                              flexShrink: 1,
                            }}
                          >
                            {">>"} Declaração de Voto em Separado:{" "}
                            <Text
                              style={{
                                fontSize: pallet.general.size16,
                                color: "black",
                              }}
                            >
                              [19/03/2019] - Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </Text>
                          </Text>
                        </View> */}







                                                                                    {/* -------------- ACAO PREPARATÓRIA SF --------------- */}

                                                                                    {z.acoes_preparatorias.length > 0 && user.id_permissao !== 4 ?
                                                                                        z.acoes_preparatorias.map((t) => {
                                                                                            return (
                                                                                                t.nota_tecnica.map((q) => {
                                                                                                    return (
                                                                                                        <Grid container
                                                                                                            style={{ marginLeft: "14px", }}
                                                                                                        >
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    fontSize: pallet.general.size16,
                                                                                                                    marginTop: "3px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                            </Grid>
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    flexDirection: "row",
                                                                                                                }}>
                                                                                                                <Grid style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                                                                                    /
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: posicionamentoColor(posicionamentoDescricao(q.nota_tecnica_posicionamento)),
                                                                                                                    }}
                                                                                                                />
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {posicionamentoDescricao(q.nota_tecnica_posicionamento)}
                                                                                                                </Grid>
                                                                                                                <Grid style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                                                                                    /
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                    }}
                                                                                                                ></Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>




                                                                                                    )
                                                                                                })


                                                                                            );
                                                                                        })
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }


                                                                {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4 ? 
                                                                                        <Grid     style={{fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :z.posicionamento_unidade_negocio.length < 0 && user.id_permissao !== 4 ?
                                                                                                 <Grid     style={{fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  <span style={{color:'black'}}>Não possui</span></Grid>
                                                                                                    :
                                                                                                    null     
                                                                                                }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>

                                                                                    
                                                                                    
                                                                                </Grid>


                                                                            );
                                                                        })}

                                                                    </Grid>
                                                                );
                                                            })}


                                                        </Grid>

                                                    );
                                                })}

                                            </Grid>
                                        </Grid>
                                    );
                                })}




                            {relatorio.filter((k) =>
                                k.eventos.find((i) =>
                                    i.pautas.every((f) => f.proposicoes.length > 0)
                                )
                            )
                                .filter((q) => q.eventos.find((v) => v.agenda_casa_sigla === "CN")).map((x, o) => {
                                    return (
                                        <Grid>
                                            <Grid style={{ display: o === 0 ? "block" : "none", marginTop: '30px', }}>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: '990px',
                                                        height: "2px",
                                                    }}
                                                ></Grid>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: "370px",
                                                        height: "80px",
                                                        marginLeft: "25px",
                                                        borderBottomLeftRadius: "10px",
                                                        borderBottomRightRadius: "30px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Grid style={{ marginBottom: "8px" }}>
                                                        <img
                                                            style={{
                                                                width: "35px",
                                                                height: "30px",
                                                                marginTop: "10px",
                                                                marginLeft: "10px",
                                                            }}
                                                            src={PlayArrowIcon}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            color: "white",
                                                            fontSize: `${pallet.general.size12}`,
                                                        }}
                                                    >
                                                        CONGRESSO NACIONAL
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ padding: "20px" }}>
                                                <Grid

                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: '990px',

                                                    }}>
                                                    <Grid
                                                        style={{
                                                            height: "38px",
                                                            width: "35px",
                                                            borderBottomLeftRadius: "5px",
                                                            borderTopLeftRadius: "5px",
                                                            backgroundColor: pallet.backgroundColorQuaternary,
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >

                                                        <CalendarTodayIcon />
                                                    </Grid>
                                                    <Grid container
                                                        style={{
                                                            border: '1px solid #E8E8E8',
                                                            height: "38px",
                                                            width: "100%",
                                                            alignContent: 'center'
                                                        }}
                                                    >
                                                        <Grid
                                                            style={{
                                                                fontSize: pallet.general.size16,
                                                                marginTop: "3px",
                                                                marginBottom: "3px",
                                                                marginLeft: "5px",
                                                            }}

                                                        >
                                                            {diaSemana(
                                                                String(new Date(x.dia).getDay()).padStart(2, "0")
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                color: pallet.general.color1,
                                                                fontSize: pallet.general.size16,
                                                                marginLeft: "10px",
                                                                paddingTop: '3px'
                                                            }}

                                                        >
                                                            {moment(x.dia).format("DD/MM")}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {x.eventos.filter((v) => v.agenda_casa_sigla === "CN").map((y) => {
                                                    return (
                                                        <Grid>
                                                            <Grid>
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "20px",
                                                                        color: pallet.general.color1,
                                                                    }}
                                                                >
                                                                    {y.comissoes_descricao === "" || y.comissoes_descricao === null ?
                                                                        "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm")
                                                                        :
                                                                        "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm") + " " + "- " + y.comissoes_descricao

                                                                    }
                                                                </Grid>
                                                                <Grid
                                                                    style={{
                                                                        fontSize: pallet.general.size16,
                                                                        marginTop: "5px",
                                                                        marginLeft: "8px",
                                                                    }}
                                                                >
                                                                    {"LOCAL: " + y.agenda_local_nome}
                                                                </Grid>
                                                            </Grid>
                                                            {y.pautas.filter(w => w.proposicoes.length > 0).map((b) => {
                                                                return (
                                                                    <Grid style={{ marginBottom: "30px" }}>
                                                                        {b.agenda_pauta_regime ?
                                                                            <Grid
                                                                                style={{
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginTop: "5px",
                                                                                    marginLeft: "8px",
                                                                                }}
                                                                            >
                                                                                {"REGIME: " + b.agenda_pauta_regime}
                                                                            </Grid>
                                                                            :
                                                                            <Grid></Grid>
                                                                        }

                                                                        <Grid
                                                                            style={{
                                                                                marginTop: "15px",
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                style={{
                                                                                    borderRadius: "50%",
                                                                                    border: "2px",
                                                                                    borderColor: "#EAE7E7",
                                                                                    width: "9px",
                                                                                    height: "9px",
                                                                                    marginTop: "6px",
                                                                                    backgroundColor: pallet.general.color1,
                                                                                }}
                                                                            ></Grid>
                                                                            <Grid
                                                                                style={{
                                                                                    fontSize: pallet.general.size16,
                                                                                    marginLeft: "5px",
                                                                                    color: pallet.general.color1,
                                                                                }}
                                                                            >
                                                                                {"ITEM " + b.agenda_pauta_item}
                                                                            </Grid>
                                                                        </Grid>



                                                                        {/* -------------- NUMERO DA PROPOSICAO SF --------------- */}
                                                                        {b.proposicoes.map((z) => {
                                                                            return (

                                                                                <Grid style={{ marginLeft: "15px", marginBottom: "5px" }}>
                                                                                    <Grid
                                                                                        style={{
                                                                                            fontSize: pallet.general.size16,
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >


                                                                                        {z.cliente.proposicao_cliente_apelido ?
                                                                                            <Grid>
                                                                                                <a href={
                                                                                                    ini_end +
                                                                                                    "//" +
                                                                                                    end2 +
                                                                                                    "/proposicao/" +
                                                                                                    z.proposicao_id
                                                                                                }
                                                                                                >
                                                                                                    {
                                                                                                        z.proposicao_descricao
                                                                                                    }
                                                                                                </a>
                                                                                                <Grid>
                                                                                                    {" [" + z.cliente.proposicao_cliente_apelido + "] "}
                                                                                                </Grid>
                                                                                                {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                    <Grid style={{ color: "red" }} >
                                                                                                        <Grid>
                                                                                                            {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                <Grid style={{ color: "red" }} >
                                                                                                                    {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                                }
                                                                                            </Grid> :
                                                                                            <Grid>
                                                                                                <Grid>
                                                                                                    <a href={
                                                                                                        ini_end +
                                                                                                        "//" +
                                                                                                        end2 +
                                                                                                        "/proposicao/" +
                                                                                                        z.proposicao_id
                                                                                                    }
                                                                                                    >
                                                                                                        {
                                                                                                            z.proposicao_descricao + " "
                                                                                                        }
                                                                                                    </a>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                                <Grid>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        }
                                                                                    </Grid>
                                                                                    {b.terminativo === true ?
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                style={{
                                                                                                    width: "15px",
                                                                                                    height: "12px",
                                                                                                    marginRight: "5px",
                                                                                                }}
                                                                                                src={PlayArrowIcon}
                                                                                            />
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                }}
                                                                                            >
                                                                                                TERMINATIVO
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }





                                                                                    {/* -------------- AUTORES SF --------------- */}

                                                                                    <Grid container style={{ maxWidth: '1000px' }}>
                                                                                        {z.autores.length > 1 ? (
                                                                                            <Grid container
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "10px",
                                                                                                }}
                                                                                            >
                                                                                                <Grid item>{'>> Autores:'}</Grid>
                                                                                                <Grid item>
                                                                                                    {z.autores?.map((k, i) => {
                                                                                                        return (
                                                                                                            <Grid container>
                                                                                                                <Grid item style={{ width: '100%' }}>
                                                                                                                    <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                        {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                    </a>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        );
                                                                                                    })}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        ) : z.autores.length == 1 ? (
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    marginTop: "10px",
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row"

                                                                                                }}
                                                                                            >
                                                                                                <Grid>{'>> Autor:'}</Grid>
                                                                                                {z.autores?.map((k) => {
                                                                                                    return (
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/interlocutor/" +
                                                                                                            k.proposicao_autores_id_parlamentar
                                                                                                        }
                                                                                                        >
                                                                                                            {k.proposicao_autores_nome}
                                                                                                        </a>
                                                                                                    );
                                                                                                })}
                                                                                            </Grid>
                                                                                        ) : (
                                                                                            <Grid
                                                                                                style={{ display: "none" }}
                                                                                            ></Grid>
                                                                                        )}
                                                                                    </Grid>




                                                                                    {/* -------------- EMENTA SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,

                                                                                            }}
                                                                                        >
                                                                                            {">>"} Ementa:{" "}


                                                                                            <span style={{ color: "black" }}>  {z.proposicao_ementa}</span>

                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- PARECER DO RELATOR SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                                flex: 1,
                                                                                                flexWrap: "wrap",
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Parecer do Relator:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {b.agenda_pauta_parecer_relator !== "" && b.agenda_pauta_parecer_relator !== null && b.agenda_pauta_parecer_relator !== undefined ? b.agenda_pauta_parecer_relator : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                                flex: 1,
                                                                                                flexWrap: "wrap",
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Relator:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {b.agenda_pauta_nome_relator !== "" && b.agenda_pauta_nome_relator !== null && b.agenda_pauta_nome_relator !== undefined ? b.agenda_pauta_nome_relator : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- PEDIDO DE VISTA SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Pedido de vista:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.pedido_de_vista ? z.pedido_de_vista : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>





                                                                                    {/* -------------- VOTO EM SEPARADO SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Voto em separado:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.voto_em_separado ? z.voto_em_separado : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>






                                                                                    {/* -------------- SITUAÇÃO SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Situação:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.situacao ? z.situacao : "Não informado"}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* -------------- ULTIMA TRAMITAÇAO SF --------------- */}

                                                                                    <Grid
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "row",
                                                                                            marginTop: "5px",
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                color: pallet.general.color1,
                                                                                            }}
                                                                                        >
                                                                                            {">>"} Última tramitação:{" "}
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                    color: "black",
                                                                                                }}
                                                                                            >
                                                                                                {z.ultima_tramitacao.length > 0 ? "Ocorreu em " + "[" +
                                                                                                    moment(z.ultima_tramitacao[0].data_atualizacao).format('DD-MM-yyyy') +
                                                                                                    "] - " + z.ultima_tramitacao[0].ultima_tramitacao : 'Não informado'}

                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {/* -------------- DESPACHO SF --------------- */}

                                                                                    {b.agenda_pauta_despacho ?
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Despacho:{" "}
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {b.agenda_pauta_despacho}
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }






                                                                                    {/* -------------- POSICIONAMETO PRINCIPAL SF --------------- */}
                                                                                    {user.id_permissao !== 4 ?

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Posicionamento Principal:{" "}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                style={{
                                                                                                    borderRadius: "50%",
                                                                                                    marginRight: "3px",
                                                                                                    marginTop: "7px",
                                                                                                    height: "8px",
                                                                                                    width: "8px",
                                                                                                    marginLeft: "10px",
                                                                                                    backgroundColor: posicionamentoColor(
                                                                                                        posicionamentoDescricao(
                                                                                                            z.cliente
                                                                                                                .proposicao_cliente_posicaoDaOrganizacao
                                                                                                        )
                                                                                                    ),
                                                                                                }}
                                                                                            ></Grid>
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                }}
                                                                                            >
                                                                                                {posicionamentoDescricao(
                                                                                                    z.cliente
                                                                                                        .proposicao_cliente_posicaoDaOrganizacao
                                                                                                )}
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        :

                                                                                        null

                                                                                    }









                                                                                    {/* -------------- POSICIONAMETO DETALHADO SF --------------- */}
                                                                                    {user.id_permissao !== 4 ?


                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Posicionamento Detalhado:{" "}
                                                                                                {
                                                                                                    z.acoes_preparatorias.length === 0 ?
                                                                                                        <span style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            color: "black",
                                                                                                        }}>Não definido</span>


                                                                                                        :
                                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        null
                                                                                    }













                                                                                    {/* -------------- ACAO PREPARATÓRIA SF --------------- */}

                                                                                    {z.acoes_preparatorias.length > 0 && user.id_permissao !== 4 ?
                                                                                        z.acoes_preparatorias.map((t) => {
                                                                                            return (

                                                                                                t.nota_tecnica.map((q) => {
                                                                                                    return (
                                                                                                        <Grid

                                                                                                            style={{

                                                                                                                marginLeft: "14px",
                                                                                                            }}
                                                                                                        >
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    fontSize: pallet.general.size16,
                                                                                                                    marginTop: "3px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                            </Grid>
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    flexDirection: "row",
                                                                                                                }}>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                            posicionamentoDescricao(
                                                                                                                                q.nota_tecnica_posicionamento
                                                                                                                            )
                                                                                                                        ),
                                                                                                                    }}
                                                                                                                ></Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {posicionamentoDescricao(
                                                                                                                        q.nota_tecnica_posicionamento
                                                                                                                    )},
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                    }}
                                                                                                                ></Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>




                                                                                                    )
                                                                                                })


                                                                                            );
                                                                                        })
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }
                                                                                    {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                    {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4  ? 
                                                                                                        <Grid     style={{fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :z.posicionamento_unidade_negocio.length < 0 && user.id_permissao !== 4  ?
                                                                                                 <Grid     style={{fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  <span style={{color:'black'}}>Não possui</span></Grid>
                                                                                                :
                                                                                                null    
                                                                                                
                                                                                                }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4 ? 
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>
                                                                                </Grid>


                                                                            );
                                                                        })}

                                                                    </Grid>
                                                                );
                                                            })}


                                                        </Grid>

                                                    );
                                                })}

                                            </Grid>
                                        </Grid>
                                    );
                                })}


                            {relatorio.filter((k) =>
                                k.eventos.find((i) =>
                                    i.pautas.every((f) => f.proposicoes.length > 0)
                                )
                            )
                                .filter((q) => q.eventos.find(
                                    (v) =>
                                        v.agenda_casa_sigla !== "SF" &&
                                        v.agenda_casa_sigla !== "CD" &&
                                        v.agenda_casa_sigla !== "CN"
                                )).map((x, o) => {
                                    return (
                                        <Grid>
                                            <Grid style={{ display: o === 0 ? "block" : "none", marginTop: '30px', }}>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: '990px',
                                                        height: "2px",
                                                    }}
                                                ></Grid>
                                                <Grid
                                                    style={{
                                                        backgroundColor: `${pallet.general.color1}`,
                                                        width: "370px",
                                                        height: "80px",
                                                        marginLeft: "25px",
                                                        borderBottomLeftRadius: "10px",
                                                        borderBottomRightRadius: "30px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Grid style={{ marginBottom: "8px" }}>
                                                        <img
                                                            style={{
                                                                width: "35px",
                                                                height: "30px",
                                                                marginTop: "10px",
                                                                marginLeft: "10px",
                                                            }}
                                                            src={PlayArrowIcon}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            color: "white",
                                                            fontSize: `${pallet.general.size12}`,
                                                        }}
                                                    >
                                                        OUTROS
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ padding: "20px" }}>
                                                <Grid

                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: '990px',

                                                    }}>
                                                    <Grid
                                                        style={{
                                                            height: "38px",
                                                            width: "35px",
                                                            borderBottomLeftRadius: "5px",
                                                            borderTopLeftRadius: "5px",
                                                            backgroundColor: pallet.backgroundColorQuaternary,
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >

                                                        <CalendarTodayIcon />
                                                    </Grid>
                                                    <Grid container
                                                        style={{
                                                            border: '1px solid #E8E8E8',
                                                            height: "38px",
                                                            width: "100%",
                                                            alignContent: 'center'
                                                        }}
                                                    >
                                                        <Grid
                                                            style={{
                                                                fontSize: pallet.general.size16,
                                                                marginTop: "3px",
                                                                marginBottom: "3px",
                                                                marginLeft: "5px",
                                                            }}

                                                        >
                                                            {diaSemana(
                                                                String(new Date(x.dia).getDay()).padStart(2, "0")
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                color: pallet.general.color1,
                                                                fontSize: pallet.general.size16,
                                                                marginLeft: "10px",
                                                                paddingTop: '3px'
                                                            }}

                                                        >
                                                            {moment(x.dia).format("DD/MM")}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {x.eventos.filter((v) => v.agenda_casa_sigla !== "SF" &&
                                                    v.agenda_casa_sigla !== "CD" &&
                                                    v.agenda_casa_sigla !== "CN").map((y) => {
                                                        return (
                                                            <Grid>
                                                                <Grid>
                                                                    <Grid
                                                                        style={{
                                                                            fontSize: pallet.general.size16,
                                                                            marginTop: "20px",
                                                                            color: pallet.general.color1,
                                                                        }}
                                                                    >
                                                                        {y.comissoes_descricao === "" || y.comissoes_descricao === null ?
                                                                            "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm")
                                                                            :
                                                                            "| " + moment(y.agenda_data_inicio).format("HH") + "H" + moment(y.agenda_data_inicio).format("mm") + " " + "- " + y.comissoes_descricao

                                                                        }
                                                                    </Grid>
                                                                    <Grid
                                                                        style={{
                                                                            fontSize: pallet.general.size16,
                                                                            marginTop: "5px",
                                                                            marginLeft: "8px",
                                                                        }}
                                                                    >
                                                                        {"LOCAL: " + y.agenda_local_nome}
                                                                    </Grid>
                                                                </Grid>
                                                                {y.pautas.filter(w => w.proposicoes.length > 0).map((b) => {
                                                                    return (
                                                                        <Grid style={{ marginBottom: "30px" }}>
                                                                            {b.agenda_pauta_regime ?
                                                                                <Grid
                                                                                    style={{
                                                                                        fontSize: pallet.general.size16,
                                                                                        marginTop: "5px",
                                                                                        marginLeft: "8px",
                                                                                    }}
                                                                                >
                                                                                    {"REGIME: " + b.agenda_pauta_regime}
                                                                                </Grid>
                                                                                :
                                                                                <Grid></Grid>
                                                                            }

                                                                            <Grid
                                                                                style={{
                                                                                    marginTop: "15px",
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    style={{
                                                                                        borderRadius: "50%",
                                                                                        border: "2px",
                                                                                        borderColor: "#EAE7E7",
                                                                                        width: "9px",
                                                                                        height: "9px",
                                                                                        marginTop: "6px",
                                                                                        backgroundColor: pallet.general.color1,
                                                                                    }}
                                                                                ></Grid>
                                                                                <Grid
                                                                                    style={{
                                                                                        fontSize: pallet.general.size16,
                                                                                        marginLeft: "5px",
                                                                                        color: pallet.general.color1,
                                                                                    }}
                                                                                >
                                                                                    {"ITEM " + b.agenda_pauta_item}
                                                                                </Grid>
                                                                            </Grid>



                                                                            {/* -------------- NUMERO DA PROPOSICAO SF --------------- */}
                                                                            {b.proposicoes.map((z) => {
                                                                                return (

                                                                                    <Grid style={{ marginLeft: "15px", marginBottom: "5px" }}>
                                                                                        <Grid
                                                                                            style={{
                                                                                                fontSize: pallet.general.size16,
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >


                                                                                            {z.cliente.proposicao_cliente_apelido ?
                                                                                                <Grid>
                                                                                                    <a href={
                                                                                                        ini_end +
                                                                                                        "//" +
                                                                                                        end2 +
                                                                                                        "/proposicao/" +
                                                                                                        z.proposicao_id
                                                                                                    }
                                                                                                    >
                                                                                                        {
                                                                                                            z.proposicao_descricao
                                                                                                        }
                                                                                                    </a>
                                                                                                    <Grid>{" [" + z.cliente.proposicao_cliente_apelido + "] "}</Grid>
                                                                                                    {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                        <Grid style={{ color: "red" }} >
                                                                                                            <Grid>
                                                                                                                {" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}
                                                                                                            </Grid>
                                                                                                            <Grid>
                                                                                                                {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                                    <Grid style={{ color: "red" }} >
                                                                                                                        {"Ementa: " + z.ementa_proposicao_relacionada + ""}
                                                                                                                    </Grid>
                                                                                                                    : null
                                                                                                                }
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid> :
                                                                                                <Grid>
                                                                                                    <Grid>
                                                                                                        <a href={
                                                                                                            ini_end +
                                                                                                            "//" +
                                                                                                            end2 +
                                                                                                            "/proposicao/" +
                                                                                                            z.proposicao_id
                                                                                                        }
                                                                                                        >
                                                                                                            {
                                                                                                                z.proposicao_descricao + " "
                                                                                                            }
                                                                                                        </a>
                                                                                                        {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                            <Grid style={{ color: "red" }} >{" (Em pauta: " + z.proposicao_relacionada_descricao + " - " + z.autor_proposicao_relacionada + ")"}</Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    <Grid>
                                                                                                        {z.proposicao_relacionada_descricao !== 0 ?
                                                                                                            <Grid style={{ color: "red" }} >{"Ementa: " + z.ementa_proposicao_relacionada + ""}</Grid>
                                                                                                            : null
                                                                                                        }
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            }
                                                                                        </Grid>
                                                                                        {b.terminativo === true ?
                                                                                            <Grid
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row",
                                                                                                    marginTop: "5px",
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: "15px",
                                                                                                        height: "12px",
                                                                                                        marginRight: "5px",
                                                                                                    }}
                                                                                                    src={PlayArrowIcon}
                                                                                                />
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                    }}
                                                                                                >
                                                                                                    TERMINATIVO
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            :
                                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                                        }





                                                                                        {/* -------------- AUTORES SF --------------- */}

                                                                                        <Grid container style={{ maxWidth: '1000px' }}>
                                                                                            {z.autores.length > 1 ? (
                                                                                                <Grid container
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        marginTop: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    <Grid item>{'>> Autores:'}</Grid>
                                                                                                    <Grid item>
                                                                                                        {z.autores?.map((k, i) => {
                                                                                                            return (
                                                                                                                <Grid container>
                                                                                                                    <Grid item style={{ width: '100%' }}>
                                                                                                                        <a href={ini_end + "//" + end2 + "/interlocutor/" + k.proposicao_autores_id_parlamentar}>
                                                                                                                            {z.autores.length > 1 ? ' ' + k.proposicao_autores_nome + ', ' : ' ' + k.proposicao_autores_nome}
                                                                                                                        </a>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            );
                                                                                                        })}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            ) : z.autores.length == 1 ? (
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        marginTop: "10px",
                                                                                                        display: "flex",
                                                                                                        flexDirection: "row"

                                                                                                    }}
                                                                                                >
                                                                                                    <Grid>{'>> Autor:'}</Grid>
                                                                                                    {z.autores?.map((k) => {
                                                                                                        return (
                                                                                                            <a href={
                                                                                                                ini_end +
                                                                                                                "//" +
                                                                                                                end2 +
                                                                                                                "/interlocutor/" +
                                                                                                                k.proposicao_autores_id_parlamentar
                                                                                                            }
                                                                                                            >
                                                                                                                {k.proposicao_autores_nome}
                                                                                                            </a>
                                                                                                        );
                                                                                                    })}
                                                                                                </Grid>
                                                                                            ) : (
                                                                                                <Grid
                                                                                                    style={{ display: "none" }}
                                                                                                ></Grid>
                                                                                            )}
                                                                                        </Grid>




                                                                                        {/* -------------- EMENTA SF --------------- */}

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,

                                                                                                }}
                                                                                            >
                                                                                                {">>"} Ementa:{" "}


                                                                                                <span style={{ color: "black" }}>  {z.proposicao_ementa}</span>

                                                                                            </Grid>
                                                                                        </Grid>






                                                                                        {/* -------------- PARECER DO RELATOR SF --------------- */}

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Parecer do Relator:{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {b.agenda_pauta_parecer_relator !== "" && b.agenda_pauta_parecer_relator !== null && b.agenda_pauta_parecer_relator !== undefined ? b.agenda_pauta_parecer_relator : "Não informado"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                    flex: 1,
                                                                                                    flexWrap: "wrap",
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Relator:{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {b.agenda_pauta_nome_relator !== "" && b.agenda_pauta_nome_relator !== null && b.agenda_pauta_nome_relator !== undefined ? b.agenda_pauta_nome_relator : "Não informado"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>






                                                                                        {/* -------------- PEDIDO DE VISTA SF --------------- */}

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Pedido de vista:{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.pedido_de_vista ? z.pedido_de_vista : "Não informado"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>





                                                                                        {/* -------------- VOTO EM SEPARADO SF --------------- */}

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Voto em separado:{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.voto_em_separado ? z.voto_em_separado : "Não informado"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>






                                                                                        {/* -------------- SITUAÇÃO SF --------------- */}

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Situação:{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.situacao ? z.situacao : "Não informado"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        {/* -------------- ULTIMA TRAMITAÇAO SF --------------- */}

                                                                                        <Grid
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                marginTop: "5px",
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                style={{
                                                                                                    fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1,
                                                                                                }}
                                                                                            >
                                                                                                {">>"} Última tramitação:{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                        color: "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {z.ultima_tramitacao.length > 0 ? "Ocorreu em " + "[" +
                                                                                                        moment(z.ultima_tramitacao[0].data_atualizacao).format('DD-MM-yyyy') +
                                                                                                        "] - " + z.ultima_tramitacao[0].ultima_tramitacao : 'Não informado'}

                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        {/* -------------- DESPACHO SF --------------- */}

                                                                                        {b.agenda_pauta_despacho ?
                                                                                            <Grid
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row",
                                                                                                    marginTop: "5px",
                                                                                                }}
                                                                                            >
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1,
                                                                                                    }}
                                                                                                >
                                                                                                    {">>"} Despacho:{" "}
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            fontSize: pallet.general.size16,
                                                                                                            flex: 1,
                                                                                                            flexWrap: "wrap",
                                                                                                            color: "black",
                                                                                                        }}
                                                                                                    >
                                                                                                        {b.agenda_pauta_despacho}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            :
                                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                                        }






                                                                                        {/* -------------- POSICIONAMETO PRINCIPAL SF --------------- */}
                                                                                        {user.id_permissao !== 4 ?

                                                                                            <Grid
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row",
                                                                                                    marginTop: "5px",
                                                                                                }}
                                                                                            >
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1,
                                                                                                    }}
                                                                                                >
                                                                                                    {">>"} Posicionamento Principal:{" "}
                                                                                                </Grid>
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        borderRadius: "50%",
                                                                                                        marginRight: "3px",
                                                                                                        marginTop: "7px",
                                                                                                        height: "8px",
                                                                                                        width: "8px",
                                                                                                        marginLeft: "10px",
                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                            posicionamentoDescricao(z.cliente.proposicao_cliente_posicaoDaOrganizacao)
                                                                                                        ),
                                                                                                    }}
                                                                                                ></Grid>
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        flex: 1,
                                                                                                        flexWrap: "wrap",
                                                                                                    }}
                                                                                                >
                                                                                                    {posicionamentoDescricao(z.cliente.proposicao_cliente_posicaoDaOrganizacao)}
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            :

                                                                                            null

                                                                                        }









                                                                                        {/* -------------- POSICIONAMETO DETALHADO SF --------------- */}
                                                                                        {user.id_permissao !== 4 ?
                                                                                            <Grid
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row",
                                                                                                    marginTop: "5px",
                                                                                                }}
                                                                                            >
                                                                                                <Grid
                                                                                                    style={{
                                                                                                        fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1,
                                                                                                    }}
                                                                                                >
                                                                                                    {">>"} Posicionamento Detalhado:{" "}
                                                                                                    {
                                                                                                        z.acoes_preparatorias.length === 0 ?
                                                                                                            <span style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                color: "black",
                                                                                                            }}>Não definido</span>
                                                                                                            :
                                                                                                            <Grid style={{ display: "none" }}></Grid>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            :
                                                                                            null
                                                                                        }






                                                                                        {/* <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: pallet.general.size16,
                              color: pallet.general.color1,
                            }}
                          >
                            {">>"} Pedido de Vista:{" "}
                            <Text
                              style={{
                                fontSize: pallet.general.size16,
                                flex: 1,
                                flexWrap: "wrap",
                                color: "black",
                              }}
                            >
                              [19/03/2019] - Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </Text>
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: pallet.general.size16,
                              color: pallet.general.color1,
                              flexShrink: 1,
                            }}
                          >
                            {">>"} Declaração de Voto em Separado:{" "}
                            <Text
                              style={{
                                fontSize: pallet.general.size16,
                                color: "black",
                              }}
                            >
                              [19/03/2019] - Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit, sed do eiusmod tempor
                              incididunt ut labore et dolore magna aliqua. Ut
                              enim ad minim veniam, quis nostrud exercitation
                              ullamco laboris nisi ut aliquip ex ea commodo
                              consequat.
                            </Text>
                          </Text>
                        </View> */}







                                                                                        {/* -------------- ACAO PREPARATÓRIA SF --------------- */}

                                                                                        {z.acoes_preparatorias.length > 0 && user.id_permissao !== 4 ?
                                                                                        z.acoes_preparatorias.map((t) => {
                                                                                            return (
                                                                                                t.nota_tecnica.map((q) => {
                                                                                                    return (
                                                                                                        <Grid container
                                                                                                            style={{ marginLeft: "14px", }}
                                                                                                        >
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    fontSize: pallet.general.size16,
                                                                                                                    marginTop: "3px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {q.nota_tecnica_responsavel ? q.nota_tecnica_departamento + " | " + q.nota_tecnica_responsavel + " " : q.nota_tecnica_departamento + " "}
                                                                                                            </Grid>
                                                                                                            <Grid
                                                                                                                style={{
                                                                                                                    display: "flex",
                                                                                                                    flexDirection: "row",
                                                                                                                }}>
                                                                                                                <Grid style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                                                                                    /
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: posicionamentoColor(posicionamentoDescricao(q.nota_tecnica_posicionamento)),
                                                                                                                    }}
                                                                                                                />
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {posicionamentoDescricao(q.nota_tecnica_posicionamento)}
                                                                                                                </Grid>
                                                                                                                <Grid style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                                                                                                    /
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        borderRadius: "50%",
                                                                                                                        marginTop: "10px",
                                                                                                                        marginRight: "3px",
                                                                                                                        height: "8px",
                                                                                                                        width: "8px",
                                                                                                                        backgroundColor: prioridadeColor(prioridadeDescricao(q.nota_tecnica_prioridade)),
                                                                                                                    }}
                                                                                                                ></Grid>
                                                                                                                <Grid
                                                                                                                    style={{
                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                        marginTop: "3px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {prioridadeDescricao(q.nota_tecnica_prioridade) ? prioridadeDescricao(q.nota_tecnica_prioridade) : "Não definida"}

                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>




                                                                                                    )
                                                                                                })


                                                                                            );
                                                                                        })
                                                                                        :
                                                                                        <Grid style={{ display: "none" }}></Grid>
                                                                                    }
                                                                                                                                                                            {/* --------- POSICIONAMENTO POR UNIDADE DE NEGOCIO CD ----------- */}
                                                                                                                                                                            {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4 ?  
                                                                                                        <Grid     style={{fontSize: pallet.general.size16,
                                                                                                        color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio:  </Grid>
                                                                                                 :z.posicionamento_unidade_negocio.length <= 0 && user.id_permissao !== 4 ?
                                                                                                 <Grid     style={{fontSize: pallet.general.size16,
                                                                                                    color: pallet.general.color1}} item>{'>> '}Posicionamento Unidade de Negocio: <span style={{color:'black'}}>Não possui</span> </Grid>
                                                                                                    :null

                                                                                                }

                                                                                                            <Grid container style={{ maxWidth: '1000px' }}>
                                                                                                                {z.posicionamento_unidade_negocio.length > 0 && user.id_permissao !== 4 ?  
                                                                                                            
                                                                                                            <Grid container
                                                                                                            style={{
                                                                                                                fontSize: pallet.general.size16,
                                                                                                                marginTop: "5px",

                                                                                                            }}
                                                                                                        >
                                                                                                                    
                                                                                                                    <Grid item >
                                                                                                                        {z.posicionamento_unidade_negocio?.map((k, i) => {
                                                                                                                            return (
                                                                                                                                <Grid  container>
                                                                                                                                    <Grid item style={{ width: '100%', display: "flex",
                                                                                                                                   flexDirection: "row",margin:"1px solid red" }}>
                            
                                                                                                                                    {k.unidade_negocio_descricao}    
                                                                                                                                    <Grid                                            
                                                                                                                                    style={{
                                                                                                                                        borderRadius: "50%",
                                                                                                                                        marginRight: "3px",
                                                                                                                                        marginTop: "7px",
                                                                                                                                        height: "8px",
                                                                                                                                        width: "8px",
                                                                                                                                        marginLeft: "10px",
                                                                                                                                        backgroundColor: posicionamentoColor(
                                                                                                                                            posicionamentoDescricao(
                                                                                                                                                k.unidade_negocio_posicionamento
                                                                                                                                            )
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></Grid>

                                                                                                                                <Grid
                                                                                                                                    style={{
                                                                                                                                        fontSize: pallet.general.size16,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {posicionamentoDescricao(
                                                                                                                                        k.unidade_negocio_posicionamento
                                                                                                                                    )}
                                                                                                                            
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                                </Grid>
                                                                                                                        )})}
                                                                                                                        
                                                                                                                    </Grid>
                                                                                                        </Grid>
                                                                                                    
                                                                                                                        
                                                                                                                        
                                                                                                                : 
                                                                                                                    <Grid
                                                                                                                        style={{ display: "none" }}
                                                                                                                    ></Grid>}
                                                                                                </Grid>
                                                                                    </Grid>


                                                                                );
                                                                            })}

                                                                        </Grid>
                                                                    );
                                                                })}


                                                            </Grid>

                                                        );
                                                    })}

                                            </Grid>
                                        </Grid>
                                    );
                                })}



                        </tbody>
                    </table>





                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
