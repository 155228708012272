
import { useEffect, useState } from 'react'
import { usePallet } from './../../../../contexts/PalletContext';
import { useParams } from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import legis_agenda from "../../../../img/logo_legis_alta.png";
import { INTColaborativa } from './EditorNotaTecnicaColaborativa';
import ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaColaborativaService";


const NTColaborativaImprimir = () => {
  const { pallet } = usePallet();
  const { idAcao } = useParams();

  const [notaTecnica, setNotaTecnica] = useState<INTColaborativa>();

  useEffect(() => {
    if (idAcao) {
      ProposicaoAcaoInfluenciaNotaTecnicaColaborativaService.Get(Number(idAcao))
        .then(res => {
          setNotaTecnica(res)
        })
    }
  }, [idAcao])

  const Imprimir = () => {
    window.print()
  }

  return (
    <div>
      <div className="relativeCSS">
        <style type="text/css" media="print">
          {"\
                        @page { size: A4; }\
                    "}
        </style>
        <div className="flash" />
        <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact', margin: '0 auto' }} >
          <table className="testClass" style={{ width: '1000px' }} >
            <thead>
              <tr>
                <td>
                  <Grid container style={{
                    padding: "20px",
                    backgroundColor: `${pallet.general.color1}`,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: '70px'
                  }}>
                    <Grid style={{}}>
                      <img style={{ width: '100px' }} src={legis_agenda} />
                    </Grid>
                    <Grid style={{
                      color: 'white',
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size12}`
                    }}>
                      {/* Monitoramento Inteligente no Poder Legislativo */}
                    </Grid>
                    <Grid>
                      <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                        <PrintIcon />
                      </IconButton>
                    </Grid>

                  </Grid>
                </td>
              </tr>
            </thead>
          </table>

          <Grid>
            <div
              dangerouslySetInnerHTML={{
                __html: notaTecnica?.texto,
              }}></div>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default NTColaborativaImprimir