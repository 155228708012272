import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import styled2 from "styled-components";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, Divider, Box } from '@mui/material';
import { usePallet } from "../../../../contexts/PalletContext";
import InputDatePicker from '../../../../components/InputDatePicker';
import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import SolidButton from '../../../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import ParlamentarAcontecimentosService from '../../../../services/ParlamentarAcontecimentosService';
import { useAlert } from '../../../../contexts/AlertContext';
import ProposicaoService from '../../../../services/ProposicaoService';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DeadLineDate from '../../../../components/DeadLineDate';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import { proposicaoPosicionamento } from '../../../../models/Proposicao.Model';
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import ProposicaoEmendaCreateSchema from '../../models/ProposicaoEmendaCreateSchema';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs(props: {
    idEmenda: number
    reloadList?: () => void
    closeMenu?: () => void
    idProposicao: number
    idPermissao: number
}) {
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();

    const [dateApresentacao, setDateApresentacao] = React.useState();
    const [ementa, setEmenta] = React.useState("");
    const [numero, setNumero] = React.useState("");
    const [posicionamento, setPosicionamento] = React.useState<any>();
    const [idRef, setIdRef] = React.useState(props?.idProposicao)
    const [onSave, setOnSave] = React.useState(false)
    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);
    const [data, setData] = React.useState({});

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }


    const DotPosicionamento = styled2.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
    `;

    const corPosicionamento = (value: string) => {

        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
          }
    }


    function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
        return (
            <>
                <Grid container alignItems="center">
                    <Grid item>
                        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
                    </Grid>
                    <Grid item >
                        {props?.posicionamento}
                    </Grid>
                </Grid>
            </>
        )
    }

    const posicionamentosItens = [
        { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
        { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
        { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
        { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
        { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
        { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
        { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
        { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
        { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
        { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
        { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },


    ]

    const handleClickOpen = async (id) => {
        await ProposicaoService.GetEmenda(id).then(res => {     
            setIdRef(res?.data[0].id)
            setNumero(res?.data[0].numero)
            setDateApresentacao(res?.data[0].data_apresentacao)
            setEmenta(res?.data[0].ementa)
            // let relacaoPosicionamento = res?.data[0]?.posicionamento
            setPosicionamento(res?.data[0]?.posicionamento)

        });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props?.reloadList()
        props?.closeMenu()
    };
   
    /*
    const handleNumeroOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 8)
            setNumero(Number(event.target.value))
    };
    */

    const handleNumeroChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 8)
            setNumero(event.target.value)
    };
    const handleDateApresentacaoChange = (dateAc) => {
        setDateApresentacao(dateAc)
    };
    const handleEmentaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmenta(event.target.value)
    };
    const handlePosicionamentoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPosicionamento(event.target.value)
    };
    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {


        // return(
        // <div>


        // </div>
        // );
    }

    const salvarEdicaoEmenta = async (e) => {
        setOnSave(true);
        e.preventDefault();
        const data = {
            numero: numero,
            data_apresentacao: dateApresentacao,
            // emenda: ementa === "" ? undefined : ementa,
            ementa: ementa === "" ? undefined : ementa,
            posicionamento: posicionamento
        }


        setError(() => [])
        data && ProposicaoEmendaCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoEmendaCreateSchema.validate(data).catch(function (err) {
        });

        if (ProposicaoEmendaCreateSchema.isValidSync(data)) {

            await ProposicaoService.UpdateEmenda(data, props?.idEmenda)
                .then(res => {
                    if (res) {
                        handleClose()
                        NewAlert('success', 'Emenda editada com sucesso')
                        props?.reloadList()
                        props?.closeMenu()
                    } else {
                        NewAlert('error', 'Erro ao editar, verifique os campos e salve novamente');
                    }
                })//.then(x => loadAcontecimentos(props.id_parlamentar))
        }
    }

    async function handleOnAfterUpload() {
        if (idRef) {
            setIdRef(undefined);
            setOnSave(false);
        }
    }

    return (
        <div>
            <Button onClick={() => props.idPermissao !== 3 ? handleClickOpen(props?.idEmenda) : NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil.")}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight:"85px",
                    marginRight: "-10px"
                }}>
                <EditIcon />
                &nbsp; &nbsp;<div style={{fontSize: 0}}>@</div> Editar
            </Button>
            <BootstrapDialog id={`dialog-${props?.idEmenda}`} onClose={() => handleClose()}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose()}
                >
                    <Grid style={{
                        fontFamily: `${pallet.fontFamily}`,
                        fontSize: `${pallet.general.size18}`,
                        color: `${pallet.textColorSecondary}`,
                        padding: "12px",
                        marginTop: "7px",
                    }}>
                        EDITAR EMENDA
                    </Grid>
                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px" }} >
                    <Grid style={{ padding: "30px 25px 30px 25px" }}>
                        <Grid container spacing={3}
                            style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                        >
                            <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>

                                
                                <InputMultilineTextField
                                    type={"number"}
                                    // focused={true}
                                    // placeholder={"Digite"}
                                    maxRows={10}
                                    value={numero}
                                    defaultValue={numero}
                                    onChange={handleNumeroChange}
                                    id={"numero-emenda"}
                                    label="NÚMERO"
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='numero' errors={error} />
                                </Grid>
                            </Grid>
                            <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }} >
                                <LocalizationProvider dateAdapter={AdapterDateFns}
                                    style={{
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontWeight: pallet.general.weight_600,
                                        color: `${pallet.general.color1}`,
                                    }}>
                                    <DeadLineDate
                                        onChange={handleDateApresentacaoChange}
                                        label={"DATA DE APRESENTAÇÃO"}
                                        inputFormat={"dd/MM/yyyy"}
                                        // defaultValue={prazo}
                                        value={dateApresentacao}
                                        id={"data-apresentacao"}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <InputMultilineTextField
                                    type={"text"}
                                    // focused={true}
                                    placeholder={"Digite"}
                                    maxRows={10}
                                    value={ementa}
                                    defaultValue={ementa}
                                    onChange={handleEmentaChange}
                                    id={"descricao-ementa"}
                                    label="EMENTA"
                                />
                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                    <ErrorMessage name='ementa' errors={error} />
                                </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Box component="form">
                                    <SelectTextFieldA
                                        value={posicionamento}
                                        onChange={(e) => handlePosicionamentoChange(e)}
                                        options={posicionamentosItens}
                                        id={"posicionamento-ementa"}
                                        label={"POSICIONAMENTO"}

                                    />
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='posicionamento' errors={error} />
                                    </Grid>
                                </Box>
                                <Box>
                                </Box>
                            </Grid>
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                {/* <ErrorMessage name='apelido' errors={error} /> */}
                            </Grid>
                            <Grid xl={2} lg={2} xs={12} container style={{
                                paddingLeft: `${isMobile ? "25px" : "30px"}`
                            }}
                                justifyContent="flex-start"
                                alignItems="center">
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ paddingBottom: "10px" }}>
                        <Divider />
                    </Grid>
                    <Grid style={{ padding: "20px 18px 20px 18px" }}>
                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                marginLeft: "9px"
                            }}>
                                ANEXOS
                            </Grid>

                            <Grid className="container">
                                <AnexosComponent
                                    label=''
                                    modulo={EAnexoModulos.ProposicaoEmenda}
                                    id_referencia={idRef}
                                    canEdit={true}
                                    autosave={false}
                                    save={onSave}
                                    onAfterUpload={handleOnAfterUpload}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    //onBlur={handleOnBlur}
                    style={{
                        padding: "30px",
                        backgroundColor: `${pallet.backGroundPageColorPrimary}`
                    }}>

                    {/* <ButtonDialogConfirmation
                        styles={{
                            borderRadius: "6px 6px 0px 0px",
                            marginRight: "20px",
                            textTransform: "capitalize",
                            color: `${pallet.general.color1}`,
                        }}
                        title={"CONFIRMAÇÃO"}
                        title_button={"Cancelar"}
                        content={"CANCELAR CADASTRO?"}
                        message={"Esta ação não poderá ser desfeita."}
                        confirmation_button={"Cancelar"}
                        confirmationEvent={() => {
                            setOpen(false)
                        }}
                    /> */}
                    <SolidButton
                        onClick={salvarEdicaoEmenta}
                        // onChange={props?.reloadList}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                    />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );

}