import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import useStyles from "../../../../components/layout/Styles";
import SelectTextField from "../../../../components/SelectTextField";
import { useAlert } from '../../../../contexts/AlertContext';
import { usePallet } from "../../../../contexts/PalletContext";
import { IProposicao } from "../../../../models/Proposicao.Model";
import ProposicaoService from "../../../../services/ProposicaoService";

const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;

const GridColumn = styled(Grid)`
  padding: ${isMobile ? "8px 4px 8px 4px" : "15px"};  
  overflow-wrap: "break-word";

`;
const AnexoGrid = styled(Grid)`
  .upload-message {
    display: none;
  }
`;

export const ProposicaoConsultaAnaliseDeImpacto = (props: {
  proposicao: IProposicao;
  onChange: () => void;
  idPermissao?: number;
}) => {
  const { pallet } = usePallet();
  const classes = useStyles();

  const [idReferencia, setIdReferencia] = React.useState<any>(props.proposicao.id);
  const [apelido, setApelido] = React.useState(props.proposicao.cliente.apelido);
  const [impactoFinanceiroValor, setImpactoFinanceiroValor] = React.useState(String(props.proposicao.cliente.valor_impacto_financeiro));
  const [impactoFinanceiro, setImpactoFinanceiro] = React.useState(String(props.proposicao.cliente.impacto_financeiro) || '0');
  const [prioridade, setPrioridade] = React.useState(String(props.proposicao.cliente.prioridade) || '0');
  const [posicionamento, setPosicionamento] = React.useState(String(props.proposicao.cliente.posicionamento) || '0');
  const [notas, setNotas] = React.useState(props.proposicao.cliente.analise_detalhada);

  const handleChangeImpactoFinanceiro = (value: string) => {

    setImpactoFinanceiro(value);

    ProposicaoService.SetImpacto(props.proposicao.id, { data: { fatorImpactoFinanceiro: value } })
      .then(
        res => {
          if (res)
            NewAlert('success', 'Impacto financeiro salvo com sucesso.');
        })
  };

  const { NewAlert } = useAlert();

  const handleChangePrioridade = (value: string) => {
    setPrioridade(value);

    ProposicaoService.SetImpacto(props.proposicao.id, { data: { prioridade: value } })
      .then(
        res => {
          if (res)
            NewAlert('success', 'Prioridade salva com sucesso.');
          props.onChange();
        }
      )
  };

  const handleChangePosicionamento = (value: string) => {
    setPosicionamento(value);

    ProposicaoService.SetImpacto(props.proposicao.id, { data: { posicaoDaOrganizacao: value } })
      .then(
        res => {
          if (res)
            NewAlert('success', 'Posicionamento salvo com sucesso.');
          props.onChange();
        })
  };

  const handleBlurApelido = (e) => {
    setApelido(e.target.value);

    ProposicaoService.SetImpacto(props.proposicao.id, { data: { apelido: e.target.value } })
      .then(
        res => {
          if (res)
            NewAlert('success', 'Apelido salvo com sucesso.');
          props.onChange();
        })
  };

  const handleBlurImpactoFinanceiroValor = (e) => {
    setImpactoFinanceiroValor(e.target.value);

    ProposicaoService.SetImpacto(props.proposicao.id, { data: { impactoFinanceiro: e.target.value } })
      .then(
        res => {
          if (res)
            NewAlert('success', 'Valor salvo com sucesso.');
        })
  };

  const handleBlurAnaliseDetalhada = (e) => {
    setNotas(e.target.value);

    ProposicaoService.SetImpacto(props.proposicao.id, { data: { notas: e.target.value } })
      .then(
        res => {
          if (res)
            NewAlert('success', 'Análise salva com sucesso.');
        })
  };

  const prioridadeColor = (value: string) => {
    switch (value) {
      // case '0':
      //   return 'black'
      case "Máxima":
        return `${pallet.charts.red}`;
      case "Baixa":
        return `${pallet.charts.blue}`;
      case "Média":
        return `${pallet.charts.yellow}`;
      case "Alta":
        return `${pallet.charts.purple}`;
      case "Mínima":
        return `${pallet.charts.green}`;
      case "Não informado":
        return 'white';
      default:
        return `${pallet.charts.yellow}`;
    }
  };

  function LabelPrioridadeHandle(props: { prioridade: string, bg: string }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={prioridadeColor(`${props?.bg}`)} />
          </Grid>
          <Grid item >
            {props?.prioridade}
          </Grid>
        </Grid>
      </>
    )
  }

  const corPosicionamento = (value: string) => {
    switch (value) {
      case "Contrário":
        return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
        return `${pallet.charts.orange}`;
      case "Neutro":
        return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
        return `${pallet.charts.blue}`;
      case "Favorável":
        return `${pallet.charts.green}`;
      case "Pendente":
        return `${pallet.charts.yellow}`;
      case "Indefinido":
        return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
        return '#C71585';
      case "Favorável ao parecer do relator com emenda":
        return '#B8860B';
      case "Contrário ao parecer do relator":
        return '#facb74';
      case "Contrário ao parecer do relator com emenda":
        return '#000000';
      case "Não classificado":
        return '#2F4F4F';
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
    return (
      <>
      {

        isMobile ?
        <Grid style={{display: "flex", flexDirection:"row"}} alignItems="center">
        <Grid item>
          <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
        </Grid>
        <Grid item >
          {props?.posicionamento}
        </Grid>
      </Grid>

      :

      <Grid container alignItems="center">
      <Grid item>
        <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
      </Grid>
      <Grid item >
        {props?.posicionamento}
      </Grid>
    </Grid>
      }

      </>
    )
  }

  const corImpacto = (value: string) => {

    switch (value) {
      // case '0':
      //   return 'black'
      case 'Indiferente':
        return `${pallet.charts.lightGrey}`;
      case 'Positivo':
        return `${pallet.charts.green}`;
      case 'Negativo':
        return `${pallet.charts.red}`;
      case 'Não Mensurável':
        return `${pallet.charts.purple}`;
      case 'Não informado':
        return 'white';
      default:
        return `${pallet.charts.yellow}`;
    }
  }

  function LabelImpactoHandle(props: { impacto: string, bg: string }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corImpacto(`${props?.bg}`)} />
          </Grid>
          <Grid item >
            {props?.impacto}
          </Grid>
        </Grid>
      </>
    )
  }

  const prioridadesItens = [
    // { key: 0, label: <LabelPrioridadeHandle prioridade={"Selecione"} bg={'0'} />, value: "0" },
    { key: 1, label: <LabelPrioridadeHandle prioridade={"Mínima"} bg={"Mínima"} />, value: "1" },
    { key: 2, label: <LabelPrioridadeHandle prioridade={"Baixa"} bg={"Baixa"} />, value: "2" },
    { key: 3, label: <LabelPrioridadeHandle prioridade={"Média"} bg={"Média"} />, value: "3" },
    { key: 4, label: <LabelPrioridadeHandle prioridade={"Alta"} bg={"Alta"} />, value: "4" },
    { key: 5, label: <LabelPrioridadeHandle prioridade={"Máxima"} bg={"Máxima"} />, value: "5" },
    { key: 6, label: <LabelPrioridadeHandle prioridade={"Não informado"} bg={"Não informado"} />, value: "6" },
  ]
  const posicionamentosItens = [
    // { key: 0, label: <LabelHandlePosicionamento posicionamento={"Selecione"} bg={"0"} />, value: "0" },
    { key: 1, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "3" },
    { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "2" },
    { key: 3, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "1" },
    { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "4" },
    { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "5" },
    { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "6" },
    { key: 7, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "7" },
    { key: 8, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "8" },
    { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "9" },
    { key: 9, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "99" },
    { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "0" },
    { key: 10, label: <LabelHandlePosicionamento posicionamento={"Não classificado"} bg={"Não classificado"} />, value: "10" },
  ]

  const impactoFinanceiroItens = [
    // { key: 0, label: <LabelImpactoHandle impacto={"Selecione"} bg={'0'} />, value: "0" },
    { key: 1, label: <LabelImpactoHandle impacto={"Não Mensurável"} bg={"Não Mensurável"} />, value: "4" },
    { key: 2, label: <LabelImpactoHandle impacto={"Indiferente"} bg={"Indiferente"} />, value: "1" },
    { key: 3, label: <LabelImpactoHandle impacto={"Positivo"} bg={"Positivo"} />, value: "2" },
    { key: 4, label: <LabelImpactoHandle impacto={"Negativo"} bg={"Negativo"} />, value: "3" },
    { key: 5, label: <LabelImpactoHandle impacto={"Não informado"} bg={"Não informado"} />, value: "5" },
  ]


  const permissaoErro = () => {
    NewAlert(
      "error",
      "Não é possível realizar está ação com este perfil.")

  }

  return (
    <Grid
      style={{
        paddingLeft: `${isMobile ? "10px" : "50px"}`,
        paddingRight: `${isMobile ? "10px" : "50px"}`,
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            height: "10px",
            borderRadius: "5px",
            paddingLeft: `${isMobile ? "5px" : "20px"}`,
            paddingRight: `${isMobile ? "5px" : "20px"}`,
            flexFlow: "row-reverse",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              color: `${pallet.textColorTertiary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginLeft: "10px",
            }}
          >
            <Grid style={{ display: "flex" }}>ANÁLISE DE IMPACTO</Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            fontFamily: `${pallet.general.fontFamily}`,
            paddingRight: "30px",
            paddingLeft: "30px"
          }}
        >
          <Grid container spacing={3} >
            <Grid
              item
              lg={12}
              xs={12}
              style={{ marginTop: "30px" }}
            >
              <TextField
                name="apelido-textfield"
                label="APELIDO DA PROPOSIÇÃO"
                variant="outlined"
                value={apelido}
                style={{ width: "100%" }}
                placeholder="Digite"
                inputProps={{ maxLength: 40 }}
                InputProps={{
                  style: { fontFamily: `${pallet.general.fontFamily}` },
                  classes: { notchedOutline: classes.notchedOutline },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  }
                }}
                onBlur={props.idPermissao !== 3 ? handleBlurApelido : permissaoErro}
                onChange={(e) => {
                  props.idPermissao !== 3 ? setApelido(e.target.value) : NewAlert(
                    "error",
                    "Não é possível realizar está ação com este perfil.")
                }}
              ></TextField>
            </Grid>


            <Grid
              item
              lg={6}
              xs={12}

            >
              <SelectTextField
                name="impacto-financeiro-select"
                label="IMPACTO FINANCEIRO"
                style={{ width: "100%" }}
                value={impactoFinanceiro}
                defaultValue={'0'}
                options={impactoFinanceiroItens}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  }
                }}
                onChange={props.idPermissao !== 3 ? handleChangeImpactoFinanceiro : permissaoErro}
              />

            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
            >
              <TextField
                name="valor-impacto-financeiro-textfield"
                label="VALOR DO IMPACTO FINANCEIRO"
                style={{ width: "100%" }}
                placeholder="Digite"
                variant="outlined"
                type="number"
                value={impactoFinanceiroValor}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                  inputMode: 'decimal',
                  style: { fontFamily: `${pallet.general.fontFamily}` },
                  classes: { notchedOutline: classes.notchedOutline },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  }
                }}
                onBlur={props.idPermissao !== 3 ? handleBlurImpactoFinanceiroValor : permissaoErro}
                onChange={(e) => {
                  props.idPermissao !== 3 ? setImpactoFinanceiroValor(e.target.value) :
                  NewAlert(
                    "error",
                    "Não é possível realizar está ação com este perfil.")
                }}
              ></TextField>

            </Grid>
            <Grid
              item
              lg={6}
              xs={12}

            >

              <SelectTextField
                name="prioridade-select"
                label="PRIORIDADE"
                style={{ width: "100%" }}
                options={prioridadesItens}
                value={prioridade}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  }
                }}
                onChange={props.idPermissao !== 3 ? handleChangePrioridade : permissaoErro}
              />

              {/* <TextField
                  select
                  id="outlined-select-currency"
                  label="PRIORIDADE"
                  style={{ width: "100%" }}
                  InputProps={{style: {fontFamily:  `${pallet.general.fontFamily}`}}}
                  InputLabelProps={{shrink:true,
                    style: {fontFamily:  `${pallet.general.fontFamily}`,
                              color:"gray"} }}
                              placeholder="Digite"
                ></TextField> */}
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
            >

              <SelectTextField
                name="posicionamento-select"
                label="POSICIONAMENTO"
                style={{ width: "100%" }}
                value={posicionamento}
                defaultValue={'0'}
                options={posicionamentosItens}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  }
                }}
                onChange={props.idPermissao !== 3 ? handleChangePosicionamento : permissaoErro}
              />
              {/* <TextField
                  id="outlined-select-currency"
                  label="POSICIONAMENTO"
                  select
                  style={{ width: "100%" }}
                  InputProps={{style: {fontFamily:  `${pallet.general.fontFamily}`}}}
                  InputLabelProps={{shrink:true,
                    style: {fontFamily:  `${pallet.general.fontFamily}`,
                              color:"gray"} }}
                ></TextField> */}
            </Grid>

            <Grid item lg={12} xs={12} >
              <TextField
                name="analise-financeira-textfield"
                label="ANÁLISE DE IMPACTO DETALHADA"
                style={{ width: "100%" }}
                variant="outlined"
                InputProps={{
                  style: { fontFamily: `${pallet.general.fontFamily}` },
                  classes: { notchedOutline: classes.notchedOutline }
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: "gray"
                  },
                }}
                multiline
                placeholder="Digite"
                rows={3}
                value={notas}
                onBlur={props.idPermissao !== 3 ? handleBlurAnaliseDetalhada : permissaoErro}
                onChange={(e) => {
                  props.idPermissao !== 3 ? setNotas(e.target.value)
                  : NewAlert(
                    "error",
                    "Não é possível realizar está ação com este perfil.")
                }}
              ></TextField>

            </Grid>
            <Grid item lg={12} xs={12} >
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid item lg={12} xs={12} style={{ fontSize: pallet.general.size12, fontWeight: pallet.general.weight_600, marginRight: "-25px" }}>ANEXOS</Grid>
            <AnexoGrid style={{ marginRight: "50px", width: "100%", marginLeft: "25px" }}>
              <AnexosComponent
                label=''
                id_referencia={idReferencia}
                modulo={EAnexoModulos.AnaliseDeImpacto}
                canEdit={props.idPermissao !== 3 ? true : false}
                autosave={props.idPermissao !== 3 ? true : false}
              />
            </AnexoGrid>
          </Grid>

        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
