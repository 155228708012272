import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Grid } from "@mui/material";
// import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { usePallet } from "../../../../contexts/PalletContext";
import { useNavigate } from "react-router-dom";
import MenuButtonIconEditActive from './MenuButtonIconEditActive';
import { isMobile } from 'react-device-detect';


export interface IMenuButtonEditActive {
  idregister: number,
  active: boolean,
  path?: string
}

//## StyledCompenents
const TemaContent = styled(Grid)`
 align-items: center;
 border: 1px solid #EAEAEA;
 border-radius: 4px;
 margin: 5px 0;

 div.not-header {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     width: 100%;
     flex-direction: row;
     color: #1E0736;
     font-size: 15px;

     a { 
      cursor: pointer;
     }
     
     strong {
       font-size: 17px;
       margin: 0 20px;
     }
     p {
       font-size: 17px;
       font-weight: bold;
       color: #4B1196;
       margin: 0 10px 0 0;
     }
 }
 div.not-header-icon {
     background-color: #F2F2F2;
     width: 50px;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 4px 0px 0 4px;

     svg {
         color: #9B9B9B;
     }

     &.active {
       background-color: #DED3FF;

       svg { 
         color: #4B1196;
       }
     }
 }
`;


const TemaContentMob = styled(Grid)`
 border: 1px solid #EAEAEA;
 border-radius: 4px;


 div.not-header {
     color: #1E0736;
     font-size: 15px;

     a { 
      cursor: pointer;
     }
     
     strong {
       font-size: 17px;
       margin: 0 20px;
       }
       

 }
 div.not-header-icon {
     background-color: #F2F2F2;
     width: 50px;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 4px 0px 0 4px;

     svg {
         color: #9B9B9B;
     }

     &.active {
       background-color: #DED3FF;

       svg { 
         color: #4B1196;
       }
     }
 }
`;

export default function TemaCorporativoCard(props: {
  tema_id: number,
  tema_descricao: string,
  tema_seativo: boolean,
  onChange?: () => void

}) {
  const { pallet } = usePallet();
  const navigate = useNavigate();

  
  return (
    <>
  {

    isMobile ?
    <TemaContentMob>

      <Grid  style={{wordBreak:"break-word",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <Grid>
              <div className={`not-header-icon ${props.tema_seativo ? "active" : ""}`}>
                <AccountTreeIcon />
              </div>
            </Grid>
            <Grid style={{ alignSelf: "center",justifyContent:"center",textAlign:"center" }}>
              <a onClick={() => navigate(`/classificacaodetemas/tema/${props.tema_id}`)}>
                <strong>{props.tema_descricao}</strong>
              </a>
            </Grid>

        <Grid>
          <MenuButtonIconEditActive
            idregister={props.tema_id}
            seativo={props.tema_seativo}
            onChange={props.onChange}
          />

        </Grid>

      </Grid>

  </TemaContentMob>

  :

  <TemaContent>
  <div className="not-header">
    <Grid container justifyContent={"space-between"}>
      <Grid>
        <Grid container>
          <Grid>
            <div className={`not-header-icon ${props.tema_seativo ? "active" : ""}`}>
              <AccountTreeIcon />
            </div>
          </Grid>
          <Grid style={{ alignSelf: "center" }}>
            <a onClick={() => navigate(`/classificacaodetemas/tema/${props.tema_id}`)}>
              <strong>{props.tema_descricao}</strong>
            </a>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <MenuButtonIconEditActive
          idregister={props.tema_id}
          seativo={props.tema_seativo}
          onChange={props.onChange}
        />

      </Grid>

    </Grid>
  </div>
</TemaContent>
    
  }

    </>
  )
}