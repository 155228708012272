import React from 'react';
import { Paper, Typography } from '@mui/material';
import { usePallet } from '../contexts/PalletContext';
import styled from "styled-components";

const InfoCard = (props: { title: string }) => {
    const { pallet } = usePallet();

    return (

        <Paper elevation={0}>
            <Typography
                style={{
                    color: `${pallet.color.secundary.font}`,
                    fontSize: `${pallet.fontSize.titleWidgets}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.subtitle,
                }}>

                {props.title}

            </Typography>
        </Paper>

    )
}

export const PreText = styled.span`
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    max-height: calc(1.2em * 7); 
    overflow-y: auto;
    display: block;
    line-height: 1.5;
`;

export default InfoCard;
