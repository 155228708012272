import { Divider, Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ComboBox from "../../../components/ComboBox";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import React, { useEffect, useState } from "react";
import PieChartInfoCard from "../../../components/PieChartInfoCard";
import PieChartInfoInsight from "./PieChartInfoInsight";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import ErrorMessage from "../../../components/ErrorMessage";
import { formatDate } from "../../../utils/dateUtils";
import { useNavigate } from "react-router-dom";

export function AccordionContentKey14(props: {
  // filtros?: IFiltroTipoProps[],
  titleFederal?: string;
  originFederal?: string;
  dtIni?: Date;
  dtFin?: Date;
  esfera?: string;
  onChange?: (event: any) => void;
}) {

  const [casa, setCasa] = React.useState<number>(0);
  const [casaLabel, setCasaLabel] = React.useState<string>("");
  const [casaItens, setCasaItens] = React.useState<Array<IFilterData>>([]);
  // const [ambito, setambito] = React.useState<string>("");
  const [ambito, setAmbito] = React.useState<string>("");
  const [ambitoItens, setAmbitoItens] = React.useState<Array<IFilterData>>([]);
  const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
  const { pallet } = usePallet();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const handleCasa = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: number
  ) => {

    setCasa(Number(event.target.value));


    TabelasService.getTabelaCasasEstaduaisLabel(Number(event.target.value)).then ((res) => {
      setCasaLabel(res[0]?.nome);
    })

    
  };



  const handleSiglaCasaLabel = (casa: number) => {
    switch (casa) {
      case 1:
        return "SF";
      case 2:
        return "CD";
      case 3:
        return "CN";
      default:
        return "Todas";
    }
  };

  const [listaProposicoesGrupo, setListaProposicoesGrupo] = React.useState([]);
  const [listaProposicoesTema, setListaProposicoesTema] = React.useState([]);
  const [listaProposicoesSubTema, setListaProposicoesSubTema] = React.useState([]);
  
  const [listaProposicoesAmbito, setListaProposicoesAmbito] = React.useState([]);
  const [grupo_1, setGrupo_1] = React.useState();
  const [grupo_2, setGrupo_2] = React.useState();
  const [grupo_3, setGrupo_3] = React.useState();
  const [grupo_4, setGrupo_4] = React.useState();
  const [grupo_5, setGrupo_5] = React.useState();
  const [outrosGrupos, setOutrosGrupos] = React.useState();


  const [tema_1, setTema_1] = React.useState();
  const [tema_2, setTema_2] = React.useState();
  const [tema_3, setTema_3] = React.useState();
  const [tema_4, setTema_4] = React.useState();
  const [tema_5, setTema_5] = React.useState();
  const [outrosTemas, setOutrosTemas] = React.useState();

  const [subTema_1, setSubTema_1] = React.useState();
  const [subTema_2, setSubTema_2] = React.useState();
  const [subTema_3, setSubTema_3] = React.useState();
  const [subTema_4, setSubTema_4] = React.useState();
  const [subTema_5, setSubTema_5] = React.useState();
  const [outrosSubTemas, setOutrosSubTemas] = React.useState();

  const [tema_1Ambito, setTema_1Ambito] = React.useState();
  const [tema_2Ambito, setTema_2Ambito] = React.useState();
  const [tema_3Ambito, setTema_3Ambito] = React.useState();
  const [tema_4Ambito, setTema_4Ambito] = React.useState();
  const [tema_5Ambito, setTema_5Ambito] = React.useState();
  const [outrosAmbito, setOutrosAmbito] = React.useState();


  const Title = styled(Grid)`
  padding-left:  ${isMobile ? '5px' : '24px'};
  padding-top: ${isMobile ? '5px' : '30px'} ;
  background-color: ${pallet.backGroundPageColorPrimary};
  text-transform: uppercase;
  font-size:${pallet.general.size14};
  color: ${pallet.textColorQuaternary};
  font-family: ${pallet.general.fontFamily};
  place-content:  ${isMobile ? 'center' : 'unset'};
  font-weight: ${pallet.general.weight_600};
  height: 79px;
`;

  // React.useEffect(() => {
  //   if (ambito) {
  //     TabelasService.getAmbitoBySiglaOrgao(ambito).then((res) => {
  //       if (res) {
  //         setambito(res[0]?.sigla)
  //       }
  //     });
  //   }
  // }, [ambito]);

  // const handleAmbitoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAmbito(event.target.value);
  // };


  React.useEffect(() => {
  TabelasService.getTabelaCasasEstaduais()
  .then(async res => {
      const data = []
      data.push({ key: "", label: '-- Selecione -- ', value: "" });
      res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
      setCasaItens(() => [...data]);
  });




// }
}, []);

  // React.useEffect(() => {
  //   if (casa) {
  //     TabelasService.getTabelaAmbitosTipo(casa).then(async (res) => {
  //       const data = [];
  //       data.push({ key: "", label: "-- Selecione -- ", value: "" });
  //       res?.map((item) =>
  //         data.push({
  //           key: item.id,
  //           label: item.sigla + " - " + item.resumo,
  //           value: item.sigla,
  //         })
  //       );
  //       setAmbitoItens(() => [...data]);

  //     });
  //   }
  // }, [casa]);

  const esferaConv = (value: any) => {
    switch (value) {
      case "F":
        return 1;
      case "E":
        return 2;
      case "M":
        return 3;
    }
  };

  React.useEffect(() => {
    if (casa) {




      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey14CasaGrupo(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          casa,
          esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            if (casa === 0) {
              setListaProposicoesGrupo([])

            }
            setListaProposicoesGrupo([])
            setListaProposicoesGrupo(res.data);

          }
        });


        InsightService.GetKey14CasaTema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          casa,
          esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            if (casa === 0) {
              setListaProposicoesTema([])

            }
            setListaProposicoesTema([])
            setListaProposicoesTema(res.data);

          }
        });



        InsightService.GetKey14CasaSubTema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          casa,
          esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            if (casa === 0) {
              setListaProposicoesSubTema([])

            }
            setListaProposicoesSubTema([])
            setListaProposicoesSubTema(res.data);

          }
        });


        InsightService.GetKey14CasaGrupoGrafico(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          casa,
          esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            setGrupo_1(null);
            setGrupo_2(null);
            setGrupo_3(null);
            setGrupo_4(null);
            setGrupo_5(null);
            setOutrosGrupos(null);
            setGrupo_1(res.data.grupo_1);
            setGrupo_2(res.data.grupo_2);
            setGrupo_3(res.data.grupo_3);
            setGrupo_4(res.data.grupo_4);
            setGrupo_5(res.data.tema_5);
            setOutrosGrupos(res.data.outros);

          }
        });


        InsightService.GetKey14CasaTemaGrafico(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          casa,
          esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            setTema_1(null);
            setTema_2(null);
            setTema_3(null);
            setTema_4(null);
            setTema_5(null);
            setOutrosTemas(null);
            setTema_1(res.data.tema_1);
            setTema_2(res.data.tema_2);
            setTema_3(res.data.tema_3);
            setTema_4(res.data.tema_4);
            setTema_5(res.data.tema_5);
            setOutrosTemas(res.data.outros);

          }
        });


        InsightService.GetKey14CasaSubTemaGrafico(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          casa,
          esferaConv(props.esfera)
        ).then((res) => {
          if (res) {
            setSubTema_1(null);
            setSubTema_2(null);
            setSubTema_3(null);
            setSubTema_4(null);
            setSubTema_5(null);
            setOutrosSubTemas(null);
            setSubTema_1(res.data.subtema_1);
            setSubTema_2(res.data.subtema_2);
            setSubTema_3(res.data.subtema_3);
            setSubTema_4(res.data.subtema_4);
            setSubTema_5(res.data.subtema_5);
            setOutrosSubTemas(res.data.outros);

          }
        });


      }
    }
  }, [casa, props.dtIni, props.dtFin]);

  // React.useEffect(() => {
  //   if (ambito) {
  //     if (
  //       String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
  //       String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
  //     ) {
  //       InsightService.GetKey11Ambito(
  //         format(new Date(props.dtIni), "yyyy-MM-dd"),
  //         format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
  //         casa,
  //         ambito,
  //         esferaConv(props.esfera)
  //       ).then((res) => {
  //         if (res) {
  //           setListaProposicoesAmbito([])
  //           setListaProposicoesAmbito(res.data);
  //         }
  //       });
  //       if (listaProposicoesAmbito) {
  //         InsightService.GetKey11AmbitoGrafico(
  //           format(new Date(props.dtIni), "yyyy-MM-dd"),
  //           format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
  //           casa,
  //           ambito,
  //           esferaConv(props.esfera)
  //         ).then((res) => {
  //           if (res) {
  //             setTema_1Ambito(null);
  //             setTema_2Ambito(null);
  //             setTema_3Ambito(null);
  //             setTema_4Ambito(null);
  //             setTema_5Ambito(null);
  //             setOutrosAmbito(null);
  //             setTema_1Ambito(res.data.tema_1);
  //             setTema_2Ambito(res.data.tema_2);
  //             setTema_3Ambito(res.data.tema_3);
  //             setTema_4Ambito(res.data.tema_4);
  //             setTema_5Ambito(res.data.tema_5);
  //             setOutrosAmbito(res.data.outros);

  //           }
  //         });
  //       }
  //     }
  //   }
  // }, [casa, ambito, props.dtIni, props.dtFin]);



  const navigate = useNavigate();


  return (
    <Grid>
      {/* {
         casa !== undefined ? 
      } */}
      <Grid
        container
        style={{
          justifyContent: casa !== 0 ? "space-around" : "unset",
          marginLeft: `${isMobile ? "0px" : casa !== 0 ? "0px" : "44px"}`,
          paddingTop: `${isMobile ? "40px" : "30px"}`,
          paddingBottom: `${isMobile ? "20px" : "30px"}`,
          paddingLeft: `${isMobile ? "10px" : "0px"}`,
          paddingRight: `${isMobile ? "10px" : "0"}`,
        }}
      >
        {/* // loading ? null : */}
        <Grid
          item
          md={5}
          xs={12}
          lg={5}
          style={{ paddingBottom: `${isMobile ? "20px" : "0px"}` }}
        >
          <SelectTextFieldA
            name={"casa-estadual"}
            value={casa}
            onChange={handleCasa}
            options={casaItens}
            label={"CASA LEGISLATIVA"}
            id={"casa-estadual"}
            focused={true}
            placeholder={"Selecione"}
          />

        </Grid>


        {/* {casa ? (
          <Grid item md={5} xs={12} lg={5}>
            <SelectTextFieldA
              name={"ambito"}
              value={ambito}
              onChange={handleAmbitoOnChange}
              options={ambitoItens}
              label={"ÂMBITO"}
              id={"ambito"}
              focused={true}
              placeholder={"Selecione"}
            />

          </Grid>
        ) : null} */}
      </Grid>

      <Grid style={{ marginBottom: "32px" }}>
        <Divider variant="fullWidth" />
      </Grid>
      {casa !== 0 ?
        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          <PieChartInfoInsight
            title="CASA LEGISLATIVA"
            subtitle={casaLabel}
            labels={[
              {
                key: listaProposicoesGrupo[0]?.descricao,
                data: listaProposicoesGrupo[0] ? grupo_1 : null,
                color: `${pallet.color.charts.blue}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:00") + '"},{"key":"grupos","label":"GRUPO:' + listaProposicoesGrupo[0]?.descricao + '","value":"' + listaProposicoesGrupo[0]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesGrupo[1]?.descricao,
                data: listaProposicoesGrupo[1] ? grupo_2 : null,
                color: `${pallet.color.charts.green}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"grupos","label":"GRUPO:' + listaProposicoesGrupo[1]?.descricao + '","value":"' + listaProposicoesGrupo[1]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesGrupo[2]?.descricao,
                data: listaProposicoesGrupo[2] ? grupo_3 : null,
                color: `${pallet.color.charts.purple}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"grupos","label":"GRUPO:' + listaProposicoesGrupo[2]?.descricao + '","value":"' + listaProposicoesGrupo[2]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesGrupo[3]?.descricao,
                data: listaProposicoesGrupo[3] ? grupo_4 : null,
                color: `${pallet.color.charts.red}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"grupos","label":"GRUPO:' + listaProposicoesGrupo[3]?.descricao + '","value":"' + listaProposicoesGrupo[3]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesGrupo[4]?.descricao,
                data: listaProposicoesGrupo[4] ? grupo_5 : null,
                color: `${pallet.color.charts.yellow}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"grupos","label":"GRUPO:' + listaProposicoesGrupo[4]?.descricao + '","value":"' + listaProposicoesGrupo[4]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },

              {
                key: outrosGrupos ? "Outros" : undefined,
                data: outrosGrupos ? outrosGrupos : null,
                color: `${pallet.color.charts.lightGrey}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"outrosGrupo","label":"GRUPO: OUTROS","value":"' + listaProposicoesGrupo.filter((a, b) => b < 5).map((item) => {

                    return (
                      item.id


                    )
                  }).join(',') + '"},{"key":"casa","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]',

              },

            ]}
          />
        </Grid>
        : null}
      {casa !== 0 ?
        <Grid
          style={{
            margin: `${isMobile ? "30px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          {listaProposicoesGrupo.length > 0 ?

            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                GRUPO
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES NOVAS
              </Title>
              <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                {listaProposicoesGrupo?.map((x, i) => {
                  return (
                    <>
                      <Grid container lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderLeft: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                        borderRadius: i + 1 !== listaProposicoesGrupo.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                        placeContent: `${isMobile ? 'center' : 'unset'}`,
                        textAlign: "center"
                      }}>
                        <Grid container item
                          style={{
                            paddingBottom: '5px',
                            minWidth: '100px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`,
                            textAlign: "center"

                          }}
                        >
                          {x.descricao}
                        </Grid>
                      </Grid>


                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaProposicoesGrupo.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                        onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                          '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"},{"key":"grupos","label":"GRUPO: ' + x.descricao + '","value":"' + x.id + '"}]')}>
                        {x.count}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
            :
            null
          }
        </Grid>
        : null}


    {casa !== 0 ?
        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          <PieChartInfoInsight
            title="CASA LEGISLATIVA"
            subtitle={casaLabel}
            labels={[
              {
                key: listaProposicoesTema[0]?.descricao,
                data: listaProposicoesTema[0] ? tema_1 : null,
                color: `${pallet.color.charts.blue}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"temas","label":"TEMA:' + listaProposicoesTema[0]?.descricao + '","value":"' + listaProposicoesTema[0]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesTema[1]?.descricao,
                data: listaProposicoesTema[1] ? tema_2 : null,
                color: `${pallet.color.charts.green}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"temas","label":"TEMA:' + listaProposicoesTema[1]?.descricao + '","value":"' + listaProposicoesTema[1]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesTema[2]?.descricao,
                data: listaProposicoesTema[2] ? tema_3 : null,
                color: `${pallet.color.charts.purple}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"temas","label":"TEMA:' + listaProposicoesTema[2]?.descricao + '","value":"' + listaProposicoesTema[2]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesTema[3]?.descricao,
                data: listaProposicoesTema[3] ? tema_4 : null,
                color: `${pallet.color.charts.red}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"temas","label":"TEMA:' + listaProposicoesTema[3]?.descricao + '","value":"' + listaProposicoesTema[3]?.id + '"},{"key":"casa","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesTema[4]?.descricao,
                data: listaProposicoesTema[4] ? tema_5 : null,
                color: `${pallet.color.charts.yellow}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"temas","label":"TEMA:' + listaProposicoesTema[4]?.descricao + '","value":"' + listaProposicoesTema[4]?.id + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },

              {
                key: outrosGrupos ? "Outros" : undefined,
                data: outrosGrupos ? outrosGrupos : null,
                color: `${pallet.color.charts.lightGrey}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"outrosTemas","label":"TEMA: OUTROS","value":"' + listaProposicoesTema.filter((a, b) => b < 5).map((item) => {

                    return (
                      item.id


                    )
                  }).join(',') + '"},{"key":"casa","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]',

              },

            ]}
          />
        </Grid>
        : null}
      {casa !== 0 ?
        <Grid
          style={{
            margin: `${isMobile ? "30px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          {listaProposicoesTema.length > 0 ?

            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                TEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES NOVAS
              </Title>
              <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                {listaProposicoesTema?.map((x, i) => {
                  return (
                    <>
                      <Grid container lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderLeft: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                        borderRadius: i + 1 !== listaProposicoesTema.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                        placeContent: `${isMobile ? 'center' : 'unset'}`,
                        textAlign: "center"
                      }}>
                        <Grid container item
                          style={{
                            paddingBottom: '5px',
                            minWidth: '100px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`,
                            textAlign: "center"

                          }}
                        >
                          {x.descricao}
                        </Grid>
                      </Grid>


                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaProposicoesTema.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                        onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"Estadual: Federal","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                          '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"},{"key":"temas","label":"TEMA: ' + x.descricao + '","value":"' + x.id + '"}]')}>
                        {x.count}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
            :
            null
          }
        </Grid>
        : null}




      {casa !== 0 ?
        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          <PieChartInfoInsight
            title="CASA LEGISLATIVA"
            subtitle={casaLabel}
            labels={[
              {
                key: listaProposicoesSubTema[0]?.descricao,
                data: listaProposicoesSubTema[0] ? subTema_1 : null,
                color: `${pallet.color.charts.blue}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"subtemas","label":"SUBTEMA:' + listaProposicoesSubTema[0]?.descricao + '","value":"' + listaProposicoesSubTema[0]?.id_subtema + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesSubTema[1]?.descricao,
                data: listaProposicoesSubTema[1] ? subTema_2 : null,
                color: `${pallet.color.charts.green}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"subtemas","label":"SUBTEMA:' + listaProposicoesSubTema[1]?.descricao + '","value":"' + listaProposicoesSubTema[1]?.id_subtema + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesSubTema[2]?.descricao,
                data: listaProposicoesSubTema[2] ? subTema_3 : null,
                color: `${pallet.color.charts.purple}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"subtemas","label":"SUBTEMA:' + listaProposicoesSubTema[2]?.descricao + '","value":"' + listaProposicoesSubTema[2]?.id_subtema + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesSubTema[3]?.descricao,
                data: listaProposicoesSubTema[3] ? subTema_4 : null,
                color: `${pallet.color.charts.red}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"subtemas","label":"SUBTEMA:' + listaProposicoesSubTema[3]?.descricao + '","value":"' + listaProposicoesSubTema[3]?.id_subtema + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },
              {
                key: listaProposicoesSubTema[4]?.descricao,
                data: listaProposicoesSubTema[4] ? subTema_5 : null,
                color: `${pallet.color.charts.yellow}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"subtemas","label":"SUBTEMA:' + listaProposicoesSubTema[4]?.descricao + '","value":"' + listaProposicoesSubTema[4]?.id_subtema + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]'

              },

              {
                key: outrosSubTemas ? "Outros" : undefined,
                data: outrosSubTemas ? outrosSubTemas : null,
                color: `${pallet.color.charts.lightGrey}`,
                url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                  formatDate(props.dtIni, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                  '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                  formatDate(props.dtFin, "dd/MM/yyyy") +
                  '","value" : "' +
                  formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") + '"},{"key":"outrosSubTemas","label":"SUBTEMA: OUTROS","value":"' + listaProposicoesSubTema.filter((a, b) => b < 5).map((item) => {

                    return (
                      item.id_subtema


                    )
                  }).join(',') + '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"}]',

              },

            ]}
          />
        </Grid>
        : null}
      {casa !== 0 ?
        <Grid
          style={{
            margin: `${isMobile ? "30px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          {listaProposicoesSubTema.length > 0 ?

            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                SUBTEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES NOVAS
              </Title>
              <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                {listaProposicoesSubTema?.map((x, i) => {
                  return (
                    <>
                      <Grid container lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderLeft: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                        borderRadius: i + 1 !== listaProposicoesSubTema.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                        placeContent: `${isMobile ? 'center' : 'unset'}`,
                        textAlign: "center"
                      }}>
                        <Grid container item
                          style={{
                            paddingBottom: '5px',
                            minWidth: '100px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`,
                            textAlign: "center"

                          }}
                        >
                          {x.descricao}
                        </Grid>
                      </Grid>


                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaProposicoesSubTema.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                        onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                          '"},{"key":"casaEstadual","label":"CASA: ' + casaLabel + '","value":"' + casa + '"},{"key":"subtemas","label":"SUBTEMA: ' + x.descricao + '","value":"' + x.id_subtema + '"}]')}>
                        {x.count}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
            :
            null
          }
        </Grid>
        : null}



      {/* {ambito ?
        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          <PieChartInfoInsight
            title="ÂMBITO"
            subtitle={
              ambito !== "" ? ambito : "Não definido"
            }
            labels={
              [
                {
                  key: listaProposicoesAmbito[0] ? listaProposicoesAmbito[0]?.tema : undefined,
                  data: listaProposicoesAmbito[0] ? tema_1Ambito : null,
                  color: listaProposicoesAmbito[0] ? `${pallet.color.charts.blue}` : undefined,
                  url: listaProposicoesAmbito[0] ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy") + '"},{"key":"tema","label":"TEMA:' + listaProposicoesAmbito[0]?.tema + '","value":"' + listaProposicoesAmbito[0]?.codigo_tema + '"},{"key":"casa","label":"CASA: ' + casaLabel + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]'
                    : undefined
                },
                {
                  key: listaProposicoesAmbito[1] ? listaProposicoesAmbito[1]?.tema : undefined,
                  data: listaProposicoesAmbito[1] ? tema_2Ambito : null,
                  color: listaProposicoesAmbito[1] ? `${pallet.color.charts.green}` : undefined,
                  url: listaProposicoesAmbito[1] ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy") + '"},{"key":"tema","label":"TEMA:' + listaProposicoesAmbito[1]?.tema + '","value":"' + listaProposicoesAmbito[1]?.codigo_tema + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]'
                    : undefined
                },
                {
                  key: listaProposicoesAmbito[2] ? listaProposicoesAmbito[2]?.tema : undefined,
                  data: listaProposicoesAmbito[2] ? tema_3Ambito : null,
                  color: listaProposicoesAmbito[2] ? `${pallet.color.charts.purple}` : undefined,
                  url: listaProposicoesAmbito[2] ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy") + '"},{"key":"tema","label":"TEMA:' + listaProposicoesAmbito[2]?.tema + '","value":"' + listaProposicoesAmbito[2]?.codigo_tema + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]'
                    : undefined
                },
                {
                  key: listaProposicoesAmbito[3] ? listaProposicoesAmbito[3]?.tema : undefined,
                  data: listaProposicoesAmbito[3] ? tema_4Ambito : null,
                  color: listaProposicoesAmbito[3] ? `${pallet.color.charts.red}` : undefined,
                  url: listaProposicoesAmbito[3] ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy") + '"},{"key":"tema","label":"TEMA:' + listaProposicoesAmbito[3]?.tema + '","value":"' + listaProposicoesAmbito[3]?.codigo_tema + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]'
                    : undefined
                },
                {
                  key: listaProposicoesAmbito[4] ? listaProposicoesAmbito[4]?.tema : undefined,
                  data: listaProposicoesAmbito[4] ? tema_5Ambito : null,
                  color: listaProposicoesAmbito[4] ? `${pallet.color.charts.yellow}` : undefined,
                  url: listaProposicoesAmbito[4] ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy") + '"},{"key":"tema","label":"TEMA:' + listaProposicoesAmbito[4]?.tema + '","value":"' + listaProposicoesAmbito[4]?.codigo_tema + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]'
                    : undefined
                },

                {
                  key: outrosAmbito ? "Outros" : undefined,
                  data: outrosAmbito ? outrosAmbito : null,
                  color: outrosAmbito ? ` ${pallet.color.charts.lightGrey}` : undefined,
                  url: outrosAmbito ? '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy") + '"},{"key":"outrosAmbito","label":"TEMA: OUTROS","value":"' + listaProposicoesAmbito.filter((a, b) => b < 5).map((item) => {

                      return (
                        item.codigo_tema


                      )
                    }).join(',') + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]'
                    : undefined
                },

              ]}
          />
        </Grid>
        : null} */}
      {/* {ambito ?
        <Grid
          style={{
            margin: `${isMobile ? "30px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          {listaProposicoesAmbito.length > 0 ?
            <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
              <Title container style={{
                borderTop: '1px solid #EAEAEA',
                borderLeft: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                borderRadius: '6px 0px 0px 0px'
              }}
                item lg={6} xs={6}>
                TEMA
              </Title>
              <Title container item lg={6} xs={6}
                style={{
                  borderTop: '1px solid #EAEAEA',
                  borderRight: '1px solid #EAEAEA',
                  borderBottom: '1px solid #EAEAEA',
                  borderRadius: '0px 6px 0px 0px'
                }}>
                PROPOSIÇÕES NOVAS
              </Title>
              <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
                {listaProposicoesAmbito.map((x, i) => {
                  return (
                    <>
                      <Grid container lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderLeft: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRight: isMobile ? '1px solid #EAEAEA' : '0px',
                        borderRadius: i + 1 !== listaProposicoesAmbito.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                        placeContent: `${isMobile ? 'center' : 'unset'}`,
                        textAlign: "center"
                      }}>
                        <Grid container item
                          style={{
                            paddingBottom: '5px',
                            minWidth: '100px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            placeContent: `${isMobile ? 'center' : 'unset'}`,
                            textAlign: "center"

                          }}
                        >
                          {x.tema}
                        </Grid>
                      </Grid>
                      <Grid container item lg={6} xs={6}
                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaProposicoes.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                        onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy") +
                          '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy") +
                          '"},{"key":"tema","label":"TEMA: ' + x.tema + '","value":"' + x.codigo_tema + '"},{"key":"casa","label":"CASA: ' + handleCasaLabel(casa) + '","value":"' + casa + '"},{"key":"ambito","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"}]')}>
                        {x.count}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
            :
            null
          }
        </Grid>
        : null} */}
    </Grid>
  );
}

export default AccordionContentKey14;
