import { Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import FilterItemPanel from '../../../components/filter/FilterItemPanel';
import { IListFilterInputItem, IListFilterRadioItem, ListFilterCheckBoxItem, ListFilterInputItem, ListFilterRadioItem, ListFilterSwitchItem } from '../../../components/filter/ListFilterItems';
import TabelasService from '../../../services/TabelasService';
import { IFilterData } from '../../../components/filter/ListFilter';
import { useAuthorization } from '../../../contexts/AuthorizationContext';
import { isMobile } from 'react-device-detect';

const esferaItens: IListFilterRadioItem[] = [
    { label: 'Federal', value: 'Federal' },
    { label: 'Estadual', value: 'Estadual' },
    { label: 'Municipal', value: 'Municipal' },
]

const situacaoItens: IListFilterInputItem[] = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Afastado', label: 'Afastado', value: 'Afastado' },
    { key: 'Exercício', label: 'Exercício', value: 'Exercício' },
    { key: 'Fim de Mandato', label: 'Fim de Mandato', value: 'Fim de Mandato' },
    { key: 'Licença', label: 'Licença', value: 'Licença' },
    { key: 'Vacância', label: 'Vacância', value: 'Vacância' },
    { key: 'Suplência', label: 'Suplência', value: 'Suplência' },
    { key: 'Falecido', label: 'Falecido', value: 'Falecido' },
]

const casaItens: IListFilterInputItem[] = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Câmara', label: 'Câmara', value: '2' },
    { key: 'Senado', label: 'Senado', value: '1' },
]

const qualificacaoItens: IListFilterInputItem[] = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Convergente', label: 'Convergente', value: 'Convergente' },
    { key: 'Divergente', label: 'Divergente', value: 'Divergente' },
    { key: 'Neutro', label: 'Neutro', value: 'Neutro' },
    { key: 'Não Classificado', label: 'Não Classificado', value: 'Não Classificado' },
]

const cargoItens: IListFilterInputItem[] = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Presidente', label: 'Presidente', value: 'Presidente' },
    { key: 'Vice Presidente', label: 'Vice Presidente', value: 'Vice Presidente' },
    { key: 'Ministro', label: 'Ministro', value: 'Ministro' },
    { key: 'Secretario', label: 'Secretario', value: 'Secretario' },
    { key: 'Assessor', label: 'Assessor', value: 'Assessor' },
    { key: 'Congressista', label: 'Congressista', value: 'Congressista' },
    { key: 'Deputado', label: 'Deputado', value: 'Deputado' },
    { key: 'Senador', label: 'Senador', value: 'Senador' },
    { key: 'Governador', label: 'Governador', value: 'Governador' },
    { key: 'Vice Governador', label: 'Vice Governador', value: 'Vice Governador' },
    { key: 'Vereador', label: 'Vereador', value: 'Vereador' },
    { key: 'Prefeito', label: 'Prefeito', value: 'Prefeito' },
    { key: 'Vice Prefeito', label: 'Vice Prefeito', value: 'Vice Prefeito' },
    { key: 'Juiz', label: 'Juiz', value: 'Juiz' },
    { key: 'Promotor de Justiça', label: 'Promotor de Justiça', value: 'Promotor de Justiça' },
    { key: 'Desembargador', label: 'Desembargador', value: 'Desembargador' },
]

const seguindoItens: IListFilterInputItem[] = [
    // { key: '', label: '-- Selecione -- ', value: '' },
    { key: 'Sim', label: 'Seguidos', value: 'Sim' },
    { key: 'Não', label: 'Não seguidos', value: 'Não' },
    { key: '', label: 'Todos', value: '' },
]

const faixaEtariaItens: IListFilterInputItem[] = [
    { key: '', label: '-- Selecione -- ', value: '' },
    { key: '< 20', label: '< 20', value: '0' },
    { key: '20 a 30', label: '20 a 30', value: '1' },
    { key: '30 a 40', label: '30 a 40', value: '2' },
    { key: '40 a 50', label: '40 a 50', value: '3' },
    { key: '50 a 60', label: '50 a 60', value: '4' },
    { key: '70 a 80', label: '70 a 80', value: '5' },
    { key: '80 a 90', label: '80 a 90', value: '6' },
    { key: '90+', label: '90+', value: '7' },
]

const FiltroDiv = styled.div`
    /* min-width: 500px;  */
    max-width: ${isMobile ? `360px` : `500px`} ;

    .MuiAccordionSummary-root {
        justify-content: flex-start;

        .MuiAccordionSummary-content { 
            flex-grow: unset;
        }
    }

    div.MuiPaper-elevation:first-child {
        .MuiAccordionSummary-expandIconWrapper {
            display: none;
        }
    }

    div.MuiFormGroup-root {
        flex-direction: row;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    span.Mui-checked {
        color: #160342;
    }
`;

const TitleLabel = styled(Typography)`
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    color: #4B1196;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
`;


export default function ParlamentarListFilterItens(props: { filter: IFilterData[], onChangeFilter: (data: IFilterData[]) => void, idPermissao?: number }): JSX.Element {

    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [partidosAtual, setPartidosAtual] = React.useState<Array<IListFilterInputItem>>([]);
    const [estados, setEstados] = React.useState<Array<IListFilterInputItem>>([]);
    const [municipio, setMunicipio] = React.useState<Array<IListFilterInputItem>>([]);
    const [legislatura, setLegislatura] = React.useState<Array<IListFilterInputItem>>([]);
    const [ambitos, setAmbitos] = React.useState<Array<IListFilterInputItem>>([]);
    const [cargo, setCargo] = React.useState<Array<IListFilterInputItem>>([]);
    const [faixaEtaria, setFaixaEtaria] = React.useState<Array<IListFilterInputItem>>([]);
    const [profissoes, setProfissoes] = React.useState<Array<IListFilterInputItem>>([]);
    const [escolaridades, setEscolaridades] = React.useState<Array<IListFilterInputItem>>([]);

    React.useEffect(() => {
        setLegislatura(() => [
            { key: '', label: '-- Selecione -- ', value: '' },
            { key: '57', label: '57  - Atual', value: '57' },
            { key: '56', label: '56', value: '56' },
            { key: '55', label: '55', value: '55' },
            { key: '54', label: '54', value: '54' },
        ])
        TabelasService.getTabelaPartidoAtual().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res.map(sigla => data.push({ key: sigla, label: sigla, value: sigla }));
                setPartidosAtual(() => [...data]);
            })
        TabelasService.getTabelaEstados().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res.map(item => data.push({ key: item.sigla, label: item.nome, value: item.sigla }))
                setEstados(() => [...data]);
            })
        TabelasService.getTabelaProfissoes().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res.map(item => data.push({ key: item.profissao, label: item.profissao, value: item.profissao }))
                setProfissoes(() => [...data]);
            })
        TabelasService.getTabelaEscolaridades().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res.map(item => data.push({ key: item.escolaridade, label: item.escolaridade, value: item.escolaridade }))
                setEscolaridades(() => [...data]);
            })

        const casaFilter = props.filter.filter(item => item.key === 'casa');

        if (casaFilter.length) {
            TabelasService.getTabelaAmbitos(parseInt(casaFilter[0].value)).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.sigla, label: item.sigla + ' - ' + item.resumo, value: item.sigla }))
                    setAmbitos(() => [...data]);
                })
        }

        const estadoFilter = props.filter.filter(item => item.key === 'estado')

        if (estadoFilter.length) {
            TabelasService.getTabelaMunicipios(estadoFilter[0].value).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.nome, label: item.nome, value: item.nome }));
                    setMunicipio(() => [...data]);
                })
        }

    }, []);

    const handleChangeOpen = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => setExpanded(newExpanded ? panel : false);

    const check = (key: string, value: any): boolean => {
        const item = props.filter.filter(item => item.key === key);
        return (item.length === 1 && item[0].value === value);
    };

    const handleGenericOnClick = (data: any, value: any) => {

        let values = props.filter.filter(item => item.key !== data.target.name && item.key !== data.target.id)
        if (value && data.target.name) values.push({ key: data.target.name, label: `${data.target.name.toUpperCase()}: ${value}`, value: value })
        const hasMunicipal = values.some(x => x.value === 'Municipal');
        const hasFederal = values.some(x => x.value === 'Federal');
        const hasEstadual = values.some(x => x.value === 'Estadual');

        if (hasMunicipal && hasEstadual) {
            values = values.filter(x => x.key !== 'legislatura' && x.key !== 'casa' && x.key !== 'ambito');
        }

        if (hasFederal) {
            values = values.filter(x => x.key !== 'estado' && x.key !== 'municipio');
        }

        if (hasEstadual) {
            values = values.filter(x => x.key !== 'legislatura' && x.key !== 'casa' && x.key !== 'ambito' && x.key !== 'municipio');
        }

        props.onChangeFilter(values)
    }

    const handleCasaOnClick = (data: any, value: any) => {
        const values = props.filter.filter(item => item.key !== 'casa' /*&& item.key !== 'ambito'*/)
        let id = 0
        switch (value) {
            case '1':
                id = 1;
                values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Senado', value: value })
                break;
            case '2':
                id = 2;
                values.push({ key: data.target.name, label: data.target.name.toUpperCase() + ': Câmara', value: value })
                break;
        }
        if (id && id > 0) {
            TabelasService.getTabelaAmbitos(id).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.sigla, label: item.sigla + ' - ' + item.resumo, value: item.sigla }))
                    setAmbitos(() => [...data]);
                })
        } else {
            setAmbitos(() => []);
        }
        props.onChangeFilter(values)
    }

    const handleEstadoOnClick = (data: any, value: any) => {
        const values = props.filter.filter(item => item.key !== 'estado' && item.key !== 'municipal')

        if (value) {
            values.push({ key: data.target.name, label: `${data.target.name.toUpperCase()}: ${value}`, value: value })
            TabelasService.getTabelaMunicipios(value).then(
                res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.nome, label: item.nome, value: item.nome }));
                    setMunicipio(() => [...data]);
                })
        } else {
            setMunicipio(() => []);
        }
        props.onChangeFilter(values)
    }

    const handleFaixaEtariaOnClick = (data: any, value: any) => {
        const values = props.filter.filter(item => item.key !== 'faixaEtaria')

        if (value) {
            values.push({ key: data.target.name, label: `FAIXA ETÁRIA: ${faixaEtariaItens[value].label}`, value: value })
        }
        props.onChangeFilter(values);
    }

    const { user } = useAuthorization();
    return (
        <>
            <FiltroDiv >
                <FilterItemPanel
                    panel='panel1'
                    name='esfera'
                    label='Esfera'
                    expanded='esfera'
                >
                    <ListFilterRadioItem label='Esfera' currentFilter={props.filter} filterKey='esfera' itens={esferaItens} onChange={handleGenericOnClick} />
                </FilterItemPanel>

                {/* Poder Legislativo */}
                <FilterItemPanel
                    panel='panel2'
                    name='poder'
                    label='Categorias'
                    //expanded={expanded}
                    onOpen={handleChangeOpen}
                >
                    {check('esfera', 'Federal') &&
                        <>
                            <ListFilterInputItem label='Casa Legislativa' currentFilter={props.filter} filterKey='casa' itens={casaItens} onChange={handleCasaOnClick} />
                            <ListFilterInputItem label='Âmbito' currentFilter={props.filter} filterKey='ambito' itens={ambitos} onChange={handleGenericOnClick} />
                        </>
                    }

                    {check('esfera', 'Estadual') &&
                        <>
                            <ListFilterInputItem label='Estado' currentFilter={props.filter} filterKey='estado' itens={estados} onChange={handleGenericOnClick} />
                        </>
                    }

                    {check('esfera', 'Municipal') &&
                        <>
                            <ListFilterInputItem label='Estado' currentFilter={props.filter} filterKey='estado' itens={estados} onChange={handleEstadoOnClick} />
                            <ListFilterInputItem label='Município' currentFilter={props.filter} filterKey='municipio' itens={municipio} onChange={handleGenericOnClick} />
                        </>
                    }

                    <ListFilterInputItem label='Partido' currentFilter={props.filter} filterKey='partido' itens={partidosAtual} onChange={handleGenericOnClick} />
                </FilterItemPanel>

                {/* Legislatura */}
                {(check('esfera', 'Federal')) &&
                    <>
                        <FilterItemPanel
                            panel='panel3'
                            name='legislatura'
                            label='Legislatura'
                            //expanded={expanded}
                            onOpen={handleChangeOpen}
                        >
                            <ListFilterInputItem label='Legislatura' currentFilter={props.filter} filterKey='legislatura' itens={legislatura} onChange={handleGenericOnClick} />
                        </FilterItemPanel>
                    </>
                }

                {/* Interlocutor */}
                <FilterItemPanel
                    panel='panel5'
                    name='interlocutor'
                    label='Interlocutor'
                    //expanded={expanded}
                    onOpen={handleChangeOpen}
                >
                    {props.idPermissao !== 4 ?
                        <>
                            <TitleLabel>Informações Gerais</TitleLabel>
                            <ListFilterRadioItem label='Seguindo' currentFilter={props.filter} filterKey='seguindo' itens={seguindoItens} onChange={handleGenericOnClick} />
                            <ListFilterInputItem label='Situação' currentFilter={props.filter} filterKey='situacao' itens={situacaoItens} onChange={handleGenericOnClick} />
                            <ListFilterInputItem label='Cargo' currentFilter={props.filter} filterKey='cargo' itens={cargoItens} onChange={handleGenericOnClick} />
                        </>
                        :

                        null

                    }

                    {
                        user.id_permissao !== 4 ?
                            <ListFilterInputItem label='Qualificação' currentFilter={props.filter} filterKey='qualificacao' itens={qualificacaoItens} onChange={handleGenericOnClick} />
                            :
                            null
                    }
                    <TitleLabel>Dados Pessoais</TitleLabel>
                    <ListFilterInputItem label='Faixa Etária' currentFilter={props.filter} filterKey='faixaEtaria' itens={faixaEtariaItens} onChange={handleFaixaEtariaOnClick} />
                    <ListFilterInputItem label='Profissão' currentFilter={props.filter} filterKey='profissao' itens={profissoes} onChange={handleGenericOnClick} />
                    <ListFilterInputItem label='Escolaridade' currentFilter={props.filter} filterKey='escolaridade' itens={escolaridades} onChange={handleGenericOnClick} />
                </FilterItemPanel>
            </FiltroDiv>
        </>
    )
}
