import React from "react";
import Main from "../../../main/Main";
import useStyles from "../../../../components/layout/Styles";
import { Button, Grid, Paper } from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ProposicaoAcaoPreparatoriaService from "../../../../services/ProposicaoAcaoPreparatoriaService";
import ProposicaoAcaoInfluenciaNotaTecnicaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaService";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import CircularLoading from "../../../../components/CircularLoading";
import EditorNotaTecnicaColaborativa from "./EditorNotaTecnicaColaborativa";

export default function ProposicaoAcaoPreparatoriaNTColaborativa() {
  const { idAcao, id_proposicao } = useParams();
  const [acaoPreparatoria, setAcaoPreparatoria] =
    React.useState<IAcaoPreparatoria>();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  React.useEffect(() => {
    loadAcaoPreparatoria();
    // eslint-disable-next-line
  }, [idAcao]);

  const loadAcaoPreparatoria = () => {
    if (idAcao) {
      setLoading(false);
    }
  };

  const handleOnChange = (data) => {
    setAcaoPreparatoria((oldValue) => {
      return { ...oldValue, ...data };
    });
  };

  return (
    <div className={classes.root}>
      <Main>
        <>
          <Grid container justifyContent="space-around">
            <Paper style={{ borderRadius: "7px", width: "100%" }}>
              {loading ? (
                <CircularLoading
                  size={100}
                  top={isMobile ? "37%" : "40%"}
                  left={isMobile ? "6%" : "16%"}
                  zIndex={2}
                />
              ) : (
                <>
                </>
              )}
            </Paper>
            <Grid style={{ marginTop: "23px", width: "100%" }}>
              <EditorNotaTecnicaColaborativa />
            </Grid>
          </Grid>
        </>
      </Main>
    </div>
  );
}
