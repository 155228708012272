
import { Grid } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { usePallet } from '../../../../contexts/PalletContext';
import _ from 'lodash';
import moment from 'moment-timezone';
import CheckIcon from '@mui/icons-material/Check';
import EmptyError from '../../../../components/EmptyError';


export default function ContentStepTimeLine(props: { eventos?: Array<any> }) {
    const { pallet } = usePallet();

    return (
        <>
            {props?.eventos?.map((x, index) => {
                return (
                    <Step expanded active={false}>
                        <StepLabel
                            icon={
                                <Grid container >
                                    <Grid
                                        style={{
                                            // marginTop: "-8px",
                                            marginLeft: "-1px",
                                            borderRadius: "50%",
                                            width: "27px",
                                            height: "27px",
                                            position: "relative",
                                            boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                            backgroundColor: `${index === props?.eventos.length - 1 ? pallet.backgroundColorQuintenary : 'White'}`
                                        }}
                                    >
                                        {index === props?.eventos.length - 1
                                            ?
                                            <Grid style={{
                                                position: "absolute",
                                                borderRadius: "50%",
                                                top: "50%",
                                                left: "50%",
                                                width: "15px",
                                                height: "15px",
                                                margin: "-7.5px 0px 0px -7.5px",
                                                zIndex: 1,
                                                backgroundColor: "white",
                                                border: `3px solid ${pallet.backgroundColorPrimary}`
                                            }} >
                                                <Grid style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    borderRadius: "50%",
                                                    width: "7px",
                                                    height: "7px",
                                                    margin: "-3.5px 0px 0px -3.5px",
                                                    zIndex: 1,
                                                    backgroundColor: `${pallet.backgroundColorPrimary}`
                                                }}>

                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid style={{
                                                position: "absolute",
                                                borderRadius: "50%",
                                                top: "50%",
                                                left: "50%",
                                                width: "15px",
                                                height: "15px",
                                                margin: "-7.5px 0px 0px -7.5px",
                                                zIndex: 1,
                                            }} >
                                                <Grid style={{
                                                    position: "absolute",
                                                    top: "15%",
                                                    left: "10%",
                                                    // borderRadius: "50%",
                                                    width: "1px",
                                                    margin: "-3.5px 0px 0px -3.5px",
                                                    zIndex: 1,
                                                }}>
                                                    <CheckIcon style={{ fontSize: '20px', color: '#00ff00' }} />
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            }
                        >
                            <div style={{ paddingBottom: "7px", fontSize: "15px", fontFamily: `${pallet.general.fontFamily}`, fontWeight: pallet.general.weight_300 }}>
                                {moment(x.data).format("DD/MM/YYYY")}
                            </div>
                        </StepLabel>
                        <StepContent>
                            <Grid style={{ backgroundColor: "#F1F1F1", borderRadius: '5px  ', padding: "10px 10px 10px 10px", margin: "10px 0px 20px 0px", fontSize: "12px", fontFamily: `${pallet.general.fontFamily}`, fontWeight: pallet.general.weight_100 }}>
                                {x?.descricao}
                            </Grid>
                        </StepContent>
                    </Step>
                )
            })
            }

        </>
    )
}
