import { Button, Grid, Pagination, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import Main from "../main/Main";
import filtersBackgroundImage from '../../../../../src/img/OrangeBackgroundHeader.png';
import { IListInfo } from "../../models/IListInfo";
import ButtonIconSalvar from "../../components/ButtonIconSalvar";
import { useNavigate } from "react-router-dom";
import TCService from "../../services/TCService";
import { IFilterData } from "../../components/filter/ListFilter";
import CitacoesList from "./components/CitacoesList";
import CitacoesService from "../../services/CitacoesService";
import CitacoesCadastrar from "./components/CitacoesCadastrar";
import FilterQuantity from "../../components/filter/FilterQuantity";
import EmptyError from "../../components/EmptyError";
import EmptyErrorDocCarousel from "../../components/EmptyErrorDocCarousel";
import EmptyErrorDesk from "../../components/EmptyErrorDesk";


//## StyledCompenents
const FilterArea = styled(Paper)`
	width: 100%;
	/* height: 55px; */
	margin-top: 16px;
	margin-bottom: 10px;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: #ffff;
`;

const FilterButton = styled(Button)`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	text-transform: capitalize;
	color: #160342;
	border: 0;
	font-size: 14px;
	line-height: 17px;
	background: rgba(75, 17, 150, 0.1);
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	height: 100%;

	:first-child {
		margin-right: 10px;
	}
`;

const ContainerHead = styled(Grid)`
	margin-bottom: 10px;
	margin-left: 0px;
`;

const ContainerSubHead = styled(Grid)`
	margin-bottom: 30px;
`;

const PageTitle = styled(Grid)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-weight: ${(props) => props.pallet.general.weight_bold};
	font-size: 24px;
	font-family: ${(props) => props.pallet.general.fontFamily};
`;

const ContainerFilter = styled(Grid)`
	/* width: 10%; */
	width: 100%;
	margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
	justify-content: ${isMobile ? "center" : "space-between"};
    flex-direction: column;
`;

const ContainerCard = styled(Grid)`
	margin-top: 20px;

`;

const PaperContent = styled(Paper)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
	margin-top: 40px;
	margin-bottom: 40px;
	justify-content: center;
`;

interface ICitacaoResult {
	descricao: string;
	id: any;
}

export default function Citacoes() {
	const { pallet } = usePallet();
	const classes = useStyles();
	const navigate = useNavigate();

	const [currentPageSize, setCurrentPageSize] = useState<number>(50);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [filter, setFilter] = useState({ key: "visualizacao", label: "Todas", value: "T" });
	const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

	const [lista, setLista] = React.useState<any>([])
	const [listaDescricao, setListaDescricao] = useState<string[]>([]);
	const [busca, setBusca] = useState<IFilterData[]>();
	const [descricaoParcial, setDescricaoParcial] = useState<string>("");
	const [open, setOpen] = React.useState();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	//## Paginação
	const [infoList, setInfoList] = useState<IListInfo>({
		pageIndex: 1,
		pageSize: 8,
		pageItens: 0,
		totalItens: 0,
		totalPages: 0,
	});

	const setPage = (event: object, page: number) => setCurrentPage(page);

	const handleOnChangeItensPerPageSize = (data: any) => {
		setCurrentPageSize(Number(data.target.value));
		setCurrentPage(1);
	};


	useEffect(() => {
		loadCitacao()
	}, [currentPage, currentPageSize]);


	async function loadCitacao() {

		CitacoesService.getCitacoes(currentPage, currentPageSize)
			.then(async (res) => {
				if (res) {
					setLista(res.data);
					setInfoList(res.listInfo);
				}
			});
	}


	const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
		setOpen(id);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(undefined);
	};




	return (
		<div className={classes.root}>
			<Main>
				<>
					<ContainerHead container>
						<ContainerSubHead container alignItems="center" justifyContent="space-between">

							<PageTitle pallet={pallet} >Citações</PageTitle>

						</ContainerSubHead>

						<Grid container>
						</Grid>
						<Grid
							style={{
								width: isMobile ? "60%" : "100%",
							}}
						/>

						<ContainerFilter style={{}}>
							<FilterArea>
								<Grid style={{ width: `${isMobile ? "70%" : "65%"}`, display: "flex", alignItems: "center" }}>

									<strong>
										<span style={{ color: pallet.general.color1 }}>
											{infoList.totalItens}
										</span> {infoList.totalItens === 1 ? "PALAVRA-CHAVE" : "PALAVRAS-CHAVE"}
									</strong>
								</Grid>
								<Grid style={{ marginLeft: "auto" }}>
									<CitacoesCadastrar
										reloadList={() =>
											loadCitacao()
										}

										closeMenu={() => handleClose()} />
								</Grid>

							</FilterArea>
						</ContainerFilter>
						<FilterQuantity
							list={infoList}
							currentPageSize={currentPageSize}
							onChange={handleOnChangeItensPerPageSize}
						/>

					</ContainerHead>
					<ContainerBody container>
						<Grid item style={{ width: "100%" }}>

							<Paper style={{
								borderRadius: "6px",
								paddingTop: `${isMobile ? "0px" : "0px"}`
							}}>


								<CitacoesList lista={lista} reloadList={() =>
									loadCitacao()
								} />
								{infoList?.totalItens < 1 ?
									<Grid
										style={{
											color: `${pallet.textColorQuaternary}`,
											fontSize: `${pallet.fontSize.titleWidgets}`,
											fontFamily: `${pallet.fontFamily.general}`,
											fontWeight: 600,
											textAlign: "center",
											paddingBottom: "98px",
											marginTop: "20px",
										}}>
										<EmptyErrorDesk title="" description="Você ainda não cadastrou palavras chave." />
									</Grid>
									:
									""
								}
							</Paper>
						</Grid>

					</ContainerBody>

					<ContainerFooter container>
						{infoList?.totalItens > 4 ? (
							<Stack spacing={2}>
								<Pagination
									size={isMobile ? "small" : "large"}
									onChange={setPage}
									count={infoList?.totalPages}
									page={currentPage}
									shape="rounded"
								/>
							</Stack>
						) : null}
					</ContainerFooter>


				</>
			</Main>
		</div>
	);
}
