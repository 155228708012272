import * as React from 'react';
import Button, { ButtonPropsSizeOverrides } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/index';
import { DistributiveOmit, OverridableStringUnion } from '@mui/types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { usePallet } from '../contexts/PalletContext';
import ButtonOutlineVoltar from './ButtonOutlineVoltar'
import { Grid } from '@mui/material';
import SolidButton from './SolidButton';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ExclamacaoIcon from '../../src/img/exclamacao.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;

}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { pallet } = usePallet();

    const { children, onClose, ...other } = props;

    return (
        <Grid container justifyContent="space-between" style={{
            backgroundColor: `${pallet.backgroundColorTertiary}`,
            padding: `${isMobile ? "10px 5px 7px 18px" : "23px 25px 15px 25px"}`,
            // borderRadius: "6px 6px 0px 0px",
        }}
        >
            <DialogTitle {...other}
                style={{
                    fontFamily: `${pallet.general.fontFamily}`,
                    color: `${pallet.textColorQuaternary}`,
                    fontSize: `${pallet.general.size18}`,
                    fontWeight: pallet.general.weight_600,
                }}
            >
                {children}
            </DialogTitle>
            <Grid>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{ marginTop: "10px", marginRight: "20px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default function ButtonDialogConfirmation(props: {
    confirmation_button: string,
    title: string,
    title_button: string,
    content?: string,
    message?: string,
    confirmationEvent?: () => void,
    styles?: SxProps<Theme>,
    lastPath?: string,
    endIcon?: React.ReactNode,
    size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>,
    children?: React.ReactNode;
}) {
    const { pallet } = usePallet();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleConfirmation = () => {
        if (props.confirmationEvent) {
            props.confirmationEvent();
        }
        if (props.lastPath) {
            navigate(`${props?.lastPath}`)
        }
        setOpen(false);
    };

    const handleCloseVoltar = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button 
                onClick={handleClickOpen}
                size={props?.size ? props?.size : "medium"}
                endIcon={props?.endIcon ? props?.endIcon : null}
                sx={props.styles}
            >
                {props.children}
                <div style={{ fontSize: 0 }}>@</div>{props.title_button}
            </Button>

            <Grid>
                <BootstrapDialog
                    onClose={handleCloseVoltar}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseVoltar}>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid container alignItems="center" direction="column" justifyContent="center" style={{ minHeight: `${isMobile ? "250px" : "250px"}`, minWidth: `${isMobile ? "200px" : "550px"}` }}>
                            <Grid>
                                <img src={ExclamacaoIcon} alt="exclamacao" style={{
                                    width: "50px",
                                    margin: "20px"
                                }} />
                            </Grid>
                            <Grid item textAlign={'center'} style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                color: `${pallet.textColorQuaternary}`,
                                fontSize: `${pallet.general.size16}`,
                                fontWeight: pallet.general.weight_600,
                                padding: "5px"
                            }}
                            >
                                {props.content}
                            </Grid>
                            <Grid item style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                color: `${pallet.textColorQuaternary}`,
                                fontSize: `${isMobile ? pallet.general.size14 : pallet.general.size16}`,
                                fontWeight: pallet.general.weight_100,
                                padding: "5px"
                            }}
                            >
                                {props.message}
                            </Grid>
                        </Grid>
                    </DialogContent>

                    {
                        isMobile ?


                        <Grid style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, display: "flex", flexDirection: "row", padding: "35px 20px 35px 50px" }}>
                        <Grid item style={{ marginRight: `${isMobile ? "5px" : "20px"}` }}>
                            <ButtonOutlineVoltar
                                onClick={handleCloseVoltar}
                            />
                        </Grid>
                        {/* <Grid onClick={props?.confirmationEvent} item>
                            <SolidButton
                                onClick={props?.lastPath ? () => navigate(`${props?.lastPath}`) : () => { handleConfirmation() }}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={props.confirmation_button}
                            />
                        </Grid> */}
                        <Grid item>
                            <SolidButton
                                onClick={() => { handleConfirmation() }}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={props.confirmation_button}
                            />
                        </Grid>
                    </Grid>

                        :

                        <Grid container justifyContent="end" style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, padding: "35px 20px 35px 50px" }}>
                        <Grid item style={{ marginRight: `${isMobile ? "5px" : "20px"}` }}>
                            <ButtonOutlineVoltar
                                onClick={handleCloseVoltar}
                            />
                        </Grid>
                        {/* <Grid onClick={props?.confirmationEvent} item>
                            <SolidButton
                                onClick={props?.lastPath ? () => navigate(`${props?.lastPath}`) : () => { handleConfirmation() }}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={props.confirmation_button}
                            />
                        </Grid> */}
                        <Grid item>
                            <SolidButton
                                onClick={() => { handleConfirmation() }}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={props.confirmation_button}
                            />
                        </Grid>
                    </Grid>

                    }

                </BootstrapDialog>
            </Grid>
        </div >
    );
}
