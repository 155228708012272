import { Grid, Typography } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import FilterItemPanel from "../../../components/filter/FilterItemPanel";
import {
    IListFilterInputItem,
    IListFilterRadioItem,
    ListFilterAutocompleteItem,
    ListFilterCheckBoxItem,
    ListFilterRangeDatePickerItem,
    ListFilterInputItem,
    ListFilterRadioItem,
    ListFilterTextItem,
    ListFilterCheckBoxItemInvertido,
} from "../../../components/filter/ListFilterItems";
import TabelasService from "../../../services/TabelasService";
import { IFilterData } from "../../../components/filter/ListFilter";
import { useEffect } from "react";
import ProposicaoService from "../../../services/ProposicaoService";
import { formatDate } from "../../../utils/dateUtils";
import CheckBoxSimple from "../../../components/CheckBoxSimple";
import FilterItemPanelUsuario from "../../../components/filter/FilterItemPanelUsuario";
import { ICheckBoxNestedItemListaUnica, InputDropDownCheckBoxNestedListaUnica } from "../../../components/CheckBoxNestedListaUnica";
import UsuarioService from "../../../services/UsuarioService";
import { CheckBoxNestedListaUnicaFilter } from "../../../components/CheckBoxNestedListaUnicaFilter";

const esferaItens: IListFilterRadioItem[] = [
    { label: "Federal", value: "Federal" },
    { label: "Estadual", value: "Estadual" },
    { label: "Municipal", value: "Municipal" },
];

const casaItens: IListFilterInputItem[] = [
    { key: "", label: "-- Selecione -- ", value: "" },
    { key: "casa", label: "Senado Federal", value: "SF" },
    { key: "casa", label: "Congresso Nacional", value: "CN" },
    { key: "casa", label: "Câmara dos Deputados", value: "CD" }
];

const criacaoItens = [{ key: "cricao", label: "Criação", value: "criacao" }];
const edicaoItens =  [{ key: "edicao", label: "Edição", value: "edicao" }];
const exclusaoItens =  [{ key: "exclusao", label: "Exclusão", value: "exclusao" }];

const visualizacaoItens: IListFilterInputItem[] = [
    { key: "", label: "-- Selecione -- ", value: "" },
    { key: "visualizacao", label: "Seguidas", value: "Seguidas" },
    { key: "visualizacao", label: "Não Seguidas", value: "NSeguidas" },
    { key: "visualizacao", label: "Novas", value: "Novas" },
    { key: "visualizacao", label: "Atualização Recente", value: "Recente" }
];

const momentoItens: IListFilterInputItem[] = [
    { key: "", label: "-- Selecione -- ", value: "" },
    { key: "momento", label: "Aguardando Designação do Relator", value: "ADR" },
    { key: "momento", label: "Aguardando Parecer do Relator", value: "APR" },
    { key: "momento", label: "Prazo para Emendas", value: "PE" },
    { key: "momento", label: "Aguardando Deliberação", value: "AD" }
];





const FiltroDiv = styled.div`

	max-width: 500px;

	.MuiAccordionSummary-root {
		justify-content: flex-start;

		.MuiAccordionSummary-content {
			flex-grow: unset;
		}
	}

	div.MuiPaper-elevation:first-child {
		.MuiAccordionSummary-expandIconWrapper {
			display: none;
		}
	}

	div.MuiFormGroup-root {
		flex-direction: row;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	span.Mui-checked {
		color: #160342;
	}
`;

const TitleLabel = styled(Typography)`
	width: 100%;
	font-weight: 600;
	font-size: 14px;
	color: #4b1196;
	margin-top: 20px;
	margin-bottom: 20px;
    margin-left: 10px;
`;

export default function GerUsuariosListFilterItens(props: {
    filter: IFilterData[];
    onChangeFilter: (data: IFilterData[]) => void;
}): JSX.Element {
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [expandedData, setExpandedData] = React.useState<boolean>(true);
    const [expandedDepartamento, setExpandedDepartamento] = React.useState<boolean>(true);
    // const [visualizacao, setVisualizacao] = React.useState<Array<IListFilterInputItem>>([]);
    const [ambitos, setAmbitos] = React.useState<Array<IListFilterInputItem>>([]);
    const [tipo, setTipo] = React.useState<Array<IListFilterInputItem>>([]);
    // const [numero, setNumero] = React.useState<Array<IListFilterInputItem>>([]);
    // const [ano, setAno] = React.useState<Array<IListFilterInputItem>>([]);
    const [departamentosLista, setDepartamentosLista] = React.useState<Array<ICheckBoxNestedItemListaUnica>>();
    const [departamentoListTratado, setDepartamentoListTratado] = React.useState<Array<IListFilterInputItem>>([]);




    React.useEffect(() => {
   
            TabelasService.getTabelaDepartamentos().then(async (res) => {
            
            const comboItens = res.map(usuarios => {
              return {
                id: usuarios.id,
                label: usuarios.descricao,
                value: false
              }
            })
            setDepartamentosLista(comboItens)
          },
          )
        
      }, []);






    const handleChangeOpenData =
    (panel: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedData(isExpanded ? true : false);
    };

    const handleChangeOpenDepartamento =
    (panel: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedDepartamento(isExpanded ? true : false);
    };




    const handleCriacaoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name) {
        values.push({
                key: data.target.name,
                label: 'CRIAÇÃO: Sim',
                value: "I",
            });

        }
        props.onChangeFilter(values);

    };

    const handleEdicaoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: 'EDIÇÃO: Sim',
                value: "A",
            });
        props.onChangeFilter(values);

    };

    const handleExclusaoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== data.target.name && item.key !== data.target.id);
        if (value && data.target.name)
            values.push({
                key: data.target.name,
                label: 'EXCLUSÃO: Sim',
                value: "A",
            });
        props.onChangeFilter(values);

    };




    const handleDataApresentacaoOnClick = (data: any, value: any) => {
        const values = props.filter.filter((item) => item.key !== "dataIni" && item.key !== "dataFin");
        if (value && data.target.name) {
            values.push({
                key: "dataIni",
                label: `PERÍODO INICIAL: ${formatDate(value[0], 'dd/MM/yyyy')}`,
                value: formatDate(value[0], 'MM/dd/yyyy'),
            });
            if (value.length > 1 && value[1]) {
                values.push({
                    key: "dataFin",
                    label: `PERÍODO FINAL: ${formatDate(value[1], 'dd/MM/yyyy')}`,
                    value: formatDate(value[1], 'MM/dd/yyyy'),
                });
            }
        }
        props.onChangeFilter(values);
    };




    const handleSetDepartamentoList = (event: any,label:string, id: number) => {
        const values = props.filter.filter((item) => item.key !== String(id));
        if (event) {
        values.push({
            key: "departamento",
            label: `UNIDADE DE NEGÓCIO: ${label}`,
            value: id,
        });
        } else {
            values.splice(values.indexOf({
                key: "departamento",
                label: `UNIDADE DE NEGÓCIO: ${label}`,
                value: id,
            }), 1)

        }


        props.onChangeFilter(values);
        
        }

    return (
        <>
            <FiltroDiv>
                <FilterItemPanel panel="panel1" name="Atividade" label="Atividade" expanded="Atividade">
                    <Grid container>
                        <Grid item xs={4}>
                    <ListFilterCheckBoxItemInvertido
                        label="Criação"
                        currentFilter={props.filter}
                        filterKey="criacao"
                        itens={criacaoItens}
                        onChange={handleCriacaoOnClick}
                        switch
                    />
                        </Grid>
                        <Grid item xs={4}>
                        <ListFilterCheckBoxItemInvertido
                        label="Edição"
                        currentFilter={props.filter}
                        filterKey="edicao"
                        itens={edicaoItens}
                        onChange={handleEdicaoOnClick}
                        switch
                    />
                        </Grid>
                        <Grid item xs={3}>
                        <ListFilterCheckBoxItemInvertido
                        label="Exclusão"
                        currentFilter={props.filter}
                        filterKey="exclusao"
                        itens={exclusaoItens}
                        onChange={handleExclusaoOnClick}
                        switch
                            />
                        </Grid> 
                    </Grid>
                </FilterItemPanel>

                <FilterItemPanelUsuario
                    panel="panel2"
                    name="periodo"
                    label="Período"
                    expanded={expandedData}
                    onOpen={handleChangeOpenData(true)}
                >
                <Grid style={{marginTop:"20px",width:"100%"}}>
                    <ListFilterRangeDatePickerItem
                            label="Período"
                            currentFilter={props.filter}
                            filterKey="dataApresentacao"
                            onChange={handleDataApresentacaoOnClick}
                        />
                    </Grid>
                </FilterItemPanelUsuario>


                <FilterItemPanelUsuario
                    panel="panel3"
                    name="departamento"
                    label="Unidade de negócio"
                    expanded={expandedDepartamento}
                    onOpen={handleChangeOpenDepartamento(true)}
                >
                <Grid style={{marginTop:"20px",width:"100%"}}>
                <CheckBoxNestedListaUnicaFilter
                                        items={departamentosLista}
                                        onChange={handleSetDepartamentoList}
                                        label={"Unidade de negócio"}
                                        editar={true}
                                        // selectedItems={props.filter}
                                    />
                    </Grid>
                </FilterItemPanelUsuario>
              
            </FiltroDiv>
        </>
    );
}
