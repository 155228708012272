import { Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import { usePallet } from "../../../contexts/PalletContext";
import styled from 'styled-components';
import legis_agenda from "../../../img/legis_agenda.png";
import legis_agenda2 from "../../../img/legis_agenda2.png";

import PrintIcon from '@mui/icons-material/Print';
import { isMobile, isWindows } from 'react-device-detect';
import { fontSize } from '@mui/system';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../utils/utils';
import CircularLoading from '../../../components/CircularLoading';
import AgendaService from '../../../services/AgendaService';
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import { formatDate } from '../../../utils/dateUtils';
import PlayArrowIcon from "../../../img/play-arrow.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';

export default function AgendaRenderPDFAcoesInternasNovo() {
    const { pallet } = usePallet();
    const [relatorio, setRelatorio] = React.useState([]);
    let data_agora = new Date();
    let dia = String(data_agora.getDay()).padStart(2, "0");
    let dia_n = String(data_agora.getDate()).padStart(2, "0");
    let mes = String(data_agora.getMonth() + 1);
    let ano = data_agora.getFullYear();
    const { dtIni } = useParams();
    const { dtFin } = useParams();
    const { tipo } = useParams();

    const { hasAccess } = useAuthorization();
    const { user } = useAuthorization();

    React.useEffect(() => {
        // loadJornal(dtIni, dtFin, tipo)

        AgendaService.GetListRelatorio(format(new Date(dtIni), "yyyy-MM-dd"), format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"), tipo).then((jornal) => {
            setRelatorio(jornal &&
                jornal.informacoes &&
                jornal.informacoes.data &&
                Array.isArray(jornal.informacoes.data) ?
                jornal.informacoes.data :
                []);
            // setUltimaTramitacao(res2)
        });
    }, []);

    const Imprimir = () => {
        window.print()
    }

    const posicionamentoDescricao = (value: number) => {
        switch (value) {
            case 0:
                return "Indefinido";
            case 1:
                return "Contrário";
            case 2:
                return "Contrário com emenda saneadora";
            case 3:
                return "Neutro";
            case 4:
                return "Favorável com emenda saneadora";
            case 5:
                return "Favorável";
            case 6:
                return "Favorável ao parecer do relator";
            case 7:
                return "Favorável ao parecer do relator com emenda";
            case 8:
                return "Contrário ao parecer do relator";
            case 9:
                return "Contrário ao parecer do relator com emenda";
            case 99:
                return "Pendente";
            default:
                return "Indefinido";
        }
    };

    const posicionamentoColor = (value: string) => {
        switch (value) {
          case "Contrário":
            return `${pallet.charts.red}`;
          case "Contrário com emenda saneadora":
              return `${pallet.charts.orange}`;
          case "Neutro":
              return `${pallet.charts.purple}`;
          case "Favorável com emenda saneadora":
              return `${pallet.charts.blue}`;
          case "Favorável":
              return `${pallet.charts.green}`;
          case "Pendente":
              return `${pallet.charts.yellow}`;
          case "Indefinido":
              return `${pallet.charts.lightGrey}`;
          case "Favorável ao parecer do relator":
              return '#C71585';
          case "Favorável ao parecer do relator com emenda":
              return '#B8860B';
          case "Contrário ao parecer do relator":
              return '#facb74';
          case "Contrário ao parecer do relator com emenda":
              return '#000000';
          default:
              return `${pallet.charts.lightGrey}`;
        }
    };

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };


    const prioridadeDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Mínima";
            case 2:
                return "Baixa";
            case 3:
                return "Média";
            case 4:
                return "Alta";
            case 5:
                return "Máxima";
            default:
                return "Não definido";
        }
    };

    const filtroDeTipo = (value: any) => {
        switch (value) {
            case "SF":
                return "SENADO";
            case "CD":
                return "CÂMARA DOS DEPUTADOS";
            case "CN":
                return "CONGRESSO NACIONAL";
            default:
                return "TUDO";
        }
    };

    const diaSemana = (value: any) => {
        switch (value) {
            case "01":
                return "SEGUNDA-FEIRA";
            case "02":
                return "TERÇA-FEIRA";
            case "03":
                return "QUARTA-FEIRA";
            case "04":
                return "QUINTA-FEIRA";
            case "05":
                return "SEXTA-FEIRA";
            case "06":
                return "SÁBADO";
            default:
                return "DOMINGO";
        }
    };

    const mesAagora = (value: string) => {
        switch (value) {
            case "1":
                return "JANEIRO";
            case "2":
                return "FEVEREIRO";
            case "3":
                return "MARÇO";
            case "4":
                return "ABRIL";
            case "5":
                return "MAIO";
            case "6":
                return "JUNHO";
            case "7":
                return "JULHO";
            case "8":
                return "AGOSTO";
            case "9":
                return "SETEMBRO";
            case "10":
                return "OUTUBRO";
            case "11":
                return "NOVEMBRO";
            case "12":
                return "DEZEMBRO";
        }
    };


    const impactoFinanceiroDescricao = (value: number) => {
        switch (value) {
          case 1:
            return "Indiferente";
          case 2:
            return "Positivo";
          case 3:
            return "Negativo";
          case 4:
            return "Não Mensurável";
          default:
            return "Não informado";
        }
      };
    
      const impactoFinanceiroColor = (value: string) => {
        switch (value) {
          case "Indiferente":
            return `${pallet.charts.lightGrey}`;
          case "Positivo":
            return `${pallet.charts.green}`;
          case "Negativo":
            return `${pallet.charts.red}`;
          case "Não Mensurável":
            return `${pallet.charts.purple}`;
          default:
            return `${pallet.charts.yellow}`;
        }
      };


      const resultadoDescricao = (value: number) => {
        switch (value) {
          case 1:
            return "Êxito";
          case 2:
            return "Sem êxito";
          case 3:
            return "Êxito parcial";
          case 4:
            return "Pendente";
          default:
            return "Não definido";
        }
      };
    
      const resultadoColor = (value: string) => {
        switch (value) {
          case "Pendente":
            return `${pallet.charts.yellow}`;
          case "Êxito":
            return `${pallet.charts.green}`;
          case "Sem êxito":
            return `${pallet.charts.red}`;
          case "Êxito parcial":
            return `${pallet.charts.blue}`;
          default:
            return "white";
        }
      };

    let letra = window.location.href.search("/");
    let ini_end = window.location.href.slice(0, letra);
    let end = window.location.href.slice(letra + 2);
    let letra2 = end.search("/");
    let end2 = end.slice(0, letra2);

    let largura = window.innerWidth
    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <span style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>Agenda Ações Internas</span>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '150px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                {
                                                    "BRASÍLIA " +
                                                    diaSemana(dia) +
                                                    ", " +
                                                    dia_n +
                                                    " DE " +
                                                    mesAagora(mes) +
                                                    " DE " +
                                                    ano
                                                }
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Agenda do Congresso Nacional
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '15px',
                                                marginTop: '8px'
                                            }}>
                                                        {
                                                "Período entre " +
                                                formatDate(new Date(dtIni), "dd/MM/yyyy") +
                                                " a " +
                                                formatDate(new Date(dtFin), "dd/MM/yyyy")
                                            }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            {relatorio
            .filter((k) =>
              k.eventos.find((i) =>
                i.pautas.every(
                  (f) =>
                    f.proposicoes[0]?.acoes_influencia?.length > 0 ||
                    f.proposicoes[0]?.acoes_influenciaEM?.length > 0 ||
                    f.proposicoes[0]?.acoes_preparatorias?.length > 0
                )
              )
            )
            .map((x) => {
              return (
                <Grid style={{ padding: "20px" }}>
                                            <Grid
                      
                      style={{
                      display: "flex",
                      flexDirection: "row",
                      width: '990px',
                      
                  
                      }}>
              <Grid
                  style={{
                      height: "38px",
                      width: "35px",
                      borderBottomLeftRadius: "5px",
                      borderTopLeftRadius: "5px",
                      backgroundColor: pallet.backgroundColorQuaternary,
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                  }}
              >

                          <CalendarTodayIcon/>
                  </Grid>
                  <Grid container
                      style={{
                          border: '1px solid #E8E8E8',
                          height: "38px",
                          width: "100%",
                          alignContent: "center",
                      }}
                  >
                      <Grid
                          style={{
                              fontSize: pallet.general.size16,
                              marginTop: "3px",
                              marginBottom: "3px",
                              marginLeft: "5px",
                          }}
              
                      >
                         { diaSemana(
                                  String(new Date(x.dia).getDay()).padStart(2, "0")
                              )}
                      </Grid>
                      <Grid
                          style={{
                              color: pallet.general.color1,
                              fontSize: pallet.general.size16,
                              marginLeft: "10px",
                              paddingTop: '3px'
                          }}

                      >
                          {moment(x.dia).format("DD/MM")}
                      </Grid>
                  </Grid>
                  </Grid>

                  {x.eventos.map((a) => {
                    return (
                      <Grid>
                        {a?.pautas
                          ?.filter(
                            (t) =>
                              t.proposicoes[0]?.acoes_influencia?.length > 0 ||
                              t.proposicoes[0]?.acoes_influenciaEM?.length > 0 ||
                              t.proposicoes[0]?.acoes_preparatorias?.length >
                              0 ||
                              t.acontecimentos?.length > 0
                          )
                          .map((b) => {
                            return (
                              <Grid>
                                <Grid
                                  style={{
                                    marginTop: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                          >
                                  <Grid
                                    style={{
                                      borderRadius: "50%",
                                      border: "2px",
                                      borderColor: "#EAE7E7",
                                      width: "9px",
                                      height: "9px",
                                      backgroundColor: pallet.general.color1,
                                    }}
                                  ></Grid>
                                  <Grid
                                    style={{
                                      fontSize: pallet.general.size16,
                                      marginLeft: "5px",
                                      color: pallet.general.color1,
                                    }}
                                  >
                                    {"ITEM " + b.agenda_pauta_item}
                                  </Grid>

                                </Grid>
                                <Grid>
                                  <Grid
                                    style={{
                                      fontSize: pallet.general.size16,
                                      marginTop: "5px",
                                    }}
                                  >
                                    <a href={
                                        ini_end +
                                        "//" +
                                        end2 +
                                        "/proposicao/" +
                                        b.proposicoes[0].proposicao_id
                                      }
                                    >
                                      {
                                        b.proposicoes[0]
                                          .proposicao_descricao
                                      }
                                    </a>
                                  </Grid>
                                </Grid>

                                <Grid>
                                  {b?.acontecimentos?.map((c, o) => {
                                    return (
                                      <Grid>
                                        <Grid
                                          style={{
                                            display: o === 0 ? "flex" : "none",
                                          }}
                                        >
                                          <Grid
                                            style={{
                                              fontSize: pallet.general.size16,
                                              marginTop: "20px",
                                              color: pallet.general.color1,
                                            }}
                                          >
                                            | ACONTECIMENTOS
                                          </Grid>
                                        </Grid>
                                        <Grid style={{ marginBottom: "30px" }}>
                                          <Grid
                                            style={{
                                              marginLeft: "15px",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <Grid
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  color: pallet.general.color1,
                                                }}
                                              >
                                                {">>"} Ementa:{" "}
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    flex: 1,
                                                    flexWrap: "wrap",
                                                    color: "black",
                                                  }}
                                                >
                                                  {
                                                    b?.proposicoes[0]
                                                      ?.proposicao_ementa
                                                  }
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            {b?.proposicoes[0]?.autores
                                              ?.length > 1 ? (
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  marginTop: "10px",
                                                  display:
                                                    b?.proposicoes[0]?.autores
                                                      ?.length > 0
                                                      ? "flex"
                                                      : "none",
                                                }}
                                              >
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    color:
                                                      pallet.general.color1,
                                                  }}
                                                >
                                                  {" "}
                                                  {">> "}Autores:{" "}
                                                </Grid>
                                                {b?.proposicoes[0]?.autores?.map(
                                                  (k, i) => {
                                                    return (
                                                      <Grid>
                                                        <a href={
                                                            ini_end +
                                                            "//" +
                                                            end2 +
                                                            "/interlocutor/" +
                                                            k.proposicao_autores_id_parlamentar
                                                          }
                                                        >
                                                          {
                                                            k.proposicao_autores_nome
                                                          }
                                                        </a>
                                                        <Grid
                                                          style={{
                                                            display:
                                                              i ===
                                                                b?.proposicoes[0]
                                                                  ?.autores
                                                                  ?.length -
                                                                1
                                                                ? "none"
                                                                : "flex",
                                                          }}
                                                        >
                                                          {i ===
                                                            b?.proposicoes[0]
                                                              ?.autores?.length -
                                                            1
                                                            ? ""
                                                            : ","}{" "}
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  }
                                                )}
                                              </Grid>
                                            ) : b?.proposicoes[0]?.autores
                                              ?.length == 1 ? (
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    color:
                                                      pallet.general.color1,
                                                  }}
                                                >
                                                  {">> "}Autor:{" "}
                                                </Grid>
                                                {b.proposicoes[0].autores?.map(
                                                  (k) => {
                                                    return (
                                                      <a href ={
                                                          ini_end +
                                                          "//" +
                                                          end2 +
                                                          "/interlocutor/" +
                                                          k.proposicao_autores_id_parlamentar
                                                        }
                                                      >
                                                        {
                                                          k.proposicao_autores_nome
                                                        }
                                                      </a>
                                                    );
                                                  }
                                                )}
                                              </Grid>
                                            ) : (
                                              <Grid
                                                style={{ display: "none" }}
                                              ></Grid>
                                            )}

                                            <Grid
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  color: pallet.general.color1,
                                                }}
                                              >
                                                {">>"} Tipo de Reunião:{" "}
                                              </Grid>
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  flex: 1,
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                Reunião
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  color: pallet.general.color1,
                                                  flex: 1,
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {">>"} Horário:{" "}
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    color: "black",
                                                  }}
                                                >
                                                  {a.horario}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  color: pallet.general.color1,
                                                }}
                                              >
                                                {">>"} Local:{" "}
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    flex: 1,
                                                    flexWrap: "wrap",
                                                    color: "black",
                                                  }}
                                                >
                                                  {a.agenda_local_nome}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Grid
                                                style={{
                                                  fontSize:
                                                    pallet.general.size16,
                                                  color: pallet.general.color1,
                                                }}
                                              >
                                                {">>"} Descrição:{" "}
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    flex: 1,
                                                    flexWrap: "wrap",
                                                    color: "black",
                                                  }}
                                                >
                                                  {
                                                    c.acontecimentos_acontecimento
                                                  }
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                {b?.proposicoes?.map((c) => {
                                  return (
                                    <Grid>
                                      <Grid>
                                        {c?.acoes_preparatorias?.map((d, o) => {
                                          return (
                                            <Grid>
                                              <Grid
                                                style={{
                                                  display:
                                                    o === 0 ? "flex" : "none",
                                                }}
                                              >
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    marginTop: "20px",
                                                    color:
                                                      pallet.general.color1,
                                                  }}
                                                >
                                                  {o === 0
                                                    ? "| AÇÕES PREPARATÓRIAS"
                                                    : ""}
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                style={{ marginBottom: "30px" }}
                                              >
                                                <Grid
                                                  style={{
                                                    marginTop: "10px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}
                                                ></Grid>
                                                <Grid
                                                  style={{
                                                    marginLeft: "15px",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Data de criação:{" "}
                                                    </Grid>
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      {moment(
                                                        c.acao_preparatoria_prazo
                                                      ).format("DD/MM/YYYY")}
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      {">>"} Responsável:{" "}
                                                      <Grid
                                                        style={{
                                                          fontSize:
                                                            pallet.general
                                                              .size16,
                                                          color: "black",
                                                        }}
                                                      >
                                                        {d.nota_tecnica.map(
                                                          (q) => {
                                                            return (
                                                              <Grid
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft:
                                                                    "14px",
                                                                }}
                                                              >
                                                                <Grid
                                                                  style={{
                                                                    fontSize:
                                                                      pallet
                                                                        .general
                                                                        .size16,
                                                                    marginTop:
                                                                      "3px",
                                                                  }}
                                                                >
                                                                  {q.nota_tecnica_responsavel
                                                                    ? q.nota_tecnica_departamento +
                                                                    " | " +
                                                                    q.nota_tecnica_responsavel +
                                                                    " "
                                                                    : q.nota_tecnica_departamento +
                                                                    " "}
                                                                </Grid>
                                                              </Grid>
                                                            );
                                                          }
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Descrição:{" "}
                                                      <Grid
                                                        style={{
                                                          fontSize:
                                                            pallet.general
                                                              .size16,
                                                          flex: 1,
                                                          flexWrap: "wrap",
                                                          color: "black",
                                                        }}
                                                      >
                                                        {
                                                          d.acao_preparatoria_descricao
                                                        }
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Prioridade:{" "}
                                                    </Grid>
                                                    <Grid
                                                      style={{
                                                        borderRadius: "50%",
                                                        marginTop: "3px",
                                                        height: "6px",
                                                        width: "6px",
                                                        marginRight: "2px",
                                                        backgroundColor:
                                                          prioridadeColor(
                                                            prioridadeDescricao(
                                                              d.acao_preparatoria_prioridade
                                                            )
                                                          ),
                                                      }}
                                                    ></Grid>
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      {prioridadeDescricao(
                                                        d.acao_preparatoria_prioridade
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid 
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Impacto Financeiro:{" "}
                                                    </Grid>
                                                    <Grid
                                                      style={{
                                                        borderRadius: "50%",
                                                        marginTop: "3px",
                                                        height: "6px",
                                                        width: "6px",
                                                        marginRight: "2px",
                                                        backgroundColor:
                                                          impactoFinanceiroColor(
                                                            impactoFinanceiroDescricao(
                                                              c.cliente
                                                                .proposicao_cliente_fatorImpactoFinanceiro
                                                            )
                                                          ),
                                                      }}
                                                    ></Grid>
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      {impactoFinanceiroDescricao(
                                                        c.cliente
                                                          .proposicao_cliente_fatorImpactoFinanceiro
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Valor do Impacto
                                                      Financeiro:{" "}
                                                      <Grid
                                                        style={{
                                                          fontSize:
                                                            pallet.general
                                                              .size16,
                                                          flex: 1,
                                                          flexWrap: "wrap",
                                                          color: "black",
                                                        }}
                                                      >
                                                        {
                                                          c.cliente
                                                            .proposicao_cliente_impactoFinanceiro
                                                        }
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Posicionamento
                                                      Principal:{" "}
                                                    </Grid>
                                                    <Grid
                                                      style={{
                                                        borderRadius: "50%",
                                                        marginTop: "3px",
                                                        height: "6px",
                                                        width: "6px",
                                                        marginRight: "2px",
                                                        backgroundColor:
                                                          posicionamentoColor(
                                                            posicionamentoDescricao(
                                                              c.cliente
                                                                .proposicao_cliente_posicaoDaOrganizacao
                                                            )
                                                          ),
                                                      }}
                                                    ></Grid>
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                      }}
                                                    >
                                                      {posicionamentoDescricao(
                                                        c.cliente
                                                          .proposicao_cliente_posicaoDaOrganizacao
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                      }}
                                                    >
                                                      {">>"} Posicionamento
                                                      Detalhado:{" "}
                                                    </Grid>
                                                  </Grid>
                                                  {c?.acoes_preparatorias
                                                    ?.length > 0 ? (
                                                    c.acoes_preparatorias.map(
                                                      (t) => {
                                                        return t.nota_tecnica.map(
                                                          (q) => {
                                                            return (
                                                              <Grid
                                                                                              style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginLeft:
                                                                    "14px",
                                                                }}
                                                              >
                                                                <Grid
                                                                  style={{
                                                                    fontSize:
                                                                      pallet
                                                                        .general
                                                                        .size16,
                                                                    marginTop:
                                                                      "3px",
                                                                  }}
                                                                >
                                                                  {q.nota_tecnica_responsavel
                                                                    ? q.nota_tecnica_departamento +
                                                                    " | " +
                                                                    q.nota_tecnica_responsavel +
                                                                    " "
                                                                    : q.nota_tecnica_departamento +
                                                                    " "}
                                                                </Grid>
                                                                <Grid
                                                                  style={{
                                                                    borderRadius:
                                                                      "50%",
                                                                    marginTop:
                                                                      "6px",
                                                                    marginRight:
                                                                      "3px",
                                                                    height:
                                                                      "6px",
                                                                    width:
                                                                      "6px",
                                                                    backgroundColor:
                                                                      posicionamentoColor(
                                                                        posicionamentoDescricao(
                                                                          q.nota_tecnica_posicionamento
                                                                        )
                                                                      ),
                                                                  }}
                                                                ></Grid>
                                                                <Grid
                                                                  style={{
                                                                    fontSize:
                                                                      pallet
                                                                        .general
                                                                        .size16,
                                                                    marginTop:
                                                                      "3px",
                                                                  }}
                                                                >
                                                                  {posicionamentoDescricao(
                                                                    q.nota_tecnica_posicionamento
                                                                  )}
                                                                  ,
                                                                </Grid>
                                                                <Grid
                                                                  style={{
                                                                    borderRadius:
                                                                      "50%",
                                                                    marginTop:
                                                                      "6px",
                                                                    height:
                                                                      "6px",
                                                                    marginRight:
                                                                      "3px",
                                                                    width:
                                                                      "6px",
                                                                    backgroundColor:
                                                                      prioridadeColor(
                                                                        prioridadeDescricao(
                                                                          q.nota_tecnica_prioridade
                                                                        )
                                                                      ),
                                                                  }}
                                                                ></Grid>
                                                                <Grid
                                                                  style={{
                                                                    fontSize:
                                                                      pallet
                                                                        .general
                                                                        .size16,
                                                                    marginTop:
                                                                      "3px",
                                                                  }}
                                                                >
                                                                  {prioridadeDescricao(
                                                                    q.nota_tecnica_prioridade
                                                                  )
                                                                    ? prioridadeDescricao(
                                                                      q.nota_tecnica_prioridade
                                                                    )
                                                                    : "Não definida"}
                                                                </Grid>
                                                              </Grid>
                                                            );
                                                          }
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <Grid
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    ></Grid>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          );
                                        })}
                                      </Grid>

                                      {c?.acoes_influencia?.map((d, o) => {
                                        return (
                                          <Grid>
                                            {o === 0 ? (
                                              <Grid>
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    marginTop: "20px",
                                                    color:
                                                      pallet.general.color1,
                                                  }}
                                                >
                                                  | AÇÕES DE INFLUÊNCIA
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              <Grid
                                                style={{ display: "none" }}
                                              ></Grid>
                                            )}
                                            <Grid
                                              style={{ marginBottom: "30px" }}
                                            >
                                              <Grid
                                                style={{
                                                  marginLeft: "15px",
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Data de criação:{" "}
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      flex: 1,
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {formatDate(
                                                      new Date(
                                                        d.acao_influencia_prazo
                                                      ),
                                                      "dd/MM/yyyy"
                                                    )}
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                      flex: 1,
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {">>"} Responsável:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color: "black",
                                                      }}
                                                    >
                                                      {
                                                        d.acao_influencia_responsavel
                                                      }
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Casa Legislativa:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                        color: "black",
                                                      }}
                                                    >
                                                      {d.acao_influencia_casa_legislativa ===
                                                        1
                                                        ? "Senado Federal"
                                                        : d.acao_influencia_casa_legislativa ===
                                                          2
                                                          ? "Câmara dos Deputados"
                                                          : d.acao_influencia_casa_legislativa ===
                                                            3
                                                            ? "Congresso Nacional"
                                                            : d.acao_influencia_casa_legislativa ===
                                                              4
                                                              ? "Assembléias Legislativas"
                                                              : "Câmaras Municipais"}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Âmbito:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                        color: "black",
                                                      }}
                                                    >
                                                      {
                                                        d?.acao_influencia_comissao
                                                      }
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Descrição:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                        color: "black",
                                                      }}
                                                    >
                                                      {
                                                        d?.acao_influencia_descricao
                                                      }
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                {d?.influencia_sobre?.length >
                                                  0 ? (
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                        flexShrink: 1,
                                                      }}
                                                    >
                                                      {">>"} Influência sobre:{" "}
                                                    </Grid>
                                                    <Grid>
                                                      <Grid
                                                        style={{
                                                          fontSize:
                                                            pallet.general
                                                              .size16,
                                                          color: "black",
                                                        }}
                                                      >
                                                        {d.influencia_sobre
                                                          .map((e) => {
                                                            return (
                                                              //   <Link
                                                              //   style={{ color: "black" }}
                                                              //   src={
                                                              //     ini_end +
                                                              //     "//" +
                                                              //     end2 +
                                                              //     "/parlamentar/" +
                                                              //     e.id_parlamentar
                                                              //   }
                                                              // >
                                                              e.nome
                                                              // </Link>
                                                            );
                                                          })
                                                          .join(",")}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                ) : (
                                                  <Grid
                                                    style={{ display: "none" }}
                                                  ></Grid>
                                                )}
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Resultado:{" "}
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      borderRadius: "50%",
                                                      marginRight: "2px",
                                                      marginTop: "3px",
                                                      height: "6px",
                                                      width: "6px",
                                                      backgroundColor:
                                                        resultadoColor(
                                                          resultadoDescricao(
                                                            d.acao_influencia_resultado
                                                          )
                                                        ),
                                                    }}
                                                  ></Grid>
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      flex: 1,
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {resultadoDescricao(
                                                      d.acao_influencia_resultado
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}

                                      {c?.acoes_influenciaEM?.map((d, o) => {
                                        return (
                                          <Grid>
                                            {o === 0 ? (
                                              <Grid>
                                                <Grid
                                                  style={{
                                                    fontSize:
                                                      pallet.general.size16,
                                                    marginTop: "20px",
                                                    color:
                                                      pallet.general.color1,
                                                  }}
                                                >
                                                  | AÇÕES DE INFLUÊNCIA
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              <Grid
                                                style={{ display: "none" }}
                                              ></Grid>
                                            )}
                                            <Grid
                                              style={{ marginBottom: "30px" }}
                                            >
                                              <Grid
                                                style={{
                                                  marginLeft: "15px",
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Data de criação:{" "}
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      flex: 1,
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {formatDate(
                                                      new Date(
                                                        d.acao_influencia_prazo
                                                      ),
                                                      "dd/MM/yyyy"
                                                    )}
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                      flex: 1,
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {">>"} Responsável:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color: "black",
                                                      }}
                                                    >
                                                      {
                                                        d.acao_influencia_responsavel
                                                      }
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Casa Legislativa:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                        color: "black",
                                                      }}
                                                    >
                                                      {d.acao_influencia_casa_legislativa ===
                                                        1
                                                        ? "Senado Federal"
                                                        : d.acao_influencia_casa_legislativa ===
                                                          2
                                                          ? "Câmara dos Deputados"
                                                          : d.acao_influencia_casa_legislativa ===
                                                            3
                                                            ? "Congresso Nacional"
                                                            : d.acao_influencia_casa_legislativa ===
                                                              4
                                                              ? "Assembléias Legislativas"
                                                              : "Câmaras Municipais"}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Descrição:{" "}
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        flex: 1,
                                                        flexWrap: "wrap",
                                                        color: "black",
                                                      }}
                                                    >
                                                      {
                                                        d?.acao_influencia_descricao
                                                      }
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                {d?.influencia_sobreEM?.length >
                                                  0 ? (
                                                  <Grid
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <Grid
                                                      style={{
                                                        fontSize:
                                                          pallet.general.size16,
                                                        color:
                                                          pallet.general.color1,
                                                        flexShrink: 1,
                                                      }}
                                                    >
                                                      {">>"} Influencia sobre:{" "}
                                                    </Grid>
                                                    <Grid>
                                                      <Grid
                                                        style={{
                                                          fontSize:
                                                            pallet.general
                                                              .size16,
                                                          color: "black",
                                                        }}
                                                      >
                                                        {d.influencia_sobreEM
                                                          .map((e) => {
                                                            return (
                                                              //   <Link
                                                              //   style={{ color: "black" }}
                                                              //   src={
                                                              //     ini_end +
                                                              //     "//" +
                                                              //     end2 +
                                                              //     "/interlocutor/" +
                                                              //     e.id_parlamentar
                                                              //   }
                                                              // >
                                                              e.nome
                                                              // </Link>
                                                            );
                                                          })
                                                          .join(",")}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                ) : (
                                                  <Grid
                                                    style={{ display: "none" }}
                                                  ></Grid>
                                                )}
                                                <Grid
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      color:
                                                        pallet.general.color1,
                                                    }}
                                                  >
                                                    {">>"} Resultado:{" "}
                                                  </Grid>
                                                  <Grid
                                                    style={{
                                                      borderRadius: "50%",
                                                      marginRight: "2px",
                                                      marginTop: "3px",
                                                      height: "6px",
                                                      width: "6px",
                                                      backgroundColor:
                                                        resultadoColor(
                                                          resultadoDescricao(
                                                            d.acao_influencia_resultado
                                                          )
                                                        ),
                                                    }}
                                                  ></Grid>
                                                  <Grid
                                                    style={{
                                                      fontSize:
                                                        pallet.general.size16,
                                                      flex: 1,
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {resultadoDescricao(
                                                      d.acao_influencia_resultado
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            );
                          })}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
            
                       
                        </tbody>
                    </table>



                  

                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
