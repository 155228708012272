// import React from 'react';

import { Grid, Divider, Typography, Paper, Box } from "@mui/material";

import { isMobile } from "react-device-detect";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Link } from "@mui/material";
import { inherits } from "util";
import useStyles from "../../../components/layout/Styles";
import EmptyError from "../../../components/EmptyError";
import { usePallet } from "../../../contexts/PalletContext";
import { SubTitle } from "chart.js";

// import { Translate } from '@material-ui/icons';

// const PieArcLabelFormat = (props) => {
//     return (
//         <div onClick={() => {alert("Ola,,,,,,,")}} style={{textAlign:"center", border:"1px solid red", textAnchor:"start", cursor:"pointer"}}>
//             <div>{props.key}</div>
//             <div style={{fontWeight:"bold"}}>{props.data}</div>
//         </div>
//     )
// }

const PieChartInfoInsight = (props: {
  title: string;
  labels: Array<{ key: string; data: number; color: string; url?: string }>;
  subtitle?: string;
}) => {
  const classes = useStyles();
  const { pallet } = usePallet();

  let data = { labels: [], datasets: [] }
  data = {

    labels: props.labels.filter(x => x.data !== null).map((i) => {
      return i.key;
    }),
    datasets: [
      {
        label: props.title,
        data: props.labels.filter(x => x.data !== null).map((i) => {
          return i.data;
        }),
        backgroundColor: props.labels.filter(x => x.data !== null).map((i) => {
          return i.color;
        }),
        hoverBackgroundColor: props.labels.filter(x => x.data !== null).map((i) => {
          return i.color;
        }),
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "#FFFFFF",
        // backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: 2,
        font: {
          size: 15,
          weight: 600,
        },
        formatter: (value, context) => {
          if (value === 0) {
            return "";
          }
          return (
            " " +
            Math.round(
              value / (context.dataset.data.reduce((a, b) => a + b, 0) / 100)
            ) +
            "% "
          ); //Retornar porcentagem
        },
      },
    },
    maintainAspectRatio: false,
    segmentShowStroke: false,
  };

  const height = 250;
  const width = 400;
  const padAngle = 0.02;
  const padRadius = 200;
  const cornerRadius = 6;


  return (
    <Paper>
      <Grid
        className={classes.paper}
        style={{ background: `${pallet.backGroundPageColorPrimary}` }}
      >
        <Grid container style={{ textAlignLast: `${isMobile && props?.subtitle ? "center" : "unset"}`, textAlign: `${isMobile && !props?.subtitle ? "center" : "unset"}` }}>
          <Grid item xs={isMobile ? 12 : null}>
            {isMobile ? (
              <Grid>
                <Typography
                  style={{
                    color: `${pallet.general.color1}`,
                    fontSize: `${pallet.general.size14}`,
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.general.weight_bold,
                    //   paddingLeft: "15px",
                  }}
                >
                  {props.title}
                </Typography>
              </Grid>
            ) : (
              <Typography
                style={{
                  color: `${pallet.general.color1}`,
                  fontSize: `${pallet.general.size14}`,
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.general.weight_bold,
                  paddingLeft: "15px",
                }}
              >
                {props.title} {props?.subtitle ? "|" : null}
              </Typography>
            )}
          </Grid>
          {props?.subtitle ?
            <Grid item xs={isMobile ? 12 : null}>
              <Typography
                style={{
                  color: `${pallet.color.secundary.font}`,
                  fontSize: `${pallet.fontSize.titleWidgets}`,
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.general.weight_bold,
                  paddingLeft: "5px",
                }}
              >
                {props?.subtitle}
              </Typography>
            </Grid>
            :
            null
          }
        </Grid>
      </Grid>
      <Divider />

      {
        props.labels.reduce((a, b) => a + b["data"], 0) > 0 ? (
          <Grid>
            <Box
              display="flex"
              justifyContent="center"
              style={{ paddingTop: "30px", paddingBottom: "30px" }}
            >
              <Doughnut
                width={width}
                height={height}
                data={data}
                options={options}
                plugins={[ChartDataLabels]}
              />
            </Box>
            <Divider />
            <Box
              justifyContent="flex-start"
              style={{
                paddingTop: "5px",
                paddingRight: "20",
                paddingBottom: "18px",
                paddingLeft: "25px",
              }}
            >
              {
                props.labels.filter(x => x.data !== null).map((i) => (

                  isMobile ?
                  <Grid
                    
                    key={i.key}
                    style={{ marginTop: `${isMobile ? "7px" : "13px"}` , display: "flex" ,flexDirection:"row"}}
                  >
                    <div
                      style={{
                        backgroundColor: `${i.color}`,
                        width: "25px",
                        height: "5px",
                        borderRadius: "3px",
                        marginTop: "8px",
                        marginRight: "12px",
                      }}
                    />
                    <div
                      style={{
                        marginRight: "10px",
                        maxWidth: "130px",
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontWeight: pallet.fontWeight.subtitle,
                      }}
                    >
                      {i.url ? (
                        <Link
                          href={i.url}
                          style={{
                            color: `${pallet.color.secundary.font}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.fontWeight.subtitle,
                            textDecoration: "none",
                            wordBreak: "break-all"
                          }}
                        >
                          {i.key}
                        </Link>
                      ) : (
                        i.key
                      )}
                    </div>

                    <div
                      style={{
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontWeight: pallet.fontWeight.lighter,
                        color: `${pallet.color.quaternary.font}`,
                      }}
                    >
                      {i.data}
                    </div>
                  </Grid>
                  :
                  <Grid
                    container
                    key={i.key}
                    style={{ marginTop: `${isMobile ? "7px" : "13px"}` }}
                  >
                    <div
                      style={{
                        backgroundColor: `${i.color}`,
                        width: "25px",
                        height: "5px",
                        borderRadius: "3px",
                        marginTop: "8px",
                        marginRight: "12px",
                      }}
                    />
                    <div
                      style={{
                        marginRight: "12px",
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontWeight: pallet.fontWeight.subtitle,
                      }}
                    >
                      {i.url ? (
                        <Link
                          href={i.url}
                          style={{
                            color: `${pallet.color.secundary.font}`,
                            fontSize: `${pallet.fontSize.titleWidgets}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontWeight: pallet.fontWeight.subtitle,
                            textDecoration: "none",
                            wordBreak: "break-all"
                          }}
                        >
                          {i.key}
                        </Link>
                      ) : (
                        i.key
                      )}
                    </div>

                    <div
                      style={{
                        fontFamily: `${pallet.fontFamily.general}`,
                        fontSize: `${pallet.fontSize.titleWidgets}`,
                        fontWeight: pallet.fontWeight.lighter,
                        color: `${pallet.color.quaternary.font}`,
                      }}
                    >
                      {i.data}
                    </div>
                  </Grid>
                ))}
            </Box>
          </Grid>
        ) : (
          <Grid
            style={{
              color: `${pallet.textColorQuaternary}`,
              fontSize: `${pallet.fontSize.titleWidgets}`,
              fontFamily: `${pallet.fontFamily.general}`,
              fontWeight: 600,
              textAlign: "center",
              paddingBottom: "98px",
              marginTop: "20px",
            }}
          >
            <EmptyError
              title="Ops!"
              description="Ainda não temos dados suficientes para gerar resultados."
            />
          </Grid>
        )
      }
    </Paper >
  );
};

export default PieChartInfoInsight;
