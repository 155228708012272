import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { usePallet } from "../../../../contexts/PalletContext";
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import RelatorioService from "../../../../services/RelatoriosService";
import { format, subYears } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ImageLoader from '../../../../components/ImageLoader';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styled from "styled-components";
import { formatDate } from '../../../../utils/dateUtils';
import { IParlamentar } from '../../../../models/Parlamentar.Model';
import ParlamentarService from '../../../../services/ParlamentarService';
import { calculaIdade } from "../../../../utils/dateUtils";
import ParlamentarDemandaService from '../../../../services/ParlamentarDemandaService';
import ParlamentarAcaoInfluenciaService from '../../../../services/ParlamentarAcaoInfluenciaService';
import ParlamentarProposicaoService from '../../../../services/ParlamentarProposicaoService';
import ParlamentarDiscursoService from '../../../../services/ParlamentarDiscursoService';
import { IFilterData } from '../../../../components/filter/ListFilter';
import ParlamentarAmbitoService from '../../../../services/ParlamentarAmbitoService';
import ParlamentarOutrosService from '../../../../services/ParlamentarOutrosService';
import ProposicaoService from '../../../../services/ProposicaoService';
import { IProposicao } from '../../../../models/Proposicao.Model';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';
import ProposicaoAcontecimentosService from '../../../../services/ProposicaoAcontecimentosService';
import ProposicaoAcaoPreparatoriaService from '../../../../services/ProposicaoAcaoPreparatoriaService';
import ProposicaoAcaoInfluenciaService from '../../../../services/ProposicaoAcaoDeInfluenciaService';
export default function RelatorioInterlocutor() {



    const { pallet } = usePallet();
    const [proposicao, setProposicao] = React.useState<IProposicao>()
    const [filtrores, setFiltrores] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);
    const [filtroint, setFiltroint] = useState<Date>(new Date());
    const [sumaryProposicao, setSumaryProposicao] = useState({ total: 0, seguindo: 0, posicionamento: [] });
    const [listaProposicao, setListaProposicao] = useState([]);
    const [summaryDiscurso, setSummaryDiscurso] = useState({ convergente: 0, divergente: 0, neutro: 0, naoClassificado: 0 });
    const [listaComissao, setListaComissao] = useState([]);
    const [tramitacoes, setTramitacoes] = useState<Array<any>>([]);
    const [acontecimentos, setAcontecimentos] = useState<Array<any>>([]);
    const [timeline, setTimeline] = useState<Array<any>>([]);
    const [acaoPreparatoria, setAcaoPreparatoria] = useState<Array<any>>([]);
    const [acaoInfluencia, setAcaoInfluencia] = useState<Array<any>>([]);
    const [emenda, setEmenda] = useState<Array<any>>([]);


    const [filtroDiscurso, setFiltroDiscurso] = useState<Array<Date>>([subYears(new Date(), 1), new Date()]);



    let filter = useQuery().get('filter');



    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };


    const Imprimir = () => {
        window.print()
    }


    const Assunto = styled.div`
    font-size: ${pallet.general.size12};
    color: ${pallet.color.secundary.font};
    font-weight: ${pallet.general.weight_600};
    font-family: ${pallet.fontFamily.general};
    align-self: center;
    max-height: ${isMobile ? "80px" : "none"};
    max-width: ${isMobile ? "200px" : "550px"}; 
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    margin-left: ${isMobile ? "10px" : "0px"};
    `;

    const ParlamentarConsultaSpanCustom = (props) => {
        const { pallet } = usePallet()
        return (
            <span style={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontWeight: pallet.fontWeight.subtitle,
                fontSize: `${pallet.fontSize.smallButton}`,
                ...props?.style
            }}>
                {props?.children}
            </span>
        )
    }

    const CustomGrid = (props: { children: React.ReactNode, style?: any }): JSX.Element => {
        const { pallet } = usePallet();
        return (
            <Grid style={{
                fontWeight: pallet.fontWeight.subtitle,
                fontSize: `${pallet.fontSize.text_10}`,
                ...props.style
            }}>
                {props.children}
            </Grid>
        )
    }



    const { id } = useParams();
    const { statusTramitacao } = useParams();
    const { statusAcontecimento } = useParams();
    const { statusTimeline } = useParams();
    const { statusAcaoPreparatoria } = useParams();
    const { statusAcaoInfluencia } = useParams();
    const { statusApensada } = useParams();
    const { statusEmenda } = useParams();




    const loadProposicao = () => {
        if (id)
            setTimeout(() => {
                ProposicaoService
                    .Get(Number(id))
                    .then(proposicao => {
                        setProposicao(proposicao)
                    });

            }, 1000);
    }


    React.useEffect(() => {
        if (id) {
            loadProposicao();
            setTimeout(() => {
                getTramitacoes()


            }, 1000);

            setTimeout(() => {
                getAcontecimentos()
                getTimeline()
            }, 3000);


            setTimeout(() => {
                getAcaoPreparatoria()
                getAcaoInfluencia()
                getEmenda()
            }, 5000)
        }
    }, [id])


    React.useEffect(() => {
        getSummaryDiscurso();
    }, [filtroDiscurso])

    const getTramitacoes = () => {
        ProposicaoService.GetTramitacaoNoPage(Number(id)).then(async res => {
            setTramitacoes(() => { return [...res.lista] })
        })
    }


    const getAcontecimentos = () => {
        ProposicaoAcontecimentosService.GetListNoPage(Number(id)).then(async res => {
            setAcontecimentos(() => { return [...res.lista] })
        })
    }


    const getTimeline = () => {
        ProposicaoService.GetTimeLine(Number(id))
            .then(res => {

                setTimeline(res?.lista)
            })
    }






    const casasLegislativa = (value: number) => {
        switch (value) {
            case 1:
                return "Senado";
            case 2:
                return "Câmara dos Deputados";
            case 3:
                return "Congresso";
            case 4:
                return "Assembléia Legislativa";
            case 5:
                return "Câmaras Municipais";
            default:
                return "Não definido";
        }
    };



    const resultadoDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Êxito";
            case 2:
                return "Sem êxito";
            case 3:
                return "Êxito parcial";
            case 4:
                return "Pendente";
            default:
                return "Não definido";
        }
    };

    const resultadoColor = (value: string) => {
        switch (value) {
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Êxito":
                return `${pallet.charts.green}`;
            case "Sem êxito":
                return `${pallet.charts.red}`;
            case "Êxito parcial":
                return `${pallet.charts.blue}`;
            default:
                return "white";
        }
    };

    const posicionamentoDescricao = (value: number) => {
        switch (value) {
            case 0:
                return "Indefinido";
            case 1:
                return "Contrário";
            case 2:
                return "Contrário com emenda saneadora";
            case 3:
                return "Neutro";
            case 4:
                return "Favorável com emenda saneadora";
            case 5:
                return "Favorável";
            case 6:
                return "Favorável ao parecer do relator";
            case 7:
                return "Favorável ao parecer do relator com emenda";
            case 8:
                return "Contrário ao parecer do relator";
            case 9:
                return "Contrário ao parecer do relator com emenda";
            case 99:
                return "Pendente";
            default:
                return "Indefinido";
        }
    };

    const posicionamentoColor = (value: string) => {
        switch (value) {
            case "Contrário":
                return `${pallet.charts.red}`;
            case "Contrário com emenda saneadora":
                return `${pallet.charts.orange}`;
            case "Neutro":
                return `${pallet.charts.purple}`;
            case "Favorável com emenda saneadora":
                return `${pallet.charts.blue}`;
            case "Favorável":
                return `${pallet.charts.green}`;
            case "Pendente":
                return `${pallet.charts.yellow}`;
            case "Indefinido":
                return `${pallet.charts.lightGrey}`;
            case "Favorável ao parecer do relator":
                return '#C71585';
            case "Favorável ao parecer do relator com emenda":
                return '#B8860B';
            case "Contrário ao parecer do relator":
                return '#facb74';
            case "Contrário ao parecer do relator com emenda":
                return '#000000';
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const prioridadeColor = (value: string) => {
        switch (value) {
            case "Máxima":
                return `${pallet.charts.red}`;
            case "Baixa":
                return `${pallet.charts.blue}`;
            case "Média":
                return `${pallet.charts.yellow}`;
            case "Alta":
                return `${pallet.charts.purple}`;
            case "Mínima":
                return `${pallet.charts.green}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const prioridadeDescricao = (value: number) => {
        switch (value) {
            case 1:
                return "Mínima";
            case 2:
                return "Baixa";
            case 3:
                return "Média";
            case 4:
                return "Alta";
            case 5:
                return "Máxima";
            default:
                return "Não definido";
        }
    };


    const qualificacaoColor = (value: string) => {
        switch (value) {
            case "Convergente":
                return `${pallet.charts.green}`;
            case "Divergente":
                return `${pallet.charts.red}`;
            case "Não Classificado":
                return `${pallet.charts.purple}`;
            case "Neutro":
                return `${pallet.charts.lightGrey}`;
            default:
                return `${pallet.charts.lightGrey}`;
        }
    };

    const setFilterDateRange = (ini, fin) => {
        setFiltrores(() => [ini, fin])
    }

    const setFilterDateYear = (dtyear) => {
        setFiltroint(() => dtyear)
    }


    const setFilterDate = (ini, fin) => {
        setFiltroDiscurso(() => [ini, fin])
    }



    const getPosicionamentoCount = (value: string) => {
        const posicionamentoCount = sumaryProposicao.posicionamento.filter(item => item.proposicao_cliente_posicionamentos === value)
        if (posicionamentoCount.length) {
            return posicionamentoCount[0].count
        }
        else {
            return 0
        }
    }

    const getSummaryDiscurso = () => {
        const filter = [
            { key: 'dtIni', value: format(filtroDiscurso[0], 'yyyy-MM-dd') },
            { key: 'dtFin', value: format(filtroDiscurso[1], 'yyyy-MM-dd') },
        ]
        if (String(format(new Date(filtroDiscurso[0]), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(filtroDiscurso[0]), "yyyy")).slice(0, 1) !== '0') {
            ParlamentarDiscursoService.GetSummary(Number(id), filter)
                .then(async res => {
                    setSummaryDiscurso(() => res)
                })
        }

    }



    const getAcaoPreparatoria = () => {

        const f = [{ key: 'status', value: "tudo" }]


        ProposicaoAcaoPreparatoriaService.GetList(Number(id), f, 1, 8).then(async res => {
            if (res && res.success) {
                setAcaoPreparatoria(res.lista);


            }

        })
    }




    const getAcaoInfluencia = () => {

        const f = [{ key: 'status', value: 'tudo' }]


        ProposicaoAcaoInfluenciaService.GetList(Number(id), f, 1, 8).then(async res => {
            if (res && res.success) {
                setAcaoInfluencia(res.lista);


            }

        })

    }



    const getEmenda = () => {


        ProposicaoService.GetListEmenda(Number(id), [{ key: 'posicionamento', label: 'Posicionamento', value: 'T' }], 1, 50).then(async res => {

            if (res && res.success) {
                setEmenda(res.listData);

            }

        })


    }






    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            {/* <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton> */}

                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px',

                                    }}>

                                        <Grid style={{ marginLeft: '50px' }}>

                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                {proposicao?.descricao}
                                            </Grid>
                                            <ParlamentarConsultaSpanCustom >
                                                Posicionamento:  {proposicao?.cliente.posicionamento_descricao} • {" "}
                                            </ParlamentarConsultaSpanCustom>
                                            <ParlamentarConsultaSpanCustom >
                                                Prioridade: {proposicao?.cliente.prioridade_descricao}
                                            </ParlamentarConsultaSpanCustom>
                                            <ParlamentarConsultaSpanCustom style={{ textTransform: "uppercase" }}>
                                                {/* {parlamentar?.uf_exercicio} • {" "} */}
                                            </ParlamentarConsultaSpanCustom>

                                            <ParlamentarConsultaSpanCustom>

                                            </ParlamentarConsultaSpanCustom>
                                            <Grid style={{ marginTop: "10px" }}>
                                                <ParlamentarConsultaSpanCustom>
                                                    {/* {parlamentar?.emails && parlamentar?.emails.length > 0 ?
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                {parlamentar?.emails?.map((item, index) => {
                                                    return (
                                                        <Grid container>
                                                            <Grid style={{fontWeight: pallet.fontWeight.subtitle, marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                               @ E-mail {index + 1}
                                                            </Grid>
                                                            <Grid style={{marginRight: `${isMobile ? "0px" : "10px"}` }}>
                                                                {item}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Box>
                                            :
                                            <Grid style={{ marginLeft: "5px" }}>
                                                Email não informado
                                            </Grid>
                                        } */}
                                                </ParlamentarConsultaSpanCustom>
                                            </Grid>
                                            <ParlamentarConsultaSpanCustom>
                                                <Grid style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}>

                                                    <Grid><PersonIcon fontSize="small" /></Grid> <Grid>{proposicao?.autores && proposicao?.autores.length > 0 ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            {proposicao?.autores?.map((item, index) => {
                                                                return (
                                                                    <Grid container>
                                                                        <Grid style={{ marginLeft: "5px", fontWeight: pallet.fontWeight.subtitle }}>
                                                                            Autor: {proposicao?.autores.length === 1 ? "" : index + 1}
                                                                        </Grid>
                                                                        <Grid style={{ marginLeft: "5px" }}>
                                                                            <span>{item.nome}</span><span></span>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Box>
                                                        :
                                                        <Grid style={{ marginLeft: "5px" }}>
                                                            Autor não informado
                                                        </Grid>
                                                    }</Grid>

                                                </Grid>
                                            </ParlamentarConsultaSpanCustom>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>

                                    <Grid style={{
                                        width: '990px',
                                        marginTop: '30px',
                                    }}>
                                        <Grid style={{ paddingLeft: '40px' }} container>
                                            <Grid style={{ marginBottom: `${isMobile ? "8px" : "0px"}`, marginRight: '50px' }}>
                                                <Grid style={{ color: "black", marginBottom: "6px", marginTop: "10px", fontWeight: pallet.general.weight_600 }}>
                                                    Ementa
                                                </Grid>
                                                <Grid style={{ color: "black", fontWeight: 100 }}>{proposicao?.ementa} anos</Grid>
                                            </Grid>



                                        </Grid>

                                    </Grid>
                                    {/* } */}

                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{ paddingLeft: '30px' }}>
                        <Divider />
                    </Grid>
                    <Grid style={{
                        marginTop: '20px',
                        marginLeft: '30px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>


                                <tr>
                                    <td>

                                        {

                                            statusTramitacao === "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    TRAMITAÇÕES
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                {

                                    statusTramitacao === "sim" ?

                                        tramitacoes?.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.casa}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>

                                                            {moment(item.data_evento).format("DD/MM/yyyy")}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {item.descricao}
                                                        </td>

                                                    </tr>
                                                </>
                                            )
                                        }) :
                                        null
                                }



                                <tr>
                                    <td>
                                        {
                                            statusAcontecimento == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginTop: "30px", marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    ACONTECIMENTOS


                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                {

                                    statusAcontecimento === "sim" ?

                                        acontecimentos?.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.descrição}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>

                                                            {moment(item.data).format("DD/MM/yyyy")}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {item.descricao}
                                                        </td>

                                                    </tr>
                                                </>
                                            )
                                        }) :
                                        null
                                }
                                <tr>
                                    <td>
                                        {
                                            statusTimeline == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginTop: "30px", marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    TIMELINE


                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                {


                                    statusTimeline === "sim" ?

                                        timeline?.map((item) => {


                                            return (
                                                item.eventos.map((b) => {
                                                    return (

                                                        <Grid style={{ marginBottom: '10px' }}>

                                                            {b.descricao}




                                                        </Grid>

                                                    )
                                                })


                                            )

                                        })


                                        :
                                        null
                                }

                                <tr>
                                    <td>
                                        {
                                            statusAcaoPreparatoria == "sim" ?
                                                <Assunto item >
                                                    Ação Preparatória
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                {
                                    statusAcaoPreparatoria == "sim" ?
                                        acaoPreparatoria?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                Data: {moment(item.prazo).format("DD/MM/yyyy")}
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                Responsavel: {item.responsavel}
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                Status: {item.status}
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                Notas Técnicas Preenchidas: {item.totalNotaTecPreench} / {item.totalNotaTec}
                                                            </Grid>
                                                        </td>
                                                    </tr>


                                                </>
                                            )
                                        }

                                        )
                                        :
                                        null
                                }
                                {
                                    statusAcaoInfluencia == "sim" ?
                                        acaoInfluencia?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >

                                                                <Grid style={{
                                                                    paddingTop: '5px',
                                                                    fontWeight: pallet.general.weight_600
                                                                }}>
                                                                    <Grid style={{
                                                                        display: "inline-block",
                                                                        color: `${pallet.color.secundary.font}`,
                                                                        fontWeight: pallet.general.weight_600
                                                                    }}>{moment(item.acao_influencia_atualizado_em).format("DD/MM/yyyy")}</Grid>
                                                                    <Grid style={{ display: "inline-block" }}> - RESULTADO</Grid>
                                                                    <Grid
                                                                        style={{
                                                                            display: "inline-block",
                                                                            borderRadius: "50%",
                                                                            marginLeft: "10px",
                                                                            marginRight: "2px",
                                                                            marginTop: "3px",
                                                                            height: "6px",
                                                                            width: "6px",
                                                                            backgroundColor:
                                                                                posicionamentoColor(
                                                                                    posicionamentoDescricao(item.acao_influencia_resultado)
                                                                                ),
                                                                        }}
                                                                    ></Grid>

                                                                    {"    "}{posicionamentoDescricao(item.acao_influencia_resultado)}
                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Data: {moment(item.acao_influencia_prazo).format("DD/MM/yyyy")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Casa Legislativa: {casasLegislativa(item.acao_influencia_casa_legislativa)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Responsável: {item.acao_influencia_responsavel}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Influência sobre: {item.influenciados.map((a) => {
                                                            return (

                                                                a.nome
                                                            )
                                                        }).join(',')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Âmbito: {item.comissoes_descricao}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Descrição: {item.acao_influencia_descricao}</td>
                                                    </tr>


                                                </>
                                            )
                                        }

                                        )
                                        :
                                        null
                                }

                                <tr>
                                    <td>
                                        {
                                            statusApensada == "sim" ?
                                                <Assunto item >
                                                    {isMobile ? null : <span style={{ marginTop: "30px", marginRight: `${isMobile ? "0" : "5px"}` }}> | </span>}
                                                    PROPOSIÇÕES
                                                </Assunto>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            statusApensada == "sim" ?
                                                <div>
                                                    <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                                        <Grid item xl={12} lg={12} xs={12}>

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>

                                    </td>
                                </tr>


                                {
                                    statusApensada == "sim" ?
                                        listaProposicao?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            <Grid container >
                                                                <Grid
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginLeft: "-2px",
                                                                        borderRadius: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        position: "relative",
                                                                        boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                        backgroundColor: `${pallet.general.color4}`
                                                                    }}
                                                                >
                                                                    <Grid style={{
                                                                        position: "absolute",
                                                                        borderRadius: "50%",
                                                                        top: "50%",
                                                                        left: "50%",
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        margin: "-7.5px 0px 0px -7.5px",
                                                                        zIndex: 1,
                                                                        backgroundColor: `${pallet.backgroundColorQuaternary}`,
                                                                        border: `3px solid ${pallet.backgroundColorQuaternary}`
                                                                    }} >

                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>


                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ paddingTop: '5px', marginLeft: "15px", fontWeight: pallet.general.weight_600 }}><Grid style={{ display: "inline-block", color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>{item.proposicao_descricao + " "}</Grid>
                                                                    <Grid style={{ display: "inline-block" }}> - {format(new Date(item.proposicao_data_apresentacao ? item.proposicao_data_apresentacao : null), 'dd/MM/yyyy')} - POSICIONAMENTO:
                                                                        <Grid
                                                                            style={{
                                                                                display: "inline-block",
                                                                                borderRadius: "50%",
                                                                                marginLeft: "10px",
                                                                                marginRight: "2px",
                                                                                marginTop: "3px",
                                                                                height: "6px",
                                                                                width: "6px",
                                                                                backgroundColor:
                                                                                    posicionamentoColor(
                                                                                        item.proposicao_cliente_posicionamento_descricao

                                                                                    ),
                                                                            }}

                                                                        ></Grid> {item.proposicao_cliente_posicionamento_descricao} |  PRIORIDADE:                     <Grid
                                                                            style={{
                                                                                display: "inline-block",
                                                                                borderRadius: "50%",
                                                                                marginLeft: "10px",
                                                                                marginRight: "2px",
                                                                                marginTop: "3px",
                                                                                height: "6px",
                                                                                width: "6px",
                                                                                backgroundColor:
                                                                                    prioridadeColor(
                                                                                        item.proposicao_cliente_prioridade_descricao


                                                                                    ),
                                                                            }}


                                                                        ></Grid>
                                                                        <span>&nbsp;</span>  {item.proposicao_cliente_prioridade_descricao}
                                                                    </Grid>


                                                                </Grid>

                                                            </Grid>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <Grid style={{ display: 'inline-block' }}>
                                                                <span style={{ color: `${pallet.color.secundary.font}`, fontWeight: pallet.general.weight_600 }}>Ementa: </span> {item.proposicao_ementa}</Grid>                    </td>
                                                    </tr>



                                                </>
                                            )

                                        }

                                        )
                                        :
                                        null
                                }

                                {
                                    statusEmenda == "sim" ?
                                        <tr>
                                            <td>
                                                <Assunto item >
                                                    Emendas
                                                </Assunto>
                                            </td>
                                        </tr>
                                        :
                                        null
                                }
                                {
                                    statusEmenda == "sim" ?
                                        emenda?.map(item => {
                                            return (

                                                <>

                                                    <tr>
                                                        <td>
                                                            Data: {moment(item.data).format("DD/MM/yyyy")}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Ementa: {item.ementa}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Posicionamento: {item.posicionamento}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Proposição: {item.proposicao}
                                                        </td>
                                                    </tr>



                                                </>
                                            )
                                        }

                                        )
                                        :
                                        null

                                }








                            </tbody>
                        </table>

                    </Grid>

                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
