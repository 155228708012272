import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

import { v4 as uuid } from "uuid";
import filesize from "filesize";

import { Grid, Tooltip, Typography } from "@mui/material";

import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteIcon from "@mui/icons-material/Delete";

import UploadFilesService from "../services/UploadFiles.service";

import { usePallet } from "../contexts/PalletContext";
import { useAlert } from "../contexts/AlertContext";

import DownloadFile from "../img/DownloadFile.png";

import ButtonAdd from "./ButtonAdd";


// https://www.bezkoder.com/react-file-upload-axios/
// https://www.bezkoder.com/material-ui-file-upload/
export enum EAnexoModulos {
  ProposicaoEmenda = 'proposicao-emenda',
  Acontecimentos = "acontecimentos",
  Resultados = "resultados",
  AcaoInfluencia = "acao-influencia",
  ProposicaoAcaoInfluencia = "proposicao-acao-influencia",
  AcaoPreparatoria = "acao-preparatoria",
  Comentario = "comentario",
  NotaTecnica = "nota-tecnica",
  Demandas = "demandas",
  DemandasParlamentar = "demandas-parlamentar",
  DemandasPosicionamento = "demandas-posicionamento",
  AcontecimentosParlamentar = "acontecimentos-parlamentar",
  EsforcoColaborativo = "esforco-colaborativo",
  // Proposicao = "proposicao",
  ProposicaoAcaoPreparatoria = "acao-preparatoria",
  ParlamentarImagem = "parlamentar-imagem",
  Tramitacao = "proposicao-tramitacao",
  AnaliseDeImpacto = "analise-de-impacto",
  Agenda = "agenda",
  UsuarioImagem = "usuario-imagem",
  Discurso = "discurso-parlamentar",
  PosicionamentoUnidadeNegocio = "posicionamento-unidade-negocio"
}


export interface IFileInfo {
  name: string;
  filename: string;
  size: number;
  new: boolean;
  file: any;
}


// Exemplo de Uso do componente de Anexos
// export function UploadFilesComponent() {
//     const [idReferencia, setIdReferencia] = React.useState<number>(5794);
//     return (
//         <div className="container">
//             <AnexosComponent
//                 label={'Anexos Teste'}
//                 modulo={EAnexoModulos.AcontecimentosParlamentar}
//                 id_referencia={idReferencia}
//                 canEdit={true}
//                 autosave={true}
//             />
//         </div>
//     );
// }


function AnexosItemComponent(props: {
  index: number;
  canEdit: boolean;
  file: IFileInfo;
  onClick: (file: IFileInfo) => void;
  onRemove: (file: IFileInfo) => void;
}): JSX.Element {
  const { pallet } = usePallet();

  return (
    <>
      <Grid container xl={6} lg={6} xs={12} style={{ paddingRight: "15px" }}>
        <Typography
          key={props.index}
          align="justify"
          // onClick={props.canEdit ? null : () => props.onClick(props.file)}
          onClick={() => props.onClick(props.file)}
          style={{
            padding: "3px 9px",
            borderRadius: "3px",
            backgroundColor: `${pallet.backgroundOpacityPrimary}`,
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.subtitle,
            fontSize: `12px`,
            //color: props.file.new ? '#0040FF' : `${pallet.color.colorBase.greyText}`,
            marginBottom: "15px",
            width: "100%",
          }}
        >
          <Tooltip
            key={`t-${props.index}`}
            title={`${props.file.name} (${filesize(props.file.size)})`}
            arrow
            placement={"bottom"}
            style={{ marginRight: "25px" }}
          >
            <Grid
              container item
              style={{
                padding: "12px 7px 12px 7px",
                marginLeft: "0px",
                width: props.canEdit ? "92%" : "96%",
                display: "inline-block",

              }}
              alignItems="center"
              direction="row"
              justifyContent={"space-between"}
            >
              <Grid item>
                <Grid container>
                  <Grid style={{ marginRight: "5px" }}>
                    <Typography
                      key={props.index}
                      align="justify"
                      // onClick={() => props.onClick(props.file)}
                      style={{
                        padding: "3px 9px",
                        borderRadius: "5px",
                        backgroundColor: `${pallet.backgroundColorPrimary}`,
                      }}
                    >
                      <AttachmentIcon
                        style={{
                          color: `${pallet.backgroundColorSecondary}`,
                          marginTop: "5px",
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid style={{ marginLeft: "10px" }}>
                    <Grid style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: `${isMobile ? "100px" : "200px"}`,
                        }}
                      >
                        {props.file.name}
                      </div>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: `${pallet.fontSize.text_10}`,
                        color: `${pallet.general.color4}`,
                      }}
                    >
                      {filesize(props.file.size)}
                    </Grid>
                    {/* {props.file.name.substring(0, 5)}... ({filesize(props.file.size)}) */}
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Tooltip>
          <Grid item style={{ width: "4%", display: "inline-block" }}>
            <Grid>
              {props.canEdit ? (
                <Tooltip
                  style={{ marginRight: "10px" }}
                  key={`tdel-${props.index}`}
                  title={`${props.file.new ? "Remover" : "Excluir"}  ${props.file.name
                    }`}
                  arrow
                  placement={"bottom"}
                >
                  <Typography
                    key={`del-${props.index}`}
                    align="justify"
                    onClick={() => props.onRemove(props.file)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: `${pallet.color.colorBase.greyBackground}`,
                      fontFamily: `${pallet.fontFamily.general}`,
                      fontWeight: pallet.fontWeight.subtitle,
                      color: props.file.new
                        ? "#0040FF"
                        : `${pallet.color.colorBase.greyText}`,
                    }}
                  >

                    <DeleteIcon
                      style={{
                        color: `${pallet.textColorTertiary}`,
                        fontSize: "28px",
                      }}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip
                  style={{ marginRight: "10px" }}
                  key={`tdel-${props.index}`}
                  title={`${props.file.new ? "Remover" : "Baixar"}  ${props.file.name
                    }`}
                  arrow
                  placement={"bottom"}
                >
                  <Typography
                    key={`del-${props.index}`}
                    align="justify"
                    // onClick={() => props.onClick(props.file)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: `${pallet.color.colorBase.greyBackground}`,
                      fontFamily: `${pallet.fontFamily.general}`,
                      fontWeight: pallet.fontWeight.subtitle,
                      color: props.file.new
                        ? "#0040FF"
                        : `${pallet.color.colorBase.greyText}`,
                    }}
                  >
                    <img src={DownloadFile} style={{ height: "17px" }} />
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Typography>

      </Grid>
    </>
  );
}


function AnexosComponent(props: {
  label?: string;
  modulo: EAnexoModulos;
  id_referencia?: number;
  autosave: boolean;
  canEdit: boolean;
  save?: boolean;
  onAfterUpload?: () => void;
  isAnexosTitle?: string;
  msgResp?: boolean
}): JSX.Element {
  const { NewAlert } = useAlert();
  const [message] = React.useState<string>();
  const [fileInfos, setFileInfos] = React.useState<Array<IFileInfo>>([]);
  const { pallet } = usePallet();

  useEffect(() => {
    if (!props.id_referencia && fileInfos.length > 0) {
      setFileInfos(() => []);
    } else {
      if (
        props.autosave &&
        props.canEdit &&
        props.id_referencia &&
        checkNewFile()
      ) {
        handleUploadFiles();
      } else if (props.id_referencia) {
        getList();
      };
    };
  }, [props.id_referencia]);

  useEffect(() => {
    if (props.save && props.id_referencia && checkNewFile()) {

      handleUploadFiles();
    } else if (props?.save && props?.onAfterUpload) props?.onAfterUpload();
  }, [props?.save]);

  useEffect(() => {
    if (
      !props.id_referencia &&
      fileInfos.filter((item) => !item.new).length > 0
    )
      setFileInfos((old) => [...old.filter((item) => item.new)]);

    if (fileInfos.length === 0 || !props.id_referencia) return;

    if (props.autosave && checkNewFile()) handleUploadFiles();
  }, [fileInfos]);

  const getList = React.useCallback(() => {
    if (!props.id_referencia) return;

    UploadFilesService.getList(props.id_referencia, props.modulo).then(
      (response) => {
        if (response) {
          setFileInfos(() => [
            ...response.map(
              (item: { name: string; filename: string; size: number }) => {
                return {
                  name: item.name,
                  filename: item.filename,
                  size: item.size,
                  new: false,
                  file: null,
                };
              }
            ),
          ]);
        };
      }
    );
  }, [props.id_referencia]);

  const handleAddFile = (event: any) => {
    event.preventDefault();

    if (event && event.target && event.target.files) {
      const filesList = Object.keys(event.target.files);
      let totalSize = fileInfos.reduce((accum, curr) => accum + curr.size, 0);

      filesList.map(
        (file) => (totalSize = totalSize + event.target.files[file].size)
      );

      if (totalSize > 10 * 1024 * 1024 - 1024) {
        event.target.value = null;
        NewAlert('error', 'Total de anexos excede 10Mb...');
        return;
      };

      filesList.map((file) => {
        const exists = !!(
          fileInfos.filter((x) => x.name == event.target.files[file].name)
            .length > 0
        );

        if (!exists) {
          setFileInfos((oldArray) => [
            ...oldArray,
            {
              name: event.target.files[file].name,
              filename: "",
              size: event.target.files[file].size,
              new: true,
              file: event.target.files[file],
            },
          ]);
        }
      });
    }

    event.target.value = null;
  };

  const handleUploadFiles = async () => {
    if (!props.id_referencia) return;

    const files = fileInfos.filter((file) => file.new === true);

    if (files.length > 0)
      await UploadFilesService.upload(
        files.map((item) => item.file),
        props.modulo,
        props.id_referencia
      )
        .then((response) => {
          if (response?.success && response?.data && response?.data?.length > 0) {
            NewAlert('success', 'Sucesso no envio dos arquivos!');
            setFileInfos([]);
          } else {
            console.error(response);
            NewAlert('error', 'Falha no upload dos arquivos!');
          }
        })
        .then(() => {
          getList();
        })
        .catch((err) => {
          console.error(err);
          NewAlert('error', 'Falha no upload dos arquivos!');
        });

    if (props.onAfterUpload) props.onAfterUpload();
  };

  const handleRemoveFile = async (file: IFileInfo) => {
    if (!file.new) {
      await UploadFilesService.delete(file.filename);
    };

    const currentFiles = fileInfos.filter(
      (item) =>
        (file.new && item.name !== file.name) ||
        (!file.new && item.filename !== file.filename)
    );

    setFileInfos(() => [...currentFiles]);
  };

  const handleDownloadFile = async (file: IFileInfo) => {
    if (!file) return;

    if (file.new || !file.filename || file.filename === "") return;

    await UploadFilesService.download(file.filename).then((resp) => {
      if (resp.status == 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");

        link.href = url;
        link?.setAttribute("download", file.name);
        document.body.appendChild(link);
        link?.click();
        link?.parentNode?.removeChild(link);
      } else {
        console.error(resp);
        NewAlert('error', `Falha no download do arquivo "${file.name.substring(0, 10)}..."`);
      }
    }).catch((err) => {
      console.error(err);
      NewAlert('error', `Falha no download do arquivo "${file.name.substring(0, 10)}..."`)
    });
  };

  const checkNewFile = () =>
    fileInfos.filter((file) => file.new === true).length > 0;
  const id = uuid();

  return (
    <div className="mg20">
      {props?.isAnexosTitle && fileInfos.length > 0 ?
        <Grid style={{
          fontWeight: pallet.fontWeight.subtitle,
          fontSize: `${pallet.general.size12}`,
          color: `${pallet.color.tertiary.font}`,
          marginBottom: "10px"
        }}>
          {props?.isAnexosTitle}
        </Grid> : null
      }

      {props?.msgResp || props?.msgResp === undefined ?
        <Typography
          variant="subtitle2"
          className={`upload-message ${message && message !== "" ? "error" : ""}`}
        >
          {message}
        </Typography> : null
      }

      <Grid container>
        <label htmlFor={`input-${id}`}>
          <input
            id={`input-${id}`}
            key={`input-${id}`}
            name={`input-${id}`}
            style={{ display: "none" }}
            type="file"
            onChange={handleAddFile}
          />
          {props.canEdit && (
            <Grid style={{ marginTop: "15px", marginBottom: "25px" }}>
              <ButtonAdd />
            </Grid>
          )}
        </label>
        <Typography variant="h6" className="list-header">
          {props?.label}
        </Typography>
      </Grid>

      <Grid container style={{ paddingTop: '12px', paddingLeft: '12px' }}>
        {fileInfos.map((file, index) => (
          <AnexosItemComponent
            key={`file-${index}-${id}`}
            index={index}
            canEdit={props.canEdit}
            file={file}
            onClick={handleDownloadFile}
            onRemove={handleRemoveFile}
          />
        ))}
      </Grid>
    </div>
  );
}


export function SalvarAnexo(props: {
  label?: string;
  modulo: EAnexoModulos;
  id_referencia?: number;
  autosave: boolean;
  canEdit: boolean;
  save?: boolean;
  onAfterUpload?: () => void;
  isAnexosTitle?: string;
  msgResp?: boolean
}): JSX.Element {
  const { NewAlert } = useAlert();
  const [message] = React.useState<string>();
  const [fileInfos, setFileInfos] = React.useState<Array<IFileInfo>>([]);
  const { pallet } = usePallet();

  useEffect(() => {
    if (!props.id_referencia && fileInfos.length > 0) {
      setFileInfos(() => []);
    } else {
      if (
        props.autosave &&
        props.canEdit &&
        props.id_referencia &&
        checkNewFile()
      ) {
        handleUploadFiles();
      } else if (props.id_referencia) {
        getList();
      };
    };
  }, [props.id_referencia]);

  useEffect(() => {
    if (props.save && props.id_referencia && checkNewFile()) {

      handleUploadFiles();
    } else if (props?.save && props?.onAfterUpload) props?.onAfterUpload();
  }, [props?.save]);

  useEffect(() => {
    if (
      !props.id_referencia &&
      fileInfos.filter((item) => !item.new).length > 0
    )
      setFileInfos((old) => [...old.filter((item) => item.new)]);

    if (fileInfos.length === 0 || !props.id_referencia) return;

    if (props.autosave && checkNewFile()) handleUploadFiles();
  }, [fileInfos]);

  const getList = React.useCallback(() => {
    if (!props.id_referencia) return;

    UploadFilesService.getList(props.id_referencia, props.modulo).then(
      (response) => {
        if (response) {
          setFileInfos(() => [
            ...response.map(
              (item: { name: string; filename: string; size: number }) => {
                return {
                  name: item.name,
                  filename: item.filename,
                  size: item.size,
                  new: false,
                  file: null,
                };
              }
            ),
          ]);
        };
      }
    );
  }, [props.id_referencia]);

  const handleAddFile = (event: any) => {
    event.preventDefault();

    if (event && event.target && event.target.files) {
      const filesList = Object.keys(event.target.files);
      let totalSize = fileInfos.reduce((accum, curr) => accum + curr.size, 0);

      filesList.map(
        (file) => (totalSize = totalSize + event.target.files[file].size)
      );

      if (totalSize > 10 * 1024 * 1024 - 1024) {
        event.target.value = null;
        NewAlert('error', 'Total de anexos excede 10Mb...');
        return;
      };

      filesList.map((file) => {
        const exists = !!(
          fileInfos.filter((x) => x.name == event.target.files[file].name)
            .length > 0
        );

        if (!exists) {
          setFileInfos((oldArray) => [
            ...oldArray,
            {
              name: event.target.files[file].name,
              filename: "",
              size: event.target.files[file].size,
              new: true,
              file: event.target.files[file],
            },
          ]);
        }
      });
    }

    event.target.value = null;
  };

  const handleUploadFiles = async () => {
    if (!props.id_referencia) return;

    const files = fileInfos.filter((file) => file.new === true);

    if (files.length > 0)
      await UploadFilesService.upload(
        files.map((item) => item.file),
        props.modulo,
        props.id_referencia
      )
        .then((response) => {
          if (response?.success && response?.data && response?.data?.length > 0) {
            NewAlert('success', 'Sucesso no envio dos arquivos!');
            setFileInfos([]);
          } else {
            console.error(response);
            NewAlert('error', 'Falha no upload dos arquivos!');
          }
        })
        .then(() => {
          getList();
        })
        .catch((err) => {
          console.error(err);
          NewAlert('error', 'Falha no upload dos arquivos!');
        });

    if (props.onAfterUpload) props.onAfterUpload();
  };

  const handleRemoveFile = async (file: IFileInfo) => {
    if (!file.new) {
      await UploadFilesService.delete(file.filename);
    };

    const currentFiles = fileInfos.filter(
      (item) =>
        (file.new && item.name !== file.name) ||
        (!file.new && item.filename !== file.filename)
    );

    setFileInfos(() => [...currentFiles]);
  };

  const handleDownloadFile = async (file: IFileInfo) => {
    if (!file) return;

    if (file.new || !file.filename || file.filename === "") return;

    await UploadFilesService.download(file.filename).then((resp) => {
      if (resp.status == 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");

        link.href = url;
        link?.setAttribute("download", file.name);
        document.body.appendChild(link);
        link?.click();
        link?.parentNode?.removeChild(link);
      } else {
        console.error(resp);
        NewAlert('error', `Falha no download do arquivo "${file.name.substring(0, 10)}..."`);
      }
    }).catch((err) => {
      console.error(err);
      NewAlert('error', `Falha no download do arquivo "${file.name.substring(0, 10)}..."`)
    });
  };

  const checkNewFile = () =>
    fileInfos.filter((file) => file.new === true).length > 0;
  const id = uuid();

  return (
    <div className="mg20">
      {props?.isAnexosTitle && fileInfos.length > 0 ?
        <Grid style={{
          fontWeight: pallet.fontWeight.subtitle,
          fontSize: `${pallet.general.size12}`,
          color: `${pallet.color.tertiary.font}`,
          marginBottom: "10px"
        }}>
          {props?.isAnexosTitle}
        </Grid> : null
      }

      {props?.msgResp || props?.msgResp === undefined ?
        <Typography
          variant="subtitle2"
          className={`upload-message ${message && message !== "" ? "error" : ""}`}
        >
          {message}
        </Typography> : null
      }

      <Grid container>
        <label htmlFor={`input-${id}`}>
          <input
            id={`input-${id}`}
            key={`input-${id}`}
            name={`input-${id}`}
            style={{ display: "none" }}
            type="file"
            onChange={handleAddFile}
          />
          {props.canEdit && (
            <Grid style={{ marginTop: "15px", marginBottom: "25px" }}>
              <ButtonAdd />
            </Grid>
          )}
        </label>
        <Typography variant="h6" className="list-header">
          {props?.label}
        </Typography>
      </Grid>

      <Grid container style={{ paddingTop: '12px', paddingLeft: '12px' }}>
        {fileInfos.map((file, index) => (
          <AnexosItemComponent
            key={`file-${index}-${id}`}
            index={index}
            canEdit={props.canEdit}
            file={file}
            onClick={handleDownloadFile}
            onRemove={handleRemoveFile}
          />
        ))}
      </Grid>
    </div>
  );
}


export default AnexosComponent;
