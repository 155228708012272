import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { usePallet } from "../../../../contexts/PalletContext";
import { IProposicao } from "../../../../models/Proposicao.Model";
import ProposicaoService from "../../../../services/ProposicaoService";
import { IListInfo } from "../../../../models/IListInfo";
import { IFilterData } from "../../../../components/filter/ListFilter";

const ProposicaoConsultaSpanCustom = (props) => {
  const { pallet } = usePallet();
  return (
    <span
      style={{
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: pallet.fontWeight.subtitle,
        fontSize: `${pallet.fontSize.smallButton}`,
        ...props?.style,
      }}
    >
      {props?.children}
    </span>
  );
};

const ProposicaoConsultaTypography = (props) => {
  const { pallet } = usePallet();
  return (
    <Typography
      align="justify"
      style={{
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: pallet.fontWeight.subtitle,
        fontSize: `${pallet.fontSize.titleCardParlamentar}`,
        color: `${pallet.color.colorBase.black}`,
        ...props?.style,
      }}
    >
      {props?.children}
    </Typography>
  );
};
const ProposicaoApelidoConsultaTypography = (props) => {
  const { pallet } = usePallet();
  return (
    <Typography
      align="justify"
      style={{
        fontFamily: `${pallet.fontFamily.general}`,
        fontWeight: `${pallet.general.weight_600}`,
        fontSize: "15px",
        paddingTop: "2px",
        color: `${pallet.color.colorBase.black}`,
        ...props?.style,
        wordBreak: "break-word",
      }}
    >
      {props?.children}
    </Typography>
  );
};

export const ProposicaoConsultaInicio = (props: {
  id_proposicao: number,
  proposicao_integrada?: boolean,
  proposicao: IProposicao;
}) => {

  const { pallet } = usePallet();
  const [lista, setLista] = useState([]);
  const [infoList, setInfoList] = useState<IListInfo>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filtro, setFiltro] = useState<IFilterData[]>([{ key: 'tipo_relacao', label: 'Tipo', value: 'T' }]);


  useEffect(() => {
    getList()
    // eslint-disable-next-line
  }, [currentPage, filtro])

  const getList = useCallback(() => {
    const filter = filtro.map(item => { return { key: item.key, value: item.value } });

    if (currentPage && currentPage > 0) {
      ProposicaoService.GetListApensada(props.id_proposicao, filter, currentPage, 8).then(async res => {
        if (res && res.success) {
          setLista(res.listData);
          setInfoList(res.listInfo);
        }
      })
    }

  }, [props.id_proposicao, currentPage, filtro])

  const associadas = lista.filter(i => i.tipo_relacao === "ASSOCIADA")
  const apensada = lista.filter(i => i.tipo_relacao === "APENSADA")
  return (
    <>
      <Grid container style={{ paddingLeft: `${isMobile ? "20px" : "50px"}`, marginTop: `${isMobile ? '1rem' : null}` }}>
        <ProposicaoConsultaTypography style={{ textTransform: "uppercase" }}>
          {props.proposicao?.descricao}&nbsp;&nbsp;
        </ProposicaoConsultaTypography>
        <ProposicaoApelidoConsultaTypography>
          {props.proposicao?.cliente.apelido ? " (" : ""}
          {props.proposicao?.cliente.apelido
            ? props?.proposicao?.cliente.apelido
            : ""}
          {props.proposicao?.cliente.apelido ? ")" : ""}
        </ProposicaoApelidoConsultaTypography>
      </Grid>
      <Grid container style={{ paddingLeft: `${isMobile ? "24px" : "50px"}`, wordBreak: "break-word" }}>
        <Typography
          style={{
            color: `${pallet.color.colorBase.black}`,
          }}
        >
          <ProposicaoConsultaSpanCustom>
            {props.proposicao?.situacao_descricao} &nbsp;&nbsp;
          </ProposicaoConsultaSpanCustom>
          {props.proposicao?.comissao_atual ?
            <>
              {props.proposicao?.situacao_descricao ?
                <span>•</span>
                :
                null}
              <ProposicaoConsultaSpanCustom style={{ marginLeft: `${props.proposicao?.situacao_descricao ? '7px' : '0px'}` }}>
                {props.proposicao?.comissao_atual} &nbsp;&nbsp;
              </ProposicaoConsultaSpanCustom>
            </>
            :
            null}

          {
            associadas.length > 0 ?
              <>
                <span>•</span> &nbsp;&nbsp;
                <ProposicaoConsultaSpanCustom>
                  Associada a{" "}
                  <a
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      fontWeight: pallet.general.weight_600,
                    }}
                    href={props.proposicao?.id_proposicao_associada ?
                      props.proposicao?.id_proposicao_apensada
                      :
                      associadas[0].id_proposicao_associada}
                  >
                    <span style={{ color: pallet.general.color1 }}>
                      {associadas[0].descricao}
                      &nbsp;- {props.proposicao.casa_nome}
                    </span>
                  </a>
                </ProposicaoConsultaSpanCustom>
              </>

              :

              props.proposicao?.id_proposicao_apensada && props.proposicao?.id_casa === props.proposicao?.proposicao_apensada_id_casa //|| apensada.length > 0

                ?

                <>
                  <span>•</span> &nbsp;&nbsp;
                  <ProposicaoConsultaSpanCustom>
                    Apensada a{" "}
                    <a
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        fontWeight: pallet.general.weight_600,
                      }}

                      href={String(props.proposicao?.id_proposicao_apensada)}
                    // href={props.proposicao?.id_proposicao_apensada ?
                    //   props.proposicao?.id_proposicao_apensada
                    //   :
                    //   apensada[0].id_proposicao_apensada}
                    >
                      <span style={{ color: pallet.general.color1 }}>
                        {props.proposicao?.proposicao_apensada_descricao}

                        {/* {props.proposicao?.proposicao_apensada_descricao ?
                          props.proposicao?.proposicao_apensada_descricao
                          :
                          apensada[0].descricao} */}
                      </span>
                    </a>
                    &nbsp;- {props.proposicao.casa_nome}
                  </ProposicaoConsultaSpanCustom>
                </>

                :

                <Grid>

                </Grid>
          }

        </Typography>
      </Grid>
    </>
  );
};
