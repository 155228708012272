import React from 'react';
import { isMobile } from 'react-device-detect';

import { Grid } from '@mui/material';

import ProposicaoCarouselTabsAcontecimentos from './ProposicaoCarouselTabsAcontecimentos';
import ProposicaoCarouselTabsResultados from './ProposicaoCarouselTabsResultados';
import ProposicaoCarouselTabsTramitacoes from './ProposicaoCarouselTabsTramitacoes';
import ProposicaoCarouselTabsTimeLine from './ProposicaoCarouselTabsTimeLine';
import ProposicaoCarouselTabsAcaoPreparatoria from './ProposicaoCarouselTabsAcaoPreparatoria';
import ProposicaoCarouselTabsAcaoDeInfluencia from './ProposicaoCarouselTabsAcaoDeInfluencia';
import ProposicaoCarouselTabsApensadas from './ProposicaoCarouselTabsApensadas';
import ProposicaoCarouselTabsCorrelatas from './ProposicaoCarouselTabsCorrelatas';
import ProposicaoCarouselTabsEmendas from './ProposicaoCarouselTabsEmendas';

import CarouselCustom from '../../../../components/carousel/CarouselCustom';

import styled from 'styled-components';

const CarouselTab = styled(CarouselCustom)`
.customTab{
    width: 500px;
    background-color: red !important;
}`;

export default function ProposicaoCarouselTabs(props: { id_proposicao: number, proposicao_integrada?: boolean, propDetail?: any, onChange?: any, permissaoUsuario?: number }) {
    const [activeTab, setActiveTab] = React.useState(0);

    const itens = [
        { index: 0, hide: false, label: "Tramitações", component: <ProposicaoCarouselTabsTramitacoes key={0} index={0} activeTab={activeTab} id_proposicao={props.id_proposicao} proposicao_integrada={props.proposicao_integrada} onChange={props?.onChange} propCasa={props?.propDetail?.id_casa} /> },
        { index: 1, hide: props.permissaoUsuario === 4, label: "Acontecimentos", component: <ProposicaoCarouselTabsAcontecimentos key={1} index={1} activeTab={activeTab} id_proposicao={props.id_proposicao} /> },
        { index: 2, hide: true, label: "Timeline", component: <ProposicaoCarouselTabsTimeLine key={2} index={2} activeTab={activeTab} id_proposicao={props.id_proposicao} /> },
        { index: 3, hide: false, label: "Ação Preparatória", component: <ProposicaoCarouselTabsAcaoPreparatoria key={3} index={3} activeTab={activeTab} id_proposicao={props.id_proposicao} onChange={props?.onChange} permissaoUsuario={props?.permissaoUsuario} /> },
        { index: 4, hide: props.permissaoUsuario === 4, label: "Ação de Influência", component: <ProposicaoCarouselTabsAcaoDeInfluencia key={4} index={4} activeTab={activeTab} id_proposicao={props.id_proposicao} propDescricao={props?.propDetail?.descricao} /> },
        { index: 5, hide: false, label: "Apensadas", component: <ProposicaoCarouselTabsApensadas key={5} index={5} activeTab={activeTab} id_proposicao={props.id_proposicao} proposicao_integrada={props.proposicao_integrada} /> },
        { index: 6, hide: props.permissaoUsuario === 4 && props.proposicao_integrada, label: "Emendas", component: <ProposicaoCarouselTabsEmendas key={6} index={6} activeTab={activeTab} id_proposicao={props.id_proposicao} proposicao_integrada={props.proposicao_integrada} onChange={props?.onChange} propCasa={props?.propDetail?.id_casa} /> },
        { index: 7, hide: props.permissaoUsuario === 4, label: "Correlatas", component: <ProposicaoCarouselTabsCorrelatas key={7} index={7} activeTab={activeTab} id_proposicao={props.id_proposicao} /> },
        { index: 8, hide: false, label: "Resultados", component: <ProposicaoCarouselTabsResultados key={8} index={8} activeTab={activeTab} id_proposicao={props.id_proposicao} /> }
    ];

    return (
        !props.id_proposicao ?
            <div></div>
            : isMobile ?
                (
                    <>
                        <Grid container style={{
                            marginTop: `${isMobile ? "0px" : "50px"} `,
                            marginBottom: `${isMobile ? "0px" : "30px"} `,
                            marginRight: "15px"
                        }
                        }
                        >
                            <CarouselTab spacing={0} itens={itens} activeTab={activeTab} onChange={setActiveTab} />
                        </Grid >
                    </>
                )
                :
                (
                    <>
                        <Grid container style={{
                            marginTop: `${isMobile ? "0px" : "50px"} `,
                            marginBottom: `${isMobile ? "0px" : "30px"} `,
                            marginRight: "15px"
                        }}
                        >
                            <CarouselTab spacing={5} itens={itens} activeTab={activeTab} onChange={setActiveTab} />
                        </Grid >
                    </>
                )
    );
};
