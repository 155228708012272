import { Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import PieChartRelatorio from '../PieChartRelatorio';
import { usePallet } from "../../../../contexts/PalletContext";
import styled from 'styled-components';
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile, isWindows } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import { fontSize } from '@mui/system';
import RelatorioService from "../../../../services/RelatoriosService";
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';


export default function RelatorioAcoesDeInfluenciaPorFulano() {
    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(true)

    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter)
    const [countExito, setCountExito] = useState<number>()
    const [countSemExito, setCountSemExito] = useState<number>()
    const [countExitoParcial, setCountExitoParcial] = useState<number>()
    const [countPendente, setCountPendente] = useState<number>()

    const [dataExito, setDataExito] = useState([])
    const [dataSemExito, setDataSemExito] = useState([])
    const [dataExitoParcial, setDataExitoParcial] = useState([])
    const [dataPendente, setDataPendente] = useState([])


    let totalGeralExito = 0;
    const[totalForaMapExito,setTotalForaMapExito] = useState(0);
    let totalGeralSemExito = 0;
    const[totalForaMapSemExito,setTotalForaMapSemExito] = useState(0);
    let totalGeralExitoParcial = 0;
    const[totalForaMapExitoParcial,setTotalForaMapNaoExitoParcial] = useState(0);
    let totalGeralPendente = 0;
    const[totalForaMapPendente,setTotalForaMapPendente] = useState(0);

    const { user } = useAuthorization();

    //let esferaTipo = filter.substring(27, 29)
    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };
    
    let esferaTipo = (filtrotitle.length>0 ? filtrotitle[0].value : "Geral")

    const Imprimir = () => {
        window.print()
    }

    React.useEffect(() => {
        setLoading(true)

        if (
            String(format(new Date(dtIni), "yyyy")).slice(0, 1) !== "0" &&
            String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {

            RelatorioService.GetProposicoesAcaoInfluenciaResultadoFulano(
                format(new Date(dtIni), "yyyy-MM-dd"),
                format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"),
                Number(user?.id),
                String(filtro)
            ).then(async res => {
                await setCountExito(Number(res?.data?.tots?.exito))
                await setCountExitoParcial(Number(res?.data?.tots?.exitoParcial))
                await setCountSemExito(Number(res?.data?.tots?.semExito))
                await setCountPendente(Number(res?.data?.tots?.pendente))

                await setDataExito(res?.data?.lists?.exito)
                await setDataExitoParcial(res?.data?.lists?.exitoParcial)
                await setDataSemExito(res?.data?.lists?.semExito)
                await setDataPendente(res?.data?.lists?.pendente)

                await setLoading(false)
            })
        }
    }, []);




    const CheckEsfera = (select) => {
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }

    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '100px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                Relatório / {esferaTipo}
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Ações de Influência criada/responsável por {user?.nome}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>
                                    {/* if countCD countSF countCN nao forem undefined */}
                                    {loading ?
                                        <CircularLoading
                                            size={100}
                                            top={isMobile ? "37%" : "40%"}
                                            left={isMobile ? "6%" : "16%"}
                                            zIndex={2}
                                        />
                                        :
                                        <Grid style={{
                                            width: '990px',
                                            paddingLeft: '5px',
                                            marginTop: '30px'
                                        }}>
                                            <PieChartRelatorio
                                                title="PERÍODO DE CRIAÇÃO"
                                                dtIni={dtIni}
                                                dtFin={dtFin}
                                                labels={[
                                                    {
                                                        key: "Êxito",
                                                        data: Number(countExito),
                                                        color: `${pallet.color.charts.green}`,
                                                    },
                                                    {
                                                        key: "Sem Êxito",
                                                        data: Number(countSemExito),
                                                        color: `${pallet.color.charts.red}`,
                                                    },
                                                    {
                                                        key: "Êxito Parcial",
                                                        data: Number(countExitoParcial),
                                                        color: `${pallet.color.charts.purple}`,
                                                    },
                                                    {
                                                        key: "Pendente",
                                                        data: Number(countPendente),
                                                        color: `${pallet.color.charts.yellow}`,
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    }

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {Number(countExito) + Number(countExitoParcial) + Number(countSemExito) + Number(countPendente)}
                        
                                </Grid>
                                <Grid style={{
                                    
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | ÊXITO
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            INTERLOCUTOR
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataExito.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: 'auto',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.acaoinfluencia_proposicao}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x?.acaoinfluencia_influenciados.map(y => {
                                                        return (
                                                            <span>
                                                                {y?.nome} ({y?.sigla} - {y?.uf}){x?.acaoinfluencia_influenciados.length > 1 ? ("; ") : null}
                                                            </span>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | SEM ÊXITO
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            INTERLOCUTOR
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataSemExito.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: 'auto',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.acaoinfluencia_proposicao}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x?.acaoinfluencia_influenciados.map(y => {
                                                        return (
                                                            <span>
                                                                {y?.nome} ({y?.sigla} - {y?.uf}){x?.acaoinfluencia_influenciados.length > 1 ? ("; ") : null}
                                                            </span>
                                                        )
                                                    })}

                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | ÊXITO PARCIAL
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            INTERLOCUTOR
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataExitoParcial.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: 'auto',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.acaoinfluencia_proposicao}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x?.acaoinfluencia_influenciados.map(y => {
                                                        return (
                                                            <span>
                                                                {y?.nome} ({y?.sigla} - {y?.uf}){x?.acaoinfluencia_influenciados.length > 1 ? ("; ") : null}
                                                            </span>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | PENDENTE
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '330px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            INTERLOCUTOR
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataPendente.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: 'auto',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.acaoinfluencia_proposicao}
                                                </Grid>
                                                <Grid style={{
                                                    width: '330px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x?.acaoinfluencia_influenciados.map(y => {
                                                        return (
                                                            <span>
                                                                {y?.nome} ({y?.sigla} - {y?.uf}){x?.acaoinfluencia_influenciados.length > 1 ? ("; ") : null}
                                                            </span>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
