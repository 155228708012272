import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { PalletProvider, usePallet } from "../../../../contexts/PalletContext";

import PaginationSaveState from "../../../../components/PaginationSaveState";
import { IListInfo } from "../../../../models/IListInfo";
import { Visibility } from "@material-ui/icons";
import useStyles from "../../../../components/layout/Styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format } from "util";
import InputDatePicker from "../../../../components/InputDatePicker";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import CircularLoading from "../../../../components/CircularLoading";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import { couldStartTrivia } from "typescript";
import ArticleIcon from "@mui/icons-material/Article";
import AccordionNotaTecnica from "./AccordionNotaTecnica";
import { INotaTecnica } from "../../../../models/Proposicao.Model";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import ButtonIcon from './../../../../components/ButtonIcon';
import { useAuthorization } from "../../../../contexts/AuthorizationContext";

export default function ProposicaoAcaoPreparatoriaEspecificaNotaTecnica(props: {
  acao: IAcaoPreparatoria;
  lista: Array<INotaTecnica>;
  listaII: Array<INotaTecnica>;
  onChange: (data: any) => void;
  reload?: () => void;
}) {
  const classes = useStyles();
  const { pallet } = usePallet();
  const navigate = useNavigate();
  const { user, getConfig } = useAuthorization();

  const openAnotherWindow = () => {
    window.open(`${props.acao.id}/nota-tecnica-colaborativa`,
      "", "width=1000, height=700");
  }


  return (
    <Grid
      container
      style={{
        paddingLeft: `${isMobile ? "10px" : "30px"}`,
        paddingRight: `${isMobile ? "10px" : "30px"}`,
        paddingTop: `${isMobile ? "10px" : "30px"}`,
        marginBottom: `${isMobile ? "-10px" : "10px"}`,
      }}
    >
      <Grid
        container
        style={{
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid
          item
          lg={8}
          style={{
            display: "flex",
            alignItems: "center",

            fontFamily: `${pallet.fontFamily.general}`,
            fontSize: "14px",
            fontWeight: pallet.general.weight_600,
            marginBottom: `${isMobile ? "5px" : "0px"}`,
          }}
        >
          <Grid
            style={{
              fontSize: pallet.general.size16,
              color: pallet.general.color2,
            }}
          >
            {props.lista
              ? props.lista?.filter((listas) => listas?.status === "Preenchido")
                ?.length ||
                props.listaII?.filter((listas) => listas?.status === "Preenchido")
                ?.length : ""}
          </Grid>{" "}
          <Grid
            style={{
              fontSize: pallet.general.size16,
              color: pallet.general.color4,
            }}
          >
            &nbsp;/&nbsp;{props.lista?.length + props.listaII?.length}
          </Grid>
          &nbsp;
          {props.lista?.filter((listas) => listas?.status === "Preenchido")
            ?.length > 1 || props.listaII?.filter((listas) => listas?.status === "Preenchido")
            ?.length > 1
            ? "NOTAS TÉCNICAS PREENCHIDAS"
            : "NOTA TÉCNICA PREENCHIDA"}
        </Grid>
        {getConfig()?.habNotaTecColab ?
        <Grid
          item
          lg={4}
          style={{
            display: 'flex'
          }}
        >
          <IconButton
            style={{
              fontFamily: `${pallet.fontFamily.general}`,
              padding: "15px",
              color: "white",
              backgroundColor: pallet.backgroundColorPrimary,
              borderRadius: "5px",
              fontSize: "0.875rem",
              marginLeft: `${isMobile ? "0px" : "auto"}`,
            }}
            onClick={() => openAnotherWindow()}
          // onClick={() => navigate(`nota-tecnica-colaborativa`)}
          >
            <ArticleIcon />
            &nbsp; <Grid>NOTA TÉCNICA COLABORATIVA</Grid>
          </IconButton>
        </Grid>
        : null }
      </Grid>
      <Grid
        style={{
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
          marginTop: "40px",
        }}
      >
        <Grid style={{ marginBottom: "30px" }}>
          {props?.lista?.map((item) => {
            return (
              <AccordionNotaTecnica
                acao={props.acao}
                item={item}
                onChange={props.onChange}
                reload={() => {
                  props?.reload();
                }}
              />
            );
          })}
          {props?.listaII?.map((item) => {
            return (
              <AccordionNotaTecnica
                acao={props.acao}
                item={item}
                onChange={props.onChange}
                reload={() => {
                  props?.reload();
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
