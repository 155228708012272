import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { usePallet } from "../../../../contexts/PalletContext";
import useStyles from "../../../../components/layout/Styles";
import { IProposicao } from "../../../../models/Proposicao.Model";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LinkIcon from "@mui/icons-material/Link";
import { formatDate } from "../../../../utils/dateUtils";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import UsuarioService from "../../../../services/UsuarioService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const ProposicaoAcaoPreparatoriaEspecificaInformacoes = (props: { acao_preparatoria: IAcaoPreparatoria}) => {
  const { pallet } = usePallet();
  const classes = useStyles();

  

  return (
    <Grid
      style={{
        paddingLeft: `${isMobile ? "20px" : "50px"}`,
        paddingRight: `${isMobile ? "20px" : "50px"}`,
        paddingTop: "35px",
        paddingBottom: "10px",
      }}
    >
      <Accordion defaultExpanded={true} elevation={0} style={{ border: "1px solid #EAEAEA" }}>
        <AccordionSummary
        
          expandIcon={
            <ArrowDropDownIcon style={{ color: `${pallet.general.color1}` }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            height: "10px",
            borderRadius: "5px",
            paddingLeft: "20px",
            paddingRight: "20px",
            flexFlow: "row-reverse",
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              color: `${pallet.textColorTertiary}`,
              fontFamily: `${pallet.general.fontFamily}`,
              fontSize: `${pallet.general.size14}`,
              fontWeight: pallet.general.weight_600,
              marginLeft: "10px",
            }}
          >
            <Grid container style={{ display: "flex" }}>
              INFORMAÇÕES
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            fontFamily: `${pallet.general.fontFamily}`}}>
               <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid
              item
              lg={12}
              xs={12}
              style={{
                fontWeight: pallet.general.weight_600,
                fontSize: pallet.general.size14,
                marginTop: "10px",
                marginLeft: "20px",
              }}
            >
              RESPONSÁVEL PELA AÇÃO PREPARATÓRIA
            </Grid>
       

                <Grid style={{ display: "flex" }} item lg={6} xs={12}>
                  <Grid style={{ marginLeft: "20px" }}>
                    <Avatar src={props.acao_preparatoria?.foto_autor} />
                  </Grid>
                  &nbsp;&nbsp;
                  <Grid container
                    style={{
                      marginTop: "8px",
                      fontSize: pallet.general.size14,
                      display:"flex"
                    }}
                  >
                    <Grid
                      style={{
                        fontWeight: pallet.general.weight_600,
                        wordBreak:"break-all"
                      }}
                    >
                      {props.acao_preparatoria?.autor}
                    </Grid><Grid style={{marginLeft:`${isMobile ? "0px" : "3px"}`}} item >({props.acao_preparatoria?.departamento})</Grid>

                  </Grid>
                </Grid>

                <Grid item
                            lg={12}
                            xs={12}
                            style={{
                                fontWeight: pallet.general.weight_600,
                                fontSize: pallet.general.size14,
                                marginTop: "10px",
                                marginLeft: "20px",
                            }}>
                    RESPONSÁVEL POR NOTA TÉCNICA
                </Grid>
                {props?.acao_preparatoria?.responsaveisNotaTec?.map((i) => {
                  return(
                            <Grid style={{ display: "flex" }} item lg={6} xs={12}>
                              <Grid style={{ marginLeft: "20px" }}>
                                <Avatar src={i?.usuario_foto} />
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid container
                                style={{
                                  marginTop: "8px",
                                  fontSize: pallet.general.size14,
                                  display:"flex"
                                }}
                              >
                                <Grid item 
                                  style={{
                                    fontWeight: pallet.general.weight_600,
                                    wordBreak:"break-all"
                                  }}
                                >
                                 { i.usuario_nome}
                                </Grid ><Grid style={{marginLeft:`${isMobile ? "0px" : "3px"}`}} item >({i.nota_tecnica_departamento})</Grid>

                              </Grid>
                              </Grid>
                  );
                })}
                                {props?.acao_preparatoria?.responsaveisNotaTecDept?.map((i) => {
                  return(
                            <Grid style={{ display: "flex" }} item lg={6} xs={12}>
                              <Grid style={{ marginLeft: "20px" }}>
                                <Avatar src={null} />
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid container
                                style={{
                                  marginTop: "8px",
                                  fontSize: pallet.general.size14,
                                  display:"flex"
                                }}
                              >
                                <Grid item 
                                  style={{
                                    fontWeight: pallet.general.weight_600,
                                    wordBreak:"break-all"
                                  }}
                                >
                                 { i.descricao}
                                </Grid ><Grid style={{marginLeft:`${isMobile ? "0px" : "3px"}`}} item >({i.descricao})</Grid>

                              </Grid>
                              </Grid>
                  );
                })}
                <Grid item
                            lg={12}
                            xs={12}
                            style={{
                                fontWeight: pallet.general.weight_600,
                                fontSize: pallet.general.size14,
                                marginTop: "10px",
                                marginLeft: "20px",
                            }}>
                OBJETIVO

                </Grid>
                <Grid item lg={12} xs={12}
                            style={{
                                fontSize: pallet.general.size14,
                                marginLeft: "20px",
                                wordBreak:"break-word",
                            }}>
                {props.acao_preparatoria?.objetivo}
                </Grid>

 

          </Grid>

        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};


export default ProposicaoAcaoPreparatoriaEspecificaInformacoes;
