import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import SelectTextField from "../../../components/SelectTextField";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import { IProposicao } from "../../../models/Proposicao.Model";
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import { IFilterData } from "../../../components/filter/ListFilter";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";

export function AccordionContentKey12(props: {
  proposicao?: IProposicao;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string
  onChange?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const [tempoMedio, setTempoMedio] = React.useState<number>(0);
  const [casa, setCasa] = React.useState<string>("");
  const [tema, setTema] = React.useState<string>(undefined);

  const [casaItens, setCasaFederalItens] = React.useState<Array<IFilterData>>([
    { key: "0", label: "Selecione ", value: "" },
    { key: "1", label: "Senado Federal", value: "SF" },
    { key: "2", label: "Câmara dos Deputados", value: "CD" },
    // { key: "3", label: "Congresso Nacional", value: "CN" },
  ]);

  const [temaItens, setTemaItens] = React.useState([]);

  const getItens = (
    data: { id: number; codigo: string; descricao: string }[]
  ): { value: string; label: string }[] => {
    const ret = data?.map((item) => {
      return {
        value: item.codigo,
        label: item.descricao,
      };
    });
    return ret;
  };

  React.useEffect(() => {

    TabelasService.getProposicaoTemasLegislativos(handleCasaN(casa)).then((res) => {

      const data = getItens(res);
      setTemaItens(data);
    });
  }, [casa]);


  const handleCasa = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: number
  ) => {
    setCasa(event.target.value);
    handleCasaLabel(casa);
  };

  const handleTema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: number
  ) => {
    setTema(event.target.value);
    handleCasaLabel(casa);
  };

  const handleCasaLabel = (casa: string) => {
    switch (casa) {
      case "SF":
        return "Senado Federal";
      case "CD":
        return "Câmara dos Deputados";
      case "CN":
        return "Congresso Nacional";
      default:
        return "Todas";
    }
  };

  const handleCasaN = (casa: string) => {
    switch (casa) {
      case "SF":
        return 1;
      case "CD":
        return 2;
      case "CN":
        return 3;
      default:
        return -99;
    }
  };

  React.useEffect(() => {
    if (tema) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey12(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), casa, Number(tema),
        ).then((res) => {
          if (res) {
            setTempoMedio(res.data.total)

          }
        });
      }
    }

  }, [casa, tema, props.dtIni, props.dtFin]);

  return (
    <Grid>
      <Grid
        container
        style={{
          padding: "40px 0px 10px 0px",
          justifyContent: "space-around",
        }}
      >
        <Grid item md={5} xs={12} lg={5} style={{ padding: `${isMobile ? "0px 30px 20px 30px" : "0px"}` }}>
          <SelectTextFieldA
            name={"casa-federal"}
            value={casa}
            onChange={handleCasa}
            options={casaItens}
            label={"CASA LEGISLATIVA"}
            id={"casa-federal"}
            focused={true}
            placeholder={"Selecione"}
          />
        </Grid>
        {casa ?
          <Grid item md={5} xs={12} lg={5} style={{ padding: `${isMobile ? "0px 30px 0px 30px" : "0px"}` }}>
            <SelectTextFieldA
              name={"tema"}
              value={tema}
              onChange={handleTema}
              options={temaItens}
              label={"TEMA"}
              id={"tema"}
              focused={true}
              placeholder={"Selecione"}
            />
          </Grid> : null}
      </Grid>
      <Grid
        container
        xs={12}
        style={{ padding: "20px 30px 20px 30px" }}
      >
        <Grid
          container
          item
          direction={"row"}
          style={{
            // marginRight: "30px",
            // marginLeft: "30px",
            padding: "20px",
            color: `${pallet.textColorTertiary}`,
            fontFamily: `${pallet.general.fontFamily}`,
            fontSize: `${pallet.general.size14}`,
            placeContent: "center",
            backgroundColor: "#F9F5FE",
            borderRadius: "5px"
          }}
        >
          <Grid style={{ fontWeight: pallet.general.weight_600 }}>
            TEMPO MÉDIO DE TRAMITAÇÃO DAS PROPOSIÇÕES
          </Grid>&nbsp;&nbsp;
          <Grid>{tempoMedio} dias</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccordionContentKey12;
