export interface IProposicao {
  id: number,
  comissao: string,
  id_casa: number,
  sigla_casa: string,
  casa_nome: string,
  casa_id_estado: number,
  descricao: string,
  tipo: string,
  tipo_descricao: string,
  ementa: string,
  texto_ementa: string,
  numero: string,
  ano: string,
  tramitacao: string,
  situacao: string,
  situacao_descricao: string,
  status_despacho: string,
  uri: string,
  uri_teor: string,
  link_externo: string,
  orgao_sigla: string,
  orgao_nome: string,
  proposicao_regime: string,
  regime: string,
  id_proposicao_apensada: number,
  proposicao_apensada_descricao: number,
  data_apresentacao: Date,
  data_atualizacao: Date,
  proposicao_tipo: string,
  comissao_atual: string,
  integrada: boolean,
  id_proposicao_associada: number,
  proposicao_tipo_relacao: number,
  proposicao_apensada_id_casa: number,
  despacho_atual: string;
  orgao_despacho_atual: string;
  cliente: {
    seguindo: boolean,
    apelido: string,
    notas: string,
    prioridade: string,
    prioridade_descricao: string,
    posicionamento: string,
    posicionamento_descricao: string,
    impacto_financeiro: number,
    valor_impacto_financeiro: number,
    analise_detalhada: string,
    tema_corporativo: {
      subtema_id: number,
      subtema_descricao: string,
      tema_id: number,
      prioridade: number,
    },
  },
  autores: [{
    id: number,
    id_parlamentar: number,
    nome: string,
    tratamento: string,
    sigla_partido: string,
    uf_exercicio: string,
    url_foto: string,
    qualificacao: string,
  }],
  autor_parcial: [{
    nome: string
  }],
  temas_legislativo: [{
    codigo: number,
    descricao: string,
  }],
  apensada: [{
    id_proposicao_relacionada: number,
    tipo_relacao: string,
    tipo: string,
  }]
}



export interface IAcaoPreparatoria {
  id: number,
  status: string,
  proposicao: string,
  prazo: Date,
  id_autor: number,
  id_responsavel: number,
  autor: string,
  foto_autor: string,
  id_proposicao: number,
  inteiro_teor?: string,
  notas: [{
    nota_tecnica_status: number,
    nota_tecnica_id_acao_preparatoria: number,
    nota_tecnica_id: number,
    nota_tecnica_id_responsavel: number,
  }],
  notaTecnica: {
    listInfo: {
      pageIndex: number,
      pageItens: number,
      pageSize: number,
      totalItens: number,
      totalPages: number,
    },
    lista: Array<INotaTecnica>,
    listaII: Array<INotaTecnica>,
    sucess: boolean
  }
  responsaveis_esforco: [{
    id: number
  }]

  objetivo: string,
  departamento: string,
  id_departamento: number,

  responsaveisNotaTec: [
    {
      id: number,
      nota_tecnica_id_responsavel: number,
      usuario_nome: string,
      usuario_foto: string,
      nota_tecnica_departamento: string,
      nota_tecnica_status: string

    }
  ],
  responsaveisNotaTecDept: [
    {
      nota_tecnica_id: number,
      descricao: string,

    }
  ]

}

export interface INotaTecnica {
  id_acao_preparatoria: number,
  id: number,
  departamento: string,
  posicionamento: string,
  prioridade: string,
  tipo_impacto: string,
  valor_impacto: string,
  justificativa: string,
  aprovado: number,
  status: string,
  recebido_em: Date,
  responsavel: string,
  id_responsavel: number,
  texto_ia: string,
  descricao: string,
};

export const proposicaoPosicionamento = [
  {
    value: 'contrário',
    label: 'Contrário',
  },
  {
    value: 'contrário com emenda saneadora',
    label: 'Contrário com Emenda Saneadora',
  },
  {
    value: 'neutro',
    label: 'Neutro',
  },
  {
    value: 'favorável',
    label: 'Favorável',
  },
  {
    value: 'favorável com emenda saneadora',
    label: 'Favorável com Emenda Saneadora',
  },
  {
    value: 'favorável ao parecer do relator',
    label: 'Favorável ao parecer do relator',
  },
  {
    value: 'favorável ao parecer do relator com emenda',
    label: 'Favorável ao parecer do relator com emenda',
  },
  {
    value: 'contrário ao parecer do relator',
    label: 'Contrário ao parecer do relator',
  },
  {
    value: 'contrário ao parecer do relator com emenda',
    label: 'Contrário ao parecer do relator com emenda',
  },
  {
    value: 'pendente',
    label: 'Pendente',
  },
];
export interface IComentario {

  id: Number,
  id_comentario: Number,
  id_referencia: string,
  modulo: string,
  contexto: string,
  mencionados: string,
  feito_em: Date,
  id_usuario: Number,
  autor: string,
  foto_autor: string,
  responsaveis_esforco: any[],
  comentario: string,
  respostas: number
}

export enum EComentarioModulos {
  AcaoPreparatoria = 'acao-preparatoria',
  NotaTecnicaColaborativa = 'nota-tecnica-colaborativa'
}

export interface IDashboardProposicao {
  universo: {
    inativas: number,
    ativas: number,
  },
  acoesInfluencia: {
    pendente: number,
    exito: number,
    semExito: number,
    exitoParcial: number
  },
  momentosCriticos: {
    designacaoRelator: number
    aguardanoParecerDoRelator: number
    prazoParaEmenda: number
    aguardaDeliberacao: number
  },

}