import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, Divider, ListItemText, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { usePallet } from "../../../contexts/PalletContext";
import InputDatePicker from '../../../components/InputDatePicker';
import InputMultilineTextField from '../../../components/InputMultilineTextField';
import AnexosComponent, { EAnexoModulos } from '../../../components/Anexos';
import SolidButton from '../../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import ProposicaoAcontecimentosService from '../../../services/ProposicaoAcontecimentosService';
import { useAlert } from '../../../contexts/AlertContext';
import RoomIcon from '@mui/icons-material/Room';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputTextField from '../../../components/InputTextField';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { formatDate } from '../../../utils/dateUtils';
import { format } from 'date-fns';
import ButtonIconSalvar from '../../../components/ButtonIconSalvar';
import ButtonDialogConfirmation from '../../../components/ButtonDialogConfirmation';
import * as Yup from "yup";
import CitacoesService from '../../../services/CitacoesService';
import { useAuthorization } from '../../../contexts/AuthorizationContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs(props: {
    idCitacao: number
    reloadList?: () => void
    closeMenu?: () => void
}) {
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();
    const [dateAcontecimentos, setDateAcontecimentos] = React.useState();
    const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] = React.useState();
    const [descricao, setDescricao] = React.useState("");
    const [onSave, setOnSave] = React.useState(false)
    const [idRef, setIdRef] = React.useState()
    const [idAgRef, setIdAgRef] = React.useState<string>()
    const [data, setData] = React.useState({});
    const [horas, setHoras] = React.useState<string>();
    const [local, setLocal] = React.useState<string>();
    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);
    const [discursos, setDiscursos] = React.useState<boolean>(false);
    const [proposicoes, setProposicoes] = React.useState<boolean>(false);
    const [dou, setDou] = React.useState<boolean>(false);
    const [twitter, setTwitter] = React.useState<boolean>(false);

    

    const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
        setDataAcontecimentosAgenda(dataAcEve)

    };

    const handleDiscursosChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const value = !!event.target.checked;
        setDiscursos(value);
      };
    
      const handleProposicoesChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const value = !!event.target.checked;
        setProposicoes(value);
      };
    
      const handleDouChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const value = !!event.target.checked;
        setDou(value);
      };

      const handleTwitterChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const value = !!event.target.checked;
        setTwitter(value);
      };

    const KeywordSchema = Yup.object({
        descricao: Yup.string().required({ name: "keyword", err: "Digite a palavra-chave" }).min(2),
    });

    const handleClickOpen = async () => {
      await CitacoesService.getCitacoesUnico(props.idCitacao).then(res => {  

        setDescricao(res.descricao)
        setProposicoes(res.proposicoes)
        setDiscursos(res.discursos)
        setDou(res.dou)
        setTwitter(res.twitter)
        

        //setHoras(res?.data[0].acontecimentos_meetingTime ? formatDate(addingtHours(new Date(res?.data[0]?.acontecimentos_meetingTime), 3),"HH:mm") : "")

    });

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };


    const handleChangePalavra = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    }

    const postCitacao = async (e) => {
        e.preventDefault();
        const data = {
            descricao: descricao.trim(),
            discursos: discursos,
            proposicoes: proposicoes,
            dou: dou,
            twitter: twitter,
        }
        setError(() => [])
        data && KeywordSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));
        setData(data)
        KeywordSchema.validate(data).catch(function (err) {
        });
        if (KeywordSchema.isValidSync(data)) {
            if(discursos === false && proposicoes === false && dou === false && twitter === false) {
                NewAlert(
                    "error",
                    "Favor selecionar ao menos umas das opções."
                );

        }
        else{

            await CitacoesService.UpdateGeral(data,props.idCitacao).then((res) => {
                let idCitacao = res;
                if (res) {
                    NewAlert("success", "Palavra-Chave alterada com sucesso.");
                    setDescricao('')
                    handleClose()
                } else {
                    NewAlert(
                        "error",
                        "Erro ao cadastrar, verifique os campos e salve novamente."
                    );
                }
            })


            
        }
        }
        props?.reloadList()
    }


    async function handleOnAfterUpload() {
        if (idRef) {
            setIdRef(undefined);
            setOnSave(false)
        }
    }
    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red', fontSize: '11px', marginTop: "5px", }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const { user, getConfig } = useAuthorization();

    const erroAlteracaoPerfil = () => {
      NewAlert(
          "error",
          "Não é possível realizar está ação com este perfil."
      );
  }


    return (
        <div>

            <Button onClick={() => {user.id_permissao !== 3 ?

                                        handleClickOpen()

                                        :

                                        NewAlert(
                                            "error",
                                            "Não é possível realizar está ação com este perfil."
                                        )
                                        
                                    }}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight:"85px",
                    marginRight: "-10px"
                }}>
                <EditIcon  style={{
                            color: `${pallet.color.tertiary.font}`,
                            marginRight: "16px",
                            fontFamily: `${pallet.fontFamily.general}`,
                        }} />
                <ListItemText>@</ListItemText>
            Editar
            </Button>


            <BootstrapDialog
            id={`dialog-${props?.idCitacao}`}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid style={{
                        fontFamily: `${pallet.fontFamily}`,
                        fontSize: `${pallet.general.size18}`,
                        color: `${pallet.textColorSecondary}`,
                        padding: "12px",
                        marginTop: "7px",
                    }}>
                        EDITAR PALAVRA-CHAVE
                    </Grid>
                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px" }} >


                    <Grid container style={{ fontFamily: pallet.fontFamily.general, padding: "25px 20px 20px 40px" }}>

                        Digite a palavra-chave:
                        <Grid style={{ marginTop: "20px" }} item lg={12} xs={12}>
                            <InputTextField
                                type={"text"}
                                value={descricao}
                                name="local"
                                onChange={handleChangePalavra}
                                id={"palavra-chave"}
                                label={"PALAVRA-CHAVE"}
                            />
                        </Grid>
                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                            <ErrorMessage name='keyword' errors={error} />
                        </Grid>


                    </Grid>
                    
                    
            <Grid
              style={{ marginTop: "5px", padding: "10px" }}
              item
              lg={12}
              xs={12}
            >
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={discursos}
                              value={discursos}
                              onChange={handleDiscursosChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Discursos"
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={proposicoes}
                              value={proposicoes}
                              onChange={handleProposicoesChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Proposições"
                        />
                      </FormGroup>
                      {getConfig()?.habDou ?
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={dou}
                              value={dou}
                              onChange={handleDouChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Diário Oficial da União - DOU"
                        />
                      </FormGroup>
                      : true}
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={twitter}
                              value={twitter}
                              onChange={handleTwitterChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="X (antigo Twitter)"
                        />
                      </FormGroup>

            </Grid>

                </DialogContent>
                <DialogActions style={{
                    padding: "30px",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>
                    <ButtonDialogConfirmation
                        styles={{
                            borderRadius: "6px 6px 0px 0px",
                            marginRight: "20px",
                            textTransform: "capitalize",
                            color: `${pallet.general.color1}`,
                        }}
                        title={"CONFIRMAÇÃO"}
                        title_button={"Cancelar"}
                        content={"CANCELAR CADASTRO?"}
                        message={"Esta ação não poderá ser desfeita."}
                        confirmation_button={"Cancelar"}
                        confirmationEvent={() => {
                            handleClose()
                        }}
                    />
                    <SolidButton
                        onClick={postCitacao}
                        onChange={props?.reloadList}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                    />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}