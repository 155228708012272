import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import moment from "moment";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import { IProposicao } from "../../../../models/Proposicao.Model";
import { formatDate } from "../../../../utils/dateUtils";

import ProposicaoConsultaInformacoesModalEspecifica from "./ProposicaoConsultaInformacoesModalEspecifica";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";
import ProposicaoService from '../../../../services/ProposicaoService';

import ComJabuti from "../../../../img/michaelangelo_jabuti_vermelho.png";
import SemJabuti from "../../../../img/michaelangelo_sem_jabuti.png";
import maqui_head from '../../../../img/maqui_head.png';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularLoading from '../../../../components/CircularLoading'

export const ProposicaoConsultaInformacoes = (props: {
    proposicao: IProposicao;
    ia: any;
    statusProcessamentoIa?: boolean;
    getOpenIa?: () => void;
}) => {
    const { getConfig } = useAuthorization();

    const { pallet } = usePallet();
    const classes = useStyles();
    const [sumarioResult, setSumarioResult] = useState(props.ia?.response?.sumario || '');
    const maxCharacters = 400;
    const [dots, setDots] = useState('');
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prev => (prev.length < 3 ? prev + '.' : ''));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (props.ia?.response?.sumario) {
            setSumarioResult(props.ia.response.sumario);
        }
    }, [props.ia?.response?.sumario]);

    useEffect(() => {
        if (props?.statusProcessamentoIa === false) {
            props.getOpenIa();
        }
    }, [props?.statusProcessamentoIa]);

    const handleOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = (e) => {
        // e && e.stopPropagation();
        // setOpen(false);
        if (e) {
            e.stopPropagation();
            e.preventDefault?.();
        }
        setOpen(false);
        (document.activeElement as HTMLElement)?.blur();
    };

    const handleExpandClick = (e) => {
        // Evita que o clique propague e feche o modal
        e.stopPropagation();
        setExpanded(!expanded);
    };

    const description = props.ia?.response.descricao_jabuti;
    let limitedDescription

    if (description) {
        limitedDescription = description.length > maxCharacters && !expanded
            ? description.substring(0, maxCharacters)
            : description;
    };

    const Estados = (value: number) => {
        switch (value) {
            case 5:
                return 'Acre';
            case 6:
                return 'Alagoas';
            case 7:
                return 'Amapá';
            case 8:
                return 'Amazonas';
            case 9:
                return 'Bahia';
            case 10:
                return 'Ceará';
            case 11:
                return 'Distrito Federal';
            case 12:
                return 'Espírito Santo';
            case 13:
                return 'Goiás';
            case 14:
                return 'Maranhão';
            case 15:
                return 'Mato Grosso';
            case 16:
                return 'Mato Grosso do Sul';
            case 17:
                return 'Minas Gerais';
            case 18:
                return 'Pará';
            case 19:
                return 'Paraíba';
            case 20:
                return 'Paraná';
            case 21:
                return 'Pernambuco';
            case 22:
                return 'Piauí';
            case 23:
                return 'Rio de Janeiro';
            case 24:
                return 'Rio Grande do Norte';
            case 25:
                return 'Rio Grande do Sul';
            case 26:
                return 'Rondônia';
            case 27:
                return 'Roraima';
            case 28:
                return 'Santa Catarina';
            case 29:
                return 'São Paulo';
            case 30:
                return 'Sergipe';
            case 31:
                return 'Tocantins'
            default:
                return "";
        }
    };

    // console.log(props.ia?.response?.sumario)
    // console.log(props.ia?.response && props.ia?.response?.sumario !== '' && !props?.statusProcessamentoIa)
    // console.log(props?.statusProcessamentoIa)


    return (
        <Grid
            style={{
                paddingLeft: `${isMobile ? "10px" : "50px"}`,
                paddingRight: `${isMobile ? "10px" : "50px"}`,
                paddingTop: "10px",
                paddingBottom: "10px",
            }}
        >
            <Accordion
                defaultExpanded={true}
                elevation={0}
                style={{ border: "1px solid #EAEAEA" }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: pallet.backGroundPageColorPrimary,
                        height: "10px",
                        borderRadius: "5px",
                        paddingLeft: isMobile ? "5px" : "20px",
                        paddingRight: isMobile ? "5px" : "20px",
                        flexFlow: "row-reverse",
                    }}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "100%" }}
                    >
                        <Grid item>
                            <Typography
                                className={classes.heading}
                                style={{
                                    color: `${pallet.textColorTertiary}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontSize: `${pallet.general.size14}`,
                                    fontWeight: pallet.general.weight_600,
                                    marginLeft: "10px",
                                }}
                            >
                                INFORMAÇÕES
                            </Typography>
                        </Grid>
                        {getConfig()?.habIa
                            ?
                            <>
                                {props?.statusProcessamentoIa
                                    ?
                                    <Grid
                                        style={{
                                            borderRadius: '0px 8px 8px 8px',
                                            padding: '20px',
                                            margin: '0px 10px 10px 0px'
                                        }}
                                    >
                                        <Grid item style={{ fontSize: '12px', fontWeight: 900, fontStyle: 'italic' }}>
                                            <Grid>
                                                IA em processamento{dots}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item>
                                            {props.ia?.response?.isJabuti === undefined
                                                ?
                                                null
                                                :
                                                <Grid item onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpen(e);
                                                }} style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={props.ia?.response?.isJabuti ? ComJabuti : SemJabuti}
                                                        alt="icone"
                                                        style={{ width: '40px', height: '40px', marginLeft: 'auto' }}
                                                    />
                                                </Grid>
                                            }
                                            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                                                <Grid onClick={(e) => e.stopPropagation()}>
                                                    <DialogTitle>
                                                        {props.ia?.response?.isJabuti ? "JABUTI IDENTIFICADO!" : "JABUTI NÃO IDENTIFICADO."}
                                                        <IconButton
                                                            aria-label="fechar"
                                                            onClick={handleClose}
                                                            style={{ position: 'absolute', right: 8, top: 8 }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent dividers style={{whiteSpace: "pre-wrap"}}>
                                                        {description}
                                                        <Grid
                                                            container
                                                            style={{
                                                                fontSize: pallet.general.size14,
                                                                marginTop: '2px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Grid item>
                                                                <LinkIcon />
                                                            </Grid>
                                                            <Grid item style={{ marginLeft: 16 }}>
                                                                {props.ia?.response && props.ia?.response?.id_proposicao_principal ? (
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            textDecoration: 'none',
                                                                            fontWeight: pallet.general.weight_600,
                                                                        }}
                                                                        href={`/proposicao/${props.ia?.response?.id_proposicao_principal}`}
                                                                    >
                                                                        PROPOSIÇÃO PRINCIPAL
                                                                    </a>
                                                                ) : (
                                                                    "PROPOSTA NÃO INFORMADA"
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Typography variant="caption" style={{ display: 'block', marginTop: '8px', fontSize: '0.6rem' }}>
                                                            {props.ia?.response.jabuti_criado_em ? (
                                                                <>Data de processamento: {moment(props.ia?.response.jabuti_criado_em).format("DD/MM/yyyy HH:mm")}</>
                                                            ) : (
                                                                <>Não processado.</>
                                                            )}
                                                        </Typography>
                                                    </DialogContent>
                                                </Grid>
                                            </Dialog>
                                        </Grid>
                                    </>
                                }
                            </>
                            :
                            null
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        fontFamily: `${pallet.general.fontFamily}`,
                    }}
                >
                    <Grid container style={{ width: "100%" }}>
                        <Grid
                            item
                            lg={12}
                            xs={12}
                            style={{
                                fontWeight: pallet.general.weight_600,
                                fontSize: pallet.general.size14,
                                marginTop: "10px",
                                marginLeft: "20px",
                            }}
                        >
                            DADOS LEGISLATIVOS
                        </Grid>

                        {getConfig()?.habIa
                            ?
                            <>

                                {/* {sumarioResult && sumarioResult !== '' ? null :
                                            <Grid
                                                style={{
                                                    fontSize: pallet.general.size12,
                                                    margin: "20px 20px 0px 0px",
                                                    paddingLeft: "30px",
                                                    background: 'linear-gradient(135deg, #3B0E6A, #6a0dad)',
                                                    color: '#fff',
                                                    padding: '8px',
                                                    borderRadius: '8px 8px 0px 0px',
                                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                                    transition: 'transform 0.2s',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    maxWidth: '400px',
                                                    cursor: 'pointer'

                                                }}
                                                onClick={() => {
                                                    if (sumarioResult === '') loadOpenaiProposicaoSumario()
                                                }}
                                            >
                                                <img
                                                    src={maqui_head}
                                                    alt="Descrição do GIF"
                                                    style={{
                                                        width: '40px',
                                                        height: '30px',
                                                        marginRight: '15px',
                                                    }}
                                                />
                                                <div>
                                                    <Grid
                                                        style={{
                                                            fontWeight: pallet.general.weight_100,
                                                            fontSize: '14px',
                                                            fontFamily: 'Montserrat',
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        Clique para o Legis sumarizar o conteúdo!
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        } */}


                                <Grid style={{
                                    // border: sumarioResult && sumarioResult !== '' ? null : '1px solid #3B0E6A',
                                    borderRadius: `0px 8px 8px 8px`,
                                    width: '100%',
                                    padding: '20px',
                                    margin: '0px 10px 10px 0px'
                                }}>
                                    <Grid
                                        item
                                        lg={12}
                                        style={{
                                            fontWeight: pallet.general.weight_600,
                                            fontSize: pallet.general.size12,
                                        }}
                                    >
                                        EMENTA
                                    </Grid>

                                    <Grid
                                        item
                                        style={{
                                            fontWeight: pallet.general.weight_600,
                                            fontSize: pallet.general.size12,
                                            marginTop: '5px'
                                        }}
                                    >
                                        <Grid>
                                            <ProposicaoConsultaInformacoesModalEspecifica
                                                ementa={props.proposicao?.ementa}
                                                proposicao_id={props.proposicao?.id}
                                                proposicao_descricao={props.proposicao?.descricao}
                                                texto_ementa={props.proposicao?.texto_ementa}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {props?.statusProcessamentoIa
                                    ?
                                    <>
                                        <Grid
                                            style={{
                                                fontSize: pallet.general.size12,
                                                paddingLeft: "30px",
                                                background: 'linear-gradient(135deg, #777475, #a09d9e)',
                                                color: '#fff',
                                                padding: '8px',
                                                borderRadius: '8px 8px 0px 0px',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                                transition: 'transform 0.2s',
                                                display: 'flex',
                                                alignItems: 'center',
                                                maxWidth: '400px',
                                            }}
                                        >
                                            <img
                                                src={maqui_head}
                                                alt="Descrição do GIF"
                                                style={{
                                                    width: '40px',
                                                    height: '30px',
                                                    marginRight: '15px',
                                                }}
                                            />
                                            <div>
                                                <Grid
                                                    style={{
                                                        fontWeight: pallet.general.weight_100,
                                                        fontSize: '14px',
                                                        fontFamily: 'Montserrat',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    Conteúdo Sumarizado! By Legis
                                                </Grid>
                                            </div>
                                        </Grid>

                                        <Grid
                                            style={{
                                                border: '1px solid #777475',
                                                borderRadius: '0px 8px 8px 8px',
                                                width: '100%',
                                                padding: '20px',
                                                margin: '0px 10px 10px 0px'
                                            }}
                                        >
                                            <Grid item style={{ fontSize: '12px', fontWeight: 900, fontStyle: 'italic' }}>
                                                <Grid>
                                                    IA em processamento{dots}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    null
                                }

                                {props?.statusProcessamentoIa === false && sumarioResult
                                    ?
                                    <>
                                        <Grid
                                            style={{
                                                fontSize: pallet.general.size12,
                                                // margin: "20px 20px 0px 0px",
                                                paddingLeft: "30px",
                                                background: 'linear-gradient(135deg, #777475, #a09d9e)',
                                                color: '#fff',
                                                padding: '8px',
                                                borderRadius: '8px 8px 0px 0px',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                                transition: 'transform 0.2s',
                                                display: 'flex',
                                                alignItems: 'center',
                                                maxWidth: '400px',

                                            }}
                                        >
                                            <img
                                                src={maqui_head}
                                                alt="Descrição do GIF"
                                                style={{
                                                    width: '40px',
                                                    height: '30px',
                                                    marginRight: '15px',
                                                }}
                                            />
                                            <div>
                                                <Grid
                                                    style={{
                                                        fontWeight: pallet.general.weight_100,
                                                        fontSize: '14px',
                                                        fontFamily: 'Montserrat',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    Conteúdo Sumarizado! By Legis
                                                </Grid>
                                            </div>
                                        </Grid>

                                        <Grid style={{
                                            border: '1px solid #777475',
                                            borderRadius: `0px 8px 8px 8px`,
                                            width: '100%',
                                            padding: '20px',
                                            margin: '0px 10px 10px 0px'
                                        }}>
                                            <Grid
                                                item
                                                style={{
                                                    fontSize: pallet.general.size12
                                                }}
                                            >
                                                <Grid>
                                                    {sumarioResult}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>
                                    :
                                    null
                                }

                            </>
                            :
                            <>
                                <Grid
                                    item
                                    lg={12}
                                    style={{
                                        fontWeight: pallet.general.weight_600,
                                        fontSize: pallet.general.size12,
                                        marginLeft: "25px",
                                        marginTop: "10px",
                                    }}
                                >
                                    EMENTA
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    xs={12}
                                    style={{
                                        fontWeight: pallet.general.weight_600,
                                        fontSize: pallet.general.size12,
                                        display: "flex",
                                        verticalAlign: "center",
                                        marginBottom: "14px",
                                        marginTop: '-3px'
                                        , marginLeft: '25px'
                                    }}
                                >
                                    {/* {props.proposicao.texto_ementa && (
                                <> */}
                                    <Grid style={{ paddingTop: "7px" }}>
                                        <ProposicaoConsultaInformacoesModalEspecifica
                                            ementa={props.proposicao?.ementa}
                                            proposicao_id={props.proposicao?.id}
                                            proposicao_descricao={props.proposicao?.descricao}
                                            texto_ementa={props.proposicao?.texto_ementa}
                                        />
                                    </Grid>
                                    {/* </>
                            )} */}
                                </Grid>
                            </>
                        }

                        <Grid container>
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    CASA LEGISLATIVA
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.casa_nome}
                                </Grid>
                            </Grid>

                            {props.proposicao?.integrada === false && props.proposicao?.id_casa >= 32
                                ?

                                <Grid
                                    item
                                    lg={3}
                                    style={{
                                        fontSize: pallet.general.size12,
                                        marginTop: "10px",
                                        paddingLeft: "30px",
                                    }}
                                >
                                    <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                        ESTADO
                                    </Grid>
                                    <Grid
                                        style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                    >
                                        {Estados(props.proposicao?.casa_id_estado)}
                                    </Grid>
                                </Grid>

                                :

                                <Grid></Grid>
                            }
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    DATA DE APRESENTAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.data_apresentacao
                                        ? moment(props.proposicao?.data_apresentacao).format("DD/MM/yyyy")


                                        : ""}
                                </Grid>
                            </Grid>
                            {/* <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    TRAMITAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.tramitacao ? (
                                        props.proposicao?.tramitacao
                                    ) : (
                                        <div style={{ color: pallet.general.color4 }}>
                                            Sem dados no momento
                                        </div>
                                    )}
                                </Grid>
                            </Grid> */}
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    SITUAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.situacao_descricao
                                        ? props.proposicao?.situacao_descricao
                                        // .map((tema) => {
                                        //     return tema.descricao;
                                        // })
                                        // .join(", ")
                                        : "Não classificado"}
                                </Grid>
                            </Grid>

                            {/* <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    TEMA LEGISLATIVO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.temas_legislativo.length
                                        ? props.proposicao?.temas_legislativo
                                            .map((tema) => {
                                                return tema.descricao;
                                            })
                                            .join(", ")
                                        : "Não classificado"}
                                </Grid>
                            </Grid> */}
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid
                                    style={{
                                        fontWeight: pallet.general.weight_600,
                                    }}
                                >
                                    ULTIMA ATUALIZAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.data_atualizacao
                                        ? formatDate(
                                            new Date(props.proposicao?.data_atualizacao),
                                            "dd/MM/yyyy"
                                        )
                                        : ""}
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                lg={12}
                                xl={12}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "25px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid
                                    style={{
                                        fontWeight: pallet.general.weight_600,
                                    }}
                                >
                                    DESPACHO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.despacho_atual
                                        ? props.proposicao?.despacho_atual
                                        : props.proposicao?.status_despacho
                                            ? props.proposicao?.status_despacho
                                            : "-"}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "25px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    REGIME
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.proposicao_regime
                                        ? props.proposicao?.proposicao_regime
                                        // .map((tema) => {
                                        //     return tema.descricao;
                                        // })
                                        // .join(", ")
                                        : "Não classificado"}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid
                                item
                                lg={12}
                                style={{
                                    fontWeight: pallet.general.weight_600,
                                    fontSize: pallet.general.size12,
                                    marginLeft: "20px",
                                    marginTop: "25px",
                                }}
                            >
                                LINKS EXTERNOS
                            </Grid>
                            <Grid
                                lg={12}
                                style={{
                                    fontSize: pallet.general.size14,
                                    marginLeft: "20px",
                                    marginTop: "2px",
                                    display: "flex",
                                }}
                            >
                                <Grid style={{ paddingTop: "4px" }}>
                                    <LinkIcon />
                                </Grid>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <Grid style={{ paddingTop: "7px" }}>
                                    {props?.proposicao?.link_externo || props?.proposicao?.uri ? (
                                        <a
                                            target="_blank"
                                            style={{
                                                textDecoration: "none",
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                            href={props?.proposicao?.link_externo || props?.proposicao?.uri}
                                        >
                                            Proposta
                                        </a>
                                    ) : (
                                        "Proposta não informada"
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                lg={12}
                                style={{
                                    fontSize: pallet.general.size14,
                                    marginLeft: "20px",
                                    marginTop: "2px",
                                    display: "flex",
                                }}
                            >
                                <Grid style={{ paddingTop: "4px" }}>
                                    <LinkIcon />
                                </Grid>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <Grid style={{ paddingTop: "7px" }}>
                                    {props?.proposicao?.uri_teor ? (
                                        <a
                                            target="_blank"
                                            style={{
                                                textDecoration: "none",
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                            href={props?.proposicao?.uri_teor.replace(
                                                "http:",
                                                "https:"
                                            )}
                                        >
                                            Texto Base
                                        </a>
                                    ) : (
                                        "Texto Base não informado"
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
