import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { usePallet } from '../contexts/PalletContext';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        width: '100%',
        height: '100%',
        margin: 0,
        borderRadius: 0,
        '@media (max-width: 600px)': {
            padding: '10px', // Ajuste para dispositivos móveis
        },
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const { pallet } = usePallet();

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            fontFamily: `${pallet.fontFamily.general}`,
            color: `${pallet.color.tertiary.font}`,
            fontWeight: pallet.general.weight_bold,
            fontSize: `${pallet.general.size18}`,
            paddingLeft: "20px",
            borderBottom: "1px solid #EAEAEA",
            textTransform: 'uppercase',
            lineHeight: "22px",
            letterSpacing: "1px",
            width: "100%",
        }}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

// export default BootstrapDialogTitle