import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import PieChartRelatorio from '../PieChartRelatorio';
import { usePallet } from "../../../../contexts/PalletContext";
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import RelatorioService from "../../../../services/RelatoriosService";
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';


export default function RelatorioInterlocutorProfissao() {
    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(true)
    let totalGeral = 0;
    const[totalForaMap,setTotalForaMap] = useState(0);

    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter)

    const [resTots, setResTots] = useState([])
    const [resLists, setResLists] = useState([])

    //let esferaTipo = filter.substring(27, 29)
    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };
    
    let esferaTipo = (filtrotitle.length>0 ? filtrotitle[0].value : "Geral")

    const Imprimir = () => {
        window.print()
    }

    React.useEffect(() => {
        setLoading(true)

        if (
            String(format(new Date(dtIni), "yyyy")).slice(0, 1) !== "0" &&
            String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {

            RelatorioService.GetInterlocutorProfissaoPeriodo(
                format(new Date(dtIni), "yyyy-MM-dd"),
                format(new Date(dtFin), "yyyy-MM-dd 23:59:00.000"),
                String(filtro)
            ).then(async res => {                

                await setResTots(res?.data?.totais)
                await setResLists(res?.data?.lista)

                // setTimeout(() => {
                await setLoading(false)
                // }, 500);
            })
        }
    }, []);


    
    React.useEffect(() => {

        resTots?.map(x => {
                
            totalGeral +=  Number(x.count) 
            

            setTotalForaMap(totalGeral)

        })
    }, [resTots]);

    const CheckEsfera = (select) => {
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }

    return (
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '100px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                Relatório / {esferaTipo}
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Interlocutores classificados pela Profissão
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>
                                    {/* if countCD countSF countCN nao forem undefined */}
                                    {loading ?
                                        <CircularLoading
                                            size={100}
                                            top={isMobile ? "37%" : "40%"}
                                            left={isMobile ? "6%" : "16%"}
                                            zIndex={2}
                                        />
                                        :
                                        <Grid style={{
                                            width: '990px',
                                            paddingLeft: '5px',
                                            marginTop: '30px'
                                        }}>
                                            <PieChartRelatorio
                                                title="PERÍODO DE APRESENTAÇÃO"
                                                dtIni={dtIni}
                                                dtFin={dtFin}
                                                //subtitle="DEPARTAMENTOS QUE MAIS REALIZAM AÇÕES DE INFLUÊNCIA"
                                                labels={resTots?.map(x => {
                                                    return (
                                                        {
                                                            key: x.desc,
                                                            data: Number(x.count),
                                                            color: '#' + Math.floor(Math.random() * 16777215).toString(16)
                                                        }
                                                    )
                                                })}
                                                profissao={'sim'}
                                            />
                                        </Grid>
                                    }

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px',marginBottom: '10px'}}>
                                TOTAL: {totalForaMap}
                                </Grid>
                                <Grid style={{
                                    
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_bold,
                                    color: `${pallet.color.secundary.font}`
                                }}>
                                    | PROFISSÕES
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '50%',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            PROFISSÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '50%',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.color.quinternary.font}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>                                     
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {resLists?.map(x => {

                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '65px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '50%',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x?.profissao}
                                                </Grid>

                                                <Grid style={{
                                                    width: '50%',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x?.count}
                                                </Grid>
                                                

                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>

                </div>
            </div>
            {/* </Grid> */}
        </div>
    );
};
