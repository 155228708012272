import React, { useCallback, useEffect, useState } from "react";
import { Grid, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../contexts/PalletContext';
import AccordionTextDataAcaoInfluenciaParlamentar from "./AccordionTextDataAcaoInfluenciaParlamentar";
import PaginationSaveState from "../../../components/PaginationSaveState";
import { IListInfo } from "../../../models/IListInfo";
import ParlamentarMenuFiltroTipoComissao from "../../../components/ParlamentarMenuFiltroTipoComissao";
import { Visibility } from "@material-ui/icons";
import ParlamentarMenuFiltroTipoAcaoInfluencia from "../../../components/ParlamentarMenuFiltroTipoAcaoInfluencia";
import ParlamentarAcaoInfluenciaService from "../../../services/ParlamentarAcaoInfluenciaService";

export interface IFilterAcaoInfluencia {
    key: string,
    value: string
}

const descFiltro = (value: string) => {
    switch (value) {
        case "T":
            return "[ Tudo ]";
        case "E":
            return "[ Êxito ]";
        case "P":
            return "[ Êxito Parcial ]";
        case "S":
            return "[ Sem Êxito ]";
        case "A":
            return "[ Pendente ]";
    }
}

export default function ParlamentarAcoesInfluenciaInfluencia(props: { id_parlamentar: number }) {
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filtro, setFiltro] = useState<IFilterAcaoInfluencia[]>([{ key: 'resultado', value: 'T' }]);
    const [opfiltro, setOpfiltro] = useState(descFiltro("T"));

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage, filtro])

    const getList = useCallback(() => {

        const filter = filtro.map(item => { return { key: item.key, value: item.value } });

        if (currentPage && currentPage > 0)
            ParlamentarAcaoInfluenciaService.GetList(props.id_parlamentar, filter, currentPage, 8).then(async res => {
                if (res && res.success) {
                    setLista(res.lista);

                    setInfoList(res.listInfo);
                }
            })
    }, [props.id_parlamentar, currentPage, filtro])

    const setPage = (p: number) => setCurrentPage(p);

    
    const setFilter = (f: any) => {
        setOpfiltro(descFiltro(f.target.value));
        setFiltro([{ key: 'resultado', value: f.target.value }]);

    }
    return (

        <div>

            <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Grid container direction="row" alignItems="baseline" style={{
                        paddingLeft: "20px",
                        paddingTop: `${isMobile ? "10px" : "0px"}`,
                        marginBottom: `${isMobile ? "-10px" : "0px"}`
                    }} >
                        <Grid container item xs={6}>
                            <Grid style={{ marginRight: "8px" }}>
                                <Grid style={{
                                    color: `${pallet.textColorSecondary}`,
                                    fontWeight: pallet.general.weight_600                                                                                                            
                                }}>
                                    {lista?.length}
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid item style={{
                                    fontSize: "12px",
                                    fontWeight: pallet.general.weight_600,
                                    paddingTop: "3px"
                                }}>
                                    {lista?.length > 1 ? "PROPOSIÇÕES COM AÇÕES DE INFLUÊNCIAS" : "PROPOSIÇÃO COM AÇÃO DE INFLUÊNCIA"} {opfiltro}
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Grid style={{ paddingRight: `${isMobile ? "13px" : "21px"}` }}>
                                        <ParlamentarMenuFiltroTipoAcaoInfluencia title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {/*
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Grid>
                                        <ParlamentarMenuFiltroTipoAcaoInfluencia title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item style={{ border:"1px solid green" }}>
                            <Grid item direction="column" justifyContent="end" >
                                <MenuButtonIconLeftRadioDisc title={"Visualizar"} icon={<Visibility />} />
                            </Grid>
                        </Grid> */}
                    </Grid>
                    <Grid style={{ paddingLeft: `${isMobile ? "20px" : "20px"}`, paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: "20px", paddingBottom: "20px" }}>
                        <AccordionTextDataAcaoInfluenciaParlamentar lista={lista} />
                    </Grid>
                </Grid>
                {/* <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                    <PaginationSaveState
                        name={'ParlamentarComissaoList'}
                        pages={infoList?.totalPages}
                        page={currentPage}
                        onChange={setPage}
                    />
                </Grid> */}
            </Grid>
        </div>
    )
}