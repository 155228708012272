import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useAlert } from "../../../contexts/AlertContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from "@mui/icons-material/Close";
import { Grid, ListItemText, Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { usePallet } from "../../../contexts/PalletContext";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import UsuarioService from "../../../services/UsuarioService";
import TabelasService from "../../../services/TabelasService";
import { IUser } from "../../../models/User.Model";
import SolidButton from "../../../components/SolidButton";
import { useAuthorization } from "../../../contexts/AuthorizationContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props: {
  reloadList?: () => void;
  closeMenu?: () => void;
  usuario: IUser;
  comSeparador?: boolean;
}) {
  const { user } = useAuthorization();
  const { NewAlert } = useAlert();
  const [open, setOpen] = React.useState(false);
  const { pallet } = usePallet();
  const [nome, setNome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const autorizado = user.id_permissao === 1 && user.id != props.usuario.id;

  const handleClickOpen = async () => {
    setNome(props.usuario.nome);
    setEmail(props.usuario.email);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const postExclusao = async (e) => {
    e.preventDefault();

    await UsuarioService.Delete(Number(props?.usuario.id))
      .then(res => {
        if (res) {
          NewAlert('success', 'Usuário excluído com sucesso!')
        } else {
          NewAlert('error', 'Não foi possível excluir o usuário.');
        }
        handleClose();
        props?.closeMenu();
        props?.reloadList();
      });
  }

  return autorizado ? (
    <MenuItem>
      <div>
        {props.comSeparador ? <Divider style={{marginTop: "0px", marginBottom: "0px"}}/> : <></>}

        <Button onClick={() => { autorizado ? handleClickOpen() :
          NewAlert("error", "Não é possível realizar está ação com este perfil.")
          }}
          style={{
            marginLeft: "-7px",
            fontFamily: `${pallet.fontFamily.general}`,
            fontWeight: pallet.fontWeight.button,
            fontSize: `${pallet.general.size14}`,
            color: `${pallet.color.tertiary.font}`,
            borderRadius: "6px",
            textTransform: "capitalize",
            paddingRight: "85px",
            marginRight: "-10px"
          }}>
          <DeleteIcon style={{
            color: `${pallet.color.tertiary.font}`,
            marginRight: "16px",
            fontFamily: `${pallet.fontFamily.general}`,
          }} />
          <ListItemText>º</ListItemText>
          Excluir usuário
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth={"sm"}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Grid
              style={{
                fontFamily: `${pallet.fontFamily}`,
                fontSize: `${pallet.general.size18}`,
                color: `${pallet.textColorSecondary}`,
                padding: "12px",
                marginTop: "7px",
              }}
            >
              EXCLUIR USUÁRIO
            </Grid>
          </BootstrapDialogTitle>

          <DialogContent dividers style={{ padding: "0px" }}>
            <Grid
              container
              style={{
                fontFamily: pallet.fontFamily.general,
                padding: "25px 20px 20px 40px",
              }}
            >
              <Grid
                style={{ padding: "10px" }}
                item
                lg={12}
                xs={12}>
                  <p>Você deseja prosseguir com a exclusão do usuário <b>{nome}</b>, registrado com o e-mail <b>{email}</b>?</p>
                  <p>Em caso de dúvida, considere inativar o usuário primeiro. Esta operação não poderá ser desfeita.</p>
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: "30px",
              backgroundColor: `${pallet.backGroundPageColorPrimary}`,
            }}
          >
            <ButtonDialogConfirmation
              styles={{
                borderRadius: "6px 6px 0px 0px",
                marginRight: "20px",
                textTransform: "capitalize",
                color: `${pallet.general.color1}`,
              }}
              title={"CONFIRMAÇÃO"}
              title_button={"Cancelar"}
              content={"CANCELAR CADASTRO?"}
              message={"Esta ação não poderá ser desfeita."}
              confirmation_button={"Cancelar"}
              confirmationEvent={() => {
                handleClose();
              }}
            />
            <SolidButton
              onClick={postExclusao}
              color={`${pallet.textColorPrimary}`}
              backgroundColor={`${pallet.backgroundColorPrimary}`}
              fontSize={`${pallet.general.size14}`}
              title={"CONFIRMAR EXCLUSÃO"}
            />
          </DialogActions>
        </BootstrapDialog>
      </div>
    </MenuItem>
  ) : null;
}
