import React, { useEffect, useState } from 'react';
import Main from '../main/Main';
import { useParams } from 'react-router-dom';
import { Box, Chip, Grid } from '@mui/material';
import InputTextField from '../../components/InputTextField';
import SelectTextFieldA from '../../components/SelectTextFieldA';
import ButtonDialogConfirmation from '../../components/ButtonDialogConfirmation';
import InputDatePicker from '../../components/InputDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ComboBox from "../../components/ComboBox";
import { useAlert } from '../../contexts/AlertContext';
import { usePallet } from '../../contexts/PalletContext';
import { useNavigate } from 'react-router-dom';
import useStyles from "../../components/layout/Styles";
import styled from "styled-components";
import { isMobile } from 'react-device-detect';
import TabelasService from '../../services/TabelasService';
import { IListFilterInputItem } from '../../components/filter/ListFilterItems';
import InputMultilineTextField from '../../components/InputMultilineTextField';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AgendaService from '../../services/AgendaService';
import SolidButton from '../../components/SolidButton';
import ProposicaoService from '../../services/ProposicaoService';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import RoomIcon from '@mui/icons-material/Room';
import { IFilterData } from '../../components/filter/ListFilter';
import { useQuery } from '../../utils/utils';
import { emptyStore } from '../../helpers/persistence';
import ParlamentarService from '../../services/ParlamentarService';
import { v4 as uuid } from "uuid";
import AgendaCadastroSchema from '../proposicao/models/AgendaCadastroSchema';
import { formatDate } from '../../utils/dateUtils';
import moment from "moment-timezone";

moment.tz.setDefault(process.env.TIMEZONE||'America/Sao_Paulo');

const Title = styled(Grid)`
        color: ${(props => props.pallet.textColorSecondary)};
        font-weight: ${(props => props.pallet.general.weight_bold)};
        font-size: 14px;
        font-family: ${(props => props.pallet.general.fontFamily)};
`;

export default function AgendaCadastro() {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const navigate = useNavigate();

    const { idAgenda } = useParams() as any;

    const [loading, setLoading] = React.useState(false)

    const [idAgendaEdit, setIdAgendaEdit] = React.useState(idAgenda);

    const [idReferencia, setIdReferencia] = React.useState<any>(idAgenda);

    const [error, setError] = useState<Array<{ name: string, err: string }>>([])

    const [onSave, setOnSave] = React.useState(false)

    const [esfera, setEsfera] = React.useState<string>("");
    const listaEsfera: IListFilterInputItem[] = [
        { key: '1', label: '  --  Selecione  --  ', value: "" },
        { key: '2', label: 'Federal', value: 'F' },
        { key: '3', label: 'Estadual', value: 'E' },
        { key: '4', label: 'Municipal', value: 'M' },
    ];

    const [estado, setEstado] = React.useState<string>("");
    const [estadoItens, setEstadoItens] = React.useState<Array<IListFilterInputItem>>([]);

    const [casaEstados, setCasaEstados] = React.useState<string>();
    const [casaEstadosItens, setCasaEstadosItens] = React.useState<Array<IListFilterInputItem>>([])
    const [data, setData] = useState({});

    const [casaMunicipios, setCasaMunicipios] = React.useState<string>("");
    const [casaMunicipiosItens, setCasaMunicipiosItens] = React.useState<Array<IListFilterInputItem>>([])

    const [casaFederal, setCasaFederal] = React.useState<number>();
    const [casaFederalItens, setCasaFederalItens] = React.useState<Array<IListFilterInputItem>>([
        { key: '0', label: '-- Selecione -- ', value: "" },
        { key: '1', label: 'Senado Federal', value: '1' },
        { key: '2', label: 'Câmara dos Deputados', value: '2' },
        { key: '3', label: 'Congresso Nacional', value: '3' },
    ]);

    const [tipo, setTipo] = React.useState<string>("");
    const [tipoItens, setTipoItens] = React.useState<Array<IListFilterInputItem>>([
        { key: '0', label: '  --  Selecione  --  ', value: "" },
        { key: '1', label: 'Proposição', value: '1' },
        { key: '2', label: 'Interlocutor', value: '2' },
    ]);
    const [ambito, setAmbito] = React.useState<string>("");
    const [ambitoItens, setAmbitoItens] = React.useState<Array<IListFilterInputItem>>([]);

    const [dataEvento, setDataEvento] = React.useState<any>(new Date);
    const [horas, setHoras] = useState<string>();
    const [local, setLocal] = useState<string>();
    const [descricao, setDescricao] = React.useState<string>("");

    const [interlocutorItens, setInterlocutorItens] = React.useState<Array<IListFilterInputItem>>([]);

    const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
    const [currentFilterinterlocutor, setCurrentFilterInterlocutor] = useState<IFilterData[]>([]);

    const filterName = 'ParlamentarListCurrentPage';

    const [listaDescricaos, setListaDescricaos] = useState<any[]>([]);
    const [descricaoParcial, setDescricaoParcial] = useState<any>();

    const [nome, setNome] = useState<string>("");
    const [nomeParcial, setNomeParcial] = useState<string>("");
    const [listaNomes, setListaNomes] = useState<string[]>([]);
    const [arrayInterlocutor, setArrayInterlocutor] = useState([])
    const [inputInterlocutor, setInputInterlocutor] = useState("");

    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };



    const mascaraTempo = (value) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "$1:$2")
    };

    useEffect(() => {
        if (tipo === '1') {
            const texto = listaDescricaos.filter(x => x.label === descricaoParcial)
            if (texto.length > 0) {
                loadProposicaoList();
            }
            else if (descricaoParcial && descricaoParcial.trim().length > 2) {
                ProposicaoService.GetPartialDescription(descricaoParcial, [{ key: 'visualizacao', value: 'Seguidas' }], true)
                    .then(async res => {
                        if (res) setListaDescricaos(res.map(item => {
                            return {
                                value: item.id,
                                label: item.descricao,
                            }
                        }))
                    });
            } else if (descricaoParcial === "") {
                loadProposicaoList();
            }
        }
    }, [descricaoParcial]);

    const loadProposicaoList = () => {
        if (tipo === '1') {

            const busca = listaDescricaos.filter(x => x.label === descricaoParcial)
            let filter = currentFilter.map((item) => {
                return { key: item.key, value: item.value };
            });
            if (busca.length > 0) {
                filter = [
                    ...filter.filter(x => x.key !== "id"),
                    { key: "id", value: busca[0].value }
                ]
            }
            else {
                ProposicaoService.GetList() // force break
                    .then(async (res) => {
                        if (res && res.success) {
                        }
                    });
            }
        }
    };

    // filtro INTERLOCUTOR

    useEffect(() => {
        if (nomeParcial && nomeParcial !== null && nomeParcial.trim().length > 2) {
            ParlamentarService.GetPartialNameWithId(nomeParcial, currentFilter)
                .then(async res => setListaNomes(res));
        }
        else if (nomeParcial === "") {
            loadParlamentarList();
        }
    }, [nomeParcial]);


    const loadParlamentarList = () => {
        let idParlamentar
        if (tipo === '2') {
            const filter = currentFilter ? currentFilter.map(item => { return { key: item.key, label: item.label, value: item.value } }) : [];
            if (nome && nome.trim() !== "") {
                filter?.push({ key: 'nome', label: nome, value: idParlamentar });
                setNome(() => undefined)
            }
            ParlamentarService.GetList()
                .then(async res => {
                    if (res && res.success) {
                    }
                });
        }

    }

    React.useEffect(() => {
        TabelasService.getTabelaEstados().then(
            res => {
                const data = []
                data.push({ key: "", label: '-- Selecione -- ', value: "" });
                res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
                setEstadoItens(() => [...data]);

            })
    }, []);

    React.useEffect(() => {

        TabelasService.getTabelaCasasEstaduais()
            .then(async res => {
                const data = []
                data.push({ key: "", label: '-- Selecione -- ', value: "" });
                res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
                setCasaEstadosItens(() => [...data]);
            });
        // }
    }, []);

    React.useEffect(() => {

        if (estado) {
            TabelasService.getTabelaCasasMunicipais(Number(estado))
                .then(async res => {
                    const data = []
                    data.push({ key: "", label: '-- Selecione -- ', value: "" });
                    res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
                    setCasaMunicipiosItens(() => [...data]);
                });
        }
    }, [estado]);


    React.useEffect(() => {

        if (casaFederal) {
            TabelasService.getTabelaAmbitosTipo(casaFederal)
                .then(async res => {
                    const data = []
                    data.push({ key: "", label: '-- Selecione -- ', value: "" });
                    res?.map(item => data.push({ key: item.id, label: item.sigla + " - " + item.resumo, value: item.id }))
                    setAmbitoItens(() => [...data]);
                });
        }
    }, [casaFederal]);


    React.useEffect(() => {
        TabelasService.getTabelaInterlocutores()
            .then(async res => {
                const data = []
                data.push({ key: "", label: '-- Selecione -- ', value: "" });
                res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
                setInterlocutorItens(() => [...data]);
            });
    }, []);


    React.useEffect(() => {
        if (idAgendaEdit && idAgendaEdit !== undefined) {
            AgendaService.Get(Number(idAgendaEdit)).then(
                async res => {
                    setDescricao(res?.data[0]?.descricao)
                    setDataEvento(res?.data[0]?.agenda_data_inicio)
                    setHoras(res?.data[0]?.horario)
                    setLocal(res?.data[0]?.local_nome)

                    if (res?.data[0]?.casa <= 3) {
                        if (res?.data[0]?.casa_sigla) {
                            setEsfera("F")
                            if (res?.data[0]?.casa_sigla === "SF") {
                                handleCasaFederalOnChange(null, 1)
                            }
                            else if (res?.data[0]?.casa_sigla === "CD") {
                                handleCasaFederalOnChange(null, 2)
                            }
                            else if (res?.data[0]?.casa_sigla === "CN") {
                                handleCasaFederalOnChange(null, 3)
                            }
                        }
                        setAmbito(res?.data[0]?.id_comissao);
                    }
                    if (res?.data[0]?.casa >= 5 && res?.data[0]?.casa <= 31) {
                        setEsfera("E")
                        setCasaEstados(res?.data[0]?.casa)
                    }
                    if (res?.data[0]?.casa > 31) {
                        setEsfera("M")
                        setEstado(res?.data[0]?.id_estado)
                        setCasaMunicipios(res?.data[0]?.casa)
                    }
                    setArrayInterlocutor(res?.data?.pauta.map(i => {
                        if (i.id_parlamentar !== null)
                            return {
                                uuid: uuid(),
                                tipo: '2',
                                value: i.id_parlamentar,
                                tagLabel: i.parlamentar_nome,
                            }


                        if (i.id_proposicao !== null)

                            return {
                                uuid: uuid(),
                                tipo: '1',
                                value: i.id_proposicao,
                                tagLabel: i.proposicao_descricao,
                            }

                        setTipo('')

                    }))
                })
        }
    }, [])



    React.useEffect(() => {
        if (idAgendaEdit) {
            setLoading(true)
            handleCasaFederalOnChange(null, casaFederal)
        }
    }, []);

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red', fontSize: '11px', marginTop: "5px", }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    // --------------------- HANDLES ONCHANGE --------------------

    const handleCasaEstadosOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCasaEstados(event.target.value)
        setCasaFederal(undefined)
        setCasaMunicipios("")
    };
    const handleCasaMunicipiosOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCasaMunicipios(event.target.value)
        setCasaFederal(undefined)
        setCasaEstados("")
    };
    const handleCasaFederalOnChange = (event?: React.ChangeEvent<HTMLInputElement>, id?: number) => {
        if (!idAgendaEdit)
            setCasaFederal(Number(event.target.value))
        setCasaMunicipios("")
        setCasaEstados("")
        if (idAgendaEdit) {
            setCasaFederal(id)
            setCasaMunicipios("")
            setCasaEstados("")
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }
    };
    const handleEsferaOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEsfera(event.target.value)
    };

    const handleAmbitoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmbito(event.target.value)
    };

    const handleTipoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipo(event.target.value)

    };
    const handleEstadoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEstado(event.target.value)
    };
    const handleDataEventoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataEvento(event)
    };

    const handleHoraAgendaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = mascaraTempo(event.target.value)
        setHoras(newTime)
        if (newTime.split(":")[0] > 23) {
            setHoras("23:")
        } else if (newTime.split(":")[1] > 59) {
            setHoras(newTime.split(":")[0] + ":59")
        }
    }

    const handleDescricaoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };

    const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setLocal(event.target.value);
    };

    const saveAgenda = async (e) => {
        setOnSave(true)
        e.preventDefault();

        const dataYup = {
            esfera: esfera ? esfera : null,
            casaFederal: casaFederal ? casaFederal : null,
            casaMunicipios: casaMunicipios ? casaMunicipios : null,
            casaEstados: casaEstados ? casaEstados : null,
            estado: estado ? estado : null,
            data_inicio: dataEvento,
            hora: horas,
            descricao: descricao,
            // tipo: tipo,
            local: local,
            ambito: ambito ? ambito : null,
            pauta: arrayInterlocutor,
        }

        const dataSend = {
            casa: casaFederal ? casaFederal : casaEstados ? casaEstados : casaMunicipios ? casaMunicipios : null,
            data_inicio: dataEvento ? formatDate(new Date(dataEvento), "yyyy-MM-dd") : null,
            hora_agendamento: horas,
            casa_sigla: casaFederal === 1 ? 'SF' : casaFederal === 2 ? 'CD' : casaFederal === 3 ? "CN" : null,
            descricao: descricao,
            local_nome: local,
            id_comissao: ambito ? ambito : null,
            pauta: arrayInterlocutor.map(i => {
                if (i.tipo === '2') {
                    return { id_parlamentar: i.value }
                }
                else if (i.tipo === '1') { return { id_proposicao: i.value } }
            }
            )
        }

        setError(() => [])
        dataYup && AgendaCadastroSchema
            .validate(dataYup, { abortEarly: false })
            .catch(err => setError(() => err.errors));
        setData(dataYup)
        if (AgendaCadastroSchema.isValidSync(dataYup)) {
            if (idAgendaEdit) {
                await AgendaService.Update(dataSend, idAgendaEdit,'p')
                    .then(res => {
                        if (res) {
                            setIdReferencia(res)
                            NewAlert('success', 'Agenda editada com sucesso')
                            setTimeout(() => {
                                navigate(`/agenda`);
                            }, 2000);
                        } else {
                            NewAlert('error', 'Erro ao editar, verifique os campos obrigatórios e salve novamente');
                        }
                    });
            } else if (!idAgendaEdit) {
                await AgendaService.Create(dataSend)
                    .then(res => {
                        if (res && res > 0) {
                            setIdReferencia(res)
                            NewAlert('success', 'Evento cadastrado com sucesso na Agenda')
                            setTimeout(() => {
                                clearForm()
                                navigate(`/agenda`);
                            }, 2000);
                        } else {
                            NewAlert('error', 'Erro ao cadastrar evento, verifique os campos obrigatórios e salve novamente');
                        }
                    });
            }
            else {
                NewAlert('error', 'Favor preencher os campos obrigatórios');
            }
        }
    };



    const clearForm = () => {
        setNome("")
        setNomeParcial("")
        setDescricaoParcial("")
        setTipo("")
        setLocal("")
        setEsfera("");
        setCasaMunicipios("");
        setCasaEstados("");
        setCasaFederal(undefined);
        setAmbito("");
        setEstado("");
        setDataEvento(new Date);
        setHoras("");
        setDescricao("");
        setIdAgendaEdit(undefined);
        setArrayInterlocutor([])
        setInputInterlocutor('')
    }

    const handleAddTags = (e, tipo) => {
        if (e !== null) {
            setArrayInterlocutor([...arrayInterlocutor, { uuid: uuid(), tipo: tipo, value: e.value, tagLabel: e.label }])
        }
        setInputInterlocutor('')
    }

    const handleDeleteTags = (uuid) => {
        const newArrayinterlocutor = arrayInterlocutor.slice(0)
        const index = newArrayinterlocutor.findIndex(arrayInterlocutor => arrayInterlocutor.uuid === uuid)
        newArrayinterlocutor.splice(index, 1)
        setArrayInterlocutor([...newArrayinterlocutor])
    }
    
    return (
        <div className={classes.root}>
            <Main>
                <Grid>
                    <Grid style={{
                        color: `${pallet.textColorSecondary}`,
                        fontWeight: pallet.general.weight_bold,
                        fontSize: "24px",
                        fontFamily: `${pallet.general.fontFamily}`,
                        marginTop: "30px"
                    }}>
                        {idAgenda ? "Editar" : "Cadastrar"} Evento
                    </Grid>
                    <Grid style={{
                        backgroundColor: `${pallet.backGroundPageColorSecondary}`,
                        padding: `${isMobile ? "15px" : "40px"}`,
                        marginTop: "30px"
                    }}>


                        <Box component="form" style={{ marginTop: "30px" }}>

                            <Grid xl={12} lg={12} xs={12} container style={{
                                marginBottom: '20px'
                            }}
                                justifyContent="flex-start"
                                alignItems="center">
                                <Title pallet={pallet}>
                                    INFORMAÇÕES DO EVENTO
                                </Title>
                            </Grid>

                        </Box>
                        <Box component="form" style={{ marginTop: "30px" }}>
                            <Grid container spacing={3} rowSpacing={5}>
                                <Grid item lg={4} xs={12}>
                                    <SelectTextFieldA
                                        name={"esferas"}
                                        value={esfera}
                                        onChange={handleEsferaOnChange}
                                        options={listaEsfera}
                                        label={"ESFERA"}
                                        id={"esferas"}
                                        focused={true}

                                    />
                                    <ErrorMessage name='esfera' errors={error} />
                                </Grid>

                                {
                                    <>
                                        {
                                            loading ? null :
                                                esfera === 'F' &&
                                                < Grid item lg={4} xs={12}>
                                                    <SelectTextFieldA
                                                        name={"casa-federal"}
                                                        value={casaFederal}
                                                        onChange={handleCasaFederalOnChange}
                                                        options={casaFederalItens}
                                                        label={"CASA LEGISLATIVA"}
                                                        id={"casa-federal"}
                                                        focused={true}
                                                        placeholder={"Selecione"}
                                                    />
                                                    <ErrorMessage name='casaFederal' errors={error} />
                                                </Grid>
                                        }


                                        {esfera === 'F' && casaFederal ?
                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    name={"ambito"}
                                                    value={ambito}
                                                    onChange={handleAmbitoOnChange}
                                                    options={ambitoItens}
                                                    label={"ÂMBITO"}
                                                    id={"ambito"}
                                                    focused={true}
                                                    placeholder={"Selecione"}
                                                />
                                                <ErrorMessage name='ambito' errors={error} />
                                            </Grid>
                                            :
                                            null

                                        }

                                        {esfera === 'E' &&

                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    name={"casas"}
                                                    value={casaEstados}
                                                    onChange={handleCasaEstadosOnChange}
                                                    options={casaEstadosItens}
                                                    label={"CASA LEGISLATIVA"}
                                                    id={"casas"}
                                                    focused={true}
                                                    placeholder={"Selecione"}
                                                />
                                                <ErrorMessage name='casaEstado' errors={error} />
                                            </Grid>

                                        }
                                        {esfera === 'M' &&


                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    name={"estado"}
                                                    value={estado}
                                                    onChange={handleEstadoOnChange}
                                                    options={estadoItens}
                                                    label={"ESTADOS"}
                                                    id={"estado"}
                                                    focused={true}
                                                    placeholder={"Selecione"}
                                                />
                                                <ErrorMessage name='estado' errors={error} />
                                            </Grid>

                                        }
                                        {esfera === 'M' && estado ?

                                            <Grid item lg={4} xs={12}>
                                                <SelectTextFieldA
                                                    name={"casas"}
                                                    value={casaMunicipios}
                                                    onChange={handleCasaMunicipiosOnChange}
                                                    options={casaMunicipiosItens}
                                                    label={"CASA LEGISLATIVA"}
                                                    id={"casas"}
                                                    focused={true}
                                                    placeholder={"Selecione"}
                                                />
                                                <ErrorMessage name='casaMunicipal' errors={error} />
                                            </Grid>

                                            :

                                            null
                                        }
                                    </>
                                }

                                <Grid item lg={4} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <InputDatePicker
                                            onChange={handleDataEventoOnChange}
                                            label={"DATA DO EVENTO"}
                                            inputFormat={"dd/MM/yyyy"}
                                            defaultValue={new Date}
                                            value={dataEvento}
                                            id={"data-evento"}
                                            startAdornment={
                                                <CalendarTodayIcon />
                                            }
                                        />
                                    </LocalizationProvider>
                                    <ErrorMessage name='dataInicio' errors={error} />
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <InputTextField
                                        type={"text"}
                                        value={horas}
                                        name="horarioEvento"
                                        onChange={handleHoraAgendaChange}
                                        id={"horario-evento"}
                                        label={"HORARIO DO EVENTO"}
                                        focused={true}
                                        maxLenght={5}
                                        startAdornment={<AccessAlarmIcon />}
                                    />
                                    <ErrorMessage name='hora' errors={error} />
                                </Grid>

                            </Grid>
                        </Box>
                        <Box component="form" style={{ marginTop: "40px" }}>
                            <Grid container spacing={3} rowSpacing={5}>
                                <Grid item lg={12} xs={12}>
                                    <InputTextField
                                        type={"text"}
                                        value={local}
                                        maxLenght={120}
                                        onChange={handleChangeLocal}
                                        id={"local"}
                                        label={"LOCAL"}
                                        focused={true}
                                        placeholder={'Digite'}
                                        startAdornment={
                                            <RoomIcon />
                                        }
                                    />
                                    <ErrorMessage name='local' errors={error} />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <InputMultilineTextField
                                        type={"text"}
                                        maxRows={6}
                                        minRows={4}
                                        value={descricao}
                                        defaultValue={""}
                                        onChange={handleDescricaoOnChange}
                                        label="DESCRIÇÃO"
                                        id={"descricao"}
                                        focused={true}
                                        placeholder={"Digite"}
                                    />

                                    <ErrorMessage name='descricao' errors={error} />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box component="form" style={{ marginTop: "30px" }}>

                            <Grid xl={12} lg={12} xs={12} container style={{
                                marginBottom: '20px'
                            }}
                                justifyContent="flex-start"
                                alignItems="center">
                                <Title pallet={pallet}>
                                    INCLUIR PAUTA
                                </Title>
                            </Grid>

                        </Box>
                        <Grid container spacing={2} justifyContent={'space-between'} >
                            <Grid item lg={4} xs={12}>
                                <SelectTextFieldA
                                    name={"tipo"}
                                    value={tipo}
                                    onChange={handleTipoChange}
                                    options={tipoItens}
                                    label={"TIPO"}
                                    id={"tipo"}
                                    focused={true}
                                    placeholder={"Selecione"}
                                />
                                <ErrorMessage name='pauta' errors={error} />
                            </Grid>
                            {
                                <>

                                    {tipo === "2" &&
                                        <Grid item lg={8} xs={12}>
                                            <ComboBox
                                                name='filtroNome'
                                                itens={listaNomes}
                                                onChange={setNomeParcial}
                                                onSelect={(e) => handleAddTags(e, '2')}
                                                placeholder="Buscar"
                                            />
                                            <ErrorMessage name='pauta' errors={error} />
                                        </Grid>
                                    }

                                    {tipo === "1" &&

                                        <Grid item lg={8} xs={12}>
                                            <ComboBox
                                                name="filtroDescricao"
                                                itens={listaDescricaos}
                                                onChange={setDescricaoParcial}
                                                onSelect={(e) => handleAddTags(e, '1')}
                                                placeholder="Buscar"
                                            />
                                            <ErrorMessage name='pauta' errors={error} />
                                        </Grid>

                                    }
                                </>
                            }
                        </Grid>
                        <Grid container style={{ border: "1px grey solid", minHeight: '100px', height: 'auto', marginTop: '15px', padding: '5px' }}>
                            <Box>
                                <Grid xl={12} lg={12} xs={12} container style={{ padding: '5px' }}>
                                    <Title pallet={pallet}>
                                        PAUTAS SELECIONADAS
                                    </Title>
                                </Grid>
                                {arrayInterlocutor.map(x => {
                                    return (
                                        <Chip
                                            style={{ margin: '3px', whiteSpace: 'pre-line', maxWidth: `${isMobile ? "280px" : "auto"}` }}
                                            label={x.tagLabel}
                                            onDelete={() => handleDeleteTags(x.uuid)}
                                        />
                                    )
                                })}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="end" style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                        <Grid item style={{ marginRight: "20px" }}>

                            <ButtonDialogConfirmation
                                styles={{
                                    borderRadius: "6px 6px 0px 0px",
                                    // marginRight: "20px",
                                    textTransform: "capitalize",
                                    color: `${pallet.general.color1}`,
                                    lineHeight: "18px",
                                    fontSize: `${pallet.general.size14}`,

                                }}
                                lastPath={`/agenda/`}
                                title={"CONFIRMAÇÃO"}
                                title_button={"Cancelar"}
                                confirmation_button={"SAIR"}
                                content={"SAIR SEM SALVAR?"}
                                message={"As informações preenchidas serão perdidas."}
                            />
                        </Grid>
                        <Grid item>
                            <SolidButton
                                onClick={saveAgenda}
                                color={`${pallet.textColorPrimary}`}
                                backgroundColor={`${pallet.backgroundColorPrimary}`}
                                fontSize={`${pallet.general.size14}`}
                                title={"SALVAR"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Main >
        </div >
    );
}