import { EAnexoModulos } from '../components/Anexos';
import * as http from '../helpers/http';
import UploadFilesService from './UploadFiles.service';

class UsuarioService {

    Get = async (id: number): Promise<any> => {
        const result = await http.get(`/usuario/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };

    GetFiltered = async (filter: any): Promise<any> => {
        const result = await http.get(`/usuario?filter=${JSON.stringify(filter)}`);
        if (result.success) { return result.data }
        return undefined;
    };

    GetList = async (): Promise<any> => {
        const result = await http.get(`/usuario`);        

        if (result.success) { return result.data }
        return undefined;
    };

    getUsuarios = async (pageSize?: Number, pageIndex?: Number, filter?: any): Promise<any> => {
        const result = await http.get(`/gerenciamento/usuario/list?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)

        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                data: result.data
            }
        }
        return undefined;

    };

    getUsuariosDep = async (id_dep: number, pageSize?: Number, pageIndex?: Number, filter?: any): Promise<any> => {
        const result = await http.get(`/usuario/list/dep/${id_dep}?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)

        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                data: result.data
            }
        }
        return undefined;

    };

    Create = async (data: any): Promise<boolean> => {
        const result = await http.post(`/usuario`, data);
        if (result.success) { return true }
        return false;
    };

    CreateGer = async (data: any): Promise<any> => {
        let imagem = undefined;

        if (data.imagem) {
            imagem = data.imagem;
            delete data.imagem;
        }


        const result = await http.post(`/gerusuario`, data);

        if (result.success) {
            const id = result.data;
            const filename = await UploadFilesService
                .upload([imagem], EAnexoModulos.UsuarioImagem, id)
                .then(files => files.data[0].filename)
                .catch((err) => {
                    console.error(err);
                })
            if (filename) {
                await http.put(`/gerusuario/${id}`, { url_foto: filename });
            }
            return id



        }
        return -10;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/usuario/${id}`);
        if (result.success) { return true }
        return false;
    };

    Update = async (id: number, data: any): Promise<boolean> => {
        const result = await http.put(`/usuario/${id}`, data);
        if (result.success) { return true }
        return false;
    };


    UpdateGer = async (id: number, data: any): Promise<boolean> => {
        let imagem = undefined;

        if (data.imagem) {
            imagem = data.imagem;
            delete data.imagem;
        }

        const result = await http.put(`/gerusuario/${id}`, data);
        if (result.success) {
            if (imagem && id && id > 0) {
                const filename = await UploadFilesService
                    .upload([imagem], EAnexoModulos.UsuarioImagem, id)
                    .then(files => files.data[0].filename)
                    .catch((err) => {
                        console.error(err);
                    })

                if (filename) {
                    await http.put(`/gerusuario/${id}`, { url_foto: filename });
                }
            }

            return true

        }
        return false;
    };


    UpdateSenha = async (data: any): Promise<any> => {
        const result = await http.put(`/usuario/trocasenha`, data);
        if (result.success) { return result.success }
        return false;
    };


    AlteraSenha = async (data: any): Promise<any> => {
        const result = await http.put(`/usuario/alterasenha`, data);
        if (result.success) { return result.data }
        return false;
    };

    GetByDpto = async (): Promise<any> => {        
        const result = await http.get(`/usuario/departamento`);
        if (result.success) { return result.data }
        return undefined;
    }

    GetListEmp = async (pageSize?: Number, pageIndex?: Number, filter?: string): Promise<any> => {
        const result = await http.get(`/usuario/listemp?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                data: result.data.data
            }
        }
        return undefined;

    };



    GetDepartamentosGer = async (pageSize?: Number, pageIndex?: Number, filter?: any): Promise<any> => {
        const result = await http.get(`/cliente/departamentos?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                data: result.data
            }
        }
        return undefined;

    };


    GetDepartamentoParcial = async (id: number): Promise<any> => {
        
        const result = await http.get(`/cliente/departamentosParcial/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };


    GetDepartamentoParaEdicao = async (id: number): Promise<any> => {
        
        const result = await http.get(`/cliente/departamentosEdicao/${id}`);
        if (result.success) { return result.data }
        return undefined;
    };


    GetNestedCheckBoxUsuario = async (): Promise<any> => {
        const result = await http.get(`/nestedCheckBox`);
        if (result.success) { return result.data }
        return undefined;
    }


    CreateGerDpto = async (data: any): Promise<any> => {
        const result = await http.post(`/cliente/departamentos`, data);
        if (result.success) { return result.data }
        return undefined;
    };


    UpdateGerDepto = async (id: number, data: any): Promise<boolean> => {
        

            const result = await http.put(`/cliente/departamentos/${id}`, data);
           
                if (result.success) { 

    
                return true 


                }
                return false;
     };


     DeleteGerDpto = async (id: number): Promise<boolean> => {
        const result = await http.del(`/cliente/departamentos/${id}`);
        if (result.success) { return result.data }
        return false;
    };


    GetLogUsuarioGer = async (pageSize?: Number, pageIndex?: Number, filter?: any): Promise<any> => {
        const result = await http.get(`/logs/list?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`)
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                data: result.data
            }
        }
        return undefined;

    };
}

export default new UsuarioService();
