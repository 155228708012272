
import { Grid } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { usePallet } from '../../../contexts/PalletContext';
import CardApi from '../../agenda/_components/CardApi'
import { format } from 'date-fns';
import _ from 'lodash';
import CircularLoading from '../../../components/CircularLoading';
import { isMobile } from 'react-device-detect';



export default function StepHourCalendar(props: { blocoEvento?: Array<any>, dateStartReference?: Date, loadEventos: () => void, loading: boolean }) {


    const { pallet } = usePallet();

    let resultGroup = []
    let grouped = _.mapValues(_.groupBy(props?.blocoEvento, 'horario'),
        clist => clist.map(x => _.omit(x, 'horario')));
    Object.keys(grouped).map(function (key) {
        resultGroup.push({
            horario: key,
            eventos: grouped[key]
        })
        return [(key), grouped[key]];
    });

    return (
        <>
            {props?.loading ?
                <CircularLoading
                    positionCustom={true}
                    size={100}
                    top={isMobile ? "37%" : "50%"}
                    left={isMobile ? "6%" : "45%"}
                    zIndex={2}
                />
                :
                resultGroup.map(x => {
                    return (
                        <Step expanded active={false}>
                            <StepLabel
                                icon={
                                    <Grid container >
                                        <Grid
                                            style={{
                                                marginTop: "-8px",
                                                marginLeft: "-1px",
                                                borderRadius: "50%",
                                                width: "27px",
                                                height: "27px",
                                                position: "relative",
                                                boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                // backgroundColor: (index === 0 && currentPage === 1) ? `${pallet.backgroundColorQuintenary}` : `${pallet.backgroundColorQuaternary}`
                                                // TODO: Fazer condicao para backbroungColor abaixo, se hora maior ou igual agora col roxa, hora menor cor cinza
                                                backgroundColor: `${x.eventos[0].agenda_data_inicio < format(new Date, 'yyyy-MM-ddTHH:mm:ss.ssss') ? '#e9e9e9' : pallet.backgroundColorQuintenary}`
                                            }}
                                        >
                                            <Grid style={{
                                                position: "absolute",
                                                borderRadius: "50%",
                                                top: "50%",
                                                left: "50%",
                                                width: "15px",
                                                height: "15px",
                                                margin: "-7.5px 0px 0px -7.5px",
                                                zIndex: 1,
                                                backgroundColor: "white",
                                                // border: (index === 0 && currentPage === 1) ? `3px solid ${pallet.backgroundColorPrimary}` : `3px solid ${pallet.general.color4}`
                                                border: `3px solid ${x.eventos[0].agenda_data_inicio < format(new Date, 'yyyy-MM-ddTHH:mm:ss.ssss') ? '#bababa' : pallet.backgroundColorPrimary}`
                                            }} >
                                                {/* {(index === 0 && currentPage === 1) ? */}
                                                {/* {(currentPage === 1) ? */}
                                                <Grid style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    borderRadius: "50%",
                                                    width: "7px",
                                                    height: "7px",
                                                    margin: "-3.5px 0px 0px -3.5px",
                                                    zIndex: 1,
                                                    backgroundColor: `${x.eventos[0].agenda_data_inicio < format(new Date, 'yyyy-MM-ddTHH:mm:ss.ssss') ? '#bababa' : pallet.backgroundColorPrimary}`
                                                }}>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            >
                                <div style={{ paddingBottom: "7px", fontSize: "16px", fontFamily: `${pallet.general.fontFamily}`, fontWeight: pallet.general.weight_bold }}>
                                    {x.horario}h | {x.eventos[0].dia.slice(0, -5)}
                                </div>
                            </StepLabel>
                            <StepContent>
                                <>
                                    {x.eventos.map(e => {
                                        return (
                                            <CardApi idAnchorEl={1} hora={x.hora} eventos={e} loadEventos={props?.loadEventos} />
                                        )
                                    })}
                                </>
                            </StepContent>
                        </Step>
                    )
                }
                )}
        </>
    )
}
