import { Grid, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
// import ReactToPrint from 'react-to-print';
import PieChartRelatorio from '../PieChartRelatorio';
import { usePallet } from "../../../../contexts/PalletContext";
import styled from 'styled-components';
import legis_agenda from "../../../../img/logo_legis_alta.png";
import PrintIcon from '@mui/icons-material/Print';
import { isMobile } from 'react-device-detect';
import legis_agenda2 from "../../../../img/legis_agenda2.png";
import { fontSize } from '@mui/system';
import RelatorioService from "../../../../services/RelatoriosService";
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../../../utils/utils';
import CircularLoading from '../../../../components/CircularLoading';
import useStyles from "../../../../components/layout/Styles";


const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`;

const box = styled.div`
width: 300px;
height: 300px;
`;

export default function RelatorioProposicoesPeloPosicionamento() {
    const classes = useStyles();
    const { pallet } = usePallet();
    const { dtIni } = useParams() as any;
    const { dtFin } = useParams() as any;
    const [loading, setLoading] = useState(false)

    let filter = useQuery().get('filter');
    const [filtro, setFiltro] = useState(filter)
    const [countFavoravel, setCountFavoravel] = useState()
    const [countFavoravelEmenda, setCountFavoravelEmenda] = useState()
    const [countFavoravelParecerRelator, setCountFavoravelParecerRelator] = useState()
    const [countFavoravelParecerRelatorEmenda, setCountFavoravelParecerRelatorEmenda] = useState()
    const [countContrarioParecerRelator, setCountContrarioParecerRelator] = useState()
    const [countContrarioParecerRelatorEmenda, setCountContrarioParecerRelatorEmenda] = useState()
    const [countContrario, setCountContrario] = useState()
    const [countContrarioEmendaSaneadora, setCountContrarioEmendaSaneadora] = useState()
    const [countPendente, setCountPendente] = useState()
    const [countNeutro, setCountNeutro] = useState()

    const [dataFavoravel, setDataFavoravel] = useState([])
    const [dataFavoravelEmenda, setDataFavoravelEmenda] = useState([])
    const [dataFavoravelParecerRelator, setDataFavoravelParecerRelator] = useState([])
    const [dataFavoravelParecerRelatorEmenda, setDataFavoravelParecerRelatorEmenda] = useState([])
    const [dataContrarioParecerRelator, setDataContrarioParecerRelator] = useState([])
    const [dataContrario, setDataContrario] = useState([])
    const [dataContrarioEmendaSaneadora, setDataContrarioEmendaSaneadora] = useState([])
    const [dataPendente, setDataPendente] = useState([])
    const [dataNeutro, setDataNeutro] = useState([])
    
    let filtrotitle = []

    try { filtrotitle = JSON.parse(filter) } catch { filtrotitle = [] };
    
    let esferaTipo = (filtrotitle.length>0 ? filtrotitle[0].value : "Geral")

    const Imprimir = () => {
        window.print()
    }

    React.useEffect(() => {
        setLoading(true)

        if (
            String(format(new Date(dtIni), "yyyy")).slice(0, 1) !== "0" &&
            String(format(new Date(dtFin), "yyyy")).slice(0, 1) !== "0"
        ) {
            RelatorioService.GetProposicoesPeloPosicionamento(
                format(new Date(dtIni), "yyyy-MM-dd"),
                format(new Date(dtFin), "yyyy-MM-dd"),
                String(filtro)
            ).then(async res => {
                setCountFavoravel(res?.data?.posicionamentoGeral?.favoravel)
                setCountFavoravelEmenda(res?.data?.posicionamentoGeral?.favoravelComEmenda)
                setCountFavoravelParecerRelator(res?.data?.posicionamentoGeral?.favoravelParecerRelator)
                setCountFavoravelParecerRelatorEmenda(res?.data?.posicionamentoGeral?.favoravelParecerRelatorEmenda)
                setCountContrarioParecerRelator(res?.data?.posicionamentoGeral?.contrarioParecerRelator)
                setCountContrarioParecerRelatorEmenda(res?.data?.posicionamentoGeral?.contrarioParecerRelatorEmenda)
                setCountContrario(res?.data?.posicionamentoGeral?.contrario)
                setCountContrarioEmendaSaneadora(res?.data?.posicionamentoGeral?.contrarioComEmenda)
                setCountPendente(res?.data?.posicionamentoGeral?.pendente)
                setCountNeutro(res?.data?.posicionamentoGeral?.neutro)

                setDataFavoravel(res?.data?.posicionamentoTipos?.propTipoFavoravel)
                setDataFavoravelEmenda(res?.data?.posicionamentoTipos?.propTipoFavoravelComEmenda)
                setDataFavoravelParecerRelator(res?.data?.posicionamentoTipos?.propTipoFavoravelParecerRelator)
                setDataFavoravelParecerRelatorEmenda(res?.data?.posicionamentoTipos?.propTipoFavoravelParecerRelatorEmenda)
                setDataContrarioParecerRelator(res?.data?.posicionamentoTipos?.propTipoContrarioParecerRelator)
                setDataContrario(res?.data?.posicionamentoTipos?.propTipoContrario)
                setDataContrarioEmendaSaneadora(res?.data?.posicionamentoTipos?.propTipoContrarioComEmenda)
                setDataPendente(res?.data?.posicionamentoTipos?.propTipoPendente)
                setDataNeutro(res?.data?.posicionamentoTipos?.propTipoNeutro)
                setLoading(false)
            })
        }
    }, []);

    const CheckEsfera = (select) => {
        switch (select) {
            case 'F':
                return 'Federal'
            case 'E':
                return 'Estadual'
            case 'M':
                return 'Municipal'
            default:
                return 'Federal'
        }
    }

    return (
        // <div className={classes.root} >
        //     <Main>
        <div>
            <div className="relativeCSS">
                <style type="text/css" media="print">
                    {"\
                        @page { size: A4; }\
                    "}
                </style>
                <div className="flash" />
                <div style={{ width: '1000px', WebkitPrintColorAdjust: 'exact' }} >
                    <table className="testClass" style={{ width: '1000px' }} >
                        <thead>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        padding: "20px",
                                        backgroundColor: `${pallet.general.color1}`,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: '70px'
                                    }}>
                                        <Grid style={{}}>
                                            <img style={{ width: '100px' }} src={legis_agenda} />
                                        </Grid>
                                        <Grid style={{
                                            color: 'white',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size12}`
                                        }}>
                                            Monitoramento Inteligente no Poder Legislativo
                                        </Grid>
                                        <Grid>
                                            <IconButton onClick={Imprimir} style={{ color: 'white' }} aria-label="upload picture" component="span">
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>

                                    </Grid>
                                </td>
                            </tr>
                        </thead>
                        <tbody style={{
                            width: '900px',
                            // display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <tr>
                                <td>
                                    <Grid container style={{
                                        width: '990px',
                                        padding: '30px'
                                    }}>
                                        <Grid style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            // alignItems: "left",
                                        }}>
                                            <img style={{ width: '100px' }} src={legis_agenda2} />
                                        </Grid>
                                        <Grid style={{ marginLeft: '50px' }}>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size16}`,
                                            }}>
                                                Relatório / {esferaTipo}
                                            </Grid>
                                            <Grid style={{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: '20px',
                                                fontWeight: pallet.general.weight_bold,
                                                marginTop: '8px'
                                            }}>
                                                Proposições Classificadas pelo Posicionamento
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                            <tr >
                                <td>
                                    {loading ?
                                        <CircularLoading
                                            size={100}
                                            top={isMobile ? "37%" : "40%"}
                                            left={isMobile ? "6%" : "16%"}
                                            zIndex={2}
                                        />
                                        :
                                        <Grid style={{
                                            width: '990px',
                                            paddingLeft: '5px',
                                            marginTop: '30px'
                                        }}>
                                            <PieChartRelatorio
                                                title="PERÍODO DE APRESENTAÇÃO"
                                                dtIni={dtIni}
                                                dtFin={dtFin}
                                                labels={[
                                                    {
                                                        key: "Favorável",
                                                        data: Number(countFavoravel),
                                                        color: `${pallet.color.charts.green}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Favorável","value":5},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Favorável com Emenda",
                                                        data: Number(countFavoravelEmenda),
                                                        color: `${pallet.color.charts.purple}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Favorável com Emenda","value":4},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Favorável ao parecer do relator",
                                                        data: Number(countFavoravelParecerRelator),
                                                        color: '#C71585',
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Favorável ao parecer do relator","value":6},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Favorável ao parecer do relator com emenda",
                                                        data: Number(countFavoravelParecerRelatorEmenda),
                                                        color: '#B8860B',
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Favorável ao parecer do relator com emenda","value":7},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Contrário ao parecer do relator",
                                                        data: Number(countContrarioParecerRelator),
                                                        color: '#facb74',
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Contrário ao parecer do relator","value":8},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Contrário ao parecer do relator com emenda",
                                                        data: Number(countContrarioParecerRelatorEmenda),
                                                        color: '#000000',
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Contrário ao parecer do relator com emenda","value":9},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Contrário",
                                                        data: Number(countContrario),
                                                        color: `${pallet.color.charts.red}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Contrário","value":1},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Contrário com Emenda Saneadora",
                                                        data: Number(countContrarioEmendaSaneadora),
                                                        color: `${pallet.color.charts.blue}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Contrário com emenda Saneadora","value":2},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Pendente",
                                                        data: Number(countPendente),
                                                        color: `${pallet.color.charts.yellow}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Pendente","value":0},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                    {
                                                        key: "Neutro",
                                                        data: Number(countNeutro),
                                                        color: `${pallet.color.charts.lightGrey}`,
                                                        url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"posicionamento","label":"Posicionamento: Neutro","value":3},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]'
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                TOTAL: {countFavoravel}
                                </Grid>
                                <Grid style={{
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | FAVORÁVEL
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataFavoravel.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"tipo","label":"TIPO : '+ x.tipo +'","value":"'+ x.tipo +'" },{"key":"posicionamento","label":"Posicionamento: Favorável","value":5},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]','_blank')} >
                                                    {x.qtd}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {countFavoravelEmenda}
                                </Grid>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | FAVORÁVEL COM EMENDA
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataFavoravelEmenda.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"tipo","label":"TIPO : '+ x.tipo +'","value":"'+ x.tipo +'" },{"key":"posicionamento","label":"Posicionamento: Favorável com Emenda","value":4},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]','_blank')} >
                                                    {x.qtd}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {countContrario}
                                </Grid>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | CONTRÁRIO
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataContrario.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"tipo","label":"TIPO : '+ x.tipo +'","value":"'+ x.tipo +'" },{"key":"posicionamento","label":"Posicionamento: Contrário","value":1},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]','_blank')} >
                                                    {x.qtd}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {countContrarioEmendaSaneadora}
                                </Grid>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | CONTRÁRIO COM EMENDA SANEADORA
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataContrarioEmendaSaneadora.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"tipo","label":"TIPO : '+ x.tipo +'","value":"'+ x.tipo +'" },{"key":"posicionamento","label":"Posicionamento: Contrário com emenda Saneadora","value":2},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]','_blank')} >
                                                    {x.qtd}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {countPendente}
                                </Grid>
                                <Grid style={{
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | PENDENTE
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataPendente.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"tipo","label":"TIPO : '+ x.tipo +'","value":"'+ x.tipo +'" },{"key":"posicionamento","label":"Posicionamento: Pendente","value":0},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]','_blank')} >
                                                    {x.qtd}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}


                    </Grid>
                    <table>
                        <tbody>
                            <tr>
                                <Grid style={{marginTop: '70px'}}>
                                    TOTAL: {countNeutro}
                                </Grid>
                                <Grid style={{
                                    marginTop: '70px',
                                    fontSize: `${pallet.general.size18}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontWeight: pallet.general.weight_600
                                }}>
                                    | NEUTRO
                                </Grid>
                            </tr>
                        </tbody>
                    </table>
                    <Grid style={{
                        border: `1px solid #e0e0e0`,
                        marginTop: '20px',
                        borderRadius: '7px'
                    }}>
                        <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                            <tbody>
                                <tr>
                                    <Grid container
                                        display='flex'
                                        justifyContent={'flex-start'}
                                        direction='row'
                                        style={{
                                            height: '50px',
                                            backgroundColor: `${pallet.backgroundGeneralPage}`,
                                            borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }}
                                    >
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            TIPO DE PROPOSIÇÃO
                                        </Grid>
                                        <Grid style={{
                                            width: '495px',
                                            paddingTop: '15px',
                                            paddingLeft: '25px',
                                            fontFamily: `${pallet.general.fontFamily}`,
                                            fontSize: `${pallet.general.size14}`,
                                            color: `${pallet.textColorSecondary}`,
                                            fontWeight: pallet.general.weight_600
                                        }}>
                                            QUANTIDADE
                                        </Grid>
                                    </Grid>
                                </tr>
                            </tbody>
                        </table>
                        {dataNeutro.map(x => {
                            return (
                                <table className="testClass" style={{ width: '1000px', marginTop: '-1px' }} >
                                    <tbody>
                                        <tr>
                                            <Grid container
                                                display='flex'
                                                justifyContent={'flex-start'}
                                                direction='row'
                                                style={{
                                                    height: '50px',
                                                    borderTop: `1px solid #e0e0e0`
                                                }}
                                            >
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                }}>
                                                    {x.tipo}
                                                </Grid>
                                                <Grid style={{
                                                    width: '495px',
                                                    paddingTop: '15px',
                                                    paddingLeft: '25px',
                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                    fontSize: `${pallet.general.size14}`,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"tipo","label":"TIPO : '+ x.tipo +'","value":"'+ x.tipo +'" },{"key":"posicionamento","label":"Posicionamento: Neutro","value":3},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                                                        format(new Date(dtIni), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtIni), "MM/dd/yyyy") +
                                                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                                                        format(new Date(dtFin), "yyyy-MM-dd") +
                                                        '","value" : "' +
                                                        format(new Date(dtFin), "MM/dd/yyyy") +
                                                        '"}]','_blank')} >
                                                    {x.qtd}
                                                </Grid>
                                            </Grid>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })}
                    </Grid>
                </div>
            </div>
        </div>
        //     </Main>
        // </div>
    );
};
