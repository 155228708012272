import { Divider, Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ComboBox from "../../../components/ComboBox";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import React, { useEffect, useState } from "react";
import PieChartInfoCard from "../../../components/PieChartInfoCard";
import PieChartInfoInsight from "./PieChartInfoInsight";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/dateUtils";
import { IListFilterInputItem } from "../../../components/filter/ListFilterItems";

export function AccordionContentKey22(props: {
  titleFederal?: string;
  originFederal?: string;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string,
  onChange?: (event: any) => void;
}) {

  const tagDiscursoCor = (value: string) => {
    if (value) {
      value = value.toLowerCase();
    }
    switch (value) {
      case "divergente":
        return `${pallet.charts.red}`;
      case "convergente":
        return `${pallet.charts.green}`;
      case "neutro":
        return `${pallet.charts.lightGrey}`;
      default:
        return `${pallet.charts.blue}`;
    }
  }
  const [loading, setLoading] = React.useState(false)
  const [estado, setEstado] = React.useState<string>("0");
  const [estadoItens, setEstadoItens] = React.useState<Array<IListFilterInputItem>>([]);
  const [casaLabel, setCasaLabel] = React.useState<string>("");
  const [casaMunicipios, setCasaMunicipios] = React.useState<number>();
  const [casaMunicipiosItens, setCasaMunicipiosItens] = React.useState<Array<IListFilterInputItem>>([])
  const [posicionamento, setPosicionamento] = React.useState<string>("");
  const { pallet } = usePallet();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const [listaPartidos, setListaPartidos] = React.useState([]);

  const [partido_1, setPartido_1] = React.useState();
  const [partido_2, setPartido_2] = React.useState();
  const [partido_3, setPartido_3] = React.useState();
  const [partido_4, setPartido_4] = React.useState();
  const [partido_5, setPartido_5] = React.useState();

  const [outros, setOutros] = React.useState();

  const navigate = useNavigate();
  const corPosicionamento = (value: string) => {

    switch (value) {
      case "Contrário":
          return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
          return `${pallet.charts.orange}`;
      case "Neutro":
          return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
          return `${pallet.charts.blue}`;
      case "Favorável":
          return `${pallet.charts.green}`;
      case "Pendente":
          return `${pallet.charts.yellow}`;
      case "Indefinido":
          return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
          return '#C71585';
      case "Favorável ao parecer do relator com emenda":
          return '#B8860B';
      case "Contrário ao parecer do relator":
          return '#facb74';
      case "Contrário ao parecer do relator com emenda":
          return '#000000';
      default:
          return `${pallet.charts.lightGrey}`;
  }
  }

  function LabelHandlePosicionamento(props: { posicionamento: string, bg: string }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
          </Grid>
          <Grid item >
            {props?.posicionamento}
          </Grid>
        </Grid>
      </>
    )
  }

  const posicionamentoItens = [
    { key: 0, label: <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />, value: "Neutro" },
    { key: 1, label: <LabelHandlePosicionamento posicionamento={"Contrário com emenda saneadora"} bg={"Contrário com emenda saneadora"} />, value: "Contrário com emenda saneadora" },
    { key: 2, label: <LabelHandlePosicionamento posicionamento={"Contrário"} bg={"Contrário"} />, value: "Contrário" },
    { key: 3, label: <LabelHandlePosicionamento posicionamento={"Favorável com emenda saneadora"} bg={"Favorável com emenda saneadora"} />, value: "Favorável com emenda saneadora" },
    { key: 4, label: <LabelHandlePosicionamento posicionamento={"Favorável"} bg={"Favorável"} />, value: "Favorável" },
    { key: 5, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator"} bg={"Favorável ao parecer do relator"} />, value: "Favorável ao parecer do relator" },
    { key: 6, label: <LabelHandlePosicionamento posicionamento={"Favorável ao parecer do relator com emenda"} bg={"Favorável ao parecer do relator com emenda"} />, value: "Favorável ao parecer do relator com emenda" },
    { key: 7, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator"} bg={"Contrário ao parecer do relator"} />, value: "Contrário ao parecer do relator" },
    { key: 9, label: <LabelHandlePosicionamento posicionamento={"Contrário ao parecer do relator com emenda"} bg={"Contrário ao parecer do relator com emenda"} />, value: "Contrário ao parecer do relator com emenda" },
    { key: 8, label: <LabelHandlePosicionamento posicionamento={"Pendente"} bg={"Pendente"} />, value: "Pendente" },
    { key: 10, label: <LabelHandlePosicionamento posicionamento={"Indefinido"} bg={"Indefinido"} />, value: "Indefinido" },
  ]
  const handlePosicionamentoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPosicionamento(event.target.value);

  };

  const DotPosicionamento = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "4px" : "25px"}; 
`;
  const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: ${isMobile ? "9px" : "10px"};
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    line-height: 12px;
    letter-spacing: 1px;
    width: 'auto'; 
`;

  const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: ${isMobile ? "3px" : "5px"};
    margin-top: 2px;
`;

  const Title = styled(Grid)`
    padding-left:  ${isMobile ? '5px' : '24px'};
    padding-top: ${isMobile ? '5px' : '30px'} ;
    background-color: ${pallet.backGroundPageColorPrimary};
    text-transform: uppercase;
    font-size:${pallet.general.size12};
    color: ${pallet.textColorQuaternary};
    font-family: ${pallet.general.fontFamily};
    font-weight: ${pallet.general.weight_600};
    place-content:  ${isMobile ? 'center' : 'unset'};
    height: 79px;
`;

const posicionamentoValor = (value: string) => {
  switch (value) {
    case "Pendente":
      return 99;
    case "Contrário":
      return 1;
    case "Contrário com emenda saneadora":
      return 2;
    case "Neutro":
      return 3;
    case "Favorável com emenda saneadora":
      return 4;
    case "Favorável":
      return 5;
    case "Favorável ao parecer do relator":
      return 6;
    case "Favorável ao parecer do relator com emenda":
      return 7;
    case "Contrário ao parecer do relator":
      return 8;
    case "Contrário ao parecer do relator com emenda":
      return 9;
    case "Indefinido":
      return 0;
    default:
      return 0;
  }
};

  React.useEffect(() => {
    TabelasService.getTabelaEstados().then(
      res => {
        if (res) {
          const data = []
          data.push({ key: '', label: 'Selecione', value: '0' });
          res.map((item, index) => data.push({ key: item.id, label: item.nome, value: item.id, }))
          setEstadoItens(() => [...data]);

        }
      })
  }, []);

  React.useEffect(() => {
    if (estado) {
      TabelasService.getTabelaCasasMunicipais(Number(estado))
        .then(async res => {
          if (res) {
            const data = []
            data.push({ key: "", label: '-- Selecione -- ', value: "" });
            res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
            setCasaMunicipiosItens(() => [...data]);
          }
        });
    }
  }, [estado]);


  React.useEffect(() => {
    if (casaMunicipios) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey22List(

          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), casaMunicipios, Number(posicionamentoValor(posicionamento))
        ).then((res) => {
          if (res?.data) {
            setListaPartidos([])
            setListaPartidos(res.data)
          }
        })
      }
    }

    if (casaMunicipios && posicionamento) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey22Grafico(

          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), casaMunicipios, Number(posicionamentoValor(posicionamento))
        ).then((res) => {
          if (res?.data) {
            setPartido_1(res.data.partido_1)
            setPartido_2(res.data.partido_2)
            setPartido_3(res.data.partido_3)
            setPartido_4(res.data.partido_4)
            setPartido_5(res.data.partido_5)
            setOutros(res.data.outros)

          }
        });

      }
    }

  }, [props.dtIni, props.dtFin, casaMunicipios, posicionamento]);

  const handleEstadoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstado(event.target.value)
    setCasaMunicipios(undefined);
  };

  const handleCasaMunicipiosOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    setCasaMunicipios(Number(event.target.value))

    TabelasService.getTabelaCasasMunicipaisLabel(Number(event.target.value)).then((res) => {
      setCasaLabel(res[0]?.nome);
    })


  };

  return (
    <>
      <Grid>
        <Grid
          container
          style={{
            justifyContent: estado ? "space-around" : 'start',
            marginLeft: `${isMobile ? "0px" : estado !== "" ? '0px' : "44px"}`,
            paddingTop: `${isMobile ? "40px" : "30px"}`,
            paddingBottom: `${isMobile ? "20px" : "30px"}`,
            paddingLeft: `${isMobile ? "10px" : "0px"}`,
            paddingRight: `${isMobile ? "10px" : "0"}`,
            alignContent: 'space-between'
          }}
        >
          <Grid
            item
            md={5}
            xs={12}
            lg={5}
            style={{
              paddingBottom: `${isMobile ? "20px" : "0px"}`,
            }}
          >
            <SelectTextFieldA
              name={"estado"}
              value={estado}
              onChange={handleEstadoOnChange}
              options={estadoItens}
              label={"ESTADO"}
              id={"estado"}
              focused={true}
              placeholder={"Selecione"}
            />
          </Grid>
          {estado !== '0' ? (
            <Grid item md={5} xs={12} lg={5} style={{ padding: `${isMobile ? "0px 30px 20px 30px" : "0px"}` }}>

              <SelectTextFieldA
                name={"casa-municipio"}
                value={casaMunicipios}
                onChange={handleCasaMunicipiosOnChange}
                options={casaMunicipiosItens}
                label={"CASA LEGISLATIVA"}
                id={"casa-municipio"}
                focused={true}
                placeholder={"Selecione"}
              />
            </Grid>
          ) : null}

          {
            casaMunicipios ?
              <Grid item md={5} xs={12} lg={5}
                style={{
                  padding: `${isMobile ? "0px 0px 10px 0px" : "30px 30px 10px 6px"}`,
                }}
              >
                <SelectTextFieldA
                  name={"posicionamento"}
                  value={posicionamento}
                  onChange={handlePosicionamentoOnChange}
                  options={posicionamentoItens}
                  label={"POSICIONAMENTO"}
                  id={"posicionamento"}
                  focused={true}
                  placeholder={"Selecione"}
                />
              </Grid>
              : null
          }

        </Grid>
        <Grid style={{ marginBottom: "32px" }}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          xs={12}
          style={{
            margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
          }}
        >
          <PieChartInfoInsight
            title="QUANTIDADE DE PROJETOS POR PARTIDO"
            subtitle={casaLabel}
            labels={
              listaPartidos.length > 0 ?
                [
                  {
                    key: listaPartidos[0] ? listaPartidos[0].siglaPartido : null,
                    data: listaPartidos[0] ? partido_1 : null,
                    color: listaPartidos[0] ? `${pallet.color.charts.blue}` : null,
                    url: listaPartidos[0] ? '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"partido","label":"PARTIDO: ' + listaPartidos[0]?.siglaPartido + '","value":"' + listaPartidos[0]?.siglaPartido + '"}]' : null


                  },
                  {
                    key: listaPartidos[1] ? listaPartidos[1]?.siglaPartido : null,
                    data: listaPartidos[1] ? partido_2 : null,
                    color: listaPartidos[1] ? `${pallet.color.charts.green}` : null,
                    url: listaPartidos[1] ? '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"partido","label":"PARTIDO: ' + listaPartidos[0]?.siglaPartido + '","value":"' + listaPartidos[1]?.siglaPartido + '"}]' : null

                  },
                  {
                    key: listaPartidos[2] ? listaPartidos[2]?.siglaPartido : null,
                    data: listaPartidos[2] ? partido_3 : null,
                    color: listaPartidos[2] ? `${pallet.color.charts.purple}` : null,
                    url: listaPartidos[2] ? '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"partido","label":"PARTIDO: ' + listaPartidos[0]?.siglaPartido + '","value":"' + listaPartidos[2]?.siglaPartido + '"}]' : null

                  },
                  {
                    key: listaPartidos[3] ? listaPartidos[3]?.siglaPartido : null,
                    data: listaPartidos[3] ? partido_4 : null,
                    color: listaPartidos[3] ? `${pallet.color.charts.yellow}` : null,
                    url: listaPartidos[3] ? '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"partido","label":"PARTIDO: ' + listaPartidos[0]?.siglaPartido + '","value":"' + listaPartidos[3]?.siglaPartido + '"}]' : null

                  },
                  {
                    key: listaPartidos[4] ? listaPartidos[4]?.siglaPartido : null,
                    data: listaPartidos[4] ? partido_5 : null,
                    color: listaPartidos[4] ? `${pallet.color.charts.red}` : null,
                    url: listaPartidos[4] ? '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"partido","label":"PARTIDO: ' + listaPartidos[0]?.siglaPartido + '","value":"' + listaPartidos[4]?.siglaPartido + '"}]' : null

                  },
                  {
                    key: listaPartidos[5] ? 'Outros' : null,
                    data: listaPartidos[5] ? outros : null,
                    color: listaPartidos[5] ? `${pallet.color.charts.lightGrey}` : null,
                    url: '/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"dataInicio","label":"DATA APRESENTAÇÃO INICIAL: ' +
                      formatDate(props.dtIni, "dd/MM/yyyy") +
                      '","value" : "' +
                      formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                      '"},{"key":"dataFinal","label":"DATA APRESENTAÇÃO FINAL: ' +
                      formatDate(props.dtFin, "dd/MM/yyyy") +
                      '","value" : "' +
                      formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                      '"},{"key":"removepartidos","label":"OUTROS PARTIDOS","value":"' + listaPartidos.filter((a, b) => b < 5).map((item) => {
                        return (
                          item.siglaPartido
                        )
                      }).join(',') + '"},{"key":"casaInterlocutor","label":"CASA: ' + casaLabel + '","value":"' + casaMunicipios + '"},{"key":"posicionamento","label":"POSICIONAMENTO: ' + posicionamento + '","value":"' + posicionamentoValor(posicionamento) + '"}]',

                  },
                ]
                :
                []
            }
          />
        </Grid>

        {listaPartidos.length > 0 ?
          <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
            <Title container style={{
              borderTop: '1px solid #EAEAEA',
              borderLeft: '1px solid #EAEAEA',
              borderBottom: '1px solid #EAEAEA',
              borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
              borderRadius: '6px 0px 0px 0px'
            }}
              item lg={6} xs={6}>
              PARTIDOS
            </Title>
            <Title container item lg={6} xs={6}
              style={{
                borderTop: '1px solid #EAEAEA',
                borderRight: '1px solid #EAEAEA',
                borderBottom: '1px solid #EAEAEA',
                borderRadius: '0px 6px 0px 0px'
              }}>
              PROJETOS
            </Title>
            <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
              {listaPartidos?.map((x, i) => {
                return (
                  <>
                    <Grid lg={6} xs={6} style={{
                      paddingLeft: `${isMobile ? "5px" : "24px"}`,
                      paddingTop: `${isMobile ? "5px" : "15px"}`,
                      borderLeft: '1px solid #EAEAEA',
                      borderRight: '1px solid #EAEAEA',
                      borderBottom: '1px solid #EAEAEA',
                      borderRadius: i + 1 !== listaPartidos.length ? null : '0px 0px 0px 6px',
                      maxWidth: 'none',
                      wordBreak: 'break-word',
                      placeContent: `${isMobile ? 'center' : 'unset'}`

                    }}>
                      <Grid container item
                        onClick={() => navigate('/interlocutor?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"partido","label":"PARTIDO: ' + x.siglaPartido + '","value":"' + x.siglaPartido + '"}]')}

                        style={{
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size12}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                          placeContent: `${isMobile ? 'center' : 'unset'}`
                        }}
                      >
                        {x.siglaPartido}

                      </Grid>
                      <Grid container style={{ paddingBottom: '10px', placeContent: `${isMobile ? 'center' : 'unset'}` }}
                      >
                        <ChipStyle pallet={pallet}>
                          <Grid container item >
                            <DotStyle bg={tagDiscursoCor(x.qualificacao)}>
                            </DotStyle>
                            <Grid >
                              {x.qualificacao ? x.qualificacao : "Não classificado"}
                            </Grid>
                          </Grid>
                        </ChipStyle>
                      </Grid>
                    </Grid>
                    <Grid container item lg={6} xs={6}

                      style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== listaPartidos.length ? null : '0px 0px 6px 0px',
                        color: `${pallet.textColorTertiary}`,
                        fontFamily: `${pallet.general.fontFamily}`,
                        fontSize: `${pallet.general.size12}`,
                        fontWeight: pallet.general.weight_600,
                        placeContent: `${isMobile ? 'center' : 'unset'}`
                      }}>
                      <Grid onClick={() => navigate('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Municipal","value":"Municipal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                        formatDate(props.dtIni, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                        '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                        formatDate(props.dtFin, "dd/MM/yyyy") +
                        '","value" : "' +
                        formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                        '"},{"key":"posicionamento","label":"POSICIONAMENTO: ' + posicionamento + '","value":"' + posicionamentoValor(posicionamento) + '"},{"key":"partido","label":"PARTIDO: ' + x.siglaPartido + '","value":"' + x.siglaPartido + '"},{"key":"casaInterlocutor","label":"CASA: ' + casaLabel + '","value":"' + casaMunicipios + '"}]')}
                        style={{ cursor: "pointer", padding: "5px", inlineSize: "fit-content" }}>
                        {x.count}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>

          :
          null
        }
      </Grid>
    </>
  );
}

export default AccordionContentKey22;
