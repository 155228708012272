import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import ComboBox from "../../../components/ComboBox";
import { usePallet } from "../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../models/IListInfo';
import EmptyError from '../../../components/EmptyError';
import PaginationSaveState from '../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
// import TemaCorporativoCard from "./TemaCorporativoCard";
import { IFilterData } from "../../../components/filter/ListFilter";
import TCService from "../../../services/TCService";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import UsuarioService from "../../../services/UsuarioService";
import GerDepartamentosCadastro from "./GerDepartamentosCadastro";
import GerDepartamentoCard from "./GerDepartamentoCard";
import useStyles from "../../../components/layout/Styles";
import SearchIcon from '@mui/icons-material/Search';

//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;

export default function GerUsuariosTabsDepartamentos(props: { id_user: number, index: number, activeTab: number,onChange: () => void }) {
  const { pallet } = usePallet();
  const navigate = useNavigate();
  const classes = useStyles();
  //## Paginação
  const [infoList, setInfoList] = useState<IListInfo>({
    pageIndex: 1,
    pageSize: 8,
    pageItens: 0,
    totalItens: 0,
    totalPages: 0,
  });
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const filterName = 'TemasRelacionadosListCurrentPage';
  // const filterItens = [
  //   { key: "visualizacao", label: "Tudo", value: "T" },
  //   { key: "visualizacao", label: "Relacionados", value: "R" },
  //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
  // ]

  //## Dados
  const [lista, setLista] = useState([]);
  const [listaDescricao, setListaDescricao] = useState<string[]>([]);
  const [busca, setBusca] = useState<IFilterData[]>();
  const [descricaoParcial, setDescricaoParcial] = useState<string>("");

  useEffect(() => {
    if (descricaoParcial && descricaoParcial.trim().length > 2) {
      UsuarioService.GetDepartamentosGer(currentPageSize, currentPage, JSON.stringify([{ key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
        .then(async res => {
          if (res) {
            const data = [];

            res.data.data.map(item => data.push({
              key: item.id,
              label: item.descricao,
              value: item.id
            }))

            setListaDescricao(data.map(item => { return item.label }));
            // setListaDescricao(() => [...data]);
            setBusca(() => [...data]);
          }
        });
    } else if (descricaoParcial === "") {
      loadGrupoList();
    }
  }, [descricaoParcial]);

  useEffect(() => {
    if (currentPage > 0) loadGrupoList();
  }, [currentPage, currentPageSize]);

  const loadGrupoList = () => {
    const f =  [];

    if (descricaoParcial && descricaoParcial.trim() !== "") {
      f.push({ key: 'descricaoParcial', label: 'Descrição', value: descricaoParcial });
      //==>setNome(() => undefined)
    }

    UsuarioService.GetDepartamentosGer(currentPageSize, currentPage,JSON.stringify(f)) // force break
      .then(async (res) => {        
        if (res) {
          setLista(res.data.data);
          setInfoList(res.listInfo);
        }
      });
  };

  //## Funções'
  const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageSize(Number(event.target.value));
  };

  const handleOnChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter: IFilterData = JSON.parse(event.target.value);
    setFilter(selectedFilter);
    setCurrentFilter([...currentFilter.filter(x => x.key !== selectedFilter.key), selectedFilter]);
  };
  const [value, setValue] = React.useState('');

  const pressBuscaUsuario = (event: any) => {
    if (event?.key === 'Enter') {
      if(event.target.value) {
  
        UsuarioService.GetDepartamentosGer(currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "Descrição", value: event.target.value }]))
        .then(async res => {
          if (res) {
            setLista(res.data.data);
            setInfoList(res.listInfo);
            
          }
        });
  
      }else{
  
        UsuarioService.GetDepartamentosGer(currentPageSize, currentPage, '') // force break --JSON.stringify(currentFilter)
        .then(async (res) => {
          if (res) {
            setLista(res.data.data);
            setInfoList(res.listInfo);
          }
        });
  
      }
    }
  }

  const handleOnSelectDescricaoParcial = async (tema: string) => {

    const selecao = busca.filter(x => x.label === tema);
    if (selecao.length) {
      //navigate(`/tc/grupo/${selecao[0].value}`);

      //Filtra por id

      await UsuarioService.GetDepartamentoParcial(selecao[0].value).then(res => {
        //setChecked(res?.data[0].grupo_ativo)
        //setNome(res?.data[0].grupo_descricao)
        //setIdRegistro(res?.data[0].grupo_id)

        if (res) {
          setLista(res.data);
        //   setInfoList(res.listInfo);
        }
      });


    }
  };

  return (
    <>
      <ContainerHead container>
        <Grid container>          
        </Grid>
        <Grid
          style={{
            width: isMobile ? "60%" : "100%",
          }}
        />
                <Grid container direction="row" alignItems="baseline" style={{
          paddingLeft: "44px",
          paddingTop: `${isMobile ? "10px" : "0px"}`,
          marginBottom: `${isMobile ? "-10px" : "0px"}`
        }} >
          <Grid container item xs={6}>
            <Grid style={{ marginRight: "8px" }}>
              <Grid style={{
                color: `${pallet.textColorTertiary}`,
                fontWeight: pallet.general.weight_600
              }}>
                {infoList?.totalItens}
              </Grid>
            </Grid>

            <Grid>
              <Grid item style={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontSize: "14px",
                fontWeight: pallet.general.weight_600,
                paddingTop: "2px"
              }}>
                {infoList?.totalItens > 1 ? "REGISTROS" : "REGISTRO"} <span style={{
                  color: `${pallet.textColorTertiary}`,
                  fontWeight: pallet.general.weight_600
                }}> </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" style={{ paddingRight: `${isMobile ? "21px" : "39px"}` }}>
              <Grid item>
                {/* <ParlamentarMenuFiltroTipoComissao title={"Cadastrar"} onChange={setFilter} icon={<Visibility />} /> */}
               
                <GerDepartamentosCadastro />
                {/* <GerDepartamentosCadastro/> */}
               

              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Grid style={{ width: "100%", margin: "20px 40px" }}>
          {/* <ComboBox
            name="filtroDepartamento"
            itens={listaDescricao}
            onChange={setDescricaoParcial}
            onSelect={handleOnSelectDescricaoParcial}
            placeholder="Buscar"
          /> */}


            <TextField
                  // placeholder={`Digite${selectedInput === 'buscaGeral' ? ' + ENTER' : ' a proposição + ENTER'}`}
                  placeholder={`Digite + ENTER`}
                  fullWidth
                  variant="outlined"
                  defaultValue={value}
                  onKeyPress={pressBuscaUsuario}
                  style={{ width: isMobile ? '250px' : '400px', display: 'inline-block' }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    },
                    style: {
                      fontFamily: `${pallet.general.fontFamily}`,
                      fontSize: `${pallet.general.size14}`,
                      fontWeight: pallet.general.weight_100,
                      background: `${pallet.backgroundColorSecondary}`
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
        </Grid>
      </ContainerHead>
      <ContainerBody container style={{ marginTop: "20px" }}>
        { lista?.map((item) => (
            <GerDepartamentoCard
            departamento_id={item.id}
            departamento_descricao={item.descricao}
            onChange={loadGrupoList}

            />
          ))}
        {!lista.length && (
          <PaperContent pallet={pallet}>
            <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
          </PaperContent>
        )}
      </ContainerBody>
      <ContainerFooter container>
        <PaginationSaveState
          name={filterName}
          pages={infoList?.totalPages}
          page={currentPage}
          onChange={setCurrentPage}
        />
      </ContainerFooter>
    </>
  )
}