import React from "react";
import Resizer from "react-image-file-resizer";
import Grid from "@material-ui/core/Grid";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import EditIcon from '@mui/icons-material/Upload';
import blankAvatar from '../img/avatar_7.jpeg';
import UploadFilesService from "../services/UploadFiles.service";
import CircularLoading from '../components/CircularLoading';
import { isMobile } from "react-device-detect";




const resizeFile = (file): Promise<any> =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            90,
            0,
            (uri) => { resolve(uri); },
            "base64",
            300,
            300
        );
    });


const Imagem = (props: { src: any, pallet: any }) => {
    return (
        <Grid container justifyContent="center">
            <div style={{
                borderRadius: "50%",
                width: "135px",
                height: "135px",
                overflow: "hidden",
                position: "relative",
                border: `3px solid ${props.pallet.color.colorBase.white}`,
                backgroundColor: `${props.pallet.color.colorBase.white}`,
                boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
            }}>
                <img src={props?.src ? props.src : blankAvatar} alt="imagem" style={{
                    height: "180px",
                    objectFit: "cover",
                    marginLeft: `${props.src ? "0px" : "-65px"}`,
                    marginTop: `${props.src ? "0px" : "-45px"}`,
                    opacity: `${props.src ? "100%" : "70%"}`,
                }} />
            </div>
        </Grid>
    )
}


function ImageLoader(props: { canEdit: boolean, pallet: any, defaultSrc?: string, src?: string, onLoad?: (image: any) => void }): JSX.Element {
    const [file, setFile] = React.useState<any>()
    const [imagem, setImagem] = React.useState<any>()
    const [loading, setloading] = React.useState(false)

    React.useEffect(() => {
        if (props?.src && props?.src !== '') {
            setloading(true)
            if (props?.src.startsWith('http')) {
                setImagem(props.src && props.src !== '' ? props.src : props.defaultSrc);
                setloading(false)
            } else {
                UploadFilesService
                    .downloadStream(props.src).then(img => {
                        if (img) setImagem(URL.createObjectURL(img));
                    });
                setloading(false)
            }
        }
    }, [props.src])

    React.useEffect(() => {
        if (file) {
            setImagem(URL.createObjectURL(file));
            if (props.onLoad) props.onLoad(file)
        }
    }, [file])

    const handleAddFile = async (event: any) => {
        if (event && event.target && event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            const newImage = await fetch(image)
            const blob = await newImage.blob();
            const newFile = new File([blob], file.name.replace(/\.[^.]+$/, '.jpg'), { type: "image/jpeg", lastModified: Date.now() })
            setFile(() => newFile)
        }
    }

    const canEdit = () => {
        return (
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <EditIcon style={{
                        backgroundColor: `${props.pallet.backGroundPageColorPrimary}`,
                        borderRadius: "50%",
                        padding: "3px",
                        border: "3px solid white",
                        fontSize: "30px",
                        color: "grey"
                    }} />
                }
            >
                <section className="one-fourth" id="html">
                    <Grid>
                        <Imagem src={imagem} pallet={props.pallet} />
                    </Grid>
                </section>
            </Badge>
        )
    }

    const canNotEdit = () => {
        return (
            <section className="one-fourth" id="html">
                <Grid>
                    <Imagem src={imagem} pallet={props.pallet} />
                </Grid>
            </section>
        )
    }

    return (
        <>
            {loading ?
                <CircularLoading
                    size={100}
                    top={isMobile ? "37%" : "40%"}
                    left={isMobile ? "6%" : "16%"}
                    zIndex={2}
                />
                :
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {props?.canEdit ?
                            <label htmlFor="btn-addfiles">
                                {props.canEdit && <input
                                    id="btn-addfiles"
                                    name="btn-addfiles"
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleAddFile}
                                />}
                                {canEdit()}
                            </label>
                            :
                            <label>
                                {props.canEdit && <input
                                    id="btn-addfiles"
                                    name="btn-addfiles"
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleAddFile}
                                />}
                                {canNotEdit()}
                            </label>
                        }
                    </Stack>
                </Grid>
            }
        </>
    );
}

export default ImageLoader;