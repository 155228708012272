import { useCallback, useEffect, useState } from "react";
import { Button, Divider, Grid, Icon, Paper, } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { usePallet } from '../../../../contexts/PalletContext';
import PaginationSaveState from "../../../../components/PaginationSaveState";
import { IListInfo } from "../../../../models/IListInfo";
import { Visibility } from "@material-ui/icons";
import RefreshIcon from '@mui/icons-material/Refresh';
import { IFilterData } from "../../../../components/filter/ListFilter";
import ProposicaoService from "../../../../services/ProposicaoService";
import styled from "styled-components";
import EmptyError from "../../../../components/EmptyError";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import EmptyErrorDocCarousel from "../../../../components/EmptyErrorDocCarousel";
import AccordionTextDataEmendaProposicao from "./AccordionTextDataCorrelataProposicao";
import CircularLoading from '../../../../components/CircularLoading';
import FilterQuantity from "../../../../components/filter/FilterQuantity";
import AccordionTextDataCorrelataProposicao from "./AccordionTextDataCorrelataProposicao";
import ProposicaoMenuFiltroCorrelatas from "./ProposicaoMenuFiltroCorrelatas";
import EmptyErrorCorrelata from "../../../../components/EmptyErrorCorrelata";
import { useAlert } from "../../../../contexts/AlertContext";


const PaperContent = styled(Paper, isMobile)`
	color: ${(props) => props.pallet.textColorQuaternary};
	font-size: ${(props) => props.pallet.fontSize.titleWidgets};
	font-family: ${(props) => props.pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 10%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;
`;

export default function ProposicaoCorrelatas(props: { id_proposicao: number, index: number, activeTab: number }) {
    const { pallet } = usePallet();
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [filtro, setFiltro] = useState<IFilterData[]>([{ key: 'posicionamento', label: 'Posicionamento', value: 'T' }]);
    const [opfiltro, setOpfiltro] = useState("Tudo");
    const [loading, setLoading] = useState(true);
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };

    const { NewAlert } = useAlert();

    // useEffect(() => {
    //     getList()
    //     // eslint-disable-next-line
    // }, [currentPage, filtro])

    useEffect(() => {
        if (currentPage > 0)
            getList()

    }, [currentPage, currentPageSize, filtro]);

    const gerarCorrelatas = () => {
        setLoading(true);
        ProposicaoService.GerarCorrelata(props.id_proposicao).then(res => {
            setLoading(false);
            if (res > 0) {
                getList()
            } else {
                NewAlert('error', 'Para essa proposição não existem informações suficientes para gerar correlatas !');
            }
        }
        )
        //setLoading(false);
    }

    const getList = useCallback(() => {
        setLoading(true);
        const filter = filtro.map(item => { return { key: item.key, value: item.value } });

        if (currentPage && currentPage > 0) {
            ProposicaoService.GetListCorrelata(props.id_proposicao, filter, currentPage, currentPageSize).then(async res => {
                if (res && res.success) {
                    setLista(res.listData);
                    setInfoList(res.listInfo);
                }
                setLoading(false);
            })
        }
        setLoading(false);
    }, [props.id_proposicao, currentPage, currentPageSize, filtro])

    const descTipo = (value: string) => {
        switch (value) {
            case "T": return "Tudo";
            case "C": return "Contrário";
            case "E": return "Contrário com Emenda";
            case "N": return "Neutro";
            case "A": return "Favorável com Emenda";
            case "F": return "Favorável";
            case "P": return "Pendente";
            case "I": return "Indefinido";
            default: return "Tudo";
        }
    }
    //const setPage = (p: number) => setCurrentPage(p);

    const setFilter = (f: any) => {
        setOpfiltro(descTipo(f.target.value));
        setFiltro([{ key: f.target.name, label: f.target.name, value: f.target.value }]);
    }


    const handleChangeNovaCorrelata = () => {
        getList();
    };

    return (

        <div style={{ minHeight: "600px" }}>

            {
                loading === true

                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}

                    />

                    :
                    infoList?.totalNoFilter === 0
                        ?
                        <Grid style={{ marginTop: isMobile ? "0px" : "-30px" }}>
                            <PaperContent pallet={pallet}>
                                <EmptyErrorCorrelata title="Você ainda não gerou nenhuma correlata, comece gerando agora !!!"
                                    id_proposicao={props.id_proposicao}
                                    onChange={getList}
                                />
                            </PaperContent>
                        </Grid>
                        :
                        null
            }

            {
                loading === true

                    ?

                    <CircularLoading
                        size={80}
                        top={"90%"}
                        left={"50%"}
                        zIndex={2}

                    />

                    :

                    infoList?.totalNoFilter > 0 ?

                        <Grid style={{ paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "-30px"}` }}>

                            <Grid item xl={12} lg={12} xs={12} >

                                <Grid container item alignItems="flex-start" style={{
                                    paddingLeft: `${isMobile ? "10px" : "30px"}`,
                                    paddingRight: `${isMobile ? "10px" : "30px"}`,
                                    paddingTop: `${isMobile ? "10px" : "30px"}`,
                                    marginBottom: `${isMobile ? "-10px" : "10px"}`
                                }} >
                                    <Grid container item alignItems="baseline" xs={12} >
                                        <Grid xs={6} container item alignItems="baseline">
                                            <Grid item style={{
                                                marginRight: `${isMobile ? "8px" : "8px"}`,
                                                paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                            }}>
                                                <Grid item style={{
                                                    fontSize: "18px",
                                                    color: `${pallet.textColorTertiary}`,
                                                    fontWeight: pallet.general.weight_600
                                                }}>
                                                    {infoList?.totalItens}
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <Grid item style={{
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: "14px",
                                                    fontWeight: pallet.general.weight_600,
                                                    paddingTop: `${isMobile ? "20px" : "15px"}`,
                                                }}>
                                                    {infoList?.totalItens > 1 ? "CORRELATAS" : "CORRELATA"} [ {opfiltro} ]
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid xs={6} container item justifyContent="flex-end" >
                                            <Grid item>
                                                <Grid style={{ paddingRight: `${isMobile ? "15px" : "15px"}`, paddingTop: `${isMobile ? "10px" : "0px"}`, paddingLeft: `${isMobile ? "0" : "0px"}` }}>
                                                    <ProposicaoMenuFiltroCorrelatas title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                </Grid>
                                            </Grid>
                                            {/* Gerar Correlatas */}
                                            <Grid item>
                                                <Grid style={{ paddingRight: `${isMobile ? "20px" : "20px"}`, paddingTop: `${isMobile ? "10px" : "0px"}`, paddingLeft: `${isMobile ? "0" : "0px"}` }}>
                                                    <Button
                                                        onClick={gerarCorrelatas}
                                                        variant="contained"
                                                        size="large"
                                                        style={{
                                                            textTransform: 'uppercase',
                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                            fontSize: `${pallet.general.size12}`,
                                                            fontWeight: pallet.general.weight_300,
                                                            border: "none",
                                                            backgroundColor: `${pallet.backgroundColorPrimary}`,
                                                            color: `${pallet.textColorPrimary}`
                                                        }}
                                                        startIcon={<RefreshIcon style={{
                                                            color: `${pallet.textColorPrimary}`
                                                        }}
                                                        />}
                                                    >
                                                        GERAR CORRELATAS
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {infoList?.totalItens > 4 ?
                                            <Grid item xs={12} style={{ marginTop: "5px", marginBottom: "20px", paddingLeft: `${isMobile ? "20px" : "20px"}` }}>
                                                <FilterQuantity
                                                    list={infoList}
                                                    currentPageSize={currentPageSize}
                                                    onChange={handleOnChangeItensPerPageSize}
                                                />
                                            </Grid>
                                            : null}
                                    </Grid>
                                    <Grid style={{
                                        width: "100%",
                                        paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                        paddingRight: `${isMobile ? "20px" : "20px"}`,
                                        paddingTop: "20px",
                                        paddingBottom: `${isMobile ? "20px" : "40px"}`
                                    }}
                                    >
                                        <AccordionTextDataCorrelataProposicao
                                            lista={lista}
                                            onChange={getList}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                                    <PaginationSaveState
                                        name={'ParlamentarCorrelataList'}
                                        pages={infoList?.totalPages}
                                        page={currentPage}
                                        onChange={setCurrentPage}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        :
                        null
            }

        </div>
    )
}