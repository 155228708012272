import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import SelectTextField from "../../../components/SelectTextField";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import { IProposicao } from "../../../models/Proposicao.Model";
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import { IFilterData } from "../../../components/filter/ListFilter";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IListFilterInputItem } from "../../../components/filter/ListFilterItems";
import { format } from "date-fns";
import InsightService from "../../../services/InsightService";

export function AccordionContentKey15(props: {
  proposicao?: IProposicao;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string,
  onChange?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const [error, setError] = useState<Array<{ name: string; err: string }>>([]);

  const [tempoMedioG, setTempoMedioG] = React.useState<number>(0);
  const [tempoMedioT, setTempoMedioT] = React.useState<number>(0);
  const [tempoMedioS, setTempoMedioS] = React.useState<number>(0);

  const [casaEstados, setCasaEstados] = React.useState<number>();
  const [casaEstadosItens, setCasaEstadosItens] = React.useState<Array<IListFilterInputItem>>([])

  const [grupo, setGrupo] = React.useState<string>("");
  const [grupoItens, setGrupoItens] = React.useState([]);
  const [tema, setTema] = React.useState<string>("");
  const [temaItens, setTemaItens] = React.useState([]);
  const [subtema, setSubtema] = React.useState<string>("");
  const [subTemaItens, setSubTemaItens] = React.useState([]);

  const [grupoLabel, setGrupoLabel] = React.useState<string>();
  const [temaLabel, setTemaLabel] = React.useState<string>();
  const [subtemaLabel, setSubtemaLabel] = React.useState<string>();

  const [loading, setLoading] = useState(false);

  const getItens = (
    data: { id: number; descricao: string }[]
  ): { value: string; label: string }[] => {
    const ret = data?.map((item) => {
      return {
        value: String(item.id),
        label: item.descricao,
      };
    });
    return ret;
  };

  React.useEffect(() => {

    TabelasService.getTabelaCasasEstaduais()
      .then(async res => {
        const data = []
        data.push({ key: "", label: 'Selecione', value: "" });
        res?.map(item => data.push({ key: item.id, label: item.nome, value: item.id }))
        setCasaEstadosItens(() => [...data]);
      });
    // }
  }, []);

  React.useEffect(() => {
    TabelasService.getProposicaoGrupoTemas().then((resII) => {
      const dataII = getItens(resII);
      // setLoading(false)
      setGrupoItens(dataII);
    });
  }, []);

  React.useEffect(() => {
    if (grupo) {
      TabelasService.getProposicaoTemasByGrupo(Number(grupo)).then((res) => {
        const data = getItens(res);
        setTemaItens(data);
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getProposicaoSubTemasByTema(Number(tema)).then((res) => {
        const data = getItens(res);
        setSubTemaItens(data);
      });
    }
  }, [tema]);

  React.useEffect(() => {
    if (grupo) {
      TabelasService.getDescricaoGrupos(Number(grupo)).then((res) => {
        setGrupoLabel("")
        setGrupoLabel(res[0].descricao)
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getDescricaoTemas(Number(tema)).then((res) => {
        setTemaLabel("")
        setTemaLabel(res[0].descricao)
      });
    }
  }, [tema]);

  React.useEffect(() => {
    if (subtema) {
      TabelasService.getDescricaoSubtemas(Number(subtema)).then((res) => {
        setSubtemaLabel("")
        setSubtemaLabel(res[0].descricao)
      });
    }
  }, [subtema]);

  const onChangeGrupo = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setGrupo(event.target.value);
      setTema("");
      setSubtema("");
      // setListaTemas([])
      // setListaSubtemas([])
    }
  };

  const onChangeTema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setTema(event.target.value);
      setSubtema("");
      // setListaSubtemas([])
    }
  };

  const onChangeSubtema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setSubtema(event.target.value);
    }
  };

  React.useEffect(() => {
    if (grupo) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey15Grupo(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(casaEstados), Number(grupo),
        ).then((res) => {
          if (res) {
            setTempoMedioG(res.data.total)

          }
        });
      }
    }
    if (tema) {
      if (tema) {
        if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
          InsightService.GetKey15Tema(
            format(new Date(props.dtIni), "yyyy-MM-dd"),
            format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(casaEstados), Number(tema),
          ).then((res) => {
            if (res) {
              setTempoMedioT(res.data.total)

            }
          });
        }
      }
      if (subtema) {
        if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
          InsightService.GetKey15Subtema(
            format(new Date(props.dtIni), "yyyy-MM-dd"),
            format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(casaEstados), Number(subtema),
          ).then((res) => {
            if (res) {
              setTempoMedioS(res.data.total)

            }
          });
        }
      }
    }
  }, [casaEstados, grupo, tema, subtema, props.dtIni, props.dtFin]);


  const handleCasaEstadosOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCasaEstados(Number(event.target.value))
    setGrupo("");
    setTema("");
    setSubtema("");
  };

  return (
    <Grid>
      <Grid
        container
        style={{
          padding: "40px 0px 10px 0px",
          placeContent: "center",
          // flexDirection: 'column',
          // justifyContent: "space-around",
        }}
      >
        <Grid item md={5} xs={12} lg={5} style={{ padding: `${isMobile ? "0px 30px 20px 30px" : "0px"}`, placeContent: "center" }}>
          <SelectTextFieldA
            name={"casaEstados"}
            value={casaEstados}
            onChange={handleCasaEstadosOnChange}
            options={casaEstadosItens}
            label={"CASA LEGISLATIVA"}
            id={"casaEstados"}
            focused={true}
            placeholder={"Selecione"}
          />
        </Grid>
        {casaEstados ?
          <Grid container
            style={{
              padding: `${isMobile ? "0px 30px 0px 30px" : "30px 30px 20px 10px"}`,
              placeContent: "center"
            }} >
            <Grid
              md={4}
              xs={12}
              lg={4}
            >
              <SelectTextFieldA
                id={"grupo"}
                label="GRUPO"
                options={grupoItens}
                value={grupo}
                onChange={onChangeGrupo}
                focused={true}
                placeholder={"Selecione"}
              />
            </Grid>
          </Grid>
          : null}
        <Grid container style={{ placeContent: "center" }} >
          {grupo ?
            <Grid
              container
              xs={12}
              style={{ padding: `${isMobile ? "20px 30px 20px 30px" : "0px 30px 0px 30px"}` }}
            >
              <Grid
                container
                item
                direction={"row"}
                style={{
                  padding: "20px",
                  color: `${pallet.textColorTertiary}`,
                  fontFamily: `${pallet.general.fontFamily}`,
                  fontSize: `${pallet.general.size14}`,
                  placeContent: "center",
                  backgroundColor: "#F9F5FE",
                  borderRadius: "5px"
                }}
              >

                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                  TEMPO MÉDIO DE TRAMITAÇÃO DAS PROPOSIÇÕES DO GRUPO - {grupoLabel} :
                </Grid>&nbsp;&nbsp;
                <Grid>{tempoMedioG} dias</Grid>
              </Grid>
            </Grid> : null
          }
        </Grid>
        {
          <>
            {grupo ?
              <Grid
                style={{
                  padding: `${isMobile ? "0px 30px 0px 30px" : "30px 30px 20px 10px"}`,
                }}
                item
                md={4}
                xs={12}
                lg={4}
              >
                <SelectTextFieldA
                  id={"tema"}
                  name="tema-select"
                  label="TEMA"
                  options={temaItens}
                  value={tema}
                  onChange={onChangeTema}
                  focused={true}
                  placeholder={"Selecione"}
                />
              </Grid>
              : null
            }
          </>
        }
        {tema ?
          <Grid
            container
            xs={12}
            style={{ padding: `${isMobile ? "20px 30px 20px 30px" : "0px 30px 0px 30px"}` }}
          >
            <Grid
              container
              item
              direction={"row"}
              style={{
                // marginRight: "30px",
                // marginLeft: "30px",
                padding: "20px",
                color: `${pallet.textColorTertiary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size14}`,
                placeContent: "center",
                backgroundColor: "#F9F5FE",
                borderRadius: "5px"
              }}
            >

              <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                TEMPO MÉDIO DE TRAMITAÇÃO DAS PROPOSIÇÕES DO TEMA -  {temaLabel} :
              </Grid>&nbsp;&nbsp;
              <Grid>{tempoMedioT} dias</Grid>
            </Grid>
          </Grid> : null}
        {
          <>
            {tema ?
              <Grid
                style={{ padding: `${isMobile ? "0px 30px 0px 30px" : "30px 30px 20px 10px"}` }}
                item
                md={4}
                xs={12}
                lg={4}
              >
                <SelectTextFieldA
                  id={"subtema"}
                  label="SUBTEMA"
                  options={subTemaItens}
                  value={subtema}
                  onChange={onChangeSubtema}
                  focused={true}
                  placeholder={"Selecione"}
                />
              </Grid>
              : null
            }
          </>
        }
      </Grid>


      {
        subtema ?
          <Grid
            container
            xs={12}
            style={{ padding: `${isMobile ? "20px 30px 20px 30px" : "0px 30px 20px 30px"}` }}
          >
            <Grid
              container
              item
              direction={"row"}
              style={{
                // marginRight: "30px",
                // marginLeft: "30px",
                padding: "20px",
                color: `${pallet.textColorTertiary}`,
                fontFamily: `${pallet.general.fontFamily}`,
                fontSize: `${pallet.general.size14}`,
                placeContent: "center",
                backgroundColor: "#F9F5FE",
                borderRadius: "5px"
              }}
            >

              <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                TEMPO MÉDIO DE TRAMITAÇÃO DAS PROPOSIÇÕES DO SUBTEMA - {subtemaLabel} :
              </Grid>&nbsp;&nbsp;
              <Grid>{tempoMedioS} dias</Grid>
            </Grid>
          </Grid> : null
      }
    </Grid >
  );
}

export default AccordionContentKey15;
