import { Avatar, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import ComboBox from "../../../components/ComboBox";
import { usePallet } from "../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconLabelButtons from '../../../components/ButtonIconSalvar';
import { IListInfo } from '../../../models/IListInfo';
import EmptyError from '../../../components/EmptyError';
import PaginationSaveState from '../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
import TemaCorporativoCard from "../../temas_corporativos/_components/Grupo/TemaCorporativoCard";
import { IFilterData } from "../../../components/filter/ListFilter";
import TCService from "../../../services/TCService";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import ParlamentarMenuFiltroTipoComissao from "../../../components/ParlamentarMenuFiltroTipoComissao";
import FilterQuantity from "../../../components/filter/FilterQuantity";
import { Visibility } from "@material-ui/icons";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UsuarioService from "../../../services/UsuarioService";
import GerUsuariosMenuFiltroAtivoInativo from "./GerUsuariosMenuFiltroAtivoInativo";
import EditIcon from '@mui/icons-material/Edit';
import GerUsuariosCadastro from "./GerUsuariosCadastro";
import GerDepartamentosCadastro from "./GerDepartamentosCadastro";
import GerUsuariosEdicaoLista from "./GerUsuariosEdicaoLista";
import ImageLoaderList from "../../../components/ImageLoaderList";


//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;
export default function GerDepartamentoTabsUsuarios(props: { id_dep: number }) {
  //export default function TCTemasCorporativosGrupoList() {
  const { pallet } = usePallet();
  const navigate = useNavigate();

  //## Paginação
  const [infoList, setInfoList] = useState<IListInfo>({
    pageIndex: 1,
    pageSize: 8,
    pageItens: 0,
    totalItens: 0,
    totalPages: 0,
  });
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState([{ key: "situacao", label: "Todos", value: "T" }]);
  //const [filtro, setFiltro] = useState<string>('T');
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

  const filterName = 'TemasRelacionadosListCurrentPage';
  // const filterItens = [
  //   { key: "visualizacao", label: "Tudo", value: "T" },
  //   { key: "visualizacao", label: "Relacionados", value: "R" },
  //   { key: "visualizacao", label: "Não Relacionados", value: "NR" },
  // ]

  //## Dados
  const [lista, setLista] = useState([]);
  const [listaDescricao, setListaDescricao] = useState<string[]>([]);
  const [busca, setBusca] = useState<IFilterData[]>();
  const [descricaoParcial, setDescricaoParcial] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState();


  useEffect(() => {
    if (descricaoParcial && descricaoParcial.trim().length > 2) {
      UsuarioService.getUsuariosDep(props?.id_dep, currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "Descrição", value: descricaoParcial }]))
        .then(async res => {
          if (res) {
            const data = [];

            res.data.data.map(item => data.push({
              key: item.id,
              //label: item.descricao + (item.casa === 1 ? ' [Senado Federal]' : (item.casa === 2 ? ' [Câmara dos Deputados]' : '')),
              label: item.nome,
              value: item.id
            }))

            setListaDescricao(data.map(item => { return item.label }));
            // setListaDescricao(() => [...data]);
            setBusca(() => [...data]);
          }
        });
    } else if (descricaoParcial === "") {
      loadUserList();
    }
  }, [descricaoParcial]);

  const descFiltro = (value: string) => {
    switch (value) {
      case "T":
        return "[ Todos ]";
      case "I":
        return "[ Inativo ]";
      case "A":
        return "[ Ativo ]";
    }
  }

  const [opfiltro, setOpfiltro] = useState(descFiltro("T"));

  useEffect(() => {
    if (currentPage > 0) loadUserList();
  }, [currentPage, currentPageSize, currentFilter, filter]);

  const loadUserList = () => {

    //const filter = currentFilter ? currentFilter.map(item => { return { key: item.key, label: item.label, value: item.value } }) : [];    
    const f = filter ? filter.map(item => { return { key: item.key, label: item.label, value: item.value } }) : [];

    //==>const f = [{ key: 'tipo', value: filtro }]

    if (descricaoParcial && descricaoParcial.trim() !== "") {
      f.push({ key: 'descricaoParcial', label: 'Descrição', value: descricaoParcial });
      //==>setNome(() => undefined)
    }

    UsuarioService.getUsuariosDep(props?.id_dep, currentPageSize, currentPage, JSON.stringify(f)) // force break --JSON.stringify(currentFilter)
      .then(async (res) => {

        if (res) {
          setLista(res.data.data);
          setInfoList(res.listInfo);
        }
      });
  };

  //## Funções'
  const handleOnChangeItensPerPageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPageSize(Number(event.target.value));
  };

  const handleOnChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter: IFilterData = JSON.parse(event.target.value);
    //setFilter(selectedFilter);
    setCurrentFilter([...currentFilter.filter(x => x.key !== selectedFilter.key), selectedFilter]);
  };

  const handleOnSelectDescricaoParcial = async (descNome: string) => {
    const selecao = busca.filter(x => x.label === descNome);


    if (selecao.length) {
      //navigate(`/tc/grupo/${selecao[0].value}`);

      /////////////////////////////////
      //Filtra por Descricao Parcial      

      await UsuarioService.Get(Number(selecao[0].value)).then(res => {

        //setChecked(res?.data[0].grupo_ativo)
        //setNome(res?.data[0].grupo_descricao)
        //setIdRegistro(res?.data[0].grupo_id)

        if (res) {
          setLista(res);
          //setInfoList(res.listInfo);
        }
      });


    }
  };

  const setFiltro = (f: any) => {
    setFilter([{ key: 'visualizacao', label: 'Visualização', value: f.target.value }]);
    setOpfiltro(descFiltro(f.target.value));
  }


  const handleClose = () => {
    setAnchorEl(null);
    setOpen(undefined);
  };


  return (
    <>
      <ContainerHead container>
        {/* <Grid container>
        </Grid>
        <Grid
          style={{
            width: isMobile ? "90%" : "100%",
          }}
        /> */}

        <Grid container direction="row" alignItems="baseline" style={{
          paddingLeft: "44px",
          paddingTop: `${isMobile ? "10px" : "0px"}`,
          marginBottom: `${isMobile ? "-10px" : "0px"}`
        }} >
          <Grid container item xs={6}>
            <Grid style={{ marginRight: "8px" }}>
              <Grid style={{
                color: `${pallet.textColorTertiary}`,
                fontWeight: pallet.general.weight_600
              }}>
                {infoList?.totalItens}
              </Grid>
            </Grid>

            <Grid>
              <Grid item style={{
                fontFamily: `${pallet.fontFamily.general}`,
                fontSize: "14px",
                fontWeight: pallet.general.weight_600,
                paddingTop: "2px"
              }}>
                {infoList?.totalItens > 1 ? "REGISTROS" : "REGISTRO"} <span style={{
                  color: `${pallet.textColorTertiary}`,
                  fontWeight: pallet.general.weight_600
                }}> {opfiltro}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" style={{ paddingRight: `${isMobile ? "21px" : "39px"}` }}>
              <Grid item style={{ paddingRight: `${isMobile ? "5x" : "10px"}` }}>
                <GerUsuariosMenuFiltroAtivoInativo title={"Visualizar"} onChange={setFiltro} icon={<Visibility />} />
              </Grid>
              <Grid item style={{marginTop:isMobile ? "10px" : null}}>
                {/* <ParlamentarMenuFiltroTipoComissao title={"Cadastrar"} onChange={setFilter} icon={<Visibility />} /> */}

                <GerUsuariosCadastro onChange={loadUserList} />
                {/* <GerDepartamentosCadastro/> */}


              </Grid>
            </Grid>
          </Grid>

        </Grid>

        {/* <ContainerFilter style={{  }}>
          <FilterArea>
            <Grid style={{ width: `${isMobile ? "70%" : "65%"}`, display: "flex", alignItems: "center" }}>              
              <strong>
                <span style={{ color: pallet.general.color1 }}>
                  {infoList?.totalItens ? infoList?.totalItens : "1"}
                </span> Grupo(s)
              </strong>
            </Grid>
            <Grid style={{ width: `${isMobile ? "30%" : "35%"}`, display: "flex", justifyContent: "flex-end" }}>
              
              <ButtonIconSalvar
                onClick={() => navigate('/tc/cadastrar/G')}
                tipo={"cadastrar_grupo"}
                backgroundColor={pallet.backgroundColorPrimary}                                
                border={"1px solid lightgrey"}
                fontColor={pallet.textColorPrimary}
                fontSize={pallet.general.size12}
                icon={""}
                title={"CADASTRAR"}
              />

            </Grid>
          </FilterArea>
        </ContainerFilter> */}
        <Grid style={{ width: "100%", margin: "20px 40px" }}>
          <ComboBox
            name="filtroTCorporativo"
            itens={listaDescricao}
            onChange={setDescricaoParcial}
            onSelect={handleOnSelectDescricaoParcial}
            placeholder="Buscar"
          />
        </Grid>
      </ContainerHead>
      <ContainerBody container >



        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>NOME</TableCell>
                <TableCell></TableCell>
                <TableCell align="left">LOGIN</TableCell>
                <TableCell align="left">E-MAIL</TableCell>
                <TableCell align="left">UNIDADE DE NEGÓCIO</TableCell>
                <TableCell align="left">PERMISSÃO</TableCell>
                <TableCell align="left">STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {
                lista && lista.map((row, index) => (


                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left"><GerUsuariosEdicaoLista
                      reloadList={loadUserList}
                      id_usuario={row.id}
                      closeMenu={() => handleClose()}


                    /></TableCell>
                    <TableCell align="left">
                      <Grid style={{ marginLeft: `${isMobile ? "5px" : "20px"}` }}>
                        {/* <Avatar src={row.arquivo} /> */}
                        <ImageLoaderList
                          src={row.arquivo}
                          pallet={pallet}
                          canEdit={false}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">

                      {row.nome}
                    </TableCell>                    
                    <TableCell align="left">{row?.login}</TableCell>
                    <TableCell align="left">{row?.email}</TableCell>
                    <TableCell align="left">{row?.dep_nome}</TableCell>
                    <TableCell align="left">{row?.permissao}</TableCell>
                    <TableCell align="left">{(row?.status === true ? "Ativo" : "Inativo")}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {lista && !lista.length && (
          <PaperContent pallet={pallet}>
            <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
          </PaperContent>
        )}

      </ContainerBody>
      <ContainerFooter container>
        <PaginationSaveState
          name={filterName}
          pages={infoList?.totalPages}
          page={currentPage}
          onChange={setCurrentPage}
        />
      </ContainerFooter>
    </>
  )
}