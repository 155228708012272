import { EAnexoModulos } from "../components/Anexos";
import { download, get, del, upload, post } from "../helpers/http";

class UploadFilesService {
    async upload(files: Array<any>, modulo: EAnexoModulos, idReferencia: number, onUploadProgress?: (event: any) => void) {

        const response = await Promise.all(files.map(async (file, index) => {
            return post(`/arquivo/upload`, {
                'modulo': `${modulo}`,
                'idReferencia': `${idReferencia}`,
                'nome': file.name,
                'tipo': file.typo,
                'tamanho': file.size,
                'Body': new Buffer(await this.blobToBase64(file) as string, 'base64')
            });
        }));
        return response[0];
    }

    async blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve((reader.result as string).split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    downloadStream(filename) {
        return download(`/arquivos/${filename}`)
            .then(response => {
                return new File([response.data], filename);
                //Buffer.from(response.data.data, 'binary').toString('base64')
            });
    }

    download(filename, fromRoot = false) {
        return download(`/arquivos/${filename}${fromRoot ? '?download-from-root=true' : ''}`);
    }

    delete(filename) {
        return del(`/arquivos/${filename}`);
    }

    async getList(id_referencia: number, modulo: EAnexoModulos) {
        let retorno = await get(`/arquivos/${modulo}/${id_referencia}`);
        if (retorno.success) { return retorno.data; }
        return undefined;
    }
}

export default new UploadFilesService();
