import FilterQuantity from "../../../components/filter/FilterQuantity";
import { Divider, Grid, Paper, Button} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import Search from "../../../components/search";
import useStyles from "../../../components/layout/Styles";
import { usePallet } from "../../../contexts/PalletContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IListInfo } from '../../../models/IListInfo';
import IAService from "../../../services/IAService";
import EmptyError from '../../../components/EmptyError';
import PaginationSaveState from '../../../components/PaginationSaveState';
import { useNavigate } from "react-router-dom";
import TemaCard from "./TemaCard";
import TemasRelacionadosMenuFiltro from "./TemasRelacionadosMenuFiltro";
import { IFilterData } from "../../../components/filter/ListFilter";
import { arraysEquals } from "../../../utils/array.utils";
import AccordionTreinamentoIALista from "../../inteligencia_artificial/_components/AccordionTreinamentoIALista";
import TreinamentoIACadastrar from "./TreinamentoIACadastrar";
import { useAuthorization } from "../../../contexts/AuthorizationContext";
import { useAlert } from "../../../contexts/AlertContext";

//## StyledCompenents
const FilterArea = styled(Paper)`
  width: 100%;
  /* height: 55px; */
  margin-top: 16px;
  margin-bottom: 10px;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffff;
  border-radius: 0px 0px 0px 0px; 

  .filterTitle {
    display: none;
  }
`;

const ContainerHead = styled(Grid)`
 margin-bottom: 10px;
 margin-left: 0px;

 .MuiOutlinedInput-root { 
   background: #F9F9F9 !important;
   border: 0px !important;
 }
 fieldset {
   border-color: #F9F9F9 !important;
 }
`;

const ContainerFilter = styled(Grid)`
 /* width: 10%; */
 width: 100%;
 margin-bottom: 5px;
`;

const ContainerBody = styled(Grid)`
 justify-content: ${isMobile ? "center" : "space-between"};
 flex-direction: column;
 padding: 20px 40px;
`;

const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
`;

const ContainerFooter = styled(Grid)`
 margin-top: 40px;
 margin-bottom: 10px;
 padding-bottom: 50px;
 justify-content: center;

 button.Mui-selected {
   background-color: #4B1196;
   color: #F2F2F2;

   &:hover {
    background-color: #4B1196;
    color: #F2F2F2;
    opacity: 0.7;
   }
 }
`;

export default function IATreinamentoList() {
    const { pallet } = usePallet();
    const navigate = useNavigate();

    //## Paginação
    const [infoList, setInfoList] = useState<IListInfo>({
        pageIndex: 1,
        pageSize: 8,
        pageItens: 0,
        totalItens: 0,
        totalPages: 0,
    });
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filter, setFilter] = useState({ key: "visualizacao", label: "Todos", value: "T" });
    const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);

    const filterName = 'TreinamentoIAListCurrentPage';
    const { NewAlert } = useAlert();

    //## Dados
    const [listaTreinamento, setListaTreinamento] = useState<Array<any>>();
    const [descricaoParcial, setDescricaoParcial] = useState<string>("");

    useEffect(() => {
        if (descricaoParcial && descricaoParcial.trim().length > 2) {
            IAService.getTreinamentoList(currentPageSize, currentPage, JSON.stringify([...currentFilter, { key: 'descricaoP', label: "descricaoP", value: descricaoParcial }]))
                .then(async res => {
                    setListaTreinamento(res.data);
                    setInfoList(res.listInfo)
                });
        } else if (descricaoParcial === "") {
            loadTreinamentoList();
        }
    }, [descricaoParcial]);

    useEffect(() => {
        if (currentPage > 0) loadTreinamentoList();
    }, [currentPage, currentPageSize, currentFilter]);

    const loadTreinamentoList = () => {
        IAService.getTreinamentoList(currentPageSize, currentPage, JSON.stringify(currentFilter)) // force break
            .then(async (res) => {
                await setListaTreinamento(res.data);
                setInfoList(res.listInfo)
            });
    };

    const { user } = useAuthorization();

    return (
        <>
            <ContainerHead container>
                <Grid
                    style={{
                        width: isMobile ? "100%" : "100%",
                    }}>
                    <Grid container
                        spacing={0}
                        direction="row"
                        justifyContent={"space-between"}
                        style={{
                            padding: `${isMobile ? '10px' : '30px'}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                        }}
                    >
                        <Grid item style={{ marginTop: '7px', color: `${pallet.backgroundColorPrimary}` }}>
                            <Grid container>
                                <Grid style={{ fontSize: '18px', fontWeight: pallet.general.weight_bold }}>
                                    {infoList.totalItens}
                                </Grid>
                                <Grid style={{ marginLeft: `${isMobile ? '5px' : '5px'}`, fontSize: '14px', marginTop: '4px' }}>
                                    {infoList.totalItens > 1 ? 'Contextos' : 'Contexto'}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <TreinamentoIACadastrar
                            reloadList={(loadTreinamentoList)}
                            idPermissao={user.id_permissao}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid style={{ width: "100%", marginBottom: "30px" }}>
                    <Divider />
                </Grid>
                <Grid style={{ width: "100%", marginTop: "0px 40px", marginLeft: `${isMobile ? "10px" : "40px"}`, marginRight: `${isMobile ? "10px" : "40px"}` }}>
                    <Search
                        onChange={setDescricaoParcial}
                        placeholder="Buscar"
                    />
                </Grid>
            </ContainerHead>
            <Grid style={{ paddingTop: `${isMobile ? "5px" : "5px"}`, paddingLeft: `${isMobile ? "10px" : "40px"}`, paddingRight: `${isMobile ? "10px" : "40px"}`, paddingBottom: `${isMobile ? "10px" : "40px"}` }}>
                {listaTreinamento?.map((item, index) => {
                    return (
                        <Grid style={{ marginTop: '20px' }} key={item.id}>
                            <AccordionTreinamentoIALista
                                id={item.id}
                                onClick={(loadTreinamentoList)}
                                item={item}
                                idPermissao={user.id_permissao}                       />
                        </Grid>
                    )
                })}
                {/* {listaTreinamento} */}
            </Grid>
            <ContainerFooter container>
                <PaginationSaveState
                    name={filterName}
                    pages={infoList?.totalPages}
                    page={currentPage}
                    onChange={setCurrentPage}
                />
            </ContainerFooter>
        </>
    )
}