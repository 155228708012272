import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, Divider, ListItemText, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { usePallet } from "../../../../contexts/PalletContext";
import InputDatePicker from '../../../../components/InputDatePicker';
import InputMultilineTextField from '../../../../components/InputMultilineTextField';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
import SolidButton from '../../../../components/SolidButton';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import ProposicaoAcontecimentosService from '../../../../services/ProposicaoAcontecimentosService';
import { useAlert } from '../../../../contexts/AlertContext';
import ProposicaoAcontecimentosCreateSchema from '../../models/ProposicaoAcontecimentosCreateSchema';
import RoomIcon from '@mui/icons-material/Room';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputTextField from '../../../../components/InputTextField';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { formatDate } from '../../../../utils/dateUtils';
import { format } from 'date-fns';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import TabelasService from '../../../../services/TabelasService';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CustomizedDialogs(props: {
    idAcontecimento: number
    reloadList: () => void
    closeMenu: () => void
    idProposicao: number
}) {
    const { NewAlert } = useAlert();
    const [open, setOpen] = React.useState(false);
    const { pallet } = usePallet();
    const [dateAcontecimentos, setDateAcontecimentos] = React.useState();
    const [dataAcontecimentosAgenda,setDataAcontecimentosAgenda] = React.useState();
    const [descricao, setDescricao] = React.useState("");
    const [onSave, setOnSave] = React.useState(false)
    const [idRef, setIdRef] = React.useState()
    const [idAgRef, setIdAgRef] = React.useState<string>()
    const [data, setData] = React.useState({});
    const [horas,setHoras] = React.useState<string>();
    const [local, setLocal] = React.useState<string>();
    const [error, setError] = React.useState<Array<{ name: string, err: string }>>([]);
    const [poder, setPoder] = React.useState("");
    const [acao, setAcao] = React.useState(0);
    const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
        setDataAcontecimentosAgenda(dataAcEve)

    };
    const [listaAcoes, setLista] = React.useState<Array<any>>([]);

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const mascaraTempo = (value) => {
        return value
        .replace(/\D/g,"")
        .replace(/(\d{2})(\d)/, "$1:$2")
     };

     function addingtHours(date, hours) {
        date.setHours(date.getHours() + hours);
      
        return date;
      }
    

    const handleClickOpen = async (id) => {
        TabelasService.getAcontecimentoStatus().then(async (res) => {
            const data = [];
            data.push({ key: "", label: "-- Selecione -- ", value: null });
            res?.map((item) =>
              data.push({
                key: item.descricao,
                label: item.descricao,
                value: item.id,
              })
            );
            setLista(() => [...data]);
          });

        await ProposicaoAcontecimentosService.Get(id).then(res => {  
            setIdAgRef(res?.data[0].acontecimentos_id_agenda)
            setIdRef(res?.data[0].acontecimentos_id)
            setDateAcontecimentos(res?.data[0].acontecimentos_data_acontecimento)
            setDescricao(res?.data[0].acontecimentos_acontecimento)
            setAcao(res?.data[0].status)
            setPoder(res?.data[0].poder)
            setDataAcontecimentosAgenda(res?.data[0].acontecimentos_meetingTime ? res?.data[0]?.acontecimentos_meetingTime : "")
            //setHoras(res?.data[0].acontecimentos_meetingTime ? formatDate(addingtHours(new Date(res?.data[0]?.acontecimentos_meetingTime), 3),"HH:mm") : "")
            setHoras(res?.data[0].acontecimentos_meetingTime ? formatDate(new Date(res?.data[0]?.acontecimentos_meetingTime),"HH:mm") : "")
            setLocal(res?.data[0].agenda_local)
        });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        // props?.reloadList()
        props?.closeMenu()
    };


    const handleDateAcontecimentosChange = (dateAc) => {
        setDateAcontecimentos(dateAc)
    };

    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };

    async function handleOnAfterUpload() {
        if (idRef) {
            setIdRef(undefined);
            setOnSave(false)
        }
    }

    const handleAcaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcao(Number(event.target.value))
    };

    const handleHortaAcontecimentosAgendaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const newTime = mascaraTempo(event.target.value)

        
        setHoras(newTime)
        if (newTime.split(":")[0] > 24) {
            setHoras("24:")
        }else if( newTime.split(":")[1] > 59) {
            setHoras(newTime.split(":")[0] + ":59")
        }
    }

    const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocal(event.target.value)
    }

    const salvarEdicaoAcontecimentos = async (e) => {
        setOnSave(true)
        e.preventDefault();
        const data = {
            date: dateAcontecimentos,
            descricao: descricao,
            acao: acao,
            poder:poder,
            evento: {
                agenda_id: idAgRef,
                data_agendamento:  dataAcontecimentosAgenda ? format(new Date(dataAcontecimentosAgenda), "yyyy-MM-dd") : "",
                hora_agendamento: horas,
                local_agendamento: local,
              },

        }
       
        
        setError(() => [])
        data && ProposicaoAcontecimentosCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoAcontecimentosCreateSchema.validate(data).catch(function (err) {
        });

        if (ProposicaoAcontecimentosCreateSchema.isValidSync(data)) {
            await ProposicaoAcontecimentosService.Update(data, props?.idProposicao, props?.idAcontecimento,'p')
                .then(res => {                    
                    if (res) {
                        NewAlert('success', 'Acontecimento editado com sucesso')
                        setTimeout(() => {
                            
                            handleClose()
                            props?.reloadList()
                            props?.closeMenu()
                        }, 2000);

                    } else {
                        NewAlert('error', 'Erro ao editar, verifique os campos e salve novamente');
                    }
                })
            // .then(x => loadAcontecimentos(props.id_Proposicao))
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
        }

    }

    const { user } = useAuthorization();

    const handleChangePoderLegislativo =  () => {
        setPoder("Legislativo")
};


const handleChangePoderExecutivo =  () => {
setPoder("Executivo")
};

const Legislativo = () => {
    return (
        <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}` }}>
            Legislativo
        </div>
    )
}

const Executivo = () => {
    return (
        <div style={{ fontFamily: `${pallet.general.fontFamily}`, fontSize: `${pallet.general.size14}` }}>
            Executivo
        </div>
    )
}

    return (
        <div>
            <Button onClick={() => {user.id_permissao !== 3 ?

                                        handleClickOpen(props?.idAcontecimento)

                                        :

                                        NewAlert(
                                            "error",
                                            "Não é possível realizar está ação com este perfil."
                                        )
                                        
                                    }}
                style={{
                    marginLeft: "-7px",
                    fontFamily: `${pallet.fontFamily.general}`,
                    fontWeight: pallet.fontWeight.button,
                    fontSize: `${pallet.general.size14}`,
                    color: `${pallet.color.tertiary.font}`,
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    paddingRight:"85px",
                    marginRight: "-10px"
                }}>
                <EditIcon  style={{
                            color: `${pallet.color.tertiary.font}`,
                            marginRight: "16px",
                            fontFamily: `${pallet.fontFamily.general}`,
                        }} />
                <ListItemText>@</ListItemText>
            Editar
            </Button>
            <BootstrapDialog
                id={`dialog-${props?.idAcontecimento}`}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Grid style={{
                        fontFamily: `${pallet.fontFamily}`,
                        fontSize: `${pallet.general.size18}`,
                        color: `${pallet.textColorSecondary}`,
                        padding: "12px",
                        marginTop: "7px",
                    }}>
                        EDITAR ATIVIDADE
                    </Grid>
                </BootstrapDialogTitle>

                <DialogContent dividers style={{ padding: "0px" }} >
                    <Grid style={{ padding: "30px 25px 30px 25px" }}>
                        <Grid item lg={4} xs={12}>
                            <InputDatePicker
                                // defaultValue={dataAcontecimentos?.acontecimentos_data_acontecimento}
                                onChange={handleDateAcontecimentosChange}
                                label={"DATA"}
                                inputFormat={"dd/MM/yyyy"}
                                value={dateAcontecimentos}
                                id={"dataAcontecimentos-proposicao"}
                            />
                        </Grid>
                        <Grid item style={{ marginTop: "20px" }} lg={12} xs={12}>
                            <InputMultilineTextField
                                // defaultValue={dataAcontecimentos?.acontecimentos_acontecimento}
                                type={"text"}
                                maxRows={4}
                                value={descricao}
                                onChange={handleDescricaoChange}
                                id={"descricao-proposicao"}
                                label="DESCRIÇÃO"
                            />
                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                <ErrorMessage name='descricao' errors={error} />
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: "20px" }} lg={6} xs={12}>
                                        <RadioGroup
                                        aria-label="gender"
                                        defaultValue="tudo"
                                        name="radio-buttons-group"
                                        >
                                            <Grid style={{display:"flex",flexDirection:"row"}}>
                                                <Grid >
                                                    <FormControlLabel  value='Legislativo' control={<Radio checked={poder === 'Legislativo' ? true : false} onClick={handleChangePoderLegislativo} style={{ color: `${pallet.backgroundColorPrimary}` }} />} label={<Legislativo />} />
                                                </Grid>
                                                <Grid>
                                                    <FormControlLabel  value='Executivo' control={<Radio checked={poder === 'Executivo' ? true : false} onClick={handleChangePoderExecutivo} style={{ color: `${pallet.backgroundColorPrimary}` }} />} label={<Executivo />} />
                                                </Grid>
                                            </Grid>
                                           </RadioGroup>
                                    </Grid>

                                  
                                <Grid item style={{ marginTop: "20px" }} lg={6} xs={12}>

                                    <SelectTextFieldA
                                        value={acao}
                                        onChange={handleAcaoChange}
                                        options={listaAcoes}
                                        label={"ESCOLHA A AÇÃO"}
                                        id={"acao"}
                                        />
                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                        <ErrorMessage name='id_responsavel' errors={error} />
                                    </Grid>
                                </Grid>
                    </Grid>

                    <Grid style={{ paddingBottom: "10px" }}>
                        <Divider />
                    </Grid>
                    <Grid container style={{fontFamily:pallet.fontFamily.general, padding: "25px 20px 20px 40px" }}>
                                <Grid item lg={12} xs={12}  style={{fontSize:pallet.general.size12,fontWeight:pallet.general.weight_600}}>
                                    AGENDA
                                </Grid>
                                <Grid item lg={12} xs={12}  style={{fontSize:pallet.general.size12}}>
                                    Crie um evento na agenda para este registro.
                                </Grid>
                                <Grid style={{marginTop:"20px"}} item container>
                                    <Grid  item lg={6} xs={12}>
                                    <Grid style={{width:"100%", paddingRight:`${isMobile ? "0px" : "20px"}`}}>
                                            <InputDatePicker
                                                onChange={handleDataAcontecimentosAgendaChange}
                                                label={"DATA"}
                                                inputFormat={"dd/MM/yyyy"}
                                                startAdornment={<CalendarTodayIcon/>}
                                                value={dataAcontecimentosAgenda}
                                                defaultValue={null}
                                                id={"dataAcontecimentosAgenda-proposicao"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Grid style={{width:"100%", paddingLeft:`${isMobile ? "0px" : "20px"}`,marginTop: `${isMobile ? "20px" : "0px"}`}}>
                                        <InputTextField
                                                        type={"text"}
                                                        value={horas}
                                                        name="horarioEvento"
                                                        onChange={handleHortaAcontecimentosAgendaChange}
                                                        id={"horario-evento"}
                                                        label={"HORARIO DO EVENTO"}
                                                        maxLenght={5}
                                                        startAdornment={<AccessAlarmIcon/>}
                                                    />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid style={{marginTop:"20px"}} item lg={12} xs={12}>
                                 <InputTextField
                                                    type={"text"}
                                                    value={local}
                                                    name="local"
                                                    onChange={handleChangeLocal}
                                                    id={"local-evento"}
                                                    label={"LOCAL"}
                                                    startAdornment={<RoomIcon/>}
                                                />
                                </Grid>


                            </Grid>
                    <Grid style={{ padding: "20px 18px 20px 18px" }}>
                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                            <Grid style={{
                                fontWeight: pallet.fontWeight.subtitle,
                                fontSize: `${pallet.general.size12}`,
                                color: `${pallet.color.tertiary.font}`,
                                marginLeft: "9px"
                            }}>
                                ANEXOS
                            </Grid>

                            <Grid className="container">
                                <AnexosComponent
                                    label=''
                                    modulo={EAnexoModulos.Acontecimentos}
                                    id_referencia={idRef}
                                    canEdit={true}
                                    autosave={false}
                                    save={onSave}
                                    onAfterUpload={handleOnAfterUpload}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{
                    padding: "30px",
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`
                }}>
                    <SolidButton
                        onClick={salvarEdicaoAcontecimentos}
                        onChange={props?.reloadList}
                        color={`${pallet.textColorPrimary}`}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                    />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}