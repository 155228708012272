import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorMessage from "../../../components/ErrorMessage";

import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import SelectTextField from "../../../components/SelectTextField";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import { IProposicao } from "../../../models/Proposicao.Model";
import { usePallet } from "../../../contexts/PalletContext";
import useStyles from "../../../components/layout/Styles";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import PieChartInfoInsight from "./PieChartInfoInsight";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import { formatDate } from "../../../utils/dateUtils";

export function AccordionContentKey6(props: {
  proposicao?: IProposicao;
  expandido?: string;
  dtIni?: Date,
  dtFin?: Date,
  esfera?: string
  onChange?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const [grupoItens, setGrupoItens] = React.useState([]);
  const [temaItens, setTemaItens] = React.useState([]);
  const [subTemaItens, setSubTemaItens] = React.useState([]);
  const [topicoItens, setTopicoItens] = React.useState([]);
  const [grupo, setGrupo] = React.useState<string>('');
  const [grupoLabel, setGrupoLabel] = React.useState<string>();
  const [labelItens, setLabelItens] = React.useState<string>();
  const [tema, setTema] = React.useState<string>('');
  const [temaLabel, setTemaLabel] = React.useState<string>();
  const [subtema, setSubtema] = React.useState<string>('');
  const [subtemaLabel, setSubtemaLabel] = React.useState<string>();
  const [listaGrupos, setListaGrupos] = React.useState([]);
  const [listaTemas, setListaTemas] = React.useState([]);
  const [listaSubtemas, setListaSubtemas] = React.useState([]);
  const [listaTopicos, setListaTopicos] = React.useState([]);
  const [topico, setTopico] = React.useState<string>('');
  const [topicoLabel, setTopicoLabel] = React.useState<string>();
  const [altaG, setAltaG] = useState<number>();
  const [maximaG, setMaximaG] = useState<number>();
  const [mediaG, setMediaG] = useState<number>();
  const [baixaG, setBaixaG] = useState<number>();
  const [minimaG, setMinimaG] = useState<number>();
  const [naoClassificadoG, setNaoClassificadoG] = useState<number>();

  const [altaT, setAltaT] = useState<number>();
  const [maximaT, setMaximaT] = useState<number>();
  const [mediaT, setMediaT] = useState<number>();
  const [baixaT, setBaixaT] = useState<number>();
  const [minimaT, setMinimaT] = useState<number>();
  const [naoClassificadoT, setNaoClassificadoT] = useState<number>();

  const [altaS, setAltaS] = useState<number>();
  const [maximaS, setMaximaS] = useState<number>();
  const [mediaS, setMediaS] = useState<number>();
  const [baixaS, setBaixaS] = useState<number>();
  const [minimaS, setMinimaS] = useState<number>();
  const [naoClassificadoS, setNaoClassificadoS] = useState<number>();

  const [altaTop, setAltaTop] = useState<number>();
  const [maximaTop, setMaximaTop] = useState<number>();
  const [mediaTop, setMediaTop] = useState<number>();
  const [baixaTop, setBaixaTop] = useState<number>();
  const [minimaTop, setMinimaTop] = useState<number>();
  const [naoClassificadoTop, setNaoClassificadoTop] = useState<number>();

  // Monta itens do select com o retorno da query
  const getItens = (
    data: { id: number; descricao: string }[]
  ): { value: string; label: string }[] => {
    const ret = data?.map((item) => {
      return {
        value: String(item.id),
        label: item.descricao,
      };
    });
    return ret;
  };

  React.useEffect(() => {
    TabelasService.getProposicaoGrupoTemas().then((res) => {
      const data = getItens(res);
      setGrupoItens(data);
    });
  }, []);

  React.useEffect(() => {
    if (grupo) {
      TabelasService.getProposicaoTemasByGrupo(Number(grupo)).then((res) => {
        const data = getItens(res);
        setTemaItens(data);
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getProposicaoSubTemasByTema(Number(tema)).then((res) => {
        const data = getItens(res);
        setSubTemaItens(data);
      });
    }
  }, [tema]);


  React.useEffect(() => {
    if (subtema) {
      TabelasService.getProposicaoTopicosBySubtema(Number(subtema)).then((res) => {
        const data = getItens(res);
        setTopicoItens(data);
      });
    }
  }, [subtema]);

  const onChangeGrupo = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string,
  ) => {
    if (id) {
      setGrupo(event.target.value);
      setTema("");
      setSubtema("");
      setTopico("");
      setListaTemas([])
      setListaSubtemas([])
      setListaTopicos([])
    }
  };

  const onChangeTema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setTema(event.target.value);
      setSubtema("");
      setListaSubtemas([])
    }
  };

  const onChangeSubtema = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setSubtema(event.target.value);
    }
  };

  const onChangeTopico = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (id) {
      setTopico(event.target.value);
    }
  };

  React.useEffect(() => {
    if (grupo) {
      TabelasService.getDescricaoGrupos(Number(grupo)).then((res) => {
        if (res) setGrupoLabel(res[0].descricao)
      });
    }
  }, [grupo]);

  React.useEffect(() => {
    if (tema) {
      TabelasService.getDescricaoTemas(Number(tema)).then((res) => {
        if (res) setTemaLabel(res[0].descricao)
      });
    }
  }, [tema]);

  React.useEffect(() => {
    if (subtema) {
      TabelasService.getDescricaoSubtemas(Number(subtema)).then((res) => {
        if (res) setSubtemaLabel(res[0].descricao)
      });
    }
  }, [subtema]);

  React.useEffect(() => {
    if (topico) {
      TabelasService.getDescricaoSubtemas(Number(topico)).then((res) => {
        if (res) setTopicoLabel(res[0].descricao)
      });
    }
  }, [topico]);



  React.useEffect(() => {
    if (grupo) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey30Grupo(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(grupo)
        ).then((res) => {
          if (res) {
            setAltaG(res.data.data.alta)
            setMaximaG(res.data.data.maxima)
            setMediaG(res.data.data.media)
            setBaixaG(res.data.data.baixa)
            setMinimaG(res.data.data.minima)
            setNaoClassificadoG(res.data.data.naoClassificado)
          }
        });
      }
    }
    if (tema) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey30Tema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(tema)
        ).then((res) => {
          if (res) {
            setAltaT(res.data.data.alta)
            setMaximaT(res.data.data.maxima)
            setMediaT(res.data.data.media)
            setBaixaT(res.data.data.baixa)
            setMinimaT(res.data.data.minima)
            setNaoClassificadoT(res.data.data.naoClassificado)

          }
        });
      }
    }
    if (subtema) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey30Subtema(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(subtema)
        ).then((res) => {
          if (res) {
            setAltaS(res.data.data.alta)
            setMaximaS(res.data.data.maxima)
            setMediaS(res.data.data.media)
            setBaixaS(res.data.data.baixa)
            setMinimaS(res.data.data.minima)
            setNaoClassificadoS(res.data.data.naoClassificado)
          }
        });
      }
    }

    if (topico) {
      if (String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== '0' && String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== '0') {
        InsightService.GetKey30Topico(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"), Number(topico)
        ).then((res) => {
          if (res) {
            setAltaTop(res.data.data.alta)
            setMaximaTop(res.data.data.maxima)
            setMediaTop(res.data.data.media)
            setBaixaTop(res.data.data.baixa)
            setMinimaTop(res.data.data.minima)
            setNaoClassificadoTop(res.data.data.naoClassificado)
          }
        });
      }
    }
  }, [props.dtIni, props.dtFin, grupo, tema, subtema,topico]);


  return (
    <Grid>
      <Grid container style={{ padding: "40px 15px 10px 15px", justifyContent: "space-around" }}>
        <Grid style={{ padding: `${isMobile ? "0px 0px 10px 0px" : "6px 30px 10px 6px"}` }} item md={4} xs={12} lg={4}>
          <SelectTextFieldA
            name="grupo-select"
            label="GRUPO"
            options={grupoItens}
            value={grupo}
            onChange={onChangeGrupo}
            focused={true}
            placeholder={"Selecione"} id={"GRUPO"} />
        </Grid>

        {
          <>
            {grupo ?
              <Grid style={{ padding: `${isMobile ? "0px 0px 10px 0px" : "6px 30px 30px 0px"}` }} item md={4} xs={12} lg={4}>
                <SelectTextFieldA
                  name="tema-select"
                  label="TEMA"
                  options={temaItens}
                  value={tema}
                  onChange={onChangeTema}
                  focused={true}
                  placeholder={"Selecione"}
                  id={"TEMA"}
                />
              </Grid>
              : null
            }
          </>
        }

        {
          <>
            {tema ?
              <Grid style={{ padding: `${isMobile ? "0px" : "6px 6px 30px 0px"}` }} item md={4} xs={12} lg={4}>
                <SelectTextFieldA
                  name="subtema-select"
                  label="SUBTEMA"
                  options={subTemaItens}
                  value={subtema}
                  focused={true}
                  placeholder={"Selecione"}
                  onChange={onChangeSubtema}
                  id={"SUBTEMA"}

                />
              </Grid>
              : null
            }
          </>
        }


{
          <>
            {subtema ?
              <Grid style={{ padding: `${isMobile ? "0px" : "6px 6px 30px 0px"}` }} item md={4} xs={12} lg={4}>
                <SelectTextFieldA
                  name="topico-select"
                  label="TOPICO"
                  options={topicoItens}
                  value={topico}
                  focused={true}
                  placeholder={"Selecione"}
                  onChange={onChangeTopico}
                  id={"TOPICO"}

                />
              </Grid>
              : null
            }
          </>
        }

        


      </Grid>
      <Grid style={{ marginBottom: "32px" }}>
        <Divider variant="fullWidth" />
      </Grid>

      {
        grupo ?
          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{
              margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
            }}
          >

            <PieChartInfoInsight
              title="GRUPO"
              subtitle={grupoLabel}
              labels={[
                {
                  key: 'Prioridade Máxima',
                  data: maximaG,
                  color: `${pallet.charts.red}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]',
                },
                {
                  key: 'Prioridade Alta',
                  data: altaG,
                  color: `${pallet.charts.purple}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]',
                },
                {
                  key: 'Prioridade Média',
                  data: mediaG,
                  color: `${pallet.charts.yellow}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]',
                },
                {
                  key: 'Prioridade baixa',
                  data: baixaG,
                  color: `${pallet.charts.blue}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]',
                },
                {
                  key: 'Prioridade Mínima',
                  data: minimaG,
                  color: `${pallet.charts.green}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]',

                },
                {
                  key: 'Não Classificado',
                  data: naoClassificadoG,
                  color: `${pallet.color.charts.lightGrey}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"grupos","label":"GRUPO: ' + grupoLabel + '","value":"' + grupo + '"},{"key":"prioridade","label":"PRIORIDADE: Não Classificado","value":0}]',

                },
              ]}
            />
          </Grid>
          :
          null
      }

      {
        tema ?
          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{
              margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
            }}
          >
            <PieChartInfoInsight
              title="TEMA"
              subtitle={temaLabel}
              labels={[
                {
                  key: 'Prioridade Máxima',
                  data: maximaT,
                  color: `${pallet.charts.red}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]',
                },
                {
                  key: 'Prioridade Alta',
                  data: altaT,
                  color: `${pallet.charts.purple}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]',
                },
                {
                  key: 'Prioridade Média',
                  data: mediaT,
                  color: `${pallet.charts.yellow}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]',
                },
                {
                  key: 'Prioridade baixa',
                  data: baixaT,
                  color: `${pallet.charts.blue}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]',
                },
                {
                  key: 'Prioridade Mínima',
                  data: minimaT,
                  color: `${pallet.charts.green}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]',

                },
                {
                  key: 'Não Classificado',
                  data: naoClassificadoT,
                  color: `${pallet.color.charts.lightGrey}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"temas","label":"TEMA: ' + temaLabel + '","value":"' + tema + '"},{"key":"prioridade","label":"PRIORIDADE: Não Classificado","value":0}]',

                },
              ]}
            />
          </Grid>
          : null}

      {
        subtema ?
          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{
              margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
            }}
          >
            <PieChartInfoInsight
              title="SUBTEMA"
              subtitle={subtemaLabel}
              labels={[
                {
                  key: 'Prioridade Máxima',
                  data: maximaS,
                  color: `${pallet.charts.red}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]',
                },
                {
                  key: 'Prioridade Alta',
                  data: altaS,
                  color: `${pallet.charts.purple}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]',
                },
                {
                  key: 'Prioridade Média',
                  data: mediaS,
                  color: `${pallet.charts.yellow}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]',
                },
                {
                  key: 'Prioridade baixa',
                  data: baixaS,
                  color: `${pallet.charts.blue}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]',
                },
                {
                  key: 'Prioridade Mínima',
                  data: minimaS,
                  color: `${pallet.charts.green}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]',
                },
                {
                  key: 'Não Classificado',
                  data: naoClassificadoS,
                  color: `${pallet.color.charts.lightGrey}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Não Classificado","value":0}]',
                },
              ]}
            />
          </Grid>
          : null
      }


{
        topico ?
          <Grid
            item
            xl={12}
            lg={12}
            xs={12}
            style={{
              margin: `${isMobile ? "10px 10px 10px 10px" : "10px 30px 32px 30px"}`,
            }}
          >
            <PieChartInfoInsight
              title="TOPICO"
              subtitle={topicoLabel}
              labels={[
                {
                  key: 'Prioridade Máxima',
                  data: maximaTop,
                  color: `${pallet.charts.red}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Máxima","value":5}]',
                },
                {
                  key: 'Prioridade Alta',
                  data: altaTop,
                  color: `${pallet.charts.purple}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Alta","value":4}]',
                },
                {
                  key: 'Prioridade Média',
                  data: mediaTop,
                  color: `${pallet.charts.yellow}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Média","value":3}]',
                },
                {
                  key: 'Prioridade baixa',
                  data: baixaTop,
                  color: `${pallet.charts.blue}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Baixa","value":2}]',
                },
                {
                  key: 'Prioridade Mínima',
                  data: minimaTop,
                  color: `${pallet.charts.green}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Mínima","value":1}]',
                },
                {
                  key: 'Não Classificado',
                  data: naoClassificadoTop,
                  color: `${pallet.color.charts.lightGrey}`,
                  url: '/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Estadual","value":"Estadual"},{"key":"emTramitacao","label":"EM TRAMITAÇÃO: Sim","value":true},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dataIni","label":"DATA APRESENTAÇÃO INICIAL: ' +
                    formatDate(props.dtIni, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtIni, "MM/dd/yyyy 00:00:00") +
                    '"},{"key":"dataFin","label":"DATA APRESENTAÇÃO FINAL: ' +
                    formatDate(props.dtFin, "dd/MM/yyyy") +
                    '","value" : "' +
                    formatDate(props.dtFin, "MM/dd/yyyy 23:59:59") +
                    '"},{"key":"subtemas","label":"SUBTEMA: ' + subtemaLabel + '","value":"' + subtema + '"},{"key":"prioridade","label":"PRIORIDADE: Não Classificado","value":0}]',
                },
              ]}
            />
          </Grid>
          : null
      }
    </Grid>
  );
}

export default AccordionContentKey6;
