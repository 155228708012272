import { Divider, Grid } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ComboBox from "../../../components/ComboBox";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import { IFilterData } from "../../../components/filter/ListFilter";
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";
import React, { useEffect, useState } from "react";
import PieChartInfoCard from "../../../components/PieChartInfoCard";
import PieChartInfoInsight from "./PieChartInfoInsight";
import InsightService from "../../../services/InsightService";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/dateUtils";
export function AccordionContentKey1(props: {
  // filtros?: IFiltroTipoProps[],
  titleFederal?: string;
  originFederal?: string;
  dtIni?: Date,
  dtFin?: Date
  esfera?: string,
  onChange?: (event: any) => void;
}) {
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };
  const tagDiscursoCor = (value: string) => {
    if (value) {
      value = value.toLowerCase();
    }
    switch (value) {
      case "divergente":
        return `${pallet.charts.red}`;
      case "convergente":
        return `${pallet.charts.green}`;
      case "neutro":
        return `${pallet.charts.lightGrey}`;
      default:
        return `${pallet.charts.blue}`;
    }
  }
  const [casa, setCasa] = React.useState<number>(undefined);
  const [casaLabel, setCasaLabel] = React.useState<string>("");
  const [casaItens, setCasaFederalItens] = React.useState<Array<IFilterData>>([
    { key: "0", label: "Todas ", value: "0" },
    { key: "1", label: "Senado Federal", value: "1" },
    { key: "2", label: "Câmara dos Deputados", value: "2" },
    // { key: "3", label: "Congresso Nacional", value: "3" },
  ]);
  const [ambitoLabel, setAmbitoLabel] = React.useState<string>("");
  const [ambito, setAmbito] = React.useState<string>("");
  const [ambitoItens, setAmbitoItens] = React.useState<Array<IFilterData>>([]);
  const [listaInterlocutores, setListaInterlocutores] = React.useState([]);
  const [seguida, setSeguida] = React.useState<string>("sim");
  const [seguidaItens, setSeguidaItens] = React.useState<Array<IFilterData>>([
    { key: "0", label: "Selecione ", value: "" },
    { key: "1", label: "Seguida ", value: "sim" },
    { key: "2", label: "Não seguida ", value: "nao" }
  ]);
  const [error, setError] = useState<Array<{ name: string; err: string }>>([]);
  const { pallet } = usePallet();
  const [currentFilter, setCurrentFilter] = useState<IFilterData[]>([]);
  const navigate = useNavigate();
  const handleCasa = (
    event?: React.ChangeEvent<HTMLInputElement>,
    id?: number
  ) => {
    setCasa(Number(event.target.value));
    handleCasaLabel(casa);
    setAmbito("");
    setSeguida("");
  };
  const handleCasaLabel = (casa: number) => {
    switch (casa) {
      case 1:
        return "Senado Federal";
      case 2:
        return "Câmara dos Deputados";
      case 3:
        return "Congresso Nacional";
      default:
        return "Todas";
    }
  };
  const handleSeguidaOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeguida(event.target.value);
  };
  const handleAmbitoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmbito(event.target.value);
    setSeguida("");
  };
  const esferaConv = (value: any) => {
    switch (value) {
      case "F":
        return 1;
      case "E":
        return 2;
      case "M":
        return 3;
    }
  };
  React.useEffect(() => {
    if (casa) {
      TabelasService.getTabelaAmbitosTipo(casa).then(async (res) => {
        const data = [];
        data.push({ key: "", label: "-- Selecione -- ", value: "" });
        res?.map((item) =>
          data.push({
            key: item.id,
            label: item.sigla + " - " + item.resumo,
            value: item.sigla
          })
        );
        setAmbitoItens(() => [...data]);
      });
    }
  }, [casa]);
  const handleSiglaCasaLabel = (casa: number) => {
    switch (casa) {
      case 1:
        return "SF";
      case 2:
        return "CD";
      case 3:
        return "CN";
      default:
        return "Todas";
    }
  };
  React.useEffect(() => {
    if (ambito && seguida !== null) {
      if (
        String(format(new Date(props.dtIni), "yyyy")).slice(0, 1) !== "0" &&
        String(format(new Date(props.dtFin), "yyyy")).slice(0, 1) !== "0"
      ) {
        InsightService.GetKey1(
          format(new Date(props.dtIni), "yyyy-MM-dd"),
          format(new Date(props.dtFin), "yyyy-MM-dd 23:59:00.000"),
          ambito,
          Number(casa),
          seguida
        ).then((res) => {
          if (res) {
            setListaInterlocutores(res.data);
          }
        });
      }
    }
  }, [casa, ambito, seguida, props.dtIni, props.dtFin]);
  const ChipStyle = styled(Grid)`
    background-color: ${(props => props.pallet.backGroundPageColorSecondary)};
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 10px;
    border: 1px solid ${(props => props.pallet.general.color4)};
    color: ${(props => props.pallet.general.color4)};
    padding: 4px 6px 4px 6px;
    line-height: 12px;
    letter-spacing: 1px;
    width: 'auto'; 
`;
  const DotStyle = styled.div`
    background-color: ${(props => props.bg)};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 5px;
    margin-top: 3px;
`;
  const Title = styled(Grid)`
    padding-left:  ${isMobile ? '5px' : '24px'};
    padding-top: ${isMobile ? '5px' : '30px'} ;
    background-color: ${pallet.backGroundPageColorPrimary};
    text-transform: uppercase;
    font-size:${pallet.general.size12};
    color: ${pallet.textColorQuaternary};
    font-family: ${pallet.general.fontFamily};
    font-weight: ${pallet.general.weight_600};
    height: 79px;
`;
  return (
    <>
      <Grid>
        <Grid
          container
          style={{
            justifyContent: casa > 0 && ambito !== "" ? "space-around" : "unset",
            placeContent: casa !== undefined && ambito === "" ? "space-evenly" : "space-around",
            marginLeft: `${isMobile ? "0px" : casa !== 0 ? "0px" : "0px"}`,
            paddingTop: `${isMobile ? "40px" : "30px"}`,
            paddingBottom: `${isMobile ? "20px" : "30px"}`,
            paddingLeft: `${isMobile ? "10px" : "0px"}`,
            paddingRight: `${isMobile ? "10px" : "0"}`,
          }}
        >
          {/* // loading ? null : */}
          <Grid
            item
            md={3}
            xs={12}
            lg={3}
            style={{ paddingBottom: `${isMobile ? "20px" : "0px"}` }}
          >
            <SelectTextFieldA
              name={"casa-federal"}
              value={casa}
              onChange={handleCasa}
              options={casaItens}
              label={"CASA LEGISLATIVA"}
              id={"casa-federal"}
              focused={true}
              placeholder={"Selecione"}
            />
            <ErrorMessage name="casaFederal" errors={error} />
          </Grid>
          {
            <>
              {casa ?
                <Grid item md={4} xs={12} lg={4}>
                  <SelectTextFieldA
                    name={"ambito"}
                    value={ambito}
                    onChange={handleAmbitoOnChange}
                    options={ambitoItens}
                    label={"ÂMBITO"}
                    id={"ambito"}
                    focused={true}
                    placeholder={"Selecione"}
                  />
                  <ErrorMessage name="ambito" errors={error} />
                </Grid>
                : null
              }
            </>
          }
          {ambito && (
            <>
              <Grid item md={3} xs={12} lg={3}
                style={{ paddingTop: `${isMobile ? "20px" : "0px"}` }}
              >
                <SelectTextFieldA
                  name={"seguida"}
                  value={seguida}
                  onChange={handleSeguidaOnChange}
                  options={seguidaItens}
                  label={"FILTRAR PROPOSIÇÕES"}
                  id={"proposicoes"}
                  focused={true}
                  placeholder={"Selecione"}
                />
                <ErrorMessage name="proposicoes" errors={error} />
              </Grid>
            </>
          )}
        </Grid>
        <Grid style={{ marginBottom: "32px" }}>
          <Divider variant="fullWidth" />
        </Grid>
        {/* {
          proposicao && (
            <> */}
        <Grid container style={{ padding: `${isMobile ? "3px 10px 10px 10px" : "3px 30px 30px 30px"}` }} >
          <Title style={{
            borderTop: '1px solid #EAEAEA',
            borderLeft: '1px solid #EAEAEA',
            borderBottom: '1px solid #EAEAEA',
            borderRight: `${isMobile ? '1px solid #EAEAEA' : 0}`,
            borderRadius: '6px 0px 0px 0px'
          }}
            item lg={6} xs={6}>
            INTERLOCUTORES
          </Title>
          <Title item lg={6} xs={6}
            style={{
              borderTop: '1px solid #EAEAEA',
              borderRight: '1px solid #EAEAEA',
              borderBottom: '1px solid #EAEAEA',
              borderRadius: '0px 6px 0px 0px'
            }}>
            PROPOSIÇÕES
          </Title>
          <Grid item container style={{ maxHeight: "400px", overflowY: "auto" }}>
            {
              listaInterlocutores.length > 0 && seguida !== "" ?
                listaInterlocutores.map((x, i) => {
                  return (
                    <>
                      <Grid lg={6} xs={6} style={{
                        paddingLeft: `${isMobile ? "5px" : "24px"}`,
                        paddingTop: `${isMobile ? "5px" : "15px"}`,
                        borderLeft: '1px solid #EAEAEA',
                        borderRight: '1px solid #EAEAEA',
                        borderBottom: '1px solid #EAEAEA',
                        borderRadius: i + 1 !== listaInterlocutores.length ? null : '0px 0px 0px 6px',
                        maxWidth: 'none',
                        wordBreak: 'break-word',
                      }}>
                        <Grid item lg={6} xs={6}
                          onClick={() => navigate('/interlocutor/' + x.id_parlamentar)}
                          style={{
                            paddingBottom: '5px',
                            color: `${pallet.textColorTertiary}`,
                            fontFamily: `${pallet.general.fontFamily}`,
                            fontSize: `${pallet.general.size14}`,
                            fontWeight: pallet.general.weight_600,
                            cursor: "pointer",
                            maxWidth: 'none'
                          }}
                        >
                          {x.nome + " (" + x.siglaPartido + " - " + x.ufExercicio + ")"}
                        </Grid>
                        <Grid container style={{ paddingBottom: '10px' }}
                        >
                          <ChipStyle pallet={pallet}>
                            <Grid container >
                              <DotStyle bg={tagDiscursoCor(x.qualificacao)}>
                              </DotStyle>
                              <Grid >
                                {x.qualificacao}
                              </Grid>
                            </Grid>
                          </ChipStyle>
                        </Grid>
                      </Grid>
                      <Grid item lg={6} xs={6}
                        onClick={seguida === 'sim' ? () => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Seguidas","value":"Seguidas"},{"key":"dtIniRel","label":"DATA RELATORIA INICIAL: ' +
                          formatDate(props.dtIni, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtIni, "MM/dd/yyyy") +
                          '"},{"key":"dtFinRel","label":"DATA RELATORIA FINAL: ' +
                          formatDate(props.dtFin, "dd/MM/yyyy") +
                          '","value" : "' +
                          formatDate(props.dtFin, "MM/dd/yyyy") +
                          '"},{"key":"ambitoR","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"},{"key":"relator","label":"RELATOR: ' + x.nome + '","value":"' + x.id_parlamentar + '"}]', "_blank")
                          :
                          () => window.open('/proposicao?filtro=[{"key":"esfera","label":"ESFERA: Federal","value":"Federal"},{"key":"visualizacao","label":"VISUALIZAÇÃO: Não Seguidas","value":"NSeguidas"},{"key":"dtIniRel","label":"DATA RELATORIA INICIAL: ' +
                            formatDate(props.dtIni, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtIni, "MM/dd/yyyy") +
                            '"},{"key":"dtFinRel","label":"DATA RELATORIA FINAL: ' +
                            formatDate(props.dtFin, "dd/MM/yyyy") +
                            '","value" : "' +
                            formatDate(props.dtFin, "MM/dd/yyyy") +
                            '"},{"key":"ambitoR","label":"AMBITO: ' + ambito + '","value":"' + ambito + '"},{"key":"relator","label":"RELATOR: ' + x.nome + '","value":"' + x.id_parlamentar + '"}]', "_blank")
                        }
                        style={{
                          paddingLeft: `${isMobile ? "5px" : "24px"}`,
                          paddingTop: `${isMobile ? "5px" : "15px"}`,
                          borderRight: '1px solid #EAEAEA',
                          borderBottom: '1px solid #EAEAEA',
                          borderRadius: i + 1 !== listaInterlocutores.length ? null : '0px 0px 6px 0px',
                          color: `${pallet.textColorTertiary}`,
                          fontFamily: `${pallet.general.fontFamily}`,
                          fontSize: `${pallet.general.size14}`,
                          fontWeight: pallet.general.weight_600,
                          cursor: "pointer",
                        }}>
                        {x.count}
                      </Grid>
                    </>
                  );
                })
                :
                null
            }
          </Grid>
        </Grid>
        {/* </>
          )} */}
      </Grid>
    </>
  );
}
export default AccordionContentKey1;