import * as http from '../helpers/http';

class ProposicaoAcaoInfluenciaNotaTecnicaService {

    GetList = async (id: number, pageIndex: number, pageSize: number = 8): Promise<any> => {
        const result = await http.get(`/proposicao/acao-preparatoria/${id}/nota-tecnica?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        if (result.success) {
            return {
                success: true,
                listInfo: {
                    pageSize: result.data.pageSize,
                    pageIndex: result.data.pageIndex,
                    pageItens: result.data.pageItens,
                    totalItens: result.data.totalItens,
                    totalPages: result.data.totalPages
                },
                lista: result.data.data,
                listaII: result.data.dataII


            }
        }
        return undefined;
    }
    Update = async (data: any, id: number,): Promise<boolean> => {
        const result = await http.put(`/proposicao/acao-preparatoria/nota-tecnica/${id}`, data);
        if (result.success) { return result.success }
        return false;
    };

    Delete = async (id: number): Promise<boolean> => {
        const result = await http.del(`/proposicao/acao-preparatoria/nota-tecnica/${id}`);
        if (result.success) { return true }
        return false;
    };



}
export default new ProposicaoAcaoInfluenciaNotaTecnicaService();
