import { Button, Icon, Grid, Alert } from "@mui/material";
import React from "react";
import { usePallet } from "../contexts/PalletContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { isMobile } from "react-device-detect";
import WarningIcon from "@mui/icons-material/Warning";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

export default function GridAlerta(props: { descricao: string, tipo?: any }) {
  const { pallet } = usePallet();

  return (
    <Alert
      variant="outlined"
      sx={{
        width: "100%",
        color: `${props.tipo === "error" ? "red" : props.tipo === "alert" ? "yellow" : "#228B22"}`,
        height:isMobile ? "85px" : "70px",
        display: "flex",
        alignItems: "center",
        fontWeight: pallet.general.weight_500,
        justifyContent: "center",
        backgroundColor: pallet.backGroundPageColorPrimary
      }}
      icon={props.tipo === "error" ? <WarningIcon /> : <ThumbUpAltIcon />}
      severity={props.tipo}
    >
      {props.descricao}
    </Alert>
  );
}
